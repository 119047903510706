import {
  Autocomplete,
  Button,
  Grid,
  Paper,
  styled,
  TableContainer,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Backdrop,
  Stack,
  CircularProgress,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../components/Maincard";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DownloadIcon from "@mui/icons-material/Download";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function AdvisorTargetRevenue() {
  const userRole = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const [branchList, setBranchList] = useState([]);
  const [advisorList, setAdvisorList] = useState([]);
  const [advisorName, setAdvisorName] = useState([
    { user_id: "all", first_name: "Select All" },
  ]);
  const [branchName, setBranchName] = useState([
    { id: "all", branch_name: "Select All" },
  ]);
  const [selectedYear, setSelectedYear] = useState({ year: "2024" });
  const [targetData, setTargetTData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBranchId, setSelectedBranchId] = useState(["all"]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();
          setBranchList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const [allBranchList, setAllBranchlist] = useState([
    { id: "all", branch_name: "Select All" },
    ...(branchList || []),
  ]);

  useEffect(() => {
    if (Array.isArray(branchList)) {
      setAllBranchlist([
        { id: "all", branch_name: "Select All" },
        ...branchList,
      ]);
    }
  }, [branchList]);

  const handleBranchChange = (event, value) => {
    console.log("valueis", value);

    if (value.some((item) => item.id === "all")) {
      // If "Select All" is selected, set all branches
      setBranchName([{ id: "all", branch_name: "Select All" }]);
      setSelectedBranchId(value.map((i) => i.id));
    } else {
      // If individual branches are selected, remove "Select All"
      setBranchName(value);
      setSelectedBranchId(value.map((i) => i.id));
    }
  };

  console.log("allBranchList", allBranchList);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}advisor`);
        if (response.ok) {
          const data = await response.json();

          setAdvisorList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const [allAdvisorList, setAllAdvisorList] = useState([
    { user_id: "all", first_name: "Select All" },
    ...(advisorList || []),
  ]);

  useEffect(() => {
    if (Array.isArray(advisorList)) {
      setAllAdvisorList([
        { user_id: "all", first_name: "Select All" },
        ...advisorList,
      ]);
    }
  }, [advisorList]);

  const handleUserChange = (event, value) => {
    setAdvisorName(value);

    if (value.some((item) => item.user_id === "all")) {
      // If "Select All" is selected, set all branches
      setAdvisorName([{ user_id: "all", first_name: "Select All" }]);
    } else {
      // If individual branches are selected, remove "Select All"
      setAdvisorName(value);
    }
  };

  // Select Advisor Automatic base on Branch
  useEffect(() => {
    if (!Array.isArray(advisorList)) {
      console.error("advisorList is not an array:", advisorList);
      return;
    }

    if (selectedBranchId.includes("all")) {
      setAdvisorName([{ first_name: "Select All", user_id: "all" }]);
    } else {
      const branchViseAdvisor = advisorList.filter((advisor) =>
        selectedBranchId.includes(advisor.branch_id)
      );
      setAdvisorName(branchViseAdvisor);
    }
  }, [selectedBranchId, advisorList]);

  const years = Array.from({ length: 5 }, (_, index) => ({
    year: new Date().getFullYear() - 3 + index,
  }));

  console.log(years);

  const allYearList = [{ year: "Select All" }, ...years];
  const handleselectedYear = async (value) => {
    setSelectedYear(value);
  };

  const selectedAdvisor = advisorName.map((i) => i.user_id);
  const handleSearchData = async (event) => {
    setLoading(true);

    const requestData = {
      user_id: parseInt(userId),
      year: selectedYear?.year,
      report_for:
        userRole === "center_admin" && selectedAdvisor.includes("all")
          ? "all"
          : userRole === "center_admin" && !selectedAdvisor.includes("all")
          ? selectedAdvisor
          : null,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}advisor-rev-target/getyeartargetsandrevenuecollected`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        // const reversedData = result.slice().reverse();
        setTargetTData(result);
        setLoading(false);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        showSnackbar("Please select user", "error");
        setTargetTData([]);
        setLoading(false);
      } else if (response.status === 440) {
        navigate("/page440");
      } else if (response.status === 400) {
        showSnackbar("Some fields are missing", "error");
        setTargetTData([]);
        setLoading(false);
      } else {
        navigate("/page500");
      }
    } catch (error) {
      navigate("/page500");
    }
  };

  useEffect(() => {
    handleSearchData();
  }, []);

  const handleclear = () => {
    setBranchName([]);
    setSelectedBranchId([]);
    setAdvisorName([]);
    setTargetTData([]);
    setSelectedYear(null);
  };

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    marginTop: "20px",
    "& .MuiTableCell-head": {
      backgroundColor: "#abf7b1",
      fontWeight: 600,
      padding: "8px 16px",
      fontSize: "17px",
      color: "#000",
      textAlign: "center",
      fontFamily: "Poppins",
    },
    "& .MuiTableCell-body": {
      textAlign: "center",
      padding: "8px 16px",
      overflow: "hidden", // Hide overflowing content
      textOverflow: "ellipsis", // Show ellipsis when content overflows

      whiteSpace: "normal", // Allow text to wrap inside the cell
      wordWrap: "break-word",
    },
  }));

  const determineStatus = (row, currentMonth, currentYear) => {
    if (row?.targetRevenue === row?.collectedRevenue) {
      return "Achieved";
    } else if (
      currentMonth === row?.month &&
      row?.targetRevenue > row?.collectedRevenue
    ) {
      return "In Progress";
    } else if (
      currentMonth > row?.month &&
      row?.targetRevenue > row?.collectedRevenue
    ) {
      return "Incompleted";
    } else if (row?.year === currentYear + 1) {
      return "Upcoming";
    }
    return "Not Given"; // This case should rarely occur.
  };

  const getStatusChipProps = (status) => {
    switch (status) {
      case "Achieved":
        return { label: "Achieved", color: "success", variant: "contained" };
      case "Incompleted":
        return { label: "Incomplete", color: "error", variant: "contained" };
      case "In Progress":
        return { label: "In-Progress", color: "warning", variant: "contained" };
      case "Upcoming":
        return { label: "Upcoming", color: "primary", variant: "contained" };
      default:
        return { label: "Not Given", color: "default", variant: "contained" };
    }
  };

console.log("advisorName",advisorName)
  const handleViewReport = () => {
    const url = `/advisor-target-report-pdf`;
    window.open(url, "_blank");
  


    const targetDataObject = {
      targetData,
      advisorName,
      selectedYear,
    };
    localStorage.setItem("targetdata", JSON.stringify(targetDataObject));
  };

  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Stack direction="row" spacing={1}>
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={800}
            marginRight={2}
          >
            Fetching
          </Typography>
          <CircularProgress color="primary" />
        </Stack>
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={6} lg={6} sm={6} md={6}>
          <Paper
            sx={{
              width: "200px",
              fontSize: "22px",
              fontWeight: 600,
              height: "50px",
              borderRadius: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="/rb_25962 1.png"
              alt="img"
              style={{ marginRight: "5px", width: "40px" }}
            />
            {userRole === "center_admin" ? "Advisor Target" : "Your Target"}
          </Paper>
        </Grid>{
          userRole==="center_admin" && (

        
        <Grid item xs={6} textAlign="end">
          <Button
            size="small"
            variant="contained"
            color="secondary"
            style={{ borderRadius: "25px" }}
            startIcon={<DownloadIcon />}
            onClick={handleViewReport}
          >
            Download Report
          </Button>
        </Grid>
          )
        }
        <Grid item xs={12}>
          <MainCard>
            <Grid container spacing={2}>
              {userRole === "center_admin" && (
                <>
                  <Grid item xs={12} sm={3}>
                    <Typography>Select Branch</Typography>
                    <Autocomplete
                      size="small"
                      value={branchName}
                      multiple
                      onChange={handleBranchChange}
                      options={allBranchList}
                      getOptionLabel={(option) =>
                        option.id === "all"
                          ? "All Selected"
                          : option.branch_name
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "14px" }, // Add borderRadius here
                          }}
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderOption={(props, option, { selected }) => (
                        <li
                          {...props}
                          style={{
                            backgroundColor: selected ? "#e0f7fa" : "inherit",
                          }}
                        >
                          {option.branch_name}
                        </li>
                      )}
                    />
                  </Grid>

                  {/* <Grid item xs={12} sm={3}>
                    <Typography>Select Advisor</Typography>
                    <Autocomplete
                      size="small"
                      value={advisorName}
                      multiple
                      onChange={handleUserChange}
                      options={allAdvisorList}
                      getOptionLabel={(option) =>
                        `${option.first_name ? option.first_name : ""} ${
                          option.last_name ? option.last_name : ""
                        }`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "14px" }, // Add borderRadius here
                          }}
                        />
                      )}
                    />
                  </Grid> */}

                  <Grid item xs={12} sm={3}>
                    <Typography>Select Advisor</Typography>
                    <Autocomplete
                      size="small"
                      value={advisorName}
                      multiple
                      onChange={handleUserChange}
                      options={allAdvisorList}
                      getOptionLabel={(option) =>
                        option.user_id === "all"
                          ? "All Selected"
                          : `${option.first_name ? option.first_name : ""} ${
                              option.last_name ? option.last_name : ""
                            }`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "14px" }, // Add borderRadius here
                          }}
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.user_id === value.user_id
                      }
                      renderOption={(props, option, { selected }) => {
                        console.log("selected", selected);
                        return (
                          <li
                            {...props}
                            style={{
                              backgroundColor: selected ? "#e0f7fa" : "inherit",
                            }}
                          >
                            {option.first_name} {option.last_name}
                          </li>
                        );
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={3}>
                <Typography>Select Year</Typography>
                <Autocomplete
                  value={selectedYear}
                  onChange={(event, value) => handleselectedYear(value)}
                  size="small"
                  options={years}
                  getOptionLabel={(option) => option.year}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "15px" }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <Typography sx={{ visibility: "hidden" }}>
                  Spacing purpose
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  style={{ borderRadius: "25px" }}
                  onClick={handleSearchData}
                  startIcon={<SearchIcon />}
                >
                  Search
                </Button>

                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  style={{ borderRadius: "25px", marginLeft: "10px" }}
                  onClick={handleclear}
                  startIcon={<ClearIcon />}
                >
                  Clear
                </Button>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>

        <Grid item xs={12}>
          <MainCard>
            <StyledTableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650, tableLayout: "fixed" }}
                aria-label="simple table"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Month</TableCell>
                    <TableCell sx={{}}>Target</TableCell>
                    <TableCell sx={{}}>Collected</TableCell>
                    <TableCell>Achieved Percentage</TableCell>
                    <TableCell sx={{}}>Status</TableCell>
                  </TableRow>
                </TableHead>

                {targetData?.length === 0 ? (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5">No data available</Typography>
                  </Grid>
                ) : (
                  targetData?.map((row, index) => {
                    const currentDate = new Date();
                    const currentMonth = currentDate.getMonth() + 1;
                    const currentYear = currentDate.getFullYear();
                    console.log("currentMonth", currentYear + 1);
                    // let status = "";
                    // if (
                    //   currentMonth === row?.month &&
                    //   row?.targetRevenue > row?.collectedRevenue
                    // ) {
                    //   status = "In Process";
                    // } else if (
                    //   currentMonth > row?.month &&
                    //   row?.targetRevenue > row?.collectedRevenue
                    // ) {
                    //   status = "Incompleted";
                    // } else if (row?.targetRevenue === row?.collectedRevenue) {
                    //   status = "Completed";
                    // }
                    // if (row?.year === currentYear + 1) {
                    //   status = "Upcomming";
                    // } else {
                    //   status = "Not Given";
                    // }
                    const status = determineStatus(
                      row,
                      currentMonth,
                      currentYear
                    );

                    return (
                      <TableBody>
                        <TableRow
                          key={row.name}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#f0f0f0" : "inherit",
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ borderLeft: "0.1px solid #C3C3C3" }}
                          >
                            {row?.monthName}
                          </TableCell>
                          <TableCell>
                            {row.targetRevenue === "No Target Found"
                              ? "N/A"
                              : `${row?.targetRevenue}/-`}{" "}
                          </TableCell>
                          <TableCell>{row?.collectedRevenue} /-</TableCell>

                          <TableCell>
                            {row?.percentageAchieved !== "N/A"
                              ? `${row?.percentageAchieved} %`
                              : row?.percentageAchieved}
                          </TableCell>
                          <TableCell
                            sx={{ borderRight: "0.1px solid #C3C3C3" }}
                          >
                            {" "}
                            <Chip
                              size="small"
                              {...getStatusChipProps(status)}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })
                )}
              </Table>
            </StyledTableContainer>
          </MainCard>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
