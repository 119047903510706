import React, { useEffect, useState } from "react";
import MainCard from "../../components/Maincard";
import { Box, Grid, LinearProgress, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InfoIcon from "@mui/icons-material/Info";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
function LinearProgressWithLabel({ collected, target }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <Tooltip title={`Collected: ₹${collected}`} arrow>
          <LinearProgress
            variant="determinate"
            color="secondary"
            value={(collected / target) * 100}
            sx={{
              height: 10,
              borderRadius: 5,
              backgroundColor: (theme) => theme.palette.grey[300],
            }}
          />
        </Tooltip>
      </Box>
      <Box sx={{ minWidth: 100 }}>
        {/* Display collected and target revenue */}
        <Typography  sx={{fontSize:'14px', fontWeight:600}}>
          {" "}
          {collected === 0 && target === 0
            ? "No Target"
            : collected === 0 && target !== 0
            ? `₹${collected} / ${target}`
            : `₹${target}`}
        </Typography>
      </Box>
    </Box>
  );
}

export default function TargetRevenueCard() {
  const [collectedRevenue, setCollectedRevenue] = useState(0);
  const [targetRevenue, setTargetRevenue] = useState(1); // Default to 1 to avoid division by zero
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();

  const currentDate = new Date();
  const currentMonthName = currentDate.toLocaleString("default", {
    month: "long",
  });

  console.log(currentMonthName); // e.g., "December"

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}advisor-rev-target/getadvtargetandcollectedrevenue/${userId}`
        );
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);

          setCollectedRevenue(data?.collectedRevenue);
          setTargetRevenue(data?.targetRevenue);
        } else if (response.status === 404) {
          setCollectedRevenue(0);
          setTargetRevenue(0);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  console.log();

  const handleView = () => {
    navigate("/advisor-target");
  };

  return (
    <>
      <MainCard>
        <Grid container spacing={1}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item container xs={6} sm={7} alignItems="center">
              <img
                src="/rb_25962 1.png"
                alt="img"
                style={{ marginRight: "5px", width: "40px" }}
              />
              <Typography variant="h4">Your Target </Typography>
              <Tooltip title={`Your Target For ${currentMonthName} month`} arrow>
                <InfoOutlinedIcon
                  fontSize="small"
                  style={{
                    fill: "#C0C0C0",
                    marginLeft: "5px",
                    cursor: "pointer",
                 
                  }}
                />
              </Tooltip>
            </Grid>

            <Grid item xs={6} sm={5} textAlign="end">
              <Typography
                style={{ color: "#17CE62", cursor: "pointer" }}
                onClick={handleView}
                variant="h6"
              >
                View Your Target
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <LinearProgressWithLabel
              collected={collectedRevenue}
              target={targetRevenue}
            />
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
}
