// Author : Ayesha Shaikh
// Date : 23 Aug 2024
import {
  Autocomplete,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Collapse,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import MainCard from "../../../components/Maincard";
import { useGetTokenOrLogout } from "../../../utils/token";
import { useNavigate } from "react-router-dom";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function DemoFilter({
  demos,
  setDemos,
  setIsLoading,
  showSnackbar,
  originalDemo,
  setPage,
  setSearchDemos,
}) {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0];
  const theme = useTheme();
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [deliverymodename, setdeliverymodename] = useState([]);
  const [branch, setBranch] = useState([]);

  const [branchlist, setBranchlist] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [trainernames, settrainername] = useState([]);
  const [selectedBranchId, setSelectedBranchId] = useState([]);
  const [statusname, setstatusname] = useState([]);
  const [testTypelist, settestTypeList] = useState([]);
  const [testTypename, setTestTypename] = useState([]);
  const [createdByList, setcreatedByList] = useState([]);
  const [createdBy, setCreatedBy] = useState([]);
  const [branchLoading, setBranchLoading] = useState(false);

  console.log("trainers", trainers);
  const Deliverymode = [
    {
      name: "Online",
      value: "online",
    },
    {
      name: "Offline",
      value: "offline",
    },
  ];
  const handleDrawerOpen = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const newDeliveryMode = deliverymodename.map((i) => i.value);
  const newbranchname = branch.map((i) => i.id);
  const newTrainername = trainernames.map((i) => i.id);
  const newStatus = statusname.map((i) => i.value);

  const handleApplyFilter = async () => {
    setPage(0);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const payload = {
      from_date: startDate || null,
      to_date: endDate || null,
      status: newStatus,
      branch_id: newbranchname,
      trainer_id: newTrainername,
      test_type_id: testTypename.map((i) => i.id),
      mode_of_delivery: newDeliveryMode,
      user_id: parseInt(userId),
      created_by: createdBy.map((i) => i.id),
    };

    try {
      setIsLoading(true);
      const response = await fetch(`${API_ENDPOINT}route/demofilter`, {
        method: "POST",
        headers,
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();

        setDemos(data);
        setSearchDemos(data);

        setIsLoading(false);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      showSnackbar("Error Fetching Data", "error");
      setDemos([]);
      navigate("/page500");

      setIsLoading(false);
    }
  };

  const resetFilter = () => {
    setPage(0);
  

    setBranch([]);
    settrainername([]);
    setdeliverymodename([]);
    setstatusname([]);

   
    setTestTypename([]);
    setDemos(originalDemo);
    setSearchDemos(originalDemo);
    setSelectedBranchId([]);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handelstartdate = (e) => {
    setStartDate(e.target.value);
    console.log(e.target.value);
  };

  const handeltodate = (e) => {
    setEndDate(e.target.value);
    console.log(e.target.value);
  };

  const deliverymodechange = (event, value) => {
    setdeliverymodename(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalltesttype`);
        if (response.ok) {
          const data = await response.json();

          settestTypeList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handletestTypeChange = (event, value) => {
    setTestTypename(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setBranchlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allBranchList = [{ branch_name: "Select All" }, ...branchlist];

  const handelbranchchange = (event, value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchlist);
      const ids = branchlist.map((i) => i.id);
      setSelectedBranchId(ids);
    } else {
      setBranch(value);
      const ids = value.map((i) => i.id);
      setSelectedBranchId(ids);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}trainer`);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setTrainers(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const Status = [
    { name: "Completed", value: "completed" },
    { name: "Pending", value: "pending" },
    { name: "Confirmed", value: "confirmed" },
    { name: "Missed", value: "missed" },
    { name: "Cancelled", value: "cancelled" },
  ];

  const handleStatusChange = (event, value) => {
    setstatusname(value);
  };

  const handleOwnerNameChange = (event, value) => {
    settrainername(value);
  };

  const handleCancel = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setcreatedByList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleCreatedBy = (event, value) => {
    setCreatedBy(value);
  };

  // Add default 1 month start and end date
  const formatDate = (date) => date.toISOString().split("T")[0];
  useEffect(() => {
    let today = new Date();
    let oneMonthAgo = new Date();
    oneMonthAgo.setDate(oneMonthAgo.getDate() - 30);

    setEndDate(formatDate(today));
    setStartDate(formatDate(oneMonthAgo));
  }, []);
  return (
    <>
      {/* <Tooltip title="Filter" placement="left">
        <ButtonBase sx={{ mt: 0.5 }} onClick={handleDrawerOpen}>
          <FilterAltOutlinedIcon
            color={theme.palette.secondary.main}
            aria-label="Filter"
            sx={{ fontSize: 32 }}
          />
        </ButtonBase>
      </Tooltip> */}

      <MainCard>
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              marginLeft: "5px",
              fontSize: "24px",
              fontWeight: 700,
            }}
          >
            Demos List
          </Typography>
          <FilterListOutlinedIcon
            onClick={handleDrawerOpen}
            fontSize="medium"
          />
        </Stack>

        <Collapse in={isDrawerOpen} sx={{ marginTop: "5px" }}>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} lg={12} md={12} sm={12}>
          <Typography sx={{fontWeight:600}} >Enquiry Name </Typography>
            <TextField
            size="small"
              sx={{ width: "100%" }}
          
             
              type="text"
              value={trainerFilter}
              onChange={handeltrainername}
              InputProps={{
                style: { borderRadius: "15px" },
              }}
            />
          </Grid> */}

            <Grid item xs={12} lg={3} md={3} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>Demo From Date </Typography>
              <TextField
                size="small"
                sx={{ mb: 1, width: "100%" }}
                type="date"
                value={startDate}
                onChange={handelstartdate}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={3} md={3} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>Demo To Date </Typography>
              <TextField
                size="small"
                sx={{ mb: 1, width: "100%" }}
                type="date"
                value={endDate}
                onChange={handeltodate}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
              />
            </Grid>

            <Grid item xs={12} lg={3} md={3} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>Test Type</Typography>

              <Autocomplete
                size="small"
                fullWidth
                value={testTypename}
                multiple
                onChange={handletestTypeChange}
                options={testTypelist}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            {(userRole === "center_admin" ||
              userRole === "advisor" ||
              userRole === "data_entry" ||
              userRole === "admin") && (
              <>
                <Grid item xs={12} lg={3} md={3} sm={6}>
                  <Typography sx={{ fontWeight: 600 }}>Branch</Typography>

                  <Autocomplete
                    size="small"
                    fullWidth
                    value={branch}
                    multiple
                    onChange={handelbranchchange}
                    options={allBranchList}
                    getOptionLabel={(option) => option.branch_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} lg={3} md={3} sm={6}>
                  <Typography sx={{ fontWeight: 600 }}>Trainer</Typography>

                  <Autocomplete
                    size="small"
                    value={trainernames}
                    noOptionsText={
                      branchLoading
                        ? "Loading..."
                        : selectedBranchId.length === 0
                        ? "Select Branch First"
                        : "No trainer available for this branch "
                    }
                    multiple
                    onChange={handleOwnerNameChange}
                    options={trainers}
                    getOptionLabel={(option) =>
                      `${option.first_name ? option.first_name : ""} ${
                        option.last_name ? option.last_name : ""
                      }`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} lg={3} md={3} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>Delivery Mode </Typography>

              <Autocomplete
                size="small"
                value={deliverymodename}
                multiple
                onChange={deliverymodechange}
                options={Deliverymode}
                getOptionLabel={(option) => option.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={3} md={3} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>Status </Typography>

              <Autocomplete
                size="small"
                value={statusname}
                multiple
                onChange={handleStatusChange}
                options={Status}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            {(userRole === "center_admin" ||
              userRole === "data_entry" ||
              userRole === "trainer" ||
              userRole === "admin") && (
              <Grid item xs={12} lg={3} md={3} sm={6}>
                <Typography sx={{ fontWeight: 600 }}>Created By </Typography>

                <Autocomplete
                  size="small"
                  value={createdBy}
                  multiple
                  onChange={handleCreatedBy}
                  options={createdByList}
                  getOptionLabel={(option) =>
                    `${option.first_name ? option.first_name : " "} ${
                      option.last_name ? option.last_name : ""
                    }  `
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "15px" }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} lg={12} md={12} textAlign="end">
              <Button
                variant="contained"
                style={{
                  background: "#4242f5",
                  width: "150px",
                  marginRight: "7px",
                  borderRadius: "25px",
                }}
                onClick={handleApplyFilter}
              >
                {" "}
                Apply Filter
              </Button>
              <Button
                variant="contained"
                style={{
                  background: "#f7690a",
                  width: "150px",
                  marginRight: "7px",
                  borderRadius: "25px",
                }}
                onClick={handleCancel}
              >
                {" "}
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{ width: "150px", borderRadius: "25px" }}
                onClick={resetFilter}
              >
                {" "}
                Reset Filter
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </MainCard>
    </>
  );
}
