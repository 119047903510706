
import { Box, Button, CardContent, Chip, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { gridSpacing } from '../../../store/constant';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useGetTokenOrLogout } from '../../../../utils/token';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

const Card = styled("div")({
    backgroundColor: `rgba(255, 255, 255, 1)`,
    boxShadow: `0px 0px 15px rgba(0, 0, 0, 0.05)`,
    borderRadius: `34px`,
    width: '100%', // Set the width to 90% of the parent container
    height: '100%', // Automatically adjust the height based on content
    margin: '0 auto', // Center the card horizontally
    padding: '10px',
  });

  const Icon = styled("img")({
    display: `flex`,
    //position: `absolute`,
    //isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: `18px`,
    height: `18px`,
  });


function ProfileCard() {
  const navigate=useNavigate()
  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem('userId');
  const userRole = localStorage.getItem('userRole');
  

  const [profileData, setProfileData] = useState({})

  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
};

useEffect(() => {
  const token = getTokenOrLogout();
    if (!token) return;
    let headers = { 
      Authorization: `Bearer ${token}`,
    
    };

  const fetchData = async () => {
    try {
      const response = await fetch(`${API_ENDPOINT}employee/${userId}`,{headers});
      if (response.ok) {
        const data = await response.json();
        console.log("data", data);

        setProfileData(data);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
} catch (error) {
 navigate("/page500");
}
  };

  fetchData();
}, []);

  console.log(profileData);

  const handleEdit = () => {
    const newState = { employeedetails: profileData };
    localStorage.setItem("employeeState", JSON.stringify(newState));

    const url = `/view-profile/${profileData?.id}`;
    const newTab = window.open(url, "_blank");

    if (!newTab) {
      navigate(url, { state: newState });
    }
  };

  const { first_name, last_name, email, image, branch, mobile, role} = profileData || {};


  return (
    <Card >
    <CardContent>
        <Grid container spacing={2}>
        {/*<Grid item xs={4}>
        <Box>
    <Avatar {...stringAvatar(advisorData)} sx={{height: '70px', width: '70px', backgroundColor: 'rgba(219, 246, 255, 1)'}}/>
    </Box>
  </Grid>*/}
    <Grid item xs={12}>
   
      
      <Typography variant='h4'>
      {first_name ? capitalizeFirstLetter(first_name) : ''}{' '}
      {last_name ? capitalizeFirstLetter(last_name) : ''}{' '}
      <Chip size='small' label={userRole || ''} color="primary" />
      </Typography>
        <Box xs={12} mt={1} sx={{display: 'flex'}} >
            <Icon alt='email-icon' sx={{height: '25px', width: '25px'}} src={'/wetransfer_add-enquiry-svg_2023-07-25_1047/mail.svg'}></Icon>
      <Typography variant='h6' sx={{ letterSpacing: '0.25px', overflowWrap: 'break-word', wordWrap: 'break-word', marginLeft: '8px' }}>
       {email || ''}
      </Typography>
      </Box>
      <Box sx={{display: 'flex'}} mt={1}>
          <Icon alt='call-icon' margin={2} sx={{height: '25px', width: '25px'}} src={'/wetransfer_add-enquiry-svg_2023-07-25_1047/Phone.svg'}></Icon>
      <Typography variant='h6' sx={{ letterSpacing: '0.25px', marginLeft: '8px' }}>
      {mobile || ''}
      </Typography>
      </Box>
      <Box sx={{display: 'flex'}} mt={1}>
            <Icon alt='location-icon' sx={{height: '25px', width: '25px'}} src={'/wetransfer_add-enquiry-svg_2023-07-25_1047/Location.svg'}></Icon>
      <Typography variant='h6' sx={{ letterSpacing: '0.25px', marginLeft: '8px' }}>
      { branch?.branch_name|| ''}
      </Typography>
      </Box>

      <Grid item xs={12} textAlign='end'>
        <Button size='small' variant='contained'   onClick={handleEdit}>Edit My Profile</Button>
      </Grid>


   
      

      </Grid>
      </Grid>
    </CardContent>
  </Card>
  )
}

export default ProfileCard