import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../components/ui-component/card/MainCard";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import RegisterSvoForm from "./TaskAssignForm";
import CustomPopup from "../../../components/CustomPopup";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { fDate, fDateTime } from "../../../utils/formatTime";
import AddSvo from "./AddSvo";

import AssignToAdivosor from "./AssignToAdvisorForm";
import TaskAssignForm from "./TaskAssignForm";
import NoenquiryForm from "../../components/DataTransferComp.jsx/NoenquiryForm";

export default function RegisterSvo() {
  const userId = localStorage.getItem("userId");
  console.log(userId);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const navigate = useNavigate();

  const [openpopup, setOpenpopup] = useState(false);
  const [openAddsvoPopup, setOpenAddsvoPopup] = useState(false);
  const [openAssignPopup, setOpenAssignPopup] = useState(false);
  const [registerdata, setRegisterdate] = useState([]);
  const [svoPageData, setSvoPageData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [svooriginalData, setsvoOriginalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedid, setSelectedid] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [selectedCriteria, setSelectedCriteria] = useState("website");

  console.log(selectedid);

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handelopen = (rowId) => {
    setSelectedid([rowId]);
    setOpenpopup(true);
    // Pass the row ID to the RegisterSvoForm component
    console.log("Row ID:", rowId);
  };

  const handelclose = () => {
    setOpenpopup(false);
  };

  const handelopenAssign = (rowId) => {
    setSelectedid([rowId]);
    setOpenAssignPopup(true);
    // Pass the row ID to the RegisterSvoForm component
    console.log("Row ID:", rowId);
  };

  const handelcloseAssign = () => {
    setOpenAssignPopup(false);
    setSelectedid([]);
  };

  const handelopenAddsvo = (row) => {
    setSelectedRowData(row);
    setOpenAddsvoPopup(true);
    // Pass the row ID to the RegisterSvoForm component
    setSelectedid([row?.id]);
  };

  const handelcloseAddsvo = () => {
    setOpenAddsvoPopup(false);
    setSelectedRowData([]);
    setSelectedid([]);
  };

  const handleViewMore = (row) => {
    const url = `/enquirydetails/${row.enquiry_id}`;
    window.open(url, "_blank"); // '_blank' parameter opens the URL in a new tab
  };

  //select all checkbox

  //select one checkbox
  const handleCheckboxChange = (event, id) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedid([...selectedid, id]);
    } else {
      setSelectedid(selectedid.filter((selectedId) => selectedId !== id));
    }
  };

  const handleAssignToAdvisor = () => {
    setOpenpopup(true);
  };

  const handleTaskAssign = () => {
    setOpenAssignPopup(true);
  };
  //fetch enquiry api

  // useEffect(() => {
  //   setLoading(true);
  //   setRegisterdate([]);
  //   let url = "";
  //   if (selectedCriteria === "website") {
  //     url = `${API_ENDPOINT}route/websiteenquiries/${userId}`;
  //   } else {
  //     url = `${API_ENDPOINT}svoenquiries/fetchactivesvoenquiries/${userId}`;
  //   }

  //   fetch(url)
  //     .then((response) => {
  //       if (response.ok) {
  //         return response.json();
  //       } else if (response.status === 403) {
  //         // Handle 403 error by showing Snackbar
  //         showSnackbar("No enq found between yeterday and today", "error");
  //         setLoading(false);
  //         return; // No need to continue further
  //       } else if (response.status === 401) {
  //         navigate("/");
  //         return showSnackbar("Unknown user:", "error");
  //       } else if (response.status === 404) {
  //         navigate("/page404");
  //         throw new Error(`Request failed with status: ${response.status}`);
  //       }
  //     })
  //     .then((data) => {
  //       if (!data) return; // Stop further execution if data is not present

  //       console.log(data);
  //       const sortedData = data.sort(
  //         (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  //       );
  //       console.log(sortedData);
  //       setRegisterdate(sortedData);
  //       setOriginalData(sortedData);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //       setLoading(false);
  //       navigate("/page404");
  //     });
  // }, [userId, selectedCriteria]);

  useEffect(() => {
    setLoading(true);
    if (selectedCriteria === "website") {
      const fetchData = async () => {
        try {
          const response = await fetch(
            `${API_ENDPOINT}route/websiteenquiries/${userId}`
          );
          if (response.ok) {
            const data = await response.json();
            const sortedData = data.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );
            setRegisterdate(sortedData);
            setOriginalData(sortedData);
            setLoading(false);
          } else {
            setLoading(false);
            throw new Error(`Request failed with status: ${response.status}`);
          }
        } catch (error) {
          setLoading(false);
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [userId, selectedCriteria]);

  useEffect(() => {
    setLoading(true);
    if (selectedCriteria === "svopage") {
      const fetchData = async () => {
        try {
          const response = await fetch(
            `${API_ENDPOINT}svoenquiries/fetchactivesvoenquiries/${userId}`
          );
          if (response.ok) {
            const data = await response.json();
            const sortedData = data.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );
            setSvoPageData(sortedData);
            setsvoOriginalData(sortedData);
            setLoading(false);
          } else {
            setLoading(false);
            throw new Error(`Request failed with status: ${response.status}`);
          }
        } catch (error) {
          setLoading(false);
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [userId, selectedCriteria]);

  console.log("selectedData", registerdata);

  const columns = [
    {
      field: "selection",
      headerName: "Select",
      width: 50,
      renderCell: (params) => {
        return (
          <>
            <input
              type="checkbox"
              onChange={(event) => handleCheckboxChange(event, params.row.id)}
              checked={selectedid.includes(params.row.id)}
            />
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div>
            {params.row.assign_to === null ? (
              <Button
                size="small"
                color="primary"
                variant="contained"
                style={{ cursor: "pointer", borderRadius: "24px" }}
                onClick={() => handelopen(params.row.id)}
              >
                Assign To Advisor
              </Button>
            ) : (
              <Button
                size="small"
                color="secondary"
                variant="contained"
                style={{ cursor: "pointer", borderRadius: "24px" }}
                onClick={() => handelopenAssign(params.row.id)}
              >
                Assign Task
              </Button>
            )}
          </div>
        );
      },
    },

    {
      field: "name",
      headerName: "Name",
      width: 150,
      renderCell: (params) => (
        <Typography>
          {params.row?.first_name} {params.row?.last_name}
        </Typography>
      ),
    },

    {
      field: "mobile",
      headerName: "Mobile",
      width: 150,
      renderCell: (params) => <Typography>{params.row?.mobile}</Typography>,
    },

    {
      field: "enquired_service",
      headerName: "Enquired Service",
      width: 150,
      renderCell: (params) => (
        <Typography>{params.row.service_id?.service_name}</Typography>
      ),
    },

    {
      field: "sub_service",
      headerName: "Sub Service",
      width: 150,
      renderCell: (params) => (
        <Typography>{params.row.sub_service_id?.name}</Typography>
      ),
    },

    {
      field: "email_id",
      headerName: "Email ID",
      width: 250,
      renderCell: (params) => <Typography>{params.row?.email} </Typography>,
    },

    {
      field: "first_country",
      headerName: "First Country Of Interest",
      width: 150,
      renderCell: (params) => (
        <Typography>{params.row.target_country_id?.country_name} </Typography>
      ),
    },

    {
      field: "branch",
      headerName: "STM Nearest Branch",
      width: 200,
      renderCell: (params) => (
        <Typography>{params.row.nearest_branch_id?.branch_name} </Typography>
      ),
    },
    {
      field: "branchname",
      headerName: "Gradlynk Branch Name",
      width: 200,
      renderCell: (params) => (
        <Typography>{params.row.nearest_branch_name} </Typography>
      ),
    },
    {
      field: "campaign",
      headerName: "STM Campaign Name",
      width: 250,
      renderCell: (params) => (
        <Typography>{params.row.campaign_id?.campaign_name} </Typography>
      ),
    },
    {
      field: "campaignName",
      headerName: "Gradlynk Campaign Name",
      width: 250,
      renderCell: (params) => (
        <Typography>{params.row.campaign_name} </Typography>
      ),
    },
    {
      field: "permanent_assignee",
      headerName: "Permanent Assignee",
      width: 150,
      renderCell: (params) => (
        <Typography>{params.row.assign_to?.first_name} </Typography>
      ),
    },

    {
      field: "created_time",
      headerName: "Created Time",
      width: 170,
      renderCell: (params) => (
        <Typography>{fDateTime(params.row?.createdAt)} </Typography>
      ),
    },
    {
      field: "leadEntry",
      headerName: "Lead Entry",
      width: 250,
      renderCell: (params) => (
        <Typography>{params.row?.lead_entry_point} </Typography>
      ),
    },
  ];

  const column2 = [
   
    {
      field: "action",
      headerName: "Action",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div>
            <Button
              size="small"
              color="primary"
              variant="contained"
              style={{ cursor: "pointer", borderRadius: "24px" }}
              onClick={() => handelopenAddsvo(params.row)}
            >
              Add SVO Enquiry
            </Button>
          </div>
        );
      },
    },

    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <Typography>{params.row?.mobile_country_code}-{params.row?.mobile}</Typography>,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <Typography>{params.row?.email}</Typography>,
    },

    {
      field: "createdTime",
      headerName: "Created Time",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Typography>{fDateTime(params.row?.createdAt)}</Typography>
      ),
    },
  ];

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Available</Box>
      </StyledGridOverlay>
    );
  }

  const handleCriteria = (criteria) => {
    setSelectedCriteria(criteria);
    setSelectedid([]);
    setSelectedRowData([]);
  };

  const handleSearch = (e) => {
    console.log("search", typeof e.target.value);
    const value = e.target.value;

    if (!isNaN(value) && /^\d+$/.test(value)) {
      const searchData = svooriginalData.filter((data) =>
        data.mobile.includes(value)
      );
      setSvoPageData(searchData);
    } else {
      const searchData = svooriginalData.filter((data) =>
        data.email.includes(value)
      );
      setSvoPageData(searchData);
    }

    // if (value.includes("@")) {
    //   const searchData = originalData.filter((data) => data.email.includes(value));
    //   setRegisterdate(searchData);
    // } else if (/^\d+$/.test(value)) {
    //   const searchData = originalData.filter((data) => data.mobile.includes(value));
    //   setRegisterdate(searchData);
    // }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={6} sm={6}>
          <Chip
            label="Register In SVO"
            sx={{
              fontWeight: 600,
              fontSize: "20px",
              background: "white",
              padding: "10px",
              margin: "5px",
            }}
          />

          <Chip
            label="SIEC website and Gradlynk Enquiries"
            variant={selectedCriteria === "website" ? "contained" : "outlined"}
            color={selectedCriteria === "website" ? "secondary" : undefined}
            sx={{ margin: "5px" }}
            onClick={() => handleCriteria("website")}
          />
          <Chip
            label="SVO Enquiries"
            variant={selectedCriteria === "svopage" ? "contained" : "outlined"}
            color={selectedCriteria === "svopage" ? "secondary" : undefined}
            sx={{ margin: "5px" }}
            onClick={() => handleCriteria("svopage")}
          />
        </Grid>

        <Grid item xs={12} lg={6} sm={6} sx={{ textAlign: { sm: "right" } }}>
          {selectedCriteria === "website" ? (
            <>
              <Button
                size="small"
                variant="contained"
                style={{ borderRadius: "24px", margin: "5px" }}
                color="secondary"
                onClick={handleTaskAssign}
              >
                Assign Task
              </Button>
              <Button
                size="small"
                variant="contained"
                style={{ borderRadius: "24px", margin: "5px" }}
                onClick={handleAssignToAdvisor}
              >
                Assign To Advisor
              </Button>
            </>
          ) : (
            <Button
              size="small"
              variant="contained"
              style={{ borderRadius: "24px", margin: "5px" }}
              onClick={handelopenAddsvo}
            >
              Add SVO Enquiry
            </Button>
          )}
        </Grid>
      </Grid>
      {loading ? (
        <Box
          style={{
            minHeight: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Box mt={2}>
          <MainCard>
            {selectedCriteria === "svopage" && (
              <Grid
                item
                xs={12}
                textAlign="end"
                container
                alignItems="center"
                justifyContent="end"
              >
                <Typography mr={1}>Search:</Typography>
                <TextField
                  placeholder="Search by Mobile or Email"
                  // value={namefilter}
                  onChange={handleSearch}
                  size="small"
                  InputProps={{ style: { borderRadius: "15px" } }}
                />
              </Grid>
            )}

            <div style={{ width: "100%", maxWidth: "100%" }}>
              <DataGrid
                rows={
                  selectedCriteria === "website" ? registerdata : svoPageData
                }
                columns={selectedCriteria === "website" ? columns : column2}
                autoHeight
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 100,
                    },
                  },
                }}
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                sx={{ marginTop: "20px" }}
                pageSizeOptions={[25, 50, 100]}
              />
            </div>
          </MainCard>
        </Box>
      )}

      {/* Assign to Adivosor */}
      {selectedid.length === 0 ? (
        <CustomPopup
          open={openpopup}
          onClose={handelclose}
          component={<NoenquiryForm onClose={handelclose} />}
          maxWidth="xs"
        />
      ) : (
        <CustomPopup
          open={openpopup}
          onClose={handelclose}
          component={
            <AssignToAdivosor selectedid={selectedid} onClose={handelclose} />
          }
          title="Assign Enquiry To JCA"
          maxWidth="sm"
          showDivider={true}
        />
      )}

      {/* Assign Task */}
      {selectedid.length === 0 ? (
        <CustomPopup
          open={openAssignPopup}
          onClose={handelcloseAssign}
          component={<NoenquiryForm onClose={handelcloseAssign} />}
          maxWidth="xs"
        />
      ) : (
        <CustomPopup
          open={openAssignPopup}
          onClose={handelcloseAssign}
          component={
            <TaskAssignForm
              selectedid={selectedid}
              onClose={handelcloseAssign}
            />
          }
          title="Assign Task"
          maxWidth="md"
          showDivider={true}
        />
      )}

      <CustomPopup
        open={openAddsvoPopup}
        onClose={handelcloseAddsvo}
        component={
          <AddSvo
            onClose={handelcloseAddsvo}
            selectedRowData={selectedRowData}
          />
        }
        title="Add SVO Enquiry"
        maxWidth="sm"
        showDivider={true}
      />

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
