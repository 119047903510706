import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Person as PersonIcon } from "@mui/icons-material";
import CustomSnackbar from "../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import { fDate } from "../../utils/formatTime";
import TasksDataGridFilter from "./AdvisorTaskFilter";
import MainCard from "../../components/Maincard";
import { CSVExport } from "../../newTestPrep/components/ui-component/table/CSVExport";
import EmailIcon from "@mui/icons-material/Email";
import CustomPopup from "../../components/CustomPopup";

import NoeMailForm from "../../newTestPrep/components/DataTransferComp.jsx/NoMailForm";

import NoenquiryForm from "../../newTestPrep/components/DataTransferComp.jsx/NoenquiryForm";

import NoTaskPopup from "../../newTestPrep/pages/enquiry/tasks/NoTaskPopup";



import { useGetTokenOrLogout } from "../../utils/token";
import MassTaskAssign from "../../newTestPrep/components/DataTransferComp.jsx/MassTaskAssign";
import MassTaskTransfer from "../../newTestPrep/components/DataTransferComp.jsx/MassTaskTransfer";
import BulkEmailPopup from "../../newTestPrep/components/BulkEmail/BulkEmailPopup";
import BulkMailform from "../../newTestPrep/components/BulkEmail/BulkMailform";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function AdvisorTaskDataGrid() {
  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tasksPerPage, setTasksPerPage] = useState(25);
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [originaltaskData, setOriginalTaskData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [isDownloadAccess, setIsDownloadAccess] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedEnquiryids, setSelectedEnquiryids] = useState([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [openMask, setOpenMask] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [OpenEmailPopup, setOpenEmailPopup] = useState(false);
  const [openTaskTransfer, setOpenTaskTransfer] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 100,
  });

  const [visibleRowIds, setVisibleRowIds] = useState([]); // Store current page's row IDs
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [prevPageSize, setPrevPageSize] = useState(pagination.pageSize);

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  useEffect(() => {
    if (tasks.length > 0) {
      const totalPagesCount = Math.ceil(tasks.length / tasksPerPage);
      setTotalPages(totalPagesCount);
    }
  }, [tasks, tasksPerPage]);

  const handleViewMore = (row) => {
    // Handle the "View More" action here

    const URL = `/enquirydetails/${row.enquiry_id?.id}`;
    window.open(URL, "_blank");
  };

  useEffect(() => {
    setIsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = { Authorization: `Bearer ${token}` };
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route2.0/fetchtask/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();

          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setTasks(sortedData);
          setOriginalTaskData(sortedData);
          setSearchData(sortedData);
          setIsLoading(false);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/page500");
      }
    };

    fetchData();
  }, [userId]);

  console.log(tasks);

  useEffect(() => {
    fetch(`${API_ENDPOINT}route/downloadaccess/${userId}`)
      .then((response) => {
        if (response.ok) {
          setIsDownloadAccess(true);
        } else {
          setIsDownloadAccess(false);
        }
      })
      .catch((err) => {
        setIsDownloadAccess(false);
      });
  }, [userId]);

  useEffect(() => {
    const exportData = tasks.map((row) => ({
      type_of_task: row.type_of_task?.name_of_task_type,
      enquiry_name: `${row.enquiry_id?.first_name} ${row.enquiry_id?.last_name}`,
      mobile: `${row.enquiry_id?.mobile}`,
      follow_up_reason: row?.follow_up_reason_id?.follow_up_reason || "",
      due_date: `${row?.due_date} ${row?.due_time}`,
      stage: row.enquiry_id?.stage_id?.stage_name || "",
      sub_service: row.enquiry_id?.sub_service_id?.name || "",
      task_owner: row.task_owner?.first_name || "",
      created_by: row.created_by?.first_name || "",
      status: row?.status,
      closed_by: row.closed_by?.first_name || "",
      closed_date: `${row?.closed_date} ${row?.task_close_time}`,
      contact_mode: row?.contact_mode_id?.contact_mode || "",
      response_status: row?.response_status || "",
      remarks: row?.remarks || "",
    }));
    setExportData(exportData);
  }, [tasks]);

  const columns = [
    // {
    //   field: "selection",
    //   sortable: false,
    //   renderHeader: (params) => {
    //     return (
    //       <>
    //         <input
    //           type="checkbox"
    //           checked={isSelectAllChecked}selectedid
    //           onChange={handleSelectAllChange}
    //         />
    //       </>
    //     );
    //   },
    //   headerClassName: "header-cell",
    //   width: 70,
    //   renderCell: (params) => {
    //     const isChecked = selectedid.includes(params.row.enquiry_id?.id);
    //     return (
    //       <>
    //         <input
    //           type="checkbox"
    //           checked={isChecked}
    //           onChange={(event) =>
    //             handleCheckboxChange(event, params.row?.enquiry_id?.id)
    //           }
    //         />
    //       </>
    //     );
    //   },
    // },

    {
      field: "selection",
      sortable: false,
      renderHeader: (params) => (
        <Checkbox
          size="small"
          checked={isSelectAllChecked}
          indeterminate={isIndeterminate}
          onChange={handleSelectAllChange}
        />
      ),
      headerClassName: "header-cell",
      width: 70,
      renderCell: (params) => {
        const isChecked = selectedIds.includes(params.row?.id);
        return (
          <Checkbox
            size="small"
            checked={isChecked}
            onChange={(event) => handleCheckboxChange(event, params.row?.id)}
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Enquiry Name",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => {
        console.log("Cell Renderer - params.row:", params.row);
        return (
          <div>
            <Button size="small" onClick={() => handleViewMore(params.row)}>
              <PersonIcon /> {params.row.enquiry_id?.first_name}{" "}
              {params.row.enquiry_id?.last_name}
            </Button>
          </div>
        );
      },
      /*renderCell: (params) => (
          <div>
            <Button size='small' onClick={() => handleViewMore(params.row)}>
            <PersonIcon /> {params.row.first_name} {params.row.last_name}
            </Button>
          </div>
        ),*/
    },

    {
      field: "mobile_no",
      headerName: "Mobile Number",
      width: 150,
      headerClassName: "header-cell",
      filterable: true,
      renderCell: (params) => (
        <div>
          {" "}
          {isDownloadAccess
            ? params.row.enquiry_id?.mobile
            : params.row?.enquiry_id?.mobile?.replace(/.(?=.{4,}$)/g, "*")}
        </div>
      ),
    },

    {
      field: "taskType",
      headerName: "Type of Task",
      width: 150,
      headerClassName: "header-cell",

      renderCell: (params) => (
        <div>{params.row.type_of_task?.name_of_task_type}</div>
      ),
    },
    {
      field: "campaign_name",
      headerName: "Campaign Name",
      width: 350,
      headerClassName: "header-cell",

      renderCell: (params) => (
        <div>{params.row.campaign_id?.campaign_name}</div>
      ),
    },
    {
      field: "due_date",
      headerName: "Due Date",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDate(params.value)}</div>,
    },
    {
      field: "stage",
      headerName: "Stage",
      width: 150,
      headerClassName: "header-cell",

      renderCell: (params) => (
        <div>{params.row.enquiry_id?.stage_id?.stage_name}</div>
      ),
    },
    {
      field: "test_type",
      headerName: "Sub Service",
      width: 150,
      headerClassName: "header-cell",

      renderCell: (params) => (
        <div>{params.row.enquiry_id?.sub_service_id?.name}</div>
      ),
    },
    {
      field: "followupreason",
      headerName: "Follow Up Reason",
      width: 250,
      headerClassName: "header-cell",

      renderCell: (params) => (
        <div>{params.row.follow_up_reason_id?.follow_up_reason}</div>
      ),
    },

    {
      field: "prospective_level",
      headerName: "Prospective Level",
      width: 200,
      headerClassName: "header-cell",

      renderCell: (params) => (
        <div>
          {params.row.enquiry_id?.prospective_level_id?.prospective_level}
        </div>
      ),
    },
    {
      field: "branch",
      headerName: "Branch",
      width: 200,
      headerClassName: "header-cell",

      renderCell: (params) => (
        <div>{params.row.enquiry_id?.branch_id?.branch_name}</div>
      ),
    },
    {
      field: "taskOwner",
      headerName: "Task Owner",
      width: 150,
      headerClassName: "header-cell",

      renderCell: (params) => <div>{params.row.task_owner?.first_name}</div>,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 150,
      headerClassName: "header-cell",

      renderCell: (params) => <div>{params.row.created_by?.first_name}</div>,
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDate(params.value)}</div>,
    },
    {
      field: "closed_date",
      headerName: "Closed Date",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDate(params.value)}</div>,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => {
        let chipColor = "";
        let chipLabel = "";

        switch (params.value) {
          case 0:
            chipColor = "secondary";
            chipLabel = "Closed";
            break;
          case 1:
            chipColor = "error";
            chipLabel = "Open";
            break;
          default:
            chipColor = "orange";
            chipLabel = "Unknown";
        }

        return (
          <Chip
            label={chipLabel}
            color={chipColor === "default-color" ? "default" : chipColor}
          />
        );
      },
    },
    {
      field: "closedBy",
      headerName: "Closed By",
      width: 150,
      headerClassName: "header-cell",

      renderCell: (params) => <div>{params.row.closed_by?.first_name}</div>,
    },
  ];

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Available</Box>
      </StyledGridOverlay>
    );
  }

  const handlePageChange = (params) => {
    setCurrentPage(params.page + 1);
  };
  const handlePageSizeChange = (params) => {
    setTasksPerPage(params.pageSize);
    setCurrentPage(1); // Reset to the first page when changing the tasks per page
  };

  // Upadate current page ids when pagination is chnages
  useEffect(() => {
    console.log("pagination", pagination, selectedIds);
    setPrevPageSize(pagination.pageSize);

    // new pagesize is less than prev page size then deselct all selected ids first
    if (pagination.pageSize !== prevPageSize) {
      setSelectedIds([]);
      return;
    }

    // Update visible row IDs when pagination changes
    const currentPageRows = tasks.slice(
      pagination.page * pagination.pageSize,
      (pagination.page + 1) * pagination.pageSize
    );
    const visibleIds = currentPageRows.map((row) => row.id);
    setVisibleRowIds(visibleIds);

    // If there are no visible rows, set SelectAllChecked to false
    if (visibleIds.length === 0) {
      setIsSelectAllChecked(false);
      setIsIndeterminate(false);
      return;
    }

    // Check if all visible rows are selected
    const allVisibleSelected = visibleIds.every((id) =>
      selectedIds.includes(id)
    );
    const someVisibleSelected = visibleIds.some((id) =>
      selectedIds.includes(id)
    );

    setIsSelectAllChecked(allVisibleSelected);
    setIsIndeterminate(someVisibleSelected && !allVisibleSelected);
  }, [pagination, tasks, selectedIds]);

  // HandleSelectAllCheckBox
  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    setIsSelectAllChecked(checked);

    if (checked) {
      // Select all visible row IDs
      setSelectedIds((prevSelectedIds) => [
        ...new Set([...prevSelectedIds, ...visibleRowIds]),
      ]);
    } else {
      // Deselect all visible row IDs
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((id) => !visibleRowIds.includes(id))
      );
    }
  };

  // Individual CheckBox
  const handleCheckboxChange = (event, id) => {
    const checked = event.target.checked;

    setSelectedIds((prevSelectedIds) => {
      if (checked) {
        const newSelected = [...prevSelectedIds, id];
        // Check if all visible rows are selected after this checkbox is checked
        const allVisibleSelected = visibleRowIds.every((rowId) =>
          newSelected.includes(rowId)
        );
        setIsSelectAllChecked(allVisibleSelected);
        setIsIndeterminate(!allVisibleSelected);

        return newSelected;
      } else {
        // Deselect the row ID
        const newSelected = prevSelectedIds.filter((rowId) => rowId !== id);
        setIsSelectAllChecked(false);
        setIsIndeterminate(
          visibleRowIds.some((rowId) => newSelected.includes(rowId))
        );

        return newSelected;
      }
    });
  };

  // Select EnquiryIds
  useEffect(() => {
    if (selectedIds.length > 0) {
      console.log("taskIds", selectedIds);
      const enquiryIds = tasks.filter((id) => selectedIds.includes(id?.id));
      const selectedEnquiryids = enquiryIds.map((i) => i.enquiry_id?.id);
      setSelectedEnquiryids(selectedEnquiryids);
    }
  }, [selectedIds]);

  const handleOpenmask = () => {
    setOpenMask(true);
  };

  const handleClosemask = () => {
    setOpenMask(false);
  };
  const handleOpenEmail = () => {
    setOpenEmailPopup(true);
  };

  const handleCloseEmail = () => {
    setOpenEmailPopup(false);
  };

  const handleOpenTaskTransfer = () => {
    setOpenTaskTransfer(true);
  };

  const handleCloseTaskTransfer = () => {
    setOpenTaskTransfer(false);
  };

  // Search By Name
  const handleSearchByName = (e) => {
    const value = e.target.value;
    let filteredData1 = searchData.filter((data) =>
      `${data?.enquiry_id?.first_name} ${data?.enquiry_id?.last_name}`
        ?.toLowerCase()
        .replace(/\s+/g, "")
        .includes(value.toLowerCase().replace(/\s+/g, ""))
    );
    setTasks(filteredData1);
  };

  // Removed Data from state after transfer
  const handleFilterdata = () => {
    const filteredData = tasks.filter(
      (i) => !selectedEnquiryids.includes(i.enquiry_id?.id)
    );
    setTasks(filteredData);
    setTimeout(() => {
      setSelectedIds([]);
      setSelectedEnquiryids([]);
    }, 1500);
  };

  const handleFilterdata1 = () => {
    const filteredData = tasks.filter(
      (i) => !selectedIds.includes(i.id)
    );
    setTasks(filteredData);
    setTimeout(() => {
      setSelectedIds([]);
      setSelectedEnquiryids([]);
    }, 1500);
  };

  console.log("selectedIds", selectedIds);
  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TasksDataGridFilter
            setTasksdata={setTasks}
            originaltaskData={originaltaskData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setSearchData={setSearchData}
          />
        </Grid>
        <Grid item xs={12}>
          <MainCard>
            <Grid item xs={12} textAlign="end">
              {(userRole === "center_admin" ||
                userRole === "data_entry" ||
                userRole === "admin") && (
                <>
                  <Button
                    variant="contained"
                    onClick={() => handleOpenTaskTransfer()}
                    style={{ borderRadius: "30px", background: "#CFA31C" }}
                  >
                    Mass Task Transfer
                  </Button>

                  <Button
                    variant="contained"
                    style={{ borderRadius: "24px", marginLeft: "10px" }}
                    onClick={handleOpenmask}
                  >
                    Mass Task Assign{" "}
                  </Button>

                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "24px",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                    startIcon={<EmailIcon />}
                    color="secondary"
                    onClick={handleOpenEmail}
                  >
                    Send Email{" "}
                  </Button>
                </>
              )}
              {isDownloadAccess && (
                <CSVExport data={exportData} filename="task-report.csv" />
              )}
            </Grid>

            <Box display="flex" justifyContent="space-between" mt={2}>
              <Box>
                {" "}
                <Button
                  style={{ borderRadius: "25px" }}
                  variant="contained"
                  size="small"
                >
                  {" "}
                  Selected Items : {selectedIds.length}{" "}
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: 600 }}>Search:{"  "}</Typography>
                <TextField
                  // value={value}
                  onChange={handleSearchByName}
                  size="small"
                  InputProps={{ style: { borderRadius: "15px" } }}
                />
              </Box>
            </Box>

            <Grid item xs={12}>
              <Box style={{ width: "100%", marginTop: "10px" }}>
                <style>
                  {`
                .header-cell {
                    background-color: #ABF7B1; /* Set your desired background color */
                    border-right: 1px solid black;
                }
                `}
                </style>

                <DataGrid
                  columns={columns}
                  rows={tasks}
                  autoHeight
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  pageSize={pagination.pageSize}
                  onPaginationModelChange={(model) => setPagination(model)}
                  pageSizeOptions={[10, 25, 50, 100]}
                />
              </Box>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      {selectedIds.length === 0 ? (
        <CustomPopup
          open={openMask}
          onClose={handleClosemask}
          component={<NoenquiryForm onClose={handleClosemask} />}
          maxWidth="xs"
        />
      ) : (
        <CustomPopup
          open={openMask}
          onClose={handleClosemask}
          component={
            <MassTaskAssign
              onClose={handleClosemask}
              selectedEnquiryIds={selectedEnquiryids}
              setSelectedEnquiryIds={setSelectedEnquiryids}
              handleFilterdata={handleFilterdata}
            />
          }
          title="Mass Task Assign"
          maxWidth="md"
          showDivider={true}
        />
      )}

      {selectedIds.length === 0 ? (
        <CustomPopup
          open={OpenEmailPopup}
          onClose={handleCloseEmail}
          component={<NoeMailForm onClose={handleCloseEmail} />}
          maxWidth="xs"
        />
      ) : (
        <BulkEmailPopup
          open={OpenEmailPopup}
          handelclose={handleCloseEmail}
          component={
            <BulkMailform
              handelclose={handleCloseEmail}
              selectedId={selectedEnquiryids}
            />
          }
          title="Send Bulk Emails"
          maxWidth="md"
          showDivider={true}
        />
      )}

      {selectedIds.length === 0 ? (
        <CustomPopup
          open={openTaskTransfer}
          onClose={handleCloseTaskTransfer}
          component={<NoTaskPopup onClose={handleCloseTaskTransfer} />}
          maxWidth="xs"
        />
      ) : (
        <CustomPopup
          open={openTaskTransfer}
          onClose={handleCloseTaskTransfer}
          component={
            <MassTaskTransfer
              onClose={handleCloseTaskTransfer}
            
              handleFilterdata={handleFilterdata1}
              selectedId={selectedIds}
            />
          }
          title="Task Transfer"
          maxWidth="sm"
          showDivider={true}
        />
      )}
    </>
  );
}

export default AdvisorTaskDataGrid;
