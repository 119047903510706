// Author : Ayesha Shaikh
//  Date : 12 Nov 2024

import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
function AddLeaveRequest() {
  const today = new Date().toISOString().split("T")[0];
  const userId = localStorage.getItem("userId");
  const [userName, setUserName] = useState(null);
  const [userNameList, setuserNameList] = useState([]);
  const [BackupUser, setBackupUser] = useState(null);
  const [leaveType, setLeaveType] = useState(null);
  const [leaveTypelist, setLeaveTypeList] = useState([
    { id: 1, name: "Full Day" },
    { id: 2, name: "First Half Day" },
    { id: 3, name: "Second Half Day" },
  ]);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          setuserNameList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleUserChange = (event, value) => {
    const id = value?.id;
    setUserName(value);
    setValue("employee_name", id);
  };

  const handleBackupUserChange = (event, value) => {
    const id = value?.id;
    setBackupUser(value);
    setValue("backup_emp_name", id);
  };

  const handleLeaveTypeChange = (event, value) => {
    const id = value?.id;
    setLeaveType(value);
    setValue("leave_type_id", id);
  };

  const onSubmit = async (data) => {
    try {
      const requestData = {
        ...data,
        user_id: parseInt(userId),
      };
      const response = await fetch(`${API_ENDPOINT}advisor-leave-detail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      if (response.ok) {
        showSnackbar("Employee leave request added successfully", "success");

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else if (response.status === 401) {
        showSnackbar("Unknown User", "error");
      } else if (response.status === 403) {
        showSnackbar("Unathorized User", "error");
      } else {
        showSnackbar("Error to Adding Employee Leave Request", "error");
        throw new Error("Error to Adding Employee Leave Request");
      }
    } catch (error) {
      showSnackbar("Error to Adding Employee Leave Request", "error");
      console.error("Error to Adding Employee Leave Request", error);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h5">
            Select Employee{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.2em",
              }}
            >
              *
            </span>
          </Typography>
          <Controller
            name="employee_name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                size="small"
                value={userName}
                onChange={handleUserChange}
                options={userNameList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : ""} ${
                    option.last_name ? option.last_name : ""
                  }`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "14px" }, // Add borderRadius here
                    }}
                    error={errors.employee_name}
                    helperText={
                      errors.employee_name && "Employee name is required"
                    }
                  />
                )}
              />
            )}
          ></Controller>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h5">
            Select Backup Employee{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.2em",
              }}
            >
              *
            </span>
          </Typography>
          <Controller
            name="backup_emp_name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                size="small"
                value={BackupUser}
                onChange={handleBackupUserChange}
                options={userNameList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : ""} ${
                    option.last_name ? option.last_name : ""
                  }`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "14px" }, // Add borderRadius here
                    }}
                    error={errors.backup_emp_name}
                    helperText={
                      errors.backup_emp_name &&
                      "Backoup employee name is required"
                    }
                  />
                )}
              />
            )}
          ></Controller>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h5">
            Select Leave Type{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.2em",
              }}
            >
              *
            </span>
          </Typography>
          <Controller
            name="leave_type_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                size="small"
                value={leaveType}
                onChange={handleLeaveTypeChange}
                options={leaveTypelist}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "14px" }, // Add borderRadius here
                    }}
                    error={errors.leave_type_id}
                    helperText={
                      errors.leave_type_id && "Leave type is required"
                    }
                  />
                )}
              />
            )}
          ></Controller>
        </Grid>

        {leaveType?.id === 1 && (
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5">
                  Start Date{" "}
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1.2em",
                    }}
                  >
                    *
                  </span>
                </Typography>

                <Controller
                  name="start_date"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      type="date"
                      InputProps={{
                        style: {
                          borderRadius: "14px",
                          height: "37px",
                        },
                        inputProps: { min: today },
                      }}
                      error={errors.start_date}
                      helperText={errors.start_date && "Start Date is required"}
                    />
                  )}
                ></Controller>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="h5">
                  End Date{" "}
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1.2em",
                    }}
                  >
                    *
                  </span>
                </Typography>

                <Controller
                  name="end_date"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      type="date"
                      InputProps={{
                        style: {
                          borderRadius: "14px",
                          height: "37px",
                        },
                        inputProps: { min: today },
                      }}
                      error={errors.end_date}
                      helperText={errors.end_date && "End Date is required"}
                    />
                  )}
                ></Controller>
              </Grid>
            </Grid>
          </Grid>
        )}

        {(leaveType?.id === 2 || leaveType?.id === 3) && (
          <Grid item xs={12} sm={6}>
            <Typography variant="h5">
              Start Date{" "}
              <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "4px",
                  fontSize: "1.2em",
                }}
              >
                *
              </span>
            </Typography>

            <Controller
              name="start_date"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  type="date"
                  InputProps={{
                    style: {
                      borderRadius: "14px",
                      height: "37px",
                    },
                    inputProps: { min: today },
                  }}
                  error={errors.start_date}
                  helperText={errors.start_date && "Start Date is required"}
                />
              )}
            ></Controller>
          </Grid>
        )}

        <Grid item xs={12} sm={12}>
          <Typography variant="h5">Additional Note (Optional)</Typography>

          <Controller
            name="notes"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                size="small"
                multiline
                rows={4}
                InputProps={{
                  style: {
                    borderRadius: "14px",
                  },
                }}
              />
            )}
          ></Controller>
        </Grid>

        <Grid item xs={12} textAlign="end">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default AddLeaveRequest;
