import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function EmployeeTargetFilter() {
  const [advisorList, setAdvisorList] = useState([]);
  const [advisorName, setAdvisorName] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [branchName, setBranchName] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [monthName, setMonthName] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}advisor`);
        if (response.ok) {
          const data = await response.json();

          setAdvisorList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}month`);
        if (response.ok) {
          const data = await response.json();

          setMonthList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();
          setBranchList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const allAdvisorList = [{ first_name: "Select All" }, ...advisorList];
  const handleUserChange = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setAdvisorName(advisorList);
    } else {
      setAdvisorName(value);
    }
  };

  const allBranchList = [{ branch_name: "Select All" }, ...branchList];
  const handleBranchChange = (event, value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranchName(branchList);
    } else {
      setBranchName(value);
    }
  };

  const allMonthList = [{ month_full_name: "Select All" }, ...monthList];
  const handleMonthChange = (event, value) => {
    if (value.some((option) => option.month_full_name === "Select All")) {
      setMonthName(monthList);
    } else {
      setMonthName(value);
    }
  };

  const years = Array.from({ length: 11 }, (_, index) => ({
    year: new Date().getFullYear() - 5 + index,
  }));

  console.log(years);

  const allYearList = [{ year: "Select All" }, ...years];
  const handleselectedYear = async (value) => {
    if (value.some((option) => option.year === "Select All")) {
      setSelectedYear(years);
    } else {
      setSelectedYear(value);
    }
  };

  console.log("allYearList", allYearList);

  const resetFilter = () => {
    setAdvisorName([]);
    setBranchName([]);
    setMonthName([]);
    setSelectedYear([]);
  };
  return (
    <>
      <MainCard>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Typography>Select Employee Name</Typography>

            <Autocomplete
              size="small"
              value={advisorName}
              multiple
              onChange={handleUserChange}
              options={allAdvisorList}
              getOptionLabel={(option) =>
                `${option.first_name ? option.first_name : ""} ${
                  option.last_name ? option.last_name : ""
                }`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "14px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography>Select Branch</Typography>

            <Autocomplete
              size="small"
              value={branchName}
              multiple
              onChange={handleBranchChange}
              options={allBranchList}
              getOptionLabel={(option) => option.branch_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "14px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography>Select Month</Typography>

            <Autocomplete
              size="small"
              value={monthName}
              multiple
              onChange={handleMonthChange}
              options={allMonthList}
              getOptionLabel={(option) => option.month_full_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "14px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography>Select Year</Typography>
            <Autocomplete
              multiple
              value={selectedYear}
              onChange={(event, value) => handleselectedYear(value)}
              size="small"
              sx={{ width: "300px" }}
              options={allYearList}
              getOptionLabel={(option) => option.year}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} textAlign="end">
            <Button variant="contained" style={{ borderRadius: "25px" }}>
              Apply Filter
            </Button>
            <Button
              variant="contained"
              color="error"
              style={{ borderRadius: "25px", marginLeft: "10px" }}
              onClick={resetFilter}
            >
              Reset Filter
            </Button>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
}
