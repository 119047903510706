import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterListTwoTone";
import MainCard from "../../../components/Maincard";
export default function PackagesFilter({ open, onApplyFilter, onResetFilter }) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [testtypelist, setTesttypeList] = useState([]);
  const [testtype, setTesttype] = useState([]);
  const [branchlist, setBranchlist] = useState([]);
  const [branch, setBranch] = useState([]);
  const [packageName, setPackageName] = useState("");
  const [status, setstatus] = useState([]);
  const [duration, setduration] = useState("");
  const handleNamechange = (e) => {
    setPackageName(e.target.value);
  };

  const handledurationchange = (e) => {
    setduration(e.target.value);
    console.log(e.target.value);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalltesttype`);
        if (response.ok) {
          const data = await response.json();

          setTesttypeList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allTestTypes = [{ name: "Select All" }, ...testtypelist];
  const handeltestTypechange = (event, value) => {
    if (value.some((option) => option.name === "Select All")) {
      setTesttype(testtypelist);
    } else {
      setTesttype(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();

          setBranchlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allbranchList = [{ branch_name: "Select All" }, ...branchlist];

  const handelbranchchange = (event, value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchlist);
    } else {
      setBranch(value);
    }
  };

  const statuslist = [
    {
      id: 1,
      name: "Active",
      value: "active",
    },
    {
      id: 2,
      name: "Inactive",
      value: "inActive",
    },
  ];

  const handlestatuschange = (event, value) => {
    setstatus(value);
  };

  const newBranch = branch.map((i) => i.branch_name);
  const newTestType = testtype.map((i) => i.id);
  const newStatus = status.map((i) => i.value);
  const handleApplyFilter = () => {
    onApplyFilter({
      packageName,
      duration,
      newTestType,
      newBranch,
      newStatus,
    });
  };

  const resetfilter = () => {
    onResetFilter();
    setPackageName("");
    setduration("");
    setTesttype([]);
    setBranch([]);
    setstatus([]);
  };
  return (
    <>
      <Collapse in={open} >
        <MainCard>
          <Grid container spacing={2} mt={1} mb={2}>
            <Grid item xs={6} lg={3} sm={4} md={4}>
              <Typography sx={{ fontWeight: 600 }}>Package name</Typography>

              <TextField
                size="small"
                value={packageName}
                onChange={handleNamechange}
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={6} lg={3} sm={4} md={4}>
              <Typography sx={{ fontWeight: 600 }}>
                Duration (in session)
              </Typography>

              <TextField
                size="small"
                value={duration}
                onChange={handledurationchange}
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
                fullWidth
                type="number"
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <Typography sx={{ fontWeight: 600 }}>Test Type </Typography>

              <Autocomplete
                size="small"
                value={testtype}
                multiple
                onChange={handeltestTypechange}
                options={allTestTypes}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>Branch</Typography>

              <Autocomplete
                size="small"
                fullWidth
                value={branch}
                multiple
                onChange={handelbranchchange}
                options={allbranchList}
                getOptionLabel={(option) => option.branch_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Typography sx={{ fontWeight: 600 }}>Status</Typography>

              {/*           
           
                <Select
                
                  sx={{ borderRadius: "14px" }}
                  value={status}
                  onChange={handlestatuschange}
                  MenuProps={MenuProps}
                  multiple
                  renderValue={(selected) =>
                    selected
                      .map((value) => {
                       
                        const status = statuslist.find(
                          (status) => status.value === value
                        );
                        return status ? status.name : "";
                      })
                      .join(", ")
                  }
                >
                  
                  {statuslist.map((name) => (
                    <MenuItem key={name.id} value={name.value}>
                      <Checkbox checked={status.indexOf(name.value) > -1} />
                      <ListItemText primary={name.name} />
                    </MenuItem>
                  ))}
                </Select> */}

              <Autocomplete
                size="small"
                value={status}
                multiple
                onChange={handlestatuschange}
                options={statuslist}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={3} mt={2} ml={2}>
              <Button
                variant="contained"
                color="error"
                style={{
                  borderRadius: "24px",
                  fontWeight: 500,
                  fontSize: "14px",
                  marginRight: "8px",
                }}
                onClick={resetfilter}
              >
                Reset Filter
              </Button>

              <Button
                variant="contained"
                color="primary"
                style={{
                  borderRadius: "24px",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
                onClick={handleApplyFilter}
              >
                Apply Filter
              </Button>
            </Grid>
          </Grid>
        </MainCard>
      </Collapse>
    </>
  );
}
