import React, { useEffect, useState } from "react";
import MainCard from "../../components/ui-component/card/MainCard";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { fDate } from "../../../utils/formatTime";

import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterListTwoTone";
import PrintIcon from "@mui/icons-material/PrintTwoTone";
import FileCopyIcon from "@mui/icons-material/FileCopyTwoTone";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/AddTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import PackageForm from "./PackageEditForm";
import PackagesFilter from "./PackagesFilter";
import { useNavigate } from "react-router-dom";
import { useGetTokenOrLogout } from "../../../utils/token";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function PackagesList() {
  const getTokenOrLogout = useGetTokenOrLogout();
  const userRole = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const theme = useTheme();
  const [packages, setPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterCriteria, setFilterCriteria] = useState(null);
  const [originalData, setOriginalData] = useState([]);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleApplyFilter = (criteria) => {
    setFilterCriteria(criteria);
  };
  const handleResetFilter = () => {
    setFilterCriteria(null);
    setPackages(originalData);
    setSearchData(originalData);
  };

  const [isFormOpen, setFormOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = getTokenOrLogout();
      if (!token) return;

      let headers = { Authorization: `Bearer ${token}` };
      try {
        setIsLoading(true);
        const response = await fetch(
          `${API_ENDPOINT}route2.0/getpackages/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          );

          let filteredData = [...sortedData];
          console.log(filteredData);
          if (filterCriteria) {
            filteredData = filteredData.filter((packageData) => {
              let passesFilter = true;

              if (filterCriteria && filterCriteria.packageName) {
                const Name = packageData?.package_name
                  ? packageData.package_name.toLowerCase()
                  : ""; // Check if name is null or undefined

                const filterString = filterCriteria.packageName.toLowerCase();

                passesFilter = passesFilter && Name.includes(filterString);
              }

              if (filterCriteria && filterCriteria.duration) {
                const filterString =
                  parseInt(filterCriteria.duration) === packageData?.duration;
                console.log(filterString);
                passesFilter = passesFilter && filterString;
              }

              if (filterCriteria.newTestType.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.newTestType.includes(
                    packageData.test_type?.id
                  );
              }

              if (filterCriteria.newBranch.length > 0) {
                console.log("newBranch", packageData?.branch_id);
                passesFilter =
                  passesFilter &&
                  packageData?.branch_id?.some((branchId) =>
                    filterCriteria.newBranch.includes(branchId)
                  );
              }

              if (filterCriteria.newStatus.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.newStatus.includes(packageData?.status);
              }

              return passesFilter;
            });
          }
          console.log(data);
          setPackages(filteredData);
          setOriginalData(data);
          setSearchData(filteredData);
          setIsLoading(false);
          setPage(0);
        } else if (response.status === 401) {
          setIsLoading(false);
          navigate("/page401");
        } else if (response.status === 403) {
          setIsLoading(false);
          navigate("/page403");
        } else if (response.status === 440) {
          setIsLoading(false);
          navigate("/page440");
        } else {
          setIsLoading(false);
          navigate("/page500");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/page500");
      }
    };

    fetchData();
  }, [userId, filterCriteria]);

  const handleOpenForm = (packageData) => {
    setSelectedPackage(packageData);
    setFormOpen(true);
  };

  const handleCloseForm = () => {
    setSelectedPackage(null);
    setFormOpen(false);
  };

  const handleFormSubmit = () => {
    // Implement logic to handle form submission
    // You may want to update the data or make an API call
    handleCloseForm();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target.value, 10));
    setPage(0);
  };

  console.log(filterCriteria);

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    "& .MuiTableCell-head": {
      backgroundColor: "#abf7b1",
      fontWeight: 600,
      padding: "8px 16px",
      textAlign: "center",
    },

    "& .MuiTableCell-body": {
      fontSize: "14px",

      textAlign: "center",
      padding: "8px 16px",
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 600,
  }));

  // Search by name
  const handleNameChange = (e) => {
    const value = e.target.value;
    let filteredData1 = searchData.filter((data) =>
      data?.package_name
        ?.toLowerCase()
        .replace(/\s+/g, "")
        .includes(value.toLowerCase().replace(/\s+/g, ""))
    );
    setPackages(filteredData1);
    setPage(0);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Chip
            label="Packages List"
            sx={{
              background: "white",
              fontSize: "20px",
              fontWeight: 600,
              padding: "10px",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
          {/* product add & dialog */}
          {(userRole === "center_admin" || userRole === "developer") && (
            // <Tooltip title="Add Product">
            //   <Fab
            //     color="primary"
            //     size="small"
            //     sx={{
            //       boxShadow: "none",
            //       ml: 1,
            //       width: 32,
            //       height: 32,
            //       minHeight: 32,
            //     }}
            //   >
            //     <AddIcon
            //       fontSize="small"
            //       onClick={() => handleOpenForm(null)}
            //     />
            //   </Fab>
            // </Tooltip>
            <Button
              onClick={() => handleOpenForm(null)}
              variant="contained"
              style={{ borderRadius: "25px", marginRight: "10px" }}
              size="small"
              startIcon={<AddCircleOutlineIcon />}
            >
              Add Packages
            </Button>
          )}

          <Button
            size="small"
            style={{
              background: "white",
              color: "black",
              borderRadius: "25px",
              fontWeight: 600,
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            onClick={handleToggle}
            startIcon={<FilterAltIcon size="small" color="orange" />}
            endIcon={
              !open ? (
                <KeyboardArrowDownIcon fontSize="small" />
              ) : (
                <KeyboardArrowUpIcon fontSize="small" />
              )
            }
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12}>
          <PackagesFilter
            open={open}
            onApplyFilter={handleApplyFilter}
            onResetFilter={handleResetFilter}
          />
        </Grid>

        <PackageForm
          open={isFormOpen}
          handleClose={handleCloseForm}
          handleSubmit={handleFormSubmit}
          formData={selectedPackage}
        />
        <Grid item xs={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <Typography>Search:</Typography>
                  <TextField
                    size="small"
                    onChange={handleNameChange}
                    InputProps={{
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                {/* table */}
                <StyledTableContainer component={Paper}>
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Package Name</StyledTableCell>
                        <StyledTableCell>Duration (In session)</StyledTableCell>
                        <StyledTableCell>Test Type</StyledTableCell>
                        <StyledTableCell>Sub Test Type</StyledTableCell>
                        <StyledTableCell>Branch </StyledTableCell>
                        <StyledTableCell>Original Price</StyledTableCell>
                        <StyledTableCell>Discounted Price</StyledTableCell>
                        <StyledTableCell>Payable Installments</StyledTableCell>
                        <StyledTableCell>Max Allowable Days</StyledTableCell>
                        <StyledTableCell>Mock Tests</StyledTableCell>
                        <StyledTableCell>Module-Wise Tests</StyledTableCell>
                        <StyledTableCell>Question-Wise Tests</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        {(userRole === "center_admin" 
                                ) && (
                        <StyledTableCell>Actions</StyledTableCell>)}
                      </TableRow>
                    </TableHead>

                    {isLoading ? (
                      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
                        <Stack direction="row" spacing={1}>
                          <Typography
                            variant="h4"
                            color="secondary"
                            fontWeight={800}
                            marginRight={2}
                          >
                            Fetching
                          </Typography>
                          <CircularProgress color="primary" />
                        </Stack>
                      </Backdrop>
                    ) : packages.length === 0 ? (
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h5">No data available</Typography>
                      </Grid>
                    ) : (
                      <TableBody>
                        {packages
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => (
                            <TableRow
                              hover
                              role="checkbox"
                              // aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={index}
                              // selected={isItemSelected}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#f0f0f0" : "inherit",
                              }}
                            >
                              <TableCell
                               
                             
                              >
                            
                                  {row.package_name}{" "}
                               
                              </TableCell>
                              <TableCell >
                                {row.duration}
                              </TableCell>
                              <TableCell >
                                {row.test_type?.name}
                              </TableCell>
                              <TableCell >
                                {row.sub_test_type_id?.name}
                              </TableCell>
                              <TableCell >
                                {row.branch_id.join(" , ")}
                              </TableCell>
                              <TableCell >
                                {row.original_price}
                              </TableCell>
                              <TableCell >
                                {row.discounted_price}
                              </TableCell>
                              <TableCell>
                                {row.payable_installment}
                              </TableCell>
                              <TableCell>
                              {row.max_allow_day}
                              </TableCell>
                              <TableCell>
                              {row.mock_tests}
                              </TableCell>
                              <TableCell>
                              {row.module_wise_questions}
                              </TableCell>
                              <TableCell>
                              {row.question_wise_questions}
                              </TableCell>
                              <TableCell >
                                {row.status === "active" && (
                                  <Chip
                                    label="Active"
                                    size="small"
                                    color="secondary"
                                  />
                                )}
                                {row.status === "inActive" && (
                                  <Chip
                                    label="Inactive"
                                    size="small"
                                    color="error"
                                  />
                                )}
                              </TableCell>
                              {(userRole === "center_admin" 
                                 ) && (
                              <TableCell align="center" sx={{ pr: 3 }}>
                               
                                  <IconButton
                                    color="primary"
                                    size="small"
                                    aria-label="edit"
                                    onClick={() => handleOpenForm(row)}
                                  >
                                    <Tooltip title="Edit">
                                      <EditIcon fontSize="small" />
                                    </Tooltip>
                                  </IconButton>
                           
                              </TableCell>     )}
                            </TableRow>
                          ))}
                      </TableBody>
                    )}
                  </Table>
                </StyledTableContainer>

                {/* table pagination */}
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={packages.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
}

export default PackagesList;
