import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import RevenueCard from "../AdminDashboardRevenue/RevenueCard";
import MainCard from "../../components/Maincard";
import ReactApexChart from "react-apexcharts";
import LeadCalandar from "../LeadConversation/LeadCalandar";
import AdminRevenueTable from "./AdminRevenueTable";
import AdminRevenueCard from "./AdminRevenueCard";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { useGetTokenOrLogout } from "../../utils/token";
import { useDateUtils } from "../../utils/formatTime";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function AdminRevenue() {
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const getTokenOrLogout = useGetTokenOrLogout();

  const navigate = useNavigate();
  const userRole = localStorage.getItem("userRole");
  const [dailyData, setDailyData] = useState({});
  const [monthlyData, setMonthlyData] = useState({});
  const [profileData, setProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isCenterLoading, setIsCenterLoading] = useState(false);
  const [centerRevenueData, setCenterRevenueData] = useState([]);

  const [isPieChartLoading, setIsPieChartLoading] = useState(false);
  const [pieChartData, setPieChartData] = useState({});

  const [filterType, setFilterType] = useState("today");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const [chosenDate, setChosenDate] = useState(new Date());

  const { currentMonthName, lastMonthName, weekRange } = useDateUtils();

  const handleDateChange = (date) => {
    setChosenDate(date);

    // getPieChartData(date)
  };

  const cardData = [
    {
      id: 1,
      background: "#fcdcb6",
      iconBackground: "#FF6B47",
      iconSrc: "/Revenue.svg",
      value: 300,
      label: "Total Revenue",
      //   lineSrc: '/ActiveStudentLine.svg',
    },
    {
      id: 2,
      background: "#b6fcbd",
      iconBackground: "#3CD856",
      iconSrc: "/Expenses.svg",
      value: 120,
      label: "Total Collected Revenue",
      //   lineSrc: '/PresentStudentLine.svg',
    },
    {
      id: 3,
      background: "#f7c8e5",
      iconBackground: "#FA5A7D",
      iconSrc: "/Profit.svg",
      value: 180,
      label: "Total Pending Revenue",
      //   lineSrc: '/Absentstudentline.svg',
    },
    {
      id: 4,
      background: "#a0b8ff",
      iconBackground: "#5e43f7",
      iconSrc: "/Active Student.svg",
      value: 512,
      label: "New Enrollment",
      // lineSrc: '/Absentstudentline.svg',
    },
    {
      id: 5,
      background: "#c1b6fa",

      iconSrc: "/centericon.png",
      value: 318,
      label: "Total No. of Center",
      // lineSrc: '/Absentstudentline.svg',
    },
  ];
  const length = cardData.length;
  console.log(length);

  const updatedSeriesData = [{ Collected_Revenue: 6.8, Pending: 3.2 }];

  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: "donut",
      },
      plotOptions: {
        pie: {
          donut: {
            size: "50%", // Adjust the size to control the width of the slices
          },
        },
      },
      //   labels: percentages,
      colors: ["#019E6F", "#FABA3A"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
      tooltip: {
        enabled: false,
      },
      legend: {
        show: false,
      },
    },
  });
  //const presentAbsentArray = pieChartData.map(data => [data.pending_revenue, data.collected_revenue]).flat();
  let array = [];
  const arrayData = Object.values(pieChartData);

  const selectedKeys = ["pending_revenue", "collected_revenue"];

  const arr = Object.entries(pieChartData)
    .filter(([key]) => selectedKeys.includes(key))
    .map(([, value]) => value);

  console.log(arr);

  useEffect(() => {
    setChartData((prevData) => ({
      ...prevData,
      series: arr,
    }));
  }, [pieChartData]);

  const totalRevenue = updatedSeriesData.reduce(
    (total, data) => total + data.Collected_Revenue + data.Pending,
    0
  );

  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
  };

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const handleApplyFilter = () => {
    getRevenueForCenters();
  };

  const fetchAdminDailyData = () => {
    setIsLoading(true);
    let headers = { Authorization: `Bearer ${token}` };

    fetch(`${API_ENDPOINT}route/gettodayrevenue/${userId}`, { headers })
      .then((response) => response.json())
      .then((data) => {
        setDailyData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching daily data:", error);
        setIsLoading(false);
      });
  };

  const fetchAdminMonthlyData = () => {
    setIsLoading(true);
    fetch(`${API_ENDPOINT}route/getenrollrevenue/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setMonthlyData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching daily data:", error);
        setIsLoading(false);
      });
  };

  const fetchProfileData = async () => {
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await fetch(`${API_ENDPOINT}employee/${userId}`, {
        headers,
      });
      if (response.ok) {
        const data = await response.json();
        console.log("data", data);

        setProfileData(data);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      navigate("/page500");
    }
  };

  const handleEdit = () => {
    const newState = { employeedetails: profileData };
    localStorage.setItem("employeeState", JSON.stringify(newState));

    const url = `/view-profile/${profileData?.id}`;
    const newTab = window.open(url, "_blank");

    if (!newTab) {
      navigate(url, { state: newState });
    }
  };

  const getRevenueForCenters = () => {
    setIsCenterLoading(true);

    const requestData = {
      user_id: userId,
      filter: filterType,
      to_date: toDate,
      from_date: fromDate,
    };

    fetch(`${API_ENDPOINT}route/highestrevenuecenters`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        setCenterRevenueData(data);
        setIsCenterLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching daily data:", error);
        setIsCenterLoading(false);
      });
  };

  const getPieChartData = (date) => {
    setIsPieChartLoading(true);

    const requestData = {
      user_id: userId,
      date: date,
    };

    fetch(`${API_ENDPOINT}route/piechart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setPieChartData(data);
        setIsPieChartLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching daily data:", error);
        setIsPieChartLoading(false);
      });
  };

  console.log(pieChartData);

  useEffect(() => {
    fetchAdminDailyData();
    fetchAdminMonthlyData();
    fetchProfileData();
    getRevenueForCenters();
    //getPieChartData(chosenDate)
  }, []);

  useEffect(() => {
    getPieChartData(chosenDate);
  }, [chosenDate]);

  const { first_name, last_name, role, branch } = profileData || {};



  return (
    <Box
      className="box"
      sx={{ height: "1086px", width: "100%", position: "relative", color: "" }}
    >
      {/* Gradient Background */}
      <Box
        className="rectangle"
        sx={{
          background:
            "linear-gradient(248deg, #FFF6F0 -41.57%, rgba(255, 246, 240, 0.00) 97.88%)",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: -1,
        }}
      />

      <Box
        style={{ textAlign: "left" }}
        sx={{
          position: "absolute",
          // top: '40%',
          // left: '40%',
          // transform: 'translate(-50%, -50%)',
          textAlign: "center",
          zIndex: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} lg={5}>
            <MainCard>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 800,
                }}
              >
                Dashboard
              </Typography>
              <Typography variant="h4">
                {first_name ? capitalizeFirstLetter(first_name) : ""}{" "}
                {last_name ? capitalizeFirstLetter(last_name) : ""}{" "}
                <Chip label="Super Admin" color="primary" />
              </Typography>

              <Grid item xs={12} textAlign="end">
                <Button variant="contained" onClick={handleEdit}>
                  Edit My Profile
                </Button>
              </Grid>
            </MainCard>
          </Grid>
          <Grid item xs={12} sm={6} lg={3.5} md={6}>
            <MainCard sx={{ background: "#fcdcb6" }}>
              <Stack display="flex" flexDirection="row" alignItems="center">
                <img src="/newinroll.png" />
                <Typography
                  sx={{
                    marginLeft: "10px",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  New Enrollment this month :{" "}
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    monthlyData?.total_new_enrollment
                  )}
                </Typography>
              </Stack>
            </MainCard>
          </Grid>
          <Grid item xs={12} sm={6} lg={3.5} md={6}>
            <MainCard sx={{ background: "#b6fcbd" }}>
              <Stack display="flex" flexDirection="row" alignItems="center">
                <img src="/totalrevenue.png" />
                <Typography
                  sx={{
                    marginLeft: "10px",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  Total Revenue this month: ₹{" "}
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    monthlyData?.total_revenue
                  )}{" "}
                </Typography>
              </Stack>
            </MainCard>
          </Grid>

          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={2.3} sm={6}>
                <AdminRevenueCard
                  //primary="2"
                  background="#fcdcb6"
                  iconBackground="#FF6B47"
                  iconSrc="/Revenue.svg"
                  value={
                    isLoading ? <CircularProgress /> : dailyData?.total_revenue
                  }
                  label="Total Revenue"
                />
              </Grid>
              <Grid item xs={12} lg={2.3} sm={6}>
                <AdminRevenueCard
                  background="#b6fcbd"
                  iconBackground="#3CD856"
                  iconSrc="/Expenses.svg"
                  value={
                    isLoading ? (
                      <CircularProgress />
                    ) : (
                      dailyData?.total_collected_revenue
                    )
                  }
                  label="Total Collected Revenue"
                />
              </Grid>
              <Grid item xs={12} lg={2.3} sm={6}>
                <AdminRevenueCard
                  background="#f7c8e5"
                  iconBackground="#FA5A7D"
                  iconSrc="/Profit.svg"
                  value={
                    isLoading ? (
                      <CircularProgress />
                    ) : (
                      dailyData?.total_pending_revenue
                    )
                  }
                  label="Total Pending Revenue"
                />
              </Grid>
              <Grid item xs={12} lg={2.3} sm={6}>
                <AdminRevenueCard
                  background="#a0b8ff"
                  iconBackground="#5e43f7"
                  iconSrc="/Active Student.svg"
                  value={
                    isLoading ? (
                      <CircularProgress />
                    ) : (
                      dailyData?.total_new_enrollment
                    )
                  }
                  label="New Enrollment"
                />
              </Grid>
              <Grid item xs={12} lg={2.3} sm={6}>
                <AdminRevenueCard
                  background="#c1b6fa"
                  //iconBackground = '#3CD856'
                  iconSrc="/centericon.png"
                  value={
                    isLoading ? <CircularProgress /> : dailyData?.total_center
                  }
                  label="Total No. of Center"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={9} md={12}>
            <MainCard sx={{ height: "500px" }}>
              <Box display="flex" justifyContent="space-between" mt={2}>
                <Box>
                  <Typography
                    sx={{
                      color: "#019E6F",
                      fontFamily: "Poppins",
                      fontSize: "23px",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    Highest Revenue Generating Centers
                  </Typography>
                </Box>
                <Box>
                  <Select
                    value={filterType}
                    onChange={handleFilterChange}
                    sx={{ marginLeft: 2, width: "150px" }}
                  >
                    <MenuItem value="today">Today</MenuItem>
                    <MenuItem value="currentweek">
                      This Week({weekRange})
                    </MenuItem>
                    <MenuItem value="currentmonth">
                      This Month({currentMonthName})
                    </MenuItem>
                    <MenuItem value="pastmonth">
                      Last Month({lastMonthName})
                    </MenuItem>
                    <MenuItem value="custom">Custom</MenuItem>
                  </Select>
                  {filterType === "custom" && (
                    <>
                      <TextField
                        type="date"
                        label="From"
                        value={fromDate}
                        onChange={handleFromDateChange}
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: "150px", marginRight: 2, marginLeft: 2 }}
                        inputProps={{
                          max: new Date().toISOString().split("T")[0],
                        }}
                      />
                      <TextField
                        type="date"
                        label="To"
                        value={toDate}
                        onChange={handleToDateChange}
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: "150px", marginRight: 2, marginLeft: 2 }}
                        inputProps={{
                          max: new Date().toISOString().split("T")[0],
                        }}
                      />
                    </>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleApplyFilter}
                    sx={{ marginLeft: 2 }}
                  >
                    Apply Filter
                  </Button>
                </Box>
              </Box>
              <Divider />
              {isCenterLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={2}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <AdminRevenueTable data={centerRevenueData} />
              )}
            </MainCard>
          </Grid>

          <Grid item xs={12} lg={3} md={12}>
            <MainCard>
              <LeadCalandar onDateChange={handleDateChange} />

              {isPieChartLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={2}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <Stack
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ReactApexChart
                      options={chartData.options}
                      series={chartData.series}
                      type="donut"
                      width="280px"
                    />
                  </Stack>
                  <Stack
                    display="flex"
                    justifyContent="space-between"
                    flexDirection="row"
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "Primary/Gray/600",
                      }}
                    >
                      Total Revenue
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "Primary/Gray/600",
                      }}
                    >
                      {pieChartData?.total_revenue}
                    </Typography>
                  </Stack>

                  <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack>
                      <Box
                        sx={{
                          bgcolor: "#01BE85",
                          width: 10,
                          height: 10,
                          borderRadius: "50%",
                        }}
                      />
                    </Stack>
                    <Stack
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      width="100%"
                      justifyContent="space-between"
                      marginLeft={1}
                    >
                      <Typography>Collected Revenue </Typography>
                      <Typography>{pieChartData?.collected_revenue}</Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack>
                      <Box
                        sx={{
                          bgcolor: "#FABA3A",
                          width: 10,
                          height: 10,
                          borderRadius: "50%",
                        }}
                      />
                    </Stack>
                    <Stack
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      width="100%"
                      justifyContent="space-between"
                      marginLeft={1}
                    >
                      <Typography>Pending</Typography>
                      <Typography>{pieChartData?.pending_revenue}</Typography>
                    </Stack>
                  </Stack>
                </>
              )}
            </MainCard>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
