import React, { useEffect, useState } from 'react'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentTwoTone';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltTwoTone';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayTwoTone';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import AccountCircleTwoTone from '@mui/icons-material/AccountCircleTwoTone';
import LocalMallTwoToneIcon from '@mui/icons-material/LocalMallTwoTone';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import BranchCard from '../../components/ui-component/card/branchdashboard/BranchData';
import RevenueCard from '../../components/ui-component/card/branchdashboard/RevenueCard';
import { AppBar, Box, Button, CircularProgress, Grid, Stack, Toolbar, Typography, useTheme } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { gridSpacing } from '../../store/constant';
import RowTable from './data/RowTable';
import ClassTabs from './data/classes/ClassTab';
import RoomIcon from '@mui/icons-material/Room';
import SchoolIcon from '@mui/icons-material/School';
import PaymentsIcon from '@mui/icons-material/Payments';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import TrainerTable from './data/trainer/TrainerTable';
import AdvisorTable from './data/advisor/AdvisorTable';
import { useGetTokenOrLogout } from '../../../utils/token';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

function BranchDashboard() {
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
    const userRole = localStorage.getItem('userRole');
    const theme = useTheme()
    const { id } = useParams();
    const location = useLocation();
    const { state } = location;

    const { branchName, branchAdmin } = location.state || {};

    console.log(location.state)

    const [nameBranch, setNameBranch] = useState(branchName)
    

    const [branchId, setBranchId] = useState(id)
    
   // const [branchName, setBranchName] = useState(name)
   // const [branchAdmin, setBranchAdmin] = useState(admin)

    console.log(branchAdmin, branchName, nameBranch)

    const [isRoomDialogOpen, setRoomDialogOpen] = useState(false);
  const [isClassDialogOpen, setClassDialogOpen] = useState(false);
  const [isTrainerDialogOpen, setTrainerDialogOpen] = useState(false);
  const [isAdvisorDialogOpen, setAdvisorDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [branchData, setBranchData] = useState({});
  const [branchInfo, setBranchInfo] = useState([])
  const [isRoomLoading, setIsRoomLoading] = useState(false);
  const [branchRooms, setBranchRooms] = useState([])
  const [branchTrainers, setBranchTrainers] = useState([])
  const [isTrainerLoading, setIsTrainerLoading] = useState(false)
  const [branchAdvisors, setBranchAdvisors] = useState([])
  const [isAdvisorLoading, setIsAdvisorLoading] = useState(false)
  const [name, setName] = useState(branchInfo?.branch_name)

  const [roomCount, setRoomCount] = useState(null);
  const [classCount, setClassCount] = useState(null);

  const handleRoomViewClick = () => {
    setRoomDialogOpen(true);
    setClassDialogOpen(false);
    setTrainerDialogOpen(false);
    setAdvisorDialogOpen(false);
    // Add any other logic you want to execute when "Room View" is clicked
  };

  const handleClassViewClick = () => {
    setClassDialogOpen(true);
    setRoomDialogOpen(false);
    setTrainerDialogOpen(false);
    setAdvisorDialogOpen(false);
    // Add any other logic you want to execute when "Class View" is clicked
  };

  const handleTrainerViewClick = () => {
    setTrainerDialogOpen(true);
    setClassDialogOpen(false);
    setRoomDialogOpen(false);
    setAdvisorDialogOpen(false);
    // Add any other logic you want to execute when "Room View" is clicked
  };

  const handleAdvisorViewClick = () => {
    setAdvisorDialogOpen(true);
    setTrainerDialogOpen(false);
    setClassDialogOpen(false);
    setRoomDialogOpen(false);
    // Add any other logic you want to execute when "Room View" is clicked
  };

  const handleCloseRoomDialog = () => {
    setRoomDialogOpen(false);
  };

  const handleCloseClassDialog = () => {
    setClassDialogOpen(false);
  };



 const fetchBranchData = async () => {
  setIsLoading(true)
   const token = getTokenOrLogout();
   if (!token) return;
   let headers = {
     Authorization: `Bearer ${token}`,
   };
 
   try {
     const response = await fetch(
       `${API_ENDPOINT}centeradmin/${branchId}`,
       { headers }
     );
     if (response.ok) {
       const data = await response.json();
       console.log("data", data);
 
       setBranchData(data);
                    setIsLoading(false);
                 
     } else if (response.status === 404) {
       navigate("/page404");
     } else if (response.status === 401) {
       navigate("/page401");
     } else if (response.status === 403) {
       navigate("/page403");
     } else if (response.status === 440) {
       navigate("/page440");
     } else {
       navigate("/page500");
     }
   } catch (error) {
     navigate("/page500");
   }
 }


 const fetchBranchInfo = () => {
    setIsLoading(true)
     fetch(`${API_ENDPOINT}branch/${branchId}`)
         .then(response => response.json())
         .then(data => {
             setBranchInfo(data);
             setIsLoading(false);
         })
         .catch(error => {
             console.error('Error fetching branch data:', error);
             setIsLoading(false);
         });
 };

//  const fetchBranchRooms = () => {
//     setIsLoading(true)
//      fetch(`${API_ENDPOINT}route/rooms/${branchId}`)
//          .then(response => response.json())
//          .then(data => {
//              setBranchRooms(data);
//              setIsRoomLoading(false);
//              console.log(data);
//          })
//          .catch(error => {
//              console.error('Error fetching branch rooms:', error);
//              setIsRoomLoading(false);
//          });
//  };

 const fetchBranchRooms = async () => {
  setIsRoomLoading(true)
   const token = getTokenOrLogout();
   if (!token) return;
   let headers = {
     Authorization: `Bearer ${token}`,
   };
 
   try {
     const response = await fetch(
       `${API_ENDPOINT}route/rooms/${branchId}`,
       { headers }
     );
     if (response.ok) {
       const data = await response.json();
       console.log("data", data);
 
       setBranchRooms(data);
       setIsRoomLoading(false);
                 
     } else if (response.status === 404) {
       navigate("/page404");
     } else if (response.status === 401) {
       navigate("/page401");
     } else if (response.status === 403) {
       navigate("/page403");
     } else if (response.status === 440) {
       navigate("/page440");
     } else {
       navigate("/page500");
     }
   } catch (error) {
     navigate("/page500");
   }
 }

 const fetchBranchTrainers = () => {
    setIsLoading(true)
     fetch(`${API_ENDPOINT}route/trainers/${branchId}`)
     .then((response) => {
      if (response.status === 403) {
        // Handle 403 error by setting branchTrainers to an empty array
        setBranchTrainers([]);
        throw new Error('Forbidden - 403 Error');
      }
      return response.json();
    })
         .then(data => {
             setBranchTrainers(data);
             setIsTrainerLoading(false);
             console.log(data);
         })
         .catch(error => {
             console.error('Error fetching branch trainers:', error);
             setIsTrainerLoading(false);
         });
 };



 const fetchBranchAdvisors = async () => {
  setIsAdvisorLoading(true)
   const token = getTokenOrLogout();
   if (!token) return;
   let headers = {
     Authorization: `Bearer ${token}`,
   };
 
   try {
     const response = await fetch(
       `${API_ENDPOINT}route/getadvisor/${branchId}`,
       { headers }
     );
     if (response.ok) {
       const data = await response.json();
       console.log("data", data);
 
       setBranchAdvisors(data);
       setIsAdvisorLoading(false);
                 
     } else if (response.status === 404) {
       navigate("/page404");
     } else if (response.status === 401) {
       navigate("/page401");
     } else if (response.status === 403) {
       navigate("/page403");
     } else if (response.status === 440) {
       navigate("/page440");
     } else {
       navigate("/page500");
     }
   } catch (error) {
     navigate("/page500");
   }
 }

 useEffect(() => {
    fetchBranchInfo();
     fetchBranchData();
     fetchBranchRooms();
     fetchBranchTrainers()
     fetchBranchAdvisors()
 }, []); 


  return (
    <>
   <Box mb={2}>
    <AppBar position="static" color="secondary">
        <Toolbar>
            
            <Stack direction='row' spacing={2}>
            <Typography variant='h4'>User Role: {userRole}</Typography>
            <Typography variant='h4'>In View: {branchInfo?.branch_name}</Typography>
            <Typography variant='h4'>Branch Admin: {branchInfo?.admin_id?.first_name} </Typography>
            </Stack>
            
        </Toolbar>
    </AppBar>
    </Box>
    <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
        <Stack direction='row' spacing={2}>
          <Link to='/addenquirynew'>
          <Button variant='contained'>Add New Enquiry</Button>
          </Link>
          <Link to='/enquiries'>
          <Button variant='contained' color='error'>Add Bulk Enquiries</Button>
          </Link>
          <Link to='/enroll-student'>
          <Button variant='contained' color='secondary'>Enroll New Student</Button>
          </Link>
          </Stack>
        </Grid>
            <Grid item xs={12} lg={3} sm={6}>
                <BranchCard
                    //primary="2"
                    primary={isLoading ? <CircularProgress /> : branchData?.rooms}
                    secondary="Rooms"
                    color={theme.palette.secondary.main}
                    iconPrimary={RoomIcon}
                    onClick={handleRoomViewClick}
                />
            </Grid>
            <Grid item xs={12} lg={3} sm={6}>
                <BranchCard  primary={isLoading ? <CircularProgress /> : branchData?.classes} secondary="Classes" color={theme.palette.error.main} iconPrimary={SchoolIcon}  onClick={handleClassViewClick}/>
            </Grid>
            <Grid item xs={12} lg={3} sm={6}>
                <BranchCard  primary={isLoading ? <CircularProgress /> : branchData?.trainers} secondary="Trainers" color={theme.palette.success.dark} iconPrimary={PeopleAltIcon} onClick={handleTrainerViewClick} />
            </Grid>
            <Grid item xs={12} lg={3} sm={6}>
                <BranchCard  primary={isLoading ? <CircularProgress /> : branchData?.advisors} secondary="Advisors" color={theme.palette.primary.main} iconPrimary={PeopleAltIcon} onClick={handleAdvisorViewClick}/>
            </Grid>

            
            <Grid item xs={12} lg={4} sm={6}>
                <RevenueCard
                    primary={`Today's Demos Booked : ${isLoading ? <CircularProgress /> : branchData?.demos_booked}`}
                    secondary={`Today's Enquiries : ${isLoading ? <CircularProgress /> : branchData?.enquiries}`}
                    content={`Today's Enrolled: ${isLoading ? <CircularProgress /> : branchData?.enrolled_students}`}
                    iconPrimary={AccountCircleTwoTone}
                    color={theme.palette.primary.dark}
                    action='View'
                    link='/enquiries'

                />
            </Grid>
            <Grid item xs={12} lg={4} sm={6}>
                <RevenueCard
                    primary="Today's Active Students"
                    secondary={isLoading ? <CircularProgress /> : branchData?.active_students}
                   // content="$1,055 Revenue Generated"
                    iconPrimary={SchoolIcon}
                    color={theme.palette.orange.dark}
                    action='View'
                    link='/view-students'
                />
            </Grid>
            <Grid item xs={12} lg={4} sm={6}>
                <RevenueCard
                    primary="Today's Total Payments"
                    secondary={isLoading ? <CircularProgress /> : branchData?.payment_counts}
                    content={`Rs ${isLoading ? <CircularProgress /> : branchData?.total_payments} Revenue Generated`}
                    iconPrimary={PaymentsIcon}
                    color={theme.palette.secondary.main}
                    action='View'
                    link='/all-payments'
                />
            </Grid>

            {isRoomDialogOpen && (
                isRoomLoading ? (
                    <Box
                    style={{minHeight: '50vh'}}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                   
                >
                    <CircularProgress color="primary" />
                  </Box>
                ) : (
          <Grid item xs={12}>
            <RowTable rooms={branchRooms} branchId={branchId} branchName={branchInfo?.branch_name}/>
          </Grid>
        ))}

        {isClassDialogOpen && (
          <Grid item xs={12}>
            <ClassTabs branchRooms={branchRooms} branchId={branchId}/>
          </Grid>
        )}

            {isTrainerDialogOpen && (
                isTrainerLoading ? (
                    <Box
                    style={{minHeight: '50vh'}}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                   
                >
                    <CircularProgress color="primary" />
                  </Box>
                ) : (
          <Grid item xs={12}>
            <TrainerTable trainers={branchTrainers} branchId={branchId} branchName={branchInfo?.branch_name}/>
          </Grid>
        ))}

{isAdvisorDialogOpen && (
                isAdvisorLoading ? (
                    <Box
                    style={{minHeight: '50vh'}}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                   
                >
                    <CircularProgress color="primary" />
                  </Box>
                ) : (
          <Grid item xs={12}>
            <AdvisorTable advisors={branchAdvisors} branchId={branchId} branchName={branchInfo?.branch_name}/>
          </Grid>
        ))}

            
            </Grid>
            </>
  )
}

export default BranchDashboard