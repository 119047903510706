import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function AssignToAdivosor({ selectedid, onClose }) {
  console.log("id is", selectedid);
  const userId = localStorage.getItem("userId");
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [advisorList, setAdvisorList] = useState([]);
  const [advisor, setAdvisor] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isLoading, setIsLoading] = useState(false);

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handleadvisor = (event, value) => {
    setAdvisor(value);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();

          setAdvisorList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSave = async () => {
if(advisor===null){
  showSnackbar("Please choose advisor name", 'error')
  return
}

    setIsLoading(true);
    try {
      const response = await fetch(`${API_ENDPOINT}route/createenquiry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: parseInt(userId),
          assign_to: advisor?.id,
          website_enq_id: selectedid,
        }),
      });
      if (response.ok) {
        // Handle success
        setIsLoading(false);
        showSnackbar("Enquiry assigned successfully");
        console.log("Enquiry created successfully.");

        setTimeout(() => {
          onClose();
          window.location.reload(); // Assuming handleClose is a function you've defined elsewhere
        }, 1500);
      } else if (response.status === 409) {
        // Handle conflict (status code 409)
        setIsLoading(false);
        showSnackbar("Duplicate mobile number", "error");
        console.error("Duplicate mobile number. Please use a different one.");
        setTimeout(() => {
          onClose(); // Assuming handleClose is a function you've defined elsewhere
        }, 1500);
      } else {
        // Handle other status codes
        setIsLoading(false);
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error creating enquiry:", error);
    }
  };
  console.log(advisorList);
  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Stack direction="row" spacing={1}>
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={800}
            marginRight={2}
          >
            Assigning Enquiries....
          </Typography>
          <CircularProgress color="primary" />
        </Stack>
      </Backdrop>
      <Grid container>
        <Grid item xs={6} lg={12} md={6} sm={6}>
          <FormControl fullWidth>
            <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
              Name Of The Advisor <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "4px",
                  fontSize: "1em",
                }}
              >
                *
              </span>
            </Typography>
            {/* <Select
              style={{ borderRadius: "15px" }}
              MenuProps={MenuProps}
              value={advisor}
              onChange={handleadvisor}
            >
              {advisorList.map((item) => (
                <MenuItem value={item.id}>
                  {item.first_name} {item.last_name}
                </MenuItem>
              ))}
            </Select> */}

<Autocomplete
        
              size="small"
              value={advisor}
              onChange={handleadvisor}
              options={advisorList}
              getOptionLabel={(option) =>
                `${option.first_name ? option.first_name : ""} ${
                  option.last_name ? option.last_name : ""
                }`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                  fullWidth
                />
              )}
              sx={{
                "& .MuiAutocomplete-listbox": {
                  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                  width: 250,
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          letterSpacing={12}
          md={12}
          sm={12}
          textAlign="end"
          marginTop="10px"
        >
          <Button
            variant="contained"
            color="secondary"
            style={{ fontSize: "16px", borderRadius: "24px" }}
            onClick={handleSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
