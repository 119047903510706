import React, { useEffect, useState } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  ButtonBase,
  Checkbox,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useGetTokenOrLogout } from "../../../utils/token";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../ui-component/snackbar/CustomSnackbar";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function EnquaryFilter({
  setOriginalRows,
  setRows,
  originalRows,
  setIsLoading,
  originaEnquiryData,
  setGridKey,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const [expanded, setExpanded] = useState(false);
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [perspective, setPerspective] = useState([]);
  const [perspectiveName, setPerspectiveName] = React.useState([]);
  const [ExamType, setExamType] = useState([]);
  const [branch, setbranch] = useState([]);
  const [ExamTypename, setExamTypename] = useState([]);
  const [branchname, setbranchname] = useState([]);

  const [nearestbranchname, setnearestbranchname] = useState([]);

  const [leadOptions, setLeadoptions] = useState([]);
  const [leadName, setLeadname] = useState([]);
  const [interestlevel, setInterstlevel] = useState([]);
  const [intrestlevelname, setinterestlevelname] = useState([]);
  const [stages, setStages] = useState([]);
  const [stagename, setStagename] = useState([]);
  const [assignment, setassignment] = useState([]);
  const [coassignee, setCoassignee] = useState([]);
  const [asignmentName, setAssignmentName] = useState([]);
  const [name, setName] = useState("");
  const [subServiceList, setSubServiceList] = useState([]);
  const [subservice, setSubService] = useState([]);
  const [recycleResonList, setRecycleResonList] = useState([]);
  const [recycleresonName, setRecycleResonName] = useState([]);
  const [prospectiveLevelId, setProspectiveLevelId] = useState([]);
  const [prospectiveLevelFilter, setProspectiveLevelFiletr] = useState(1);
  const [subserviceFilter, setSubserviceFiletr] = useState(1);
  const [selectedSubserviceId, setSelectedSubserviceId] = useState([]);
  const [examTypeFilter, setExamTypeFilter] = useState(1);
  const [selectexamTypeIds, setSelectedExamTypeIds] = useState([]);
  const [leadSourceFilter, setLeadSourceFilter] = useState(1);
  const [selectedLeadSourceIds, setSelectedLeadSourceIds] = useState([]);
  const [interestLevelFilter, setInterestLevelFilter] = useState(1);
  const [selectedInterestLevelIds, setSelectedInterestLevelIds] = useState([]);
  const [branchFilter, setBranchFilter] = useState(1);
  const [selectedBranchIds, setSelectedBranchIds] = useState([]);
  const [nearestBranchFilter, setNearestBranchFilter] = useState(1);
  const [selectedNearesrBranchIds, setSelectedNearesrBranchIds] = useState([]);
  const [enrolledfromDate, setEnrolledFromDate] = useState("");
  const [enrolledTodate, setEnrolledToDate] = useState("");
  const [enrollendByList, setEnrolledByList] = useState([]);
  const [enrolledBy, setEnrolledBy] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  console.log(prospectiveLevelFilter, "prospectiveLevelFilter");
  const handleApplyFilter = async (event) => {
    setIsLoading(true);
    handleDrawerClose();
    setGridKey((prev) => prev + 1);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };

    const requestData = {
      user_id: parseInt(userId),
      from_date: startDate || null,
      to_date: endDate || null,
      enquiry_name: name || null,
      prospective_level_id: prospectiveLevelId,
      test_type_id: selectexamTypeIds,
      lead_source_id: selectedLeadSourceIds,
      interest_level_id: selectedInterestLevelIds,
      branch_id: selectedBranchIds,
      nearest_branch_id: selectedNearesrBranchIds,
      stage_id: stagename.map((i) => i.id),
      assign_to: asignmentName.map((i) => i.id),
      sub_service_id: selectedSubserviceId,
      recycle_reason_id: recycleresonName.map((i) => i.id),
      co_assignee: coassignee.map((i) => i.id),
      enrolled_from_date: enrolledfromDate || null,
      enrolled_to_date: enrolledTodate || null,
      enrolled_by: enrolledBy.map((i) => i.id),
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}admindashboard/filterenquiries`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        if (result.length === 0) {
          showSnackbar("No data Available", "error");
          setIsLoading(false);
        }
        setOriginalRows(result);
        setRows(result);
        setIsLoading(false);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      navigate("/page500");
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 4;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 100,
      },
    },
  };
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const resetFilters = () => {
    setGridKey((prev) => prev + 1);
    setPerspectiveName([]);
    setExamTypename([]);
    setbranchname([]);
    setnearestbranchname([]);
    setLeadname([]);
    setinterestlevelname([]);
    setStagename([]);
    setAssignmentName([]);
    setCoassignee([]);
    setName("");

    setRows(originaEnquiryData);
    setOriginalRows(originaEnquiryData);
    setSubService([]);
    setRecycleResonName([]);
    setProspectiveLevelId([]);
    setProspectiveLevelFiletr(1);
    setSubserviceFiletr(1);
    setExamTypeFilter(1);
    setLeadSourceFilter(1);
    setInterestLevelFilter(1);
    setBranchFilter(1);
    setNearestBranchFilter(1);
    setProspectiveLevelId([]);
    setSelectedSubserviceId([]);
    setSelectedExamTypeIds([]);
    setSelectedLeadSourceIds([]);
    setSelectedInterestLevelIds([]);
    setSelectedBranchIds([]);
    setSelectedNearesrBranchIds([]);
    setEnrolledFromDate("");
    setEnrolledToDate("");
    setEnrolledBy([]);
    setDrawerOpen(false);
  };

  const handleCancelFilter = () => {
    setDrawerOpen(false);
    setPerspectiveName([]);
    setExamTypename([]);
    setbranchname([]);
    setnearestbranchname([]);
    setLeadname([]);
    setinterestlevelname([]);
    setStagename([]);
    setAssignmentName([]);
    setCoassignee([]);
    setName("");
    setStartDate("");
    setEndDate("");
    setRows(originaEnquiryData);
    setOriginalRows(originaEnquiryData);
    setSubService([]);
    setRecycleResonName([]);
    setProspectiveLevelId([]);
    setProspectiveLevelFiletr(1);
    setSubserviceFiletr(1);
    setExamTypeFilter(1);
    setLeadSourceFilter(1);
    setInterestLevelFilter(1);
    setBranchFilter(1);
    setNearestBranchFilter(1);
    setProspectiveLevelId([]);
    setSelectedSubserviceId([]);
    setSelectedExamTypeIds([]);
    setSelectedLeadSourceIds([]);
    setSelectedInterestLevelIds([]);
    setSelectedBranchIds([]);
    setSelectedNearesrBranchIds([]);
    setEnrolledFromDate("");
    setEnrolledToDate("");
    setEnrolledBy([]);
  };

  // Prospective Level

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}prospectivelevel`);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setPerspective(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleChangePrspectiveFilter = (e) => {
    setProspectiveLevelFiletr(e.target.value);
    setPerspectiveName([]);
    setProspectiveLevelId([]);
  };

  const allprspectivelevel = [
    { prospective_level: "Select All" },
    ...perspective,
  ];

  const handleChange = (event, value) => {
    if (prospectiveLevelFilter === 1) {
      if (value.some((option) => option.prospective_level === "Select All")) {
        const id = perspective.map((i) => i.id);

        setPerspectiveName(perspective);
        setProspectiveLevelId(id);
      } else {
        const ids = value.map((i) => i.id);
        setPerspectiveName(value);

        setProspectiveLevelId(ids);
      }
    } else if (prospectiveLevelFilter === 0) {
      if (value.some((option) => option.prospective_level === "Select All")) {
        setPerspectiveName(perspective);
        setProspectiveLevelId([0]);
      } else {
        const filteredPerspective = perspective.filter(
          (option) => !value.some((selected) => selected.id === option.id)
        );

        const filteredIds = filteredPerspective.map((i) => i.id);
        if (filteredIds.length === 0) {
          setProspectiveLevelId([0]);
        } else {
          setProspectiveLevelId(filteredIds);
        }
        setPerspectiveName(value);
      }
    }
  };

  // Recycle Reason
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}recyclereason`);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setRecycleResonList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleChangeRecycleReson = (event, value) => {
    setRecycleResonName(value);
  };

  // Sub Service
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}subservice`);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setSubServiceList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSubserviceFilter = (e) => {
    setSubserviceFiletr(e.target.value);
    setSubService([]);
    setSelectedSubserviceId([]);
  };

  const allSubservices = [{ name: "Select All" }, ...subServiceList];

  const handleSubserviceChange = (event, value) => {
    if (subserviceFilter === 1) {
      if (value.some((subservice) => subservice.name === "Select All")) {
        setSubService(subServiceList);
        const filteredIds = subServiceList.map((i) => i.id);
        setSelectedSubserviceId(filteredIds);
      } else {
        const filteredIds = value.map((i) => i.id);
        setSubService(value);
        setSelectedSubserviceId(filteredIds);
      }
    } else if (subserviceFilter === 0) {
      if (value.some((subservice) => subservice.name === "Select All")) {
        setSubService(subServiceList);
        setSelectedSubserviceId([0]);
      } else {
        const filteredsubservice = subServiceList.filter(
          (option) => !value.some((selected) => selected.id === option.id)
        );

        const filteredIds = filteredsubservice.map((i) => i.id);
        if (filteredIds.length === 0) {
          setSelectedSubserviceId([0]);
        } else {
          setSelectedSubserviceId(filteredIds);
        }
        setSubService(value);
      }
    }
  };

  // Exam Type
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalltesttype`);
        if (response.ok) {
          const data = await response.json();
          const names = data.map((item) => item.name);

          setExamType(data);
          // setServicename(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allExamTypesList = [{ name: "Select All" }, ...ExamType];

  const handleChangeExamTypeFilter = (e) => {
    setExamTypeFilter(e.target.value);
    setExamTypename([]);
    setSelectedExamTypeIds([]);
  };

  const handleChangeExamType = (value) => {
    if (examTypeFilter === 1) {
      if (value.some((examtype) => examtype.name === "Select All")) {
        setExamTypename(ExamType);
        const filteredIds = ExamType.map((i) => i.id);
        setSelectedExamTypeIds(filteredIds);
      } else {
        setExamTypename(value);
        const filteredValue = value.map((i) => i.id);
        setSelectedExamTypeIds(filteredValue);
      }
    } else if (examTypeFilter === 0) {
      if (value.some((examtype) => examtype.name === "Select All")) {
        setExamTypename(ExamType);
        setSelectedExamTypeIds([0]);
      } else {
        const filteredsubservice = ExamType.filter(
          (option) => !value.some((selected) => selected.id === option.id)
        );

        const filteredIds = filteredsubservice.map((i) => i.id);
        if (filteredIds.length === 0) {
          setSelectedExamTypeIds([0]);
        } else {
          setSelectedExamTypeIds(filteredIds);
        }
        setExamTypename(value);
      }
    }
  };

  //  Branch
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();

          setbranch(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleBranchFilter = (e) => {
    setBranchFilter(e.target.value);
    setbranchname([]);
    setSelectedBranchIds([]);
  };

  const allBranchList = [{ branch_name: "Select All" }, ...branch];

  const handlechangebranch = (value) => {
    if (branchFilter === 1) {
      if (value.some((branch) => branch.branch_name === "Select All")) {
        setbranchname(branch);
        const filteredIds = branch.map((i) => i.id);
        setSelectedBranchIds(filteredIds);
      } else {
        setbranchname(value);
        const filteredIds = value.map((i) => i.id);
        setSelectedBranchIds(filteredIds);
      }
    } else if (branchFilter === 0) {
      if (value.some((branch) => branch.branch_name === "Select All")) {
        setbranchname(branch);

        setSelectedBranchIds([0]);
      } else {
        const filteredbranch = branch.filter(
          (option) => !value.some((selected) => selected.id === option.id)
        );

        const filteredIds = filteredbranch.map((i) => i.id); // Get remaining IDs

        setbranchname(value);
        setSelectedBranchIds(filteredIds);
      }
    }
  };

  // Nearest Branch
  const handleNearestBranchFilter = (e) => {
    setNearestBranchFilter(e.target.value);
    setnearestbranchname([]);
    setSelectedNearesrBranchIds([]);
  };

  const handlechangenearbranch = (value) => {
    if (nearestBranchFilter === 1) {
      if (value.some((branch) => branch.branch_name === "Select All")) {
        setnearestbranchname(branch);
        const filteredIds = branch.map((i) => i.id);
        setSelectedNearesrBranchIds(filteredIds);
      } else {
        setnearestbranchname(value);
        const filteredIds = value.map((i) => i.id);
        setSelectedNearesrBranchIds(filteredIds);
      }
    } else if (nearestBranchFilter === 0) {
      if (value.some((branch) => branch.branch_name === "Select All")) {
        setnearestbranchname(branch);

        setSelectedNearesrBranchIds([0]);
      } else {
        const filteredbranch = branch.filter(
          (option) => !value.some((selected) => selected.id === option.id)
        );

        const filteredIds = filteredbranch.map((i) => i.id);
        if (filteredIds.length === 0) {
          setSelectedNearesrBranchIds([0]);
        } else {
          setSelectedNearesrBranchIds(filteredIds);
        }

        setnearestbranchname(value);
      }
    }
  };

  // Lead Source
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}leadsource`);
        if (response.ok) {
          const data = await response.json();

          setLeadoptions(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleChangeLeadsourceFilter = (e) => {
    setLeadSourceFilter(e.target.value);
    setLeadname([]);
    setSelectedLeadSourceIds([]);
  };

  const handlechangelead = (value) => {
    if (leadSourceFilter === 1) {
      setLeadname(value);
      const filteredIds = value.map((i) => i.id);
      setSelectedLeadSourceIds(filteredIds);
    } else if (leadSourceFilter === 0) {
      const filteredLeadSource = leadOptions.filter(
        (option) => !value.some((selected) => selected.id === option.id)
      );

      const filteredIds = filteredLeadSource.map((i) => i.id);
      console.log("filteredIds", filteredIds);
      if (filteredIds.length === 0) {
        setSelectedLeadSourceIds([0]);
      } else {
        setSelectedLeadSourceIds(filteredIds);
      }

      setLeadname(value);
    }
  };

  // Interest Level
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}levelofinterest`);
        if (response.ok) {
          const data = await response.json();

          setInterstlevel(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleChangeInterestLevelFilter = (e) => {
    setInterestLevelFilter(e.target.value);
    setinterestlevelname([]);
    setSelectedInterestLevelIds([]);
  };

  const handlechangeinterest = (value) => {
    if (interestLevelFilter === 1) {
      setinterestlevelname(value);
      const filteredIds = value.map((i) => i.id);
      setSelectedInterestLevelIds(filteredIds);
    } else if (interestLevelFilter === 0) {
      const filteredinterest = interestlevel.filter(
        (option) => !value.some((selected) => selected.id === option.id)
      );

      const filteredIds = filteredinterest.map((i) => i.id);
      if (filteredIds.length === 0) {
        setSelectedInterestLevelIds([0]);
      } else {
        setSelectedInterestLevelIds(filteredIds);
      }

      setinterestlevelname(value);
    }
  };

  const handelstartdate = (e) => {
    setStartDate(e.target.value);
    console.log(e.target.value);
  };

  const handeltodate = (e) => {
    setEndDate(e.target.value);
    console.log(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}stage`);
        if (response.ok) {
          const data = await response.json();
          setStages(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  console.log(stages);

  const handlechangestage = (value) => {
    setStagename(value);
  };

  const handleEnrolledDateFrom = (e) => {
    setEnrolledFromDate(e.target.value);
  };

  const handleEnrolledToDateChange = (e) => {
    setEnrolledToDate(e.target.value);
  };

  // Enrolled By List
  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route2.0/getuserbyuser/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setEnrolledByList(data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  const allEnrolledByList = [{ first_name: "Select All" }, ...enrollendByList];
  const handleEnrolledByChange = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setEnrolledBy(enrollendByList);
    } else {
      setEnrolledBy(value);
    }
  };

  // Assignment Filter
  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route/getalluser`)
      .then((response) => response.json())
      .then((data) => {
        setassignment(data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  const allUserList = [{ first_name: "Select All" }, ...assignment];

  const handlechangeassign = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setAssignmentName(assignment);
    } else {
      setAssignmentName(value);
    }
  };

  const handlechangecoassign = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setCoassignee(assignment);
    } else {
      setCoassignee(value);
    }
  };

  const handleInputChange = (event) => {
    setName(event.target.value);
  };

  const isAnyFilterSelected = () => {
    return (
      !enrolledfromDate ||
      !enrolledTodate ||
      enrolledBy.length > 0 ||
      stagename.length > 0
    );
  };

  // Add default 1 month start and end date
  const formatDate = (date) => date.toISOString().split("T")[0];
  useEffect(() => {
    const today = new Date();
    let oneMonthAgo = new Date();
    oneMonthAgo.setDate(today.getDate() - 30);

    setEndDate(formatDate(today));
    setStartDate(formatDate(oneMonthAgo));
  }, []);

  return (
    <>
      <Tooltip title="Filter" placement="bottom">
        <ButtonBase sx={{ mt: 0.5 }} onClick={handleDrawerOpen}>
          <FilterAltOutlinedIcon
            color={theme.palette.secondary.main}
            aria-label="Filter"
            sx={{ fontSize: 32 }}
          />
        </ButtonBase>
      </Tooltip>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        sx={{ width: "500px", "& .MuiDrawer-paper": { width: "500px" } }}
      >
        {/* Add your filter options and content here */}
        <Box
          sx={{
            padding: "16px",
            overflow: "auto",
            height: { md: "115vh", lg: "93vh" },
          }}
        >
          <Typography variant="h2" textAlign="center">
            Filter Options
          </Typography>

          <Accordion
            defaultExpanded={
              startDate !== "" ||
              endDate !== "" ||
              name !== "" ||
              perspectiveName.length > 0 ||
              recycleresonName.length > 0 ||
              subservice.length > 0 ||
              ExamTypename.length > 0 ||
              leadName.length > 0 ||
              intrestlevelname.length > 0 ||
              branchname.length > 0 ||
              nearestbranchname.length > 0
            }
          >
            <AccordionSummary
              expandIcon={<GridExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h4"> General Filter</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack display="flex" spacing={2}>
                <Stack
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Stack spacing={1}>
                    <Typography>Enquiry From Date </Typography>
                    <TextField
                      type="date"
                      size="small"
                      value={startDate}
                      onChange={handelstartdate}
                      style={{ width: "200px" }}
                      InputProps={{
                        style: { borderRadius: "15px" },
                      }}
                    />
                  </Stack>

                  <Stack spacing={1}>
                    <Typography>Enquiry To Date </Typography>
                    <TextField
                      type="date"
                      size="small"
                      value={endDate}
                      onChange={handeltodate}
                      style={{ width: "200px" }}
                      InputProps={{
                        style: { borderRadius: "15px" },
                      }}
                    />
                  </Stack>
                </Stack>

                <Stack spacing={1}>
                  <Typography>Name</Typography>
                  <TextField
                    size="small"
                    type="text"
                    value={name}
                    onChange={handleInputChange}
                    InputProps={{
                      style: { borderRadius: "15px" },
                    }}
                  />
                </Stack>

                <FormControl fullWidth>
                  {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}

                  <Stack display="flex" flexDirection="row" alignItems="center">
                    <Typography sx={{ marginRight: "10px" }}>
                      Prospective Level
                    </Typography>
                    <Select
                      value={prospectiveLevelFilter}
                      style={{ width: "8  0px", height: "20px" }}
                      onChange={handleChangePrspectiveFilter}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value={1}>is</MenuItem>
                      <MenuItem value={0}>is not</MenuItem>
                    </Select>
                  </Stack>
                </FormControl>

                <Autocomplete
                  disablePortal
                  value={perspectiveName}
                  multiple
                  size="small"
                  onChange={handleChange}
                  options={allprspectivelevel}
                  getOptionLabel={(option) => option.prospective_level}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "15px" }, // Add borderRadius here
                      }}
                    />
                  )}
                />

                {/* Recycle Reason */}

                {prospectiveLevelId.includes(5) && (
                  <>
                    <FormControl fullWidth>
                      <Stack
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Typography sx={{ marginRight: "10px" }}>
                          Recycle Reason
                        </Typography>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          // value={age}
                          style={{ width: "8  0px", height: "20px" }}
                          // onChange={handleChange}
                        >
                          <MenuItem value={10}>is</MenuItem>
                          <MenuItem value={20}>is not</MenuItem>
                        </Select>
                      </Stack>
                    </FormControl>

                    <Autocomplete
                      disablePortal
                      multiple
                      size="small"
                      value={recycleresonName}
                      onChange={handleChangeRecycleReson}
                      options={recycleResonList}
                      getOptionLabel={(option) => option.recycle_reason}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "15px" }, // Add borderRadius here
                          }}
                        />
                      )}
                    />
                  </>
                )}

                <FormControl fullWidth>
                  {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}

                  <Stack display="flex" flexDirection="row" alignItems="center">
                    <Typography sx={{ marginRight: "10px" }}>
                      Sub Service
                    </Typography>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={subserviceFilter}
                      style={{ width: "8  0px", height: "20px" }}
                      onChange={handleSubserviceFilter}
                    >
                      <MenuItem value={1}>is</MenuItem>
                      <MenuItem value={0}>is not</MenuItem>
                    </Select>
                  </Stack>
                </FormControl>

                <Autocomplete
                  disablePortal
                  multiple
                  value={subservice}
                  size="small"
                  onChange={handleSubserviceChange}
                  options={allSubservices}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "15px" }, // Add borderRadius here
                      }}
                    />
                  )}
                />

                <FormControl fullWidth>
                  <Stack display="flex" flexDirection="row" alignItems="center">
                    <Typography sx={{ marginRight: "10px" }}>
                      Exam Type
                    </Typography>
                    <Select
                      value={examTypeFilter}
                      style={{ width: "8  0px", height: "20px" }}
                      onChange={handleChangeExamTypeFilter}
                    >
                      <MenuItem value={1}>is</MenuItem>
                      <MenuItem value={0}>is not</MenuItem>
                    </Select>
                  </Stack>
                </FormControl>

                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    multiple
                    value={ExamTypename}
                    size="small"
                    onChange={(event, value) => {
                      handleChangeExamType(value); // Passing only the value
                    }}
                    options={allExamTypesList}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <Stack display="flex" flexDirection="row" alignItems="center">
                    <Typography sx={{ marginRight: "10px" }}>
                      Lead Source
                    </Typography>
                    <Select
                      value={leadSourceFilter}
                      style={{ width: "8  0px", height: "20px" }}
                      onChange={handleChangeLeadsourceFilter}
                    >
                      <MenuItem value={1}>is</MenuItem>
                      <MenuItem value={0}>is not</MenuItem>
                    </Select>
                  </Stack>
                </FormControl>

                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    multiple
                    value={leadName}
                    size="small"
                    onChange={(event, value) => {
                      handlechangelead(value); // Passing only the value
                    }}
                    options={leadOptions}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth>
                  {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}

                  <Stack display="flex" flexDirection="row" alignItems="center">
                    <Typography sx={{ marginRight: "10px" }}>
                      Interest Level
                    </Typography>
                    <Select
                      value={interestLevelFilter}
                      style={{ width: "8  0px", height: "20px" }}
                      onChange={handleChangeInterestLevelFilter}
                    >
                      <MenuItem value={1}>is</MenuItem>
                      <MenuItem value={0}>is not</MenuItem>
                    </Select>
                  </Stack>
                </FormControl>

                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    multiple
                    size="small"
                    value={intrestlevelname}
                    onChange={(event, value) => {
                      handlechangeinterest(value); // Passing only the value
                    }}
                    options={interestlevel}
                    getOptionLabel={(option) => option.level_of_interest}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </FormControl>

                <FormControl>
                  <Stack display="flex" flexDirection="row" alignItems="center">
                    <Typography sx={{ marginRight: "10px" }}>Branch</Typography>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={branchFilter}
                      style={{ width: "8  0px", height: "20px" }}
                      onChange={handleBranchFilter}
                    >
                      <MenuItem value={1}>is</MenuItem>
                      <MenuItem value={0}>is not</MenuItem>
                    </Select>
                  </Stack>
                </FormControl>

                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    multiple
                    value={branchname}
                    size="small"
                    onChange={(event, value) => {
                      handlechangebranch(value); // Passing only the value
                    }}
                    options={allBranchList}
                    getOptionLabel={(option) => option.branch_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth>
                  {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}

                  <Stack display="flex" flexDirection="row" alignItems="center">
                    <Typography sx={{ marginRight: "10px" }}>
                      Nearest Branch
                    </Typography>
                    <Select
                      value={nearestBranchFilter}
                      style={{ width: "8  0px", height: "20px" }}
                      onChange={handleNearestBranchFilter}
                    >
                      <MenuItem value={1}>is</MenuItem>
                      <MenuItem value={0}>is not</MenuItem>
                    </Select>
                  </Stack>
                </FormControl>

                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    size="small"
                    multiple
                    value={nearestbranchname}
                    onChange={(event, value) => {
                      handlechangenearbranch(value); // Passing only the value
                    }}
                    options={allBranchList}
                    getOptionLabel={(option) => option.branch_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Stack>

              {/* Stage Related Filter */}
            </AccordionDetails>
          </Accordion>

          <Accordion
            defaultExpanded={
              enrolledfromDate !== "" ||
              enrolledTodate !== "" ||
              enrolledBy.length > 0 ||
              stagename.length > 0
            }
          >
            <AccordionSummary
              expandIcon={<GridExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h4"> Stage Related Filter</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6} sm={6} md={6}>
                  <Typography>Enrolled From Date</Typography>
                  <TextField
                    value={enrolledfromDate}
                    onChange={handleEnrolledDateFrom}
                    size="small"
                    type="date"
                    fullWidth
                    InputProps={{ style: { borderRadius: "15px" } }}
                  />
                </Grid>

                <Grid item xs={12} lg={6} sm={6} md={6}>
                  <Typography>Enrolled To Date</Typography>
                  <TextField
                    size="small"
                    type="date"
                    value={enrolledTodate}
                    onChange={handleEnrolledToDateChange}
                    fullWidth
                    InputProps={{ style: { borderRadius: "15px" } }}
                  />
                </Grid>

                {(userRole === "center_admin" ||
                  userRole === "data_entry" ||
                  userRole === "admin") && (
                  <Grid item xs={12} lg={12} sm={12} md={12}>
                    <Typography>Enrolled By</Typography>
                    <Autocomplete
                      disablePortal
                      multiple
                      value={enrolledBy}
                      size="small"
                      onChange={
                        handleEnrolledByChange // Passing only the value
                      }
                      options={allEnrolledByList}
                      getOptionLabel={(option) =>
                        `${option.first_name ? option.first_name : ""} ${
                          option.last_name ? option.last_name : ""
                        }`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "15px" }, // Add borderRadius here
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}

                <Grid item xs={12} lg={12} sm={12} md={12}>
                  <Typography>Stages</Typography>
                  <Autocomplete
                    disablePortal
                    multiple
                    value={stagename}
                    size="small"
                    onChange={(event, value) => {
                      handlechangestage(value); // Passing only the value
                    }}
                    options={stages}
                    getOptionLabel={(option) => option.stage_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion
            defaultExpanded={asignmentName.length > 0 || coassignee.length > 0}
          >
            <AccordionSummary
              expandIcon={<GridExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h4"> Enquiry Assignment Filter</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12} sm={12} md={12}>
                  <Typography>Assigned</Typography>
                  <Autocomplete
                    multiple
                    value={asignmentName}
                    fullWidth
                    disablePortal
                    size="small"
                    onChange={handlechangeassign}
                    options={allUserList}
                    getOptionLabel={(option) =>
                      `${option.first_name ? option.first_name : ""} ${
                        option.last_name ? option.last_name : ""
                      }`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={12} sm={12} md={12}>
                  <Typography>Co-Assignee</Typography>
                  <Autocomplete
                    multiple
                    size="small"
                    onChange={handlechangecoassign}
                    value={coassignee}
                    disablePortal
                    options={allUserList}
                    getOptionLabel={(option) =>
                      `${option.first_name ? option.first_name : ""} ${
                        option.last_name ? option.last_name : ""
                      }`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          {/* Add your filter options and content here */}
        </Box>
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          width="500px"
          position="absolute"
          bottom={5}
        >
          <Button
            variant="contained"
            style={{ background: "#4242f5", width: "150px" }}
            onClick={handleApplyFilter}
          >
            {" "}
            Filter
          </Button>
          <Button
            variant="contained"
            style={{ background: "#f7690a", width: "150px" }}
            onClick={handleCancelFilter}
          >
            {" "}
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ width: "150px" }}
            onClick={resetFilters}
          >
            {" "}
            Reset
          </Button>
        </Stack>
      </Drawer>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
