import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
  styled,
  TextField,
  Autocomplete,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import InfoCard from "./InfoCard";
import EventFilter from "./EventFilter";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { fDate } from "../../../utils/formatTime";
import CustomPopup from "../../../components/CustomPopup";

import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import NoenquiryForm from "../../components/DataTransferComp.jsx/NoenquiryForm";
import {
  format,
  subMonths,
  addMonths,
  isSameMonth,
  getMonth,
  getYear,
} from "date-fns";
import EmailIcon from "@mui/icons-material/Email";
import NoeMailForm from "../../components/DataTransferComp.jsx/NoMailForm";
import BulkMailform from "../../components/BulkEmail/BulkMailform";
import BulkEmailPopup from "../../components/BulkEmail/BulkEmailPopup";
import { CSVExport } from "../../components/ui-component/table/CSVExport";
import { useGetTokenOrLogout } from "../../../utils/token";
import MassTaskAssign from "../../components/DataTransferComp.jsx/MassTaskAssign";

export default function DetailedReport({ open }) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");
  const getTokenOrLogout = useGetTokenOrLogout();

  const [openMask, setOpenMask] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [selectedid, setSelectedid] = useState([]);
  const [selectedEnquiryId, setSelectedEnquiryids] = useState([]);
  const [branchlist, setBranchlist] = useState([]);
  const [branch, setBranch] = useState([]);
  const [RegistrationList, setRegistrationList] = useState([]);
  const [resterationstatus, setRegistrationstatus] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [eventname, setEventName] = useState([]);
  const [registerdByList, setRegisteredByList] = useState([]);
  const [registerBy, setRegisterdBy] = useState([]);
  const [permanentlyOwner, setPermentlyonner] = useState([]);
  const [servicelist, setServiceList] = useState([]);
  const [service, setService] = useState([]);
  const [prospectiveLevelList, setProspectiveLevelList] = useState([]);
  const [prosectiveLevel, setProspectiveLevel] = useState([]);
  const [leadStageList, setLeadstageList] = useState([]);
  const [leadstage, setLeadstage] = useState([]);
  const [nearestBranch, setNearestBranch] = useState([]);
  const [countrylist, setcountrylist] = useState([]);
  const [country, setCountry] = useState([]);
  const [eventType, setEventType] = useState(null);
  const [checkboxChange, setCheckBoxChange] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [eventloading, setEventLoading] = useState(false);
  const [eventOriginalData, setEventOriginalData] = useState([]);
  const [eventRowData, setEventRowData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [OpenEmailPopup, setOpenEmailPopup] = useState(false);
  const [subServiceName, setSubServiceName] = useState([]);
  const [subServiceList, setSubsubServiceList] = useState([]);

  const [isDownloadAccess, setIsDownloadAccess] = useState(false);
  const [exportData, setExportData] = useState([]);

  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [selectedMonthYear, setSelectedMonthYear] = useState(null);
  const [eventFromDate, setEventFromDate] = useState("");
  const [eventToDate, setEventToDate] = useState("");
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 100,
  });

  // Current Date
  const [visibleRowIds, setVisibleRowIds] = useState([]); // Store current page's row IDs
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [prevPageSize, setPrevPageSize] = useState(pagination.pageSize);
  const currentDate = new Date();

  // Store  5 months
  const dates = [
    subMonths(currentDate, 2),
    subMonths(currentDate, 1),
    currentDate,
    addMonths(currentDate, 1),
    addMonths(currentDate, 2),
  ];

  const noOptionsText = "Loading...";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}subservice`);
        if (response.ok) {
          const data = await response.json();

          setSubsubServiceList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allSubservice = [{ name: "Select All", id: 0 }, ...subServiceList];

  // handle subservice
  const handleChangeSubservice = (id) => {
    setEventName([]);
    setEventList([]);
    setEventFromDate("");
    setEventToDate("");
    setEventType(null);
    setSelectedMonthYear(null);
    if (id === 0) {
      // Handle "Select All"
      setSubServiceName(
        (prevSelected) =>
          prevSelected.length === subServiceList.length
            ? [] // Deselect all
            : subServiceList.map((subservice) => subservice.id) // Select all
      );
    } else {
      // Handle individual subservice selection
      setSubServiceName(
        (prevSelected) =>
          prevSelected.includes(id)
            ? prevSelected.filter((subserviceId) => subserviceId !== id) // Remove ID if already selected
            : [...prevSelected, id] // Add ID if not selected
      );
    }
  };

  console.log("subServiceName", subServiceName);

  // Fetch Currnet Month data bydefault
  // useEffect(() => {

  //   handleSubmitDurationdata(currentDate);
  // }, []);

  // Fetch Custom Event Data
  const handleChangeEventFromdate = (e) => {
    setEventFromDate(e.target.value);
  };

  const handleChangeEventTodate = (e) => {
    setEventToDate(e.target.value);
    if (eventFromDate !== "") {
      getEventData(null, eventType, eventFromDate, e.target.value);
    }
  };

  // Fetch data by all, past , and date
  const handleEventData = async ({ date = null, eventType = null }) => {
    // Reset dependent states
    setEventName([]);
    setEventList([]);
    setEventFromDate("");
    setEventToDate("");
    setSelectedMonthYear(date ? format(date, "MMMM,yyyy") : null);
    setEventType(eventType);

    // Determine which filter to use for fetching data
    const filter = date ? format(date, "MMMM,yyyy") : eventType;

    if (eventType !== "custom") {
      getEventData(date, filter, null, null);
    }
  };

  const getEventData = async (date, eventcriteria, fromdate, todate) => {
    const requestData = {
      filter: date === null ? eventcriteria : format(date, "MMMM, yyyy"),
      sub_service_id: subServiceName,
      from_date: fromdate || null,
      to_date: todate || null,
    };
    setEventLoading(true);
    try {
      const response = await fetch(`${API_ENDPOINT}campaign/eventbymonth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const result = await response.json();
        const sortedData = result.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setEventList(sortedData);
        setEventLoading(false);
      } else if (response.status === 404) {
        setEventList([]);
        setEventLoading(false);
      } else {
        setEventLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setEventLoading(false);
    }
  };

  const handleOpenmask = () => {
    setOpenMask(true);
  };

  const handleClosemask = () => {
    setOpenMask(false);
  };
  const handleOpenEmail = () => {
    setOpenEmailPopup(true);
  };

  const handleCloseEmail = () => {
    setOpenEmailPopup(false);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  // fetch todays regi
  useEffect(() => {
    setLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    fetch(`${API_ENDPOINT}route2.0/showtodayregistrationstatus/${userId}`, {
      headers,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      })
      .then((data) => {
        if (!data) return; // Stop further execution if data is not present

        const sortedData = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setEventData(sortedData);
        setEventOriginalData(sortedData);
        setEventRowData(sortedData);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/page500");
      });
  }, [userId]);

  const handleSubmit = async (event) => {
if(eventname.length===0){
  showSnackbar("Please Select Event First",'error')
  return;
}

    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };

    event.preventDefault();
    setLoading(true);
    const requestData = {
      event: eventname.map((i) => i.id),
      from_date: fromDate || null,
      end_date: endDate || null,
      // branch: branch,
      registered_by: registerBy.map((i) => i.id),
      // permanetly_owner: permanentlyOwner,
      // service: service,
      // prospective_level: prosectiveLevel,
      // lead_stage: leadstage,
      registration_status: resterationstatus.map((i) => i.id),
      // registered_country: country,
      // nearest_branch: nearestBranch,
      user_id: parseInt(userId),
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route2.0/showallregistrationstatus`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        const sortedData = result.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setEventData(sortedData);
        setEventOriginalData(sortedData);
        setLoading(false);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      navigate("/page500");
    }
  };

  const handleresetFilterData = () => {
    setEventName([]);
    setFromDate("");
    setEndDate("");
    setBranch([]);
    setRegisterdBy([]);
    setPermentlyonner([]);
    setService([]);
    setProspectiveLevel([]);
    setLeadstage([]);
    setRegistrationstatus([]);
    setCountry([]);
    setNearestBranch([]);
    setEventOriginalData(eventRowData);
    setEventData(eventRowData);
    setSubServiceName([]);
    setSelectedMonthYear(null);
    setEventList([]);
    setEventType(null);
    setEventFromDate("");
    setEventToDate("");
  };

  // Upadate current page ids when pagination is chnages
  useEffect(() => {
    console.log("pagination", pagination, selectedid);
    setPrevPageSize(pagination.pageSize);

    // new pagesize is less than prev page size then deselct all selected ids first
    if (pagination.pageSize !== prevPageSize) {
      setSelectedid([]);
      return;
    }

    // Update visible row IDs when pagination changes
    const currentPageRows = eventOriginalData.slice(
      pagination.page * pagination.pageSize,
      (pagination.page + 1) * pagination.pageSize
    );
    const visibleIds = currentPageRows.map((row) => row.id);
    setVisibleRowIds(visibleIds);

    // If there are no visible rows, set SelectAllChecked to false
    if (visibleIds.length === 0) {
      setIsSelectAllChecked(false);
      setIsIndeterminate(false);
      return;
    }

    // Check if all visible rows are selected
    const allVisibleSelected = visibleIds.every((id) =>
      selectedid.includes(id)
    );
    const someVisibleSelected = visibleIds.some((id) =>
      selectedid.includes(id)
    );

    setIsSelectAllChecked(allVisibleSelected);
    setIsIndeterminate(someVisibleSelected && !allVisibleSelected);
  }, [pagination, eventOriginalData, selectedid]);

  // HandleSelectAllCheckBox
  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    setIsSelectAllChecked(checked);

    if (checked) {
      // Select all visible row IDs
      setSelectedid((prevSelectedIds) => [
        ...new Set([...prevSelectedIds, ...visibleRowIds]),
      ]);
    } else {
      // Deselect all visible row IDs
      setSelectedid((prevSelectedIds) =>
        prevSelectedIds.filter((id) => !visibleRowIds.includes(id))
      );
    }
  };

  // Individual CheckBox
  const handleCheckboxChange = (event, id) => {
    const checked = event.target.checked;

    setSelectedid((prevSelectedIds) => {
      if (checked) {
        const newSelected = [...prevSelectedIds, id];
        // Check if all visible rows are selected after this checkbox is checked
        const allVisibleSelected = visibleRowIds.every((rowId) =>
          newSelected.includes(rowId)
        );
        setIsSelectAllChecked(allVisibleSelected);
        setIsIndeterminate(!allVisibleSelected);

        return newSelected;
      } else {
        // Deselect the row ID
        const newSelected = prevSelectedIds.filter((rowId) => rowId !== id);
        setIsSelectAllChecked(false);
        setIsIndeterminate(
          visibleRowIds.some((rowId) => newSelected.includes(rowId))
        );

        return newSelected;
      }
    });
  };

  // Select EnquiryIds
  useEffect(() => {
    if (selectedid.length > 0) {
      console.log("taskIds", selectedid);
      const enquiryIds = eventOriginalData.filter((id) =>
        selectedid.includes(id?.id)
      );
      const selectedEnquiryids = enquiryIds.map((i) => i.enquiry_id?.id);
      setSelectedEnquiryids(selectedEnquiryids);
    }
  }, [selectedid]);

  const columns = [
    {
      field: "selection",
      sortable: false,
      renderHeader: (params) => (
        <Checkbox
          size="small"
          checked={isSelectAllChecked}
          indeterminate={isIndeterminate}
          onChange={handleSelectAllChange}
        />
      ),
      headerClassName: "header-cell",
      width: 70,
      renderCell: (params) => {
        const isChecked = selectedid.includes(params.row?.id);
        return (
          <Checkbox
            size="small"
            checked={isChecked}
            onChange={(event) => handleCheckboxChange(event, params.row?.id)}
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div
          style={{ color: "#2196F3", cursor: "pointer" }}
          onClick={() =>
            window.open(
              `/enquirydetails/${params?.row?.enquiry_id?.id}`,
              "_blank"
            )
          }
        >
          {params.row?.enquiry_id?.first_name}{" "}
          {params.row?.enquiry_id?.last_name}
        </div>
      ),
    },

    {
      field: "contact",
      headerName: "Contact",
      width: 100,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.connected_call} </div>
      ),
    },
    {
      field: "nonContact",
      headerName: "Non Contact",
      width: 100,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.not_connected_call} </div>
      ),
    },
    {
      field: "totalOpenTask",
      headerName: "Open Task",
      width: 100,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.total_open_task} </div>
      ),
    },
    {
      field: "lastFollowUp",
      headerName: "Last Follow Up ",
      width: 100,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.last_follow_up_date} </div>
      ),
    },
    {
      field: "nextDueDate",
      headerName: "Next Due Date ",
      width: 100,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.next_follow_up_date} </div>
      ),
    },
    {
      field: "eventName",
      headerName: "Event Name ",
      width: 450,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div>{params.row?.event_id?.campaign_name}</div>,
    },
    {
      field: "dateOfRegistration",
      headerName: "Date Of Registration",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDate(params.row?.registered_date)}</div>,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "registerBy",
      headerName: "Register By",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params.row?.registered_by?.first_name}{" "}
          {params.row?.registered_by?.last_name}
        </div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "permanentlyOwner",
      headerName: "Permanently Owner",
      width: 150,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          {params.row?.enquiry_id?.assign_to?.first_name}{" "}
          {params.row?.enquiry_id?.assign_to?.last_name}{" "}
        </div>
      ),
    },

    {
      field: "branch",
      headerName: "Branch",
      width: 150,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.branch_id?.branch_name} </div>
      ),
    },

    {
      field: "nearestBranch",
      headerName: "Nearest Branch",
      width: 150,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.nearest_branch_id?.branch_name} </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div>{params.row?.enquiry_id?.email}</div>,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div>{params.row?.enquiry_id?.mobile}</div>,
    },
    {
      field: "prospectiveLevel",
      headerName: "Prospective Level",
      width: 150,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          {params.row?.enquiry_id?.prospective_level_id?.prospective_level}
        </div>
      ),
    },
    {
      field: "leadStage",
      headerName: "Lead Stage",
      width: 150,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.stage_id?.stage_name}</div>
      ),
    },
    {
      field: "enq_date",
      headerName: "Enquiry Date",
      width: 150,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div>{params.row?.enquiry_id?.enquiry_date}</div>,
    },
    {
      field: "lead_source",
      headerName: "Lead Source",
      width: 150,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.lead_source_type_id?.name}</div>
      ),
    },
    {
      field: "sub_lead_source",
      headerName: "Sub Lead Source",
      width: 150,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          {params.row?.enquiry_id?.sub_lead_source_type_id?.source_type}
        </div>
      ),
    },
    {
      field: "service",
      headerName: "Service",
      width: 150,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.service_id?.service_name}</div>
      ),
    },
    {
      field: "sub_service",
      headerName: "Sub Service",
      width: 150,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.sub_service_id?.name}</div>
      ),
    },
    {
      field: "registrationStatus",
      headerName: "Registration Status",
      width: 250,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params.row?.registration_status}</div>,
      headerAlign: "center",
      align: "center",
    },
   

    {
      field: "visitStatus",
      headerName: "Visit Status",
      width: 250,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
    },
  ];

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Available</Box>
      </StyledGridOverlay>
    );
  }

  const totalConnectedCalls = eventData.reduce((total, item) => {
    return item.enquiry_id.connected_call !== 0 ? total + 1 : total;
  }, 0);

  const totalnonConnectedCalls = eventData.reduce((total, item) => {
    return item.enquiry_id.not_connected_call !== 0 ? total + 1 : total;
  }, 0);

  const totalOpenTask = eventData.reduce((total, item) => {
    return item.enquiry_id.total_open_task !== 0 ? total + 1 : total;
  }, 0);

  const totalRegisteredAndConfirmed = eventData.reduce((total, item) => {
    return item.registration_status === "Registered & Confirmed"
      ? total + 1
      : total;
  }, 0);

  const totalRegisteredAndNotConfirmed = eventData.reduce((total, item) => {
    return item.registration_status === "Registered & Not Confirmed"
      ? total + 1
      : total;
  }, 0);

  const totalNotRegisterd = eventData.reduce((total, item) => {
    return item.registration_status === "Not Registered" ? total + 1 : total;
  }, 0);

  const totalRecycle = eventData.reduce((total, item) => {
    return item.enquiry_id.prospective_level_id?.prospective_level ===
      "Recycled"
      ? total + 1
      : total;
  }, 0);

  const totalWarm = eventData.reduce((total, item) => {
    return item.enquiry_id.prospective_level_id?.prospective_level === "Warm"
      ? total + 1
      : total;
  }, 0);

  const totalNewLead = eventData.reduce((total, item) => {
    return item.enquiry_id.prospective_level_id?.prospective_level ===
      "New lead"
      ? total + 1
      : total;
  }, 0);

  const totalActiveCounselling = eventData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "active counselling"
      ? total + 1
      : total;
  }, 0);

  const totalQualifyinf = eventData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "qualifying"
      ? total + 1
      : total;
  }, 0);

  const totalAssignment = eventData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "assignment"
      ? total + 1
      : total;
  }, 0);

  const totalBookDemo = eventData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "book demo"
      ? total + 1
      : total;
  }, 0);

  const totalDemoCompleted = eventData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "demo completed"
      ? total + 1
      : total;
  }, 0);

  const totalEnrolled = eventData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "enrolled"
      ? total + 1
      : total;
  }, 0);

  const totalTraining = eventData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "training"
      ? total + 1
      : total;
  }, 0);

  const totalTrainingCompleted = eventData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "training completed"
      ? total + 1
      : total;
  }, 0);

  const allcampaignList = [
    ...(eventList.length > 1 ? [{ campaign_name: "Select All" }] : []),
    ...eventList,
  ];

  const handelEventChange = (event, value) => {
    if (value.some((option) => option.campaign_name === "Select All")) {
      setEventName(eventList);
    } else {
      setEventName(value);
    }
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleTodDateChange = (e) => {
    setEndDate(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setBranchlist(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handelbranchchange = (e) => {
    const selectedValues = e.target.value;

    // If "Select All" is selected, toggle between selecting and deselecting all branches
    if (selectedValues.includes("all")) {
      const allIds = branchlist.map((branch) => branch.id);
      if (branch.length === allIds.length) {
        setBranch([]); // Deselect all branches
      } else {
        setBranch(allIds);
      }
    } else {
      setBranch(selectedValues);
      console.log(selectedValues);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}eventregistrationstatus`);
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);

          setRegistrationList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allRegistrationList = [
    { registration_status: "Select All" },
    ...RegistrationList,
  ];

  const handleregStatusChange = (event, value) => {
    if (value.some((option) => option.registration_status === "Select All")) {
      setRegistrationstatus(RegistrationList);
    } else {
      setRegistrationstatus(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setRegisteredByList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allRegisteredByList = [
    { first_name: "Select All" },
    ...registerdByList,
  ];

  const handleRegisterbychange = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setRegisterdBy(registerdByList);
    } else {
      setRegisterdBy(value);
    }
  };

  const handlePermanentlyOwnerchange = (e) => {
    const selectedValues = e.target.value;

    // If "Select All" is selected, toggle between selecting and deselecting all branches
    if (selectedValues.includes("all")) {
      const allIds = registerdByList.map((item) => item.id);
      if (permanentlyOwner.length === allIds.length) {
        setPermentlyonner([]); // Deselect all branches
      } else {
        setPermentlyonner(allIds);
      }
    } else {
      setPermentlyonner(selectedValues);
      console.log(selectedValues);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}service`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setServiceList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleServiceChange = (e) => {
    setService(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}prospectivelevel`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setProspectiveLevelList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleProspectivechange = (e) => {
    const selectedValues = e.target.value;

    // If "Select All" is selected, toggle between selecting and deselecting all branches
    if (selectedValues.includes("all")) {
      const allIds = prospectiveLevelList.map((item) => item.id);
      if (prosectiveLevel.length === allIds.length) {
        setProspectiveLevel([]); // Deselect all branches
      } else {
        setProspectiveLevel(allIds);
      }
    } else {
      setProspectiveLevel(selectedValues);
      console.log(selectedValues);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}stage`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setLeadstageList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleLeadStagechange = (e) => {
    const selectedValues = e.target.value;

    // If "Select All" is selected, toggle between selecting and deselecting all branches
    if (selectedValues.includes("all")) {
      const allIds = leadStageList.map((item) => item.id);
      if (leadstage.length === allIds.length) {
        setLeadstage([]); // Deselect all branches
      } else {
        setLeadstage(allIds);
      }
    } else {
      setLeadstage(selectedValues);
      console.log(selectedValues);
    }
  };

  const handelNearbranchchange = (e) => {
    const selectedValues = e.target.value;

    // If "Select All" is selected, toggle between selecting and deselecting all branches
    if (selectedValues.includes("all")) {
      const allIds = branchlist.map((branch) => branch.id);
      if (nearestBranch.length === allIds.length) {
        setNearestBranch([]); // Deselect all branches
      } else {
        setNearestBranch(allIds);
      }
    } else {
      setNearestBranch(selectedValues);
      console.log(selectedValues);
    }
  };

  const handleFilterClick = (stageName) => {
    setSelectedFilters((prevSelectedFilters) => {
      if (prevSelectedFilters.includes(stageName)) {
        return prevSelectedFilters.filter((filter) => filter !== stageName);
      } else {
        return [...prevSelectedFilters, stageName];
      }
    });
  };

  console.log("filter", selectedFilters.length);

  useEffect(() => {
    const filteredData = eventData.filter((user) => {
      return selectedFilters.includes(user.enquiry_id?.stage_id?.stage_name);
    });
    if (selectedFilters.length > 0) {
      setEventOriginalData(filteredData);
    } else {
      setEventOriginalData(eventData);
    }
  }, [selectedFilters]);

  useEffect(() => {
    fetch(`${API_ENDPOINT}route/downloadaccess/${userId}`)
      .then((response) => {
        if (response.ok) {
          setIsDownloadAccess(true);
        } else {
          setIsDownloadAccess(false);
        }
      })
      .catch((err) => {
        setIsDownloadAccess(false);
      });
  }, [userId]);

  useEffect(() => {
    const exportData = eventOriginalData.map((row) => ({
      name: row.enquiry_id?.last_name
        ? `${row.enquiry_id.first_name} ${row.enquiry_id.last_name}`
        : row.enquiry_id.first_name,
      mobile: row.enquiry_id.mobile,
      contactable: row.enquiry_id.connected_call || "",
      non_contactable: row.enquiry_id.not_connected_call || "",
      open_tasks: row.enquiry_id.total_open_task || "",
      nearest_branch: row.enquiry_id?.nearest_branch_id?.branch_name || "",
      enquiry_date: fDate(row.enquiry_id.enquiry_date),
      last_followup_date: fDate(row.enquiry_id?.last_follow_up_date),
      next_followup_date: fDate(row.enquiry_id?.next_follow_up_date),
      event_name: row.event_id.campaign_name,
      date_of_registration: fDate(row.registered_date),
      registered_by: row.registered_by.first_name,
      registration_status: row.registration_status,
      reason_for_no_registration: row.reason_for_no_registration || "",
      prospective_level:
        row.enquiry_id?.prospective_level_id?.prospective_level || "",
      // sub_service: row.enquiry_id?.sub_service_id?.name || "",
      assign: row.enquiry_id?.assign_to?.first_name || "",
      // lead_source_type: row.enquiry_id?.lead_source_type_id?.name || "",
      stage: row.enquiry_id?.stage_id?.stage_name || "",
    }));
    setExportData(exportData);
  }, [eventOriginalData]);

  // Search By Name
  const handleSearchByName = (e) => {
    const value = e.target.value;
    let filteredData1 = eventData.filter((data) =>
      `${data?.enquiry_id?.first_name} ${data?.enquiry_id?.last_name}`
        ?.toLowerCase()
        .replace(/\s+/g, "")
        .includes(value.toLowerCase().replace(/\s+/g, ""))
    );
    setEventOriginalData(filteredData1);
  };



  // Removed Data from state after transfer
  const handleFilterdata =()=>{
     const filteredData=eventOriginalData.filter((i)=>!selectedEnquiryId.includes(i.enquiry_id?.id))
     setEventOriginalData(filteredData)
     setTimeout(() => {
      setSelectedid([])
      setSelectedEnquiryids([])
     }, 1500);
  }

console.log("selectedEnquiryId", selectedEnquiryId)

  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      <Grid item lg={12}>
        <Collapse in={open} fullWidth>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12} sm={12} md={12}>
                <Typography
                  sx={{
                    color: "#2E4069",
                    fontSize: "19px",
                    fontWeight: 600,
                    marginTop: "20px",
                  }}
                >
                  Service
                  <span
                    style={{
                      color: "red",
                      fontSize: "20px",
                      marginLeft: "3px",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Chip
                  avatar={<Avatar alt="Natacha" src="/test (1).png" />}
                  label="Test Preparation"
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    fontWeight: 600,
                    border: "2px solid green",
                    color: "green",
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={12} sm={12} md={12}>
                <Typography
                  sx={{
                    color: "#2E4069",
                    fontSize: "19px",
                    fontWeight: 600,
                    marginTop: "20px",
                  }}
                >
                  Sub Service
                </Typography>

                {allSubservice.map((subservice) => (
                  <Chip
                    key={subservice.id}
                    onClick={() => handleChangeSubservice(subservice.id)}
                    variant="outlined"
                    label={
                      subservice.id === 0
                        ? subServiceName.length === subServiceList.length
                          ? "Deselect All"
                          : "Select All"
                        : subservice.name
                    }
                    style={{
                      color: subServiceName.includes(subservice.id)
                        ? "green"
                        : "black",
                      fontWeight: 600,
                      margin: "5px",
                      border: subServiceName.includes(subservice.id)
                        ? "2px solid green"
                        : "",
                    }}
                  />
                ))}
              </Grid>

              <Grid item xs={12} lg={12} sm={12} md={12}>
                <Typography
                  sx={{ color: "#2E4069", fontSize: "19px", fontWeight: 600 }}
                >
                  Duration
                  <span
                    style={{
                      color: "red",
                      fontSize: "20px",
                      marginLeft: "3px",
                    }}
                  >
                    *
                  </span>
                </Typography>

                <Chip
                  variant="outlined"
                  onClick={() => handleEventData({ eventType: "all" })}
                  label="All Event"
                  style={{
                    borderRadius: "25px",

                    margin: "5px",
                    border: eventType === "all" ? "2px solid green" : "",
                    fontWeight: 600,
                    color: eventType === "all" ? "green" : "black",
                  }}
                />

                <Chip
                  variant="outlined"
                  onClick={() => handleEventData({ eventType: "past" })}
                  label="Past Event"
                  style={{
                    borderRadius: "25px",

                    margin: "5px",
                    border: eventType === "past" ? "2px solid green" : "",
                    fontWeight: 600,
                    color: eventType === "past" ? "green" : "black",
                  }}
                />

                {dates.map((date, index) => {
                  const formattedDate = format(date, "MMMM,yyyy");
                  const isSelected = formattedDate === selectedMonthYear;
                  console.log("selecteddata", selectedMonthYear);
                  return (
                    <Chip
                      key={index}
                      label={format(date, "MMMM yyyy")}
                      variant="outlined"
                      onClick={() => handleEventData({ date: date })}
                      style={{
                        margin: "5px",
                        borderRadius: "25px",

                        border: isSelected ? "2px solid green" : "",
                        color: isSelected ? "green" : "black",
                        fontWeight: 600,
                      }}
                    ></Chip>
                  );
                })}

                <Chip
                  variant="outlined"
                  onClick={() => handleEventData({ eventType: "custom" })}
                  label="Custom Event"
                  style={{
                    borderRadius: "25px",

                    margin: "5px",
                    border: eventType === "custom" ? "2px solid green" : "",
                    fontWeight: 600,
                    color: eventType === "custom" ? "green" : "black",
                  }}
                />

                {eventType === "custom" && (
                  <>
                    <TextField
                      size="small"
                      InputProps={{ style: { borderRadius: "14px" } }}
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      label="From Date"
                      variant="outlined"
                      sx={{ margin: "5px" }}
                      value={eventFromDate}
                      onChange={handleChangeEventFromdate}
                    />

                    <TextField
                      size="small"
                      InputProps={{ style: { borderRadius: "14px" } }}
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      label="To Date"
                      variant="outlined"
                      sx={{ margin: "5px" }}
                      value={eventToDate}
                      onChange={handleChangeEventTodate}
                    />
                  </>
                )}
              </Grid>

              <Grid item xs={12} lg={6} md={6} sm={6}>
                <Typography sx={{ fontWeight: 600 }}>Event</Typography>

                <Autocomplete
                  size="small"
                  noOptionsText={
                    eventloading && allcampaignList.length === 0
                      ? noOptionsText
                      : "Please Select Above Filter First"
                  }
                  multiple
                  value={eventname}
                  onChange={handelEventChange}
                  options={allcampaignList}
                  getOptionLabel={(option) => option.campaign_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "15px" }, // Add borderRadius here

                        endAdornment: (
                          <>
                            {eventloading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} lg={3} sm={6} md={6}>
                <Typography sx={{ fontWeight: 600 }}> From Date</Typography>
                <TextField
                  size="small"
                  value={fromDate}
                  onChange={handleFromDateChange}
                  type="date"
                  fullWidth
                  InputProps={{
                    style: { borderRadius: "15px" },
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={3} sm={6} md={6}>
                <Typography sx={{ fontWeight: 600 }}> To Date</Typography>
                <TextField
                  size="small"
                  value={endDate}
                  onChange={handleTodDateChange}
                  type="date"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                />
              </Grid>

              {/* <Grid item xs={12} lg={3} md={6} sm={6}>
                <FormControl fullWidth>
                  <Typography sx={{ fontWeight: 600 }}>Branch</Typography>

                  <Select
                    sx={{ borderRadius: "14px" }}
                    value={branch}
                    onChange={handelbranchchange}
                    MenuProps={MenuProps}
                    multiple
                    renderValue={(selected) =>
                      selected
                        .map((value) => {
                          if (value === "all") return "Select All";
                          const branches = branchlist.find(
                            (status) => status.id === value
                          );
                          return branches ? branches.branch_name : "";
                        })
                        .join(", ")
                    }
                  >
                    <MenuItem key="select-all" value="all">
                      <Checkbox checked={branch.length === branchlist.length} />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                    {branchlist.map((name) => (
                      <MenuItem key={name.id} value={name.id}>
                        <Checkbox checked={branch.indexOf(name.id) > -1} />
                        <ListItemText primary={name.branch_name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}

              <Grid item xs={12} lg={3} md={6} sm={6}>
                <Typography sx={{ fontWeight: 600 }}>Registered By</Typography>

                {/* <Select
                    sx={{ borderRadius: "14px" }}
                    value={registerBy}
                    onChange={handleRegisterbychange}
                    MenuProps={MenuProps}
                    multiple
                    renderValue={(selected) =>
                      selected
                        .map((value) => {
                          if (value === "all") return "Select All";
                          const data = registerdByList.find(
                            (status) => status.id === value
                          );
                          return data ? data.first_name : "";
                        })
                        .join(", ")
                    }
                  >
                    <MenuItem key="select-all" value="all">
                      <Checkbox
                        checked={registerBy.length === registerdByList.length}
                      />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                    {registerdByList.map((name) => (
                      <MenuItem key={name.id} value={name.id}>
                        <Checkbox checked={registerBy.indexOf(name.id) > -1} />
                        <ListItemText
                          primary={`${
                            name.first_name !== null ? name.first_name : ""
                          } ${name.last_name !== null ? name.last_name : ""}`}
                        />
                      </MenuItem>
                    ))}
                  </Select> */}
                <Autocomplete
                  size="small"
                  value={registerBy}
                  multiple
                  onChange={handleRegisterbychange}
                  options={allRegisteredByList}
                  getOptionLabel={(option) =>
                    `${option.first_name ? option.first_name : ""} ${
                      option.last_name ? option.last_name : ""
                    }`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "15px" }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid>

              {/* <Grid item xs={12} lg={3} md={6} sm={6}>
                <FormControl fullWidth>
                  <Typography sx={{ fontWeight: 600 }}>
                    Permanently Owner
                  </Typography>

                  <Select
                    sx={{ borderRadius: "14px" }}
                    value={permanentlyOwner}
                    onChange={handlePermanentlyOwnerchange}
                    MenuProps={MenuProps}
                    multiple
                    renderValue={(selected) =>
                      selected
                        .map((value) => {
                          if (value === "all") return "Select All";
                          const data = registerdByList.find(
                            (status) => status.id === value
                          );
                          return data ? data.first_name : "";
                        })
                        .join(", ")
                    }
                  >
                    <MenuItem key="select-all" value="all">
                      <Checkbox
                        checked={
                          permanentlyOwner.length === registerdByList.length
                        }
                      />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                    {registerdByList.map((name) => (
                      <MenuItem key={name.id} value={name.id}>
                        <Checkbox
                          checked={permanentlyOwner.indexOf(name.id) > -1}
                        />
                        <ListItemText
                          primary={`${
                            name.first_name !== null ? name.first_name : ""
                          } ${name.last_name !== null ? name.last_name : ""}`}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}

              {/* <Grid item xs={12} lg={3} md={6} sm={6}>
                <FormControl fullWidth>
                  <Typography sx={{ fontWeight: 600 }}>Service</Typography>

                  <Select
                    multiple
                    MenuProps={MenuProps}
                    value={service}
                    onChange={handleServiceChange}
                    style={{ borderRadius: "15px" }}
                  >
                    {servicelist.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.service_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}

              {/* <Grid item xs={12} lg={3} md={6} sm={6}>
                <FormControl fullWidth>
                  <Typography sx={{ fontWeight: 600 }}>
                    Prospective Level
                  </Typography>

                  <Select
                    sx={{ borderRadius: "14px" }}
                    value={prosectiveLevel}
                    onChange={handleProspectivechange}
                    MenuProps={MenuProps}
                    multiple
                    renderValue={(selected) =>
                      selected
                        .map((value) => {
                          if (value === "all") return "Select All";
                          const data = prospectiveLevelList.find(
                            (status) => status.id === value
                          );
                          return data ? data.prospective_level : "";
                        })
                        .join(", ")
                    }
                  >
                    <MenuItem key="select-all" value="all">
                      <Checkbox
                        checked={
                          prosectiveLevel.length === prospectiveLevelList.length
                        }
                      />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                    {prospectiveLevelList.map((name) => (
                      <MenuItem key={name.id} value={name.id}>
                        <Checkbox
                          checked={prosectiveLevel.indexOf(name.id) > -1}
                        />
                        <ListItemText primary={name.prospective_level} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}

              {/* <Grid item xs={12} lg={3} md={6} sm={6}>
                <FormControl fullWidth>
                  <Typography sx={{ fontWeight: 600 }}>Lead Stage</Typography>

                  <Select
                    sx={{ borderRadius: "14px" }}
                    value={leadstage}
                    onChange={handleLeadStagechange}
                    MenuProps={MenuProps}
                    multiple
                    renderValue={(selected) =>
                      selected
                        .map((value) => {
                          if (value === "all") return "Select All";
                          const data = leadStageList.find(
                            (status) => status.id === value
                          );
                          return data ? data.stage_name : "";
                        })
                        .join(", ")
                    }
                  >
                    <MenuItem key="select-all" value="all">
                      <Checkbox
                        checked={leadstage.length === leadStageList.length}
                      />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                    {leadStageList.map((name) => (
                      <MenuItem key={name.id} value={name.id}>
                        <Checkbox checked={leadstage.indexOf(name.id) > -1} />
                        <ListItemText primary={name.stage_name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}

              <Grid item xs={12} lg={3} md={6} sm={6}>
                <Typography sx={{ fontWeight: 600 }}>
                  Registration Status
                </Typography>

                {/* <Select
                    sx={{ borderRadius: "14px" }}
                    value={resterationstatus}
                    onChange={handleregStatusChange}
                    MenuProps={MenuProps}
                    multiple
                    renderValue={(selected) =>
                      selected
                        .map((value) => {
                          if (value === "all") return "Select All";
                          const data = RegistrationList.find(
                            (status) => status.id === value
                          );
                          return data ? data.registration_status : "";
                        })
                        .join(", ")
                    }
                  >
                    {RegistrationList.map((name) => (
                      <MenuItem key={name.id} value={name.id}>
                        <Checkbox
                          checked={resterationstatus.indexOf(name.id) > -1}
                        />
                        <ListItemText primary={name.registration_status} />
                      </MenuItem>
                    ))}
                  </Select> */}

                <Autocomplete
                  size="small"
                  value={resterationstatus}
                  multiple
                  onChange={handleregStatusChange}
                  options={allRegistrationList}
                  getOptionLabel={(option) => option.registration_status}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "15px" }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid>

              {/* <Grid item xs={12} lg={3} md={6} sm={6}>
                <FormControl fullWidth>
                  <Typography sx={{ fontWeight: 600 }}>
                    Nearest Branch
                  </Typography>

                  <Select
                    sx={{ borderRadius: "14px" }}
                    value={nearestBranch}
                    onChange={handelNearbranchchange}
                    MenuProps={MenuProps}
                    multiple
                    renderValue={(selected) =>
                      selected
                        .map((value) => {
                          if (value === "all") return "Select All";
                          const branches = branchlist.find(
                            (status) => status.id === value
                          );
                          return branches ? branches.branch_name : "";
                        })
                        .join(", ")
                    }
                  >
                    <MenuItem key="select-all" value="all">
                      <Checkbox
                        checked={nearestBranch.length === branchlist.length}
                      />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                    {branchlist.map((name) => (
                      <MenuItem key={name.id} value={name.id}>
                        <Checkbox
                          checked={nearestBranch.indexOf(name.id) > -1}
                        />
                        <ListItemText primary={name.branch_name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}

              <Grid item xs={12} md={12} lg={12} sm={12} textAlign="end">
                <Button
                  variant="contained"
                  sx={{ borderRadius: "25px" }}
                  onClick={handleSubmit}
                  startIcon={<SearchIcon />}
                >
                  Search
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ marginLeft: "10px", borderRadius: "25px" }}
                  onClick={handleresetFilterData}
                  startIcon={<ClearIcon />}
                >
                  Clear{" "}
                </Button>
              </Grid>
            </Grid>
          </MainCard>
        </Collapse>
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <MainCard>
          <Grid container direction="row">
            <Grid item xs={12} sm={4} md={4} lg={8}>
              <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
                Registration Status
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              md={8}
              lg={4}
              direction="row"
              textAlign="end"
              spacing={1}
            >
              <Button
                variant="contained"
                style={{ borderRadius: "24px" }}
                onClick={handleOpenmask}
              >
                Mass Task Assign{" "}
              </Button>

              <Button
                variant="contained"
                style={{ borderRadius: "24px", marginLeft: "10px" }}
                startIcon={<EmailIcon />}
                color="secondary"
                onClick={handleOpenEmail}
              >
                Send Email{" "}
              </Button>

              {isDownloadAccess && (
                <CSVExport data={exportData} filename="event-report.csv" />
              )}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: "14px",
            }}
          >
            <InfoCard label="Total:" value={eventData.length} color="#46BB4A" />
            <InfoCard
              label="Contactable:"
              value={totalConnectedCalls}
              color="#46BB4A"
            />
            <InfoCard
              label="Not Contactable:"
              value={totalnonConnectedCalls}
              color="#46BB4A"
            />
            <InfoCard
              label="Open Task:"
              value={totalOpenTask}
              color="#46BB4A"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: "14px",
            }}
          >
            <InfoCard
              label="Registered & Confirmed:"
              value={totalRegisteredAndConfirmed}
              color="#F44336"
            />
            <InfoCard
              label="Registered & Not Confirmed:"
              value={totalRegisteredAndNotConfirmed}
              color="#F44336"
            />
            <InfoCard
              label="Not Registered:"
              value={totalNotRegisterd}
              color="#F44336"
            />
            {/* <InfoCard label="Attended:" value="4" color="#F44336" />
      <InfoCard label="Confirmed Yes:" value="4" color="#F44336" /> */}
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: "14px",
            }}
          >
            <InfoCard label="Recycle:" value={totalRecycle} color="#46BB4A" />
            <InfoCard label="Warm:" value={totalWarm} color="#46BB4A" />
            <InfoCard label="New Lead:" value={totalNewLead} color="#46BB4A" />
          </Grid>

          <Grid
            item
            xs={12}
            // sx={{
            //   display: "flex",
            //   flexDirection: "row",
            //   alignItems: "center",
            //   marginBottom: "14px",
            // }}
          >
            <Button
              variant="outlined"
              style={{
                borderRadius: "25px",
                borderColor: selectedFilters.includes("active counselling")
                  ? "green"
                  : "#b2b2b4",
                color: selectedFilters.includes("active counselling")
                  ? "green"
                  : "#1B42BA",
                fontSize: "15px",
                fontWeight: 500,
                margin: "6px",
              }}
              onClick={() => handleFilterClick("active counselling")}
            >
              {" "}
              Active Counselling : {totalActiveCounselling}
            </Button>
            <Button
              variant="outlined"
              style={{
                borderRadius: "25px",
                borderColor: selectedFilters.includes("qualifying")
                  ? "green"
                  : "#b2b2b4",
                color: selectedFilters.includes("qualifying")
                  ? "green"
                  : "#1B42BA",
                fontSize: "15px",
                fontWeight: 500,
                margin: "6px",
              }}
              onClick={() => handleFilterClick("qualifying")}
            >
              {" "}
              Qualifying : {totalQualifyinf}
            </Button>
            <Button
              variant="outlined"
              style={{
                borderRadius: "25px",
                borderColor: selectedFilters.includes("assignment")
                  ? "green"
                  : "#b2b2b4",
                color: selectedFilters.includes("assignment")
                  ? "green"
                  : "#1B42BA",
                fontSize: "15px",
                fontWeight: 500,
                margin: "6px",
              }}
              onClick={() => handleFilterClick("assignment")}
            >
              {" "}
              Assignment : {totalAssignment}
            </Button>
            <Button
              variant="outlined"
              style={{
                borderRadius: "25px",
                borderColor: selectedFilters.includes("book demo")
                  ? "green"
                  : "#b2b2b4",
                color: selectedFilters.includes("book demo")
                  ? "green"
                  : "#1B42BA",
                fontSize: "15px",
                fontWeight: 500,
                margin: "6px",
              }}
              onClick={() => handleFilterClick("book demo")}
            >
              {" "}
              Book demo : {totalBookDemo}
            </Button>
            <Button
              variant="outlined"
              style={{
                borderRadius: "25px",
                borderColor: selectedFilters.includes("demo completed")
                  ? "green"
                  : "#b2b2b4",
                color: selectedFilters.includes("demo completed")
                  ? "green"
                  : "#1B42BA",
                fontSize: "15px",
                fontWeight: 500,
                margin: "6px",
              }}
              onClick={() => handleFilterClick("demo completed")}
            >
              {" "}
              Demo completed : {totalDemoCompleted}
            </Button>
            <Button
              variant="outlined"
              style={{
                borderRadius: "25px",
                borderColor: selectedFilters.includes("enrolled")
                  ? "green"
                  : "#b2b2b4",
                color: selectedFilters.includes("enrolled")
                  ? "green"
                  : "#1B42BA",
                fontSize: "15px",
                fontWeight: 500,
                margin: "6px",
              }}
              onClick={() => handleFilterClick("enrolled")}
            >
              {" "}
              Enrolled : {totalEnrolled}
            </Button>
            <Button
              variant="outlined"
              style={{
                borderRadius: "25px",
                borderColor: selectedFilters.includes("training")
                  ? "green"
                  : "#b2b2b4",
                color: selectedFilters.includes("training")
                  ? "green"
                  : "#1B42BA",
                fontSize: "15px",
                fontWeight: 500,
                margin: "6px",
              }}
              onClick={() => handleFilterClick("training")}
            >
              {" "}
              Training : {totalTraining}
            </Button>
            <Button
              variant="outlined"
              style={{
                borderRadius: "25px",

                borderColor: selectedFilters.includes("training completed")
                  ? "green"
                  : "#b2b2b4",
                color: selectedFilters.includes("training completed")
                  ? "green"
                  : "#1B42BA",
                fontSize: "15px",
                fontWeight: 500,
                margin: "6px",
              }}
              onClick={() => handleFilterClick("training completed")}
            >
              {" "}
              Training Completed : {totalTrainingCompleted}
            </Button>
          </Grid>

          <Box display="flex" justifyContent="space-between" mt={2}>
            <Box>
              <Button
                style={{ borderRadius: "25px" }}
                variant="contained"
                size="small"
              >
                {" "}
                Selected Items : {selectedid.length}{" "}
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "end",
              }}
            >
              <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
                Search:{"  "}
              </Typography>
              <TextField
                // value={value}
                onChange={handleSearchByName}
                size="small"
                InputProps={{ style: { borderRadius: "15px" } }}
              />
            </Box>
          </Box>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <style>
              {`
                .header-cell {
                    background-color: #ABF7B1; /* Set your desired background color */
                    border-right: 1px solid black;
                }
                `}
            </style>
          </div>
          <DataGrid
            disableColumnSorting
            rows={eventOriginalData}
            columns={columns}
            autoHeight
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            pageSize={pagination.pageSize}
            onPaginationModelChange={(model) => setPagination(model)}
            sx={{ marginTop: "20px" }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </MainCard>
      </Grid>

      {selectedid.length === 0 ? (
        <CustomPopup
          open={openMask}
          onClose={handleClosemask}
          component={<NoenquiryForm onClose={handleClosemask} />}
          maxWidth="xs"
        />
      ) : (
        <CustomPopup
          open={openMask}
          onClose={handleClosemask}
          component={
            <MassTaskAssign
              onClose={handleClosemask}
              selectedEnquiryIds={selectedEnquiryId}
              setSelectedEnquiryIds={setSelectedEnquiryids}
              handleFilterdata={handleFilterdata}
           
            />
          }
          title="Mass Task Assign"
          maxWidth="md"
          showDivider={true}
        />
      )}

      {selectedid.length === 0 ? (
        <CustomPopup
          open={OpenEmailPopup}
          onClose={handleCloseEmail}
          component={<NoeMailForm onClose={handleCloseEmail} />}
          maxWidth="xs"
        />
      ) : (
        <BulkEmailPopup
          open={OpenEmailPopup}
          handelclose={handleCloseEmail}
          component={
            <BulkMailform
              handelclose={handleCloseEmail}
              selectedId={selectedEnquiryId}
            />
          }
          title="Send Bulk Emails"
          maxWidth="md"
          showDivider={true}
        />
      )}

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
