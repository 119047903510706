// Author : Ayesha Shaikh
// Created on 22 aug 2024

import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";


import SearchIcon from "@mui/icons-material/Search";

import { PDFViewer, Document, Page } from "@react-pdf/renderer";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { formatTime, fTimeToAmPm } from "../../../utils/formatTime";
import TrainerReport from "./TrainerWorkReport";
import AdvisorWorkReport from "./AdvisorWorkReport";
import { useGetTokenOrLogout } from "../../../utils/token";
import { useNavigate } from "react-router-dom";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function WorkReport() {
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
  const [viewlogindetails, setViewLoginDetails] = useState(false);
  const [loginDetails, setLoginDetails] = useState(false);
  const [loginData, setLoginData] = useState([]);
  const [userlist, setUserlist] = useState([]);
 
  const [username, setUserName] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);
 
  const [openTodaysLogin, setOpenTodaysLogin] = useState(false);
 
  const [loginFilterType, setLoginFilterType] = useState("1week");

  const [loginfromDate, setLoginFromDate] = useState("");
  const [logintoDate, setLoginToDate] = useState("");

  const [loading, setIsLoading] = useState(false);
  const [demoloading, setIsDemoLoading] = useState(false);
  const [enrolementLoading, setEnrolementLoading] = useState(false);
  const [revenueLoading, setRevenueLoading] = useState(false);
  const [eventLoading, seteventLoadine] = useState(false);
  const [todaysLoginDetails, setTodaysLoginDetails] = useState([]);
  const [todaysLoding, setTodaysLoding] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const [trainerDemoLoading, setTrainerDemoLoading] = useState(false);
  const [classLoading, setClassLoading] = useState(false);
  const [savedEmailData,setSavedEmailData] =useState([])

  // Fetch Email Information
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${API_ENDPOINT}saveemailforreports/fetchbyuserid/${userId}`);
      if (response.ok) {
        const data = await response.json();

        setSavedEmailData(data);
        console.log(data);
      } else if(response.status===404) {
        console.log("No email data saved for this user ",'error')
      }
      else{
        console.log("error  fetching email data")
      }
    } catch (error) {
      console.error("Error fetching data:", error);
     
    }
  };

  fetchData();
}, [userId]);

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };



  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    marginTop: "20px",
    "& .MuiTableCell-head": {
      backgroundColor: "#abf7b1",
      fontWeight: 600,
      padding: "8px 16px",

      borderRight: "0.1px solid #C3C3C3",
      borderLeft: "0.1px solid #C3C3C3",
      color: "#000",
    },
    "& .MuiTableCell-body": {
      fontSize: "14px",
      fontWeight: 600,
      borderRight: "0.1px solid #C3C3C3",
      borderLeft: "0.1px solid #C3C3C3",
      align: "center",
      padding: "8px 16px",
      "&:nth-of-type(n+2)": {
        color: "blue",
      },
    },
  }));

  //login Details accordian
  const handleViewlogindetails = () => {
    setViewLoginDetails(true);
  };

  const handleCloselogindetails = () => {
    setViewLoginDetails(false);
  };

  //Table view login details
  const handleLoginDetailsopen = () => {
    setLoginDetails(true);
  };

  //Table close login details
  const handleLoginDetailsClose = () => {
    setLoginDetails(false);
  };
  console.log("logindetails", loginDetails);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          const data1 = sortedData.filter((i) => i.id === parseInt(userId));
          console.log(data1);
          if (userRole === "center_admin" || userRole === "data_entry") {
            setUserlist(sortedData);
          } else {
            setUserlist(data1);
          }
          const defaultValue = sortedData.find(
            (i) => i.id === parseInt(userId)
          );
          if (defaultValue) {
            setUserName(defaultValue);
          }
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleUserchange = (event, value) => {
    setUserName(value);
  };

  const handleLoginFilterChange = (event) => {
    setLoginFilterType(event.target.value);
    setLoginFromDate(null);
    setLoginToDate(null);
  };

  const handleLoginFromDate = (e) => {
    setLoginFromDate(e.target.value);
  };

  const handleLoginToDate = (e) => {
    setLoginToDate(e.target.value);
  };

  const handlesubmitLogin = async () => {
    setLoginLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = { 
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json" // Make sure to keep the content type
    };

    const requestData = {
      user_id: parseInt(userId),
      select_for: username?.id || parseInt(userId),
      filter: loginFilterType,
      from_date: loginfromDate || null,
      to_date: logintoDate || null,
    };

    try {
      const response = await fetch(`${API_ENDPOINT}logindetail/byuser`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result);
        setLoginData(result);

        setLoginLoading(false);
        handleViewlogindetails();
        handleLoginDetailsopen();
      }  else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }}
        catch{
          navigate("/page500");
        }
      }

  useEffect(() => {
    handlesubmitLogin();
  }, []);

  //View all users Todays  login Details

  const handleSubmitTodaysLoginDetails = async () => {
    setTodaysLoding(true);
    try {
      const response = await fetch(
        `${API_ENDPOINT}logindetail/todaylogindetail/${userId}`
      );
      if (response.ok) {
        const data = await response.json();
        const sortedData = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        setTodaysLoginDetails(sortedData);
        setTodaysLoding(false);
        handleOpenTodayslogin();
        console.log(sortedData);
      }
        else if (response.status === 403) {
          showSnackbar("UnAuthorized User!!",'error')
          setTodaysLoding(false);
      
      } else {
        setTodaysLoding(false);
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
    
      console.error("Error fetching data:", error);
    }
  };

  const handleOpenTodayslogin = () => {
    setOpenTodaysLogin(true);
  };

  const handleCloseTodayslogin = () => {
    setOpenTodaysLogin(false);
  };

 
  


 
 
  return (
    <>
      {/* loader for Task */}
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching Task Details
        </Typography>
        <CircularProgress color="secondary" />
      </Backdrop>

      {/* loader for Demo */}
      <Backdrop open={demoloading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching Demo Details
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      {/* loader for Enrolement */}
      <Backdrop open={enrolementLoading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching Enrolment Details
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      {/* loader for Revenue */}
      <Backdrop open={revenueLoading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching Revenue Details
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      {/* loader for Event */}
      <Backdrop open={eventLoading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching Event Details
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      <Backdrop open={loginLoading || todaysLoding} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching Login Details
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      {/* Loader for Trainer Demo */}
      <Backdrop open={trainerDemoLoading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching demo Details
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      <Backdrop open={classLoading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching Class Details
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <Paper
            sx={{
              width: "200px",
              fontSize: "24px",
              fontWeight: 600,
              height: "50px",
              borderRadius: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="/work-list.png"
              width="30px"
              style={{ marginRight: "10px" }}
            />
            Work Report
          </Paper>
        </Grid>

        {/* Login Details */}
        <Grid item xs={12} lg={12} sm={12} md={12} mt={2}>
          <MainCard>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12} lg={12} sm={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    color: "green",
                    cursor: "pointer",
                  }}
                  // onClick={handleViewlogindetails}

                  onClick={() =>
                    viewlogindetails
                      ? handleCloselogindetails()
                      : handleViewlogindetails()
                  }
                >
                  <VisibilityOutlinedIcon />
                  <Typography
                    sx={{
                      fontSize: "19px",
                      fontWeight: "600",
                      marginLeft: "10px",
                    }}
                  >
                    View Login Details
                  </Typography>
                </Box>
              </Grid>

              {viewlogindetails && (
                <>
                  {(userRole === "center_admin" ||
                    userRole === "data_entry") && (
                    <Grid item xs={12} lg={3} md={6} sm={6}>
                      <Typography sx={{ fontWeight: 600 }}>
                        Select Users
                      </Typography>

                      <Autocomplete
                        size="small"
                        value={username}
                        onChange={handleUserchange}
                        //disableClearable
                        options={userlist}
                        getOptionLabel={(option) =>
                          `${option.first_name ? option.first_name : ""} ${
                            option.last_name ? option.last_name : ""
                          }`
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { borderRadius: "15px" }, // Add borderRadius here
                            }}
                          />
                        )}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} lg={2} md={3} sm={3}>
                    <Typography sx={{ fontWeight: 600 }}>Select For</Typography>
                    <Select
                      value={loginFilterType}
                      onChange={handleLoginFilterChange}
                      fullWidth
                      style={{ borderRadius: "15px" }}
                      size="small"
                    >
                      <MenuItem value="1week">1 Week</MenuItem>
                      <MenuItem value="1month">1 Month</MenuItem>

                      <MenuItem value="custom">Custom</MenuItem>
                    </Select>
                  </Grid>

                  {loginFilterType === "custom" && (
                    <>
                      <Grid item xs={12} lg={2} md={3} sm={3}>
                        <Typography sx={{ fontWeight: 600 }}>
                          From Date
                        </Typography>
                        <TextField
                          value={loginfromDate}
                          onChange={handleLoginFromDate}
                          fullWidth
                          type="date"
                          size="small"
                          InputProps={{ style: { borderRadius: "15px" } }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={2} md={3} sm={3}>
                        <Typography sx={{ fontWeight: 600 }}>
                          To Date
                        </Typography>
                        <TextField
                          value={logintoDate}
                          onChange={handleLoginToDate}
                          fullWidth
                          type="date"
                          size="small"
                          InputProps={{ style: { borderRadius: "15px" } }}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} lg={(userRole==="center_admin" ||  userRole === "data_entry") && loginFilterType==="custom" ?12 :6 } md={6} sm={6}>
                    <>
                      <Typography
                        sx={{ fontWeight: 600, visibility: "hidden" }}
                      >
                        for spacing
                      </Typography>
                      <Button
                        variant="contained"
                        style={{ borderRadius: "25px" }}
                        startIcon={<SearchIcon />}
                        onClick={handlesubmitLogin}
                      >
                        Search
                      </Button>

                      {(userRole === "center_admin" ||
                        userRole === "data_entry") && (
                        <Button
                          variant="contained"
                          style={{ borderRadius: "25px", marginLeft: "10px" }}
                          startIcon={<VisibilityOutlinedIcon />}
                          onClick={
                            openTodaysLogin
                              ? handleCloseTodayslogin
                              : handleSubmitTodaysLoginDetails
                          }
                          color="secondary"
                        >
                          View All Users Today's Login Details
                        </Button>
                      )}
                    </>
                  </Grid>

                  {loginDetails && (
                    <>
                      {/* My Login details */}
                      <Grid item xs={12} lg={6} sm={12} md={12} mt={6}>
                        {loginData.length===0  ? (
                          <Typography textAlign='center' fontWeight={600} fontSize='18px'>No Login Details Available</Typography>
                        ) : (
                          <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: 600 }}
                          >
                            Name :{" "}
                            <strong style={{ color: "blue" }}>
                              {loginData?.first_name}
                            </strong>
                          </Typography>
                          <Chip
                          size="small"
                            color="secondary"
                            label={loginData?.role}
                            sx={{
                              fontSize: "14px",
                              fontWeight: 600,
                              marginLeft: "8px",
                            }}
                          />

                          <Typography
                            sx={{
                              fontSize: "16px",
                              textAlign: "end",
                              fontWeight: 600,
                            }}
                            ml={6}
                          >
                            Email :{" "}
                            <strong style={{ color: "blue" }}>
                              {loginData?.email}
                            </strong>
                          </Typography>
                        </Box>

                        <StyledTableContainer
                          component={Paper}
                          style={{ maxHeight: "300px" }}
                        >
                          <Table aria-label="simple table" stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell>Date of Login</TableCell>
                                <TableCell>First Login At</TableCell>
                                <TableCell>Last Login At</TableCell>
                                <TableCell>No of login in a day</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {loginData?.login_detail?.map((row) => (
                                <TableRow key={row.id}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ color: "blue" }}
                                  >
                                    {row.login_date}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {formatTime(row.first_login_time)}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {formatTime(row.last_login_time)}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {row.login_count}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </StyledTableContainer>
                        </>
                         )}
                      </Grid>
                    </>
                  )}
                  {/* Todays All User Login details */}

                  {openTodaysLogin && (
                    <Grid item xs={12} lg={6} sm={12} md={12} mt={6}>
                      <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
                        Today's All Users Login Details : {todaysLoginDetails?.length} Users
                      </Typography>
                      <StyledTableContainer
                        component={Paper}
                        style={{ marginTop: "30px", maxHeight: "300px" }}
                      >
                        <Table aria-label="simple table" stickyHeader>
                          <TableHead>
                            <TableRow style={{ background: "blue" }}>
                              <TableCell>Name</TableCell>

                              <TableCell>First Login At</TableCell>
                              <TableCell>Last Login At</TableCell>
                              <TableCell>No of login in a day</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {todaysLoginDetails?.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                  {row?.user_id?.first_name}{" "}
                                  {row?.user_id?.last_name}
                                </TableCell>

                                <TableCell component="th" scope="row">
                                  {formatTime(row.first_login_time)}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {formatTime(row.last_login_time)}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.login_count}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </StyledTableContainer>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </MainCard>
        </Grid>

        {(userRole === "advisor" ||
          userRole === "admin" ||
          userRole === "center_admin" ||
          userRole === "data_entry") && (
          <Grid item xs={12} lg={12} sm={12} md={12} mt={2}>
          <AdvisorWorkReport showSnackbar={showSnackbar} setIsLoading={setIsLoading} setIsDemoLoading={setIsDemoLoading} seteventLoadine={seteventLoadine}
          setRevenueLoading={setRevenueLoading}
          setEnrolementLoading={setEnrolementLoading}
          savedEmailData={savedEmailData}
          />
          </Grid>
        )}

        {(userRole === "trainer" ||
          userRole === "center_admin" ||
          userRole === "data_entry" ||
          userRole === "admin") && (
          <>
            <Grid item xs={12} lg={12} sm={12} md={12} mt={2}>
              {" "}
              <TrainerReport
                setTrainerDemoLoading={setTrainerDemoLoading}
                showSnackbar={showSnackbar}
                setIsLoading={setIsLoading}
                setClassLoading={setClassLoading}
                savedEmailData={savedEmailData}
              />
            </Grid>
          </>
        )}
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
