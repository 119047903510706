import { ChevronLeft } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  Grid,
  TextField,
  Typography,
  styled,
  Autocomplete,
  Box,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useGetTokenOrLogout } from "../../../../../utils/token";
import { useNavigate } from "react-router-dom";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function StudentFilter({
  isOpen,
  setIsOpen,
  name,
        setName,
        enrollmentNo,
        setEnrollmentNo,
        enrollmentDateFrom,
        setEnrollmentDateFrom,
        enrollmentDateTo,
        setEnrollmentDateTo,
        statusname,
        setstatusname,
        branch,
        setBranch,

        examoption,
        setexamoption,
        filterddata,
        setOriginalStudentData,
        setFilteredData,
        setIsLoading,
        branchofEnquiry,
        setBranchOfEnquiry,
        setCurrentPage,

 

 OriginalStudentData,setStudents
}) {
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 4;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  const userId = localStorage.getItem("userId");
  const [examtype, setExamtype] = useState([]);

  const [branchlist, setBranchlist] = useState([]);

  console.log("statusname", statusname);
  const newBranch = branch.map((i) => i.id);
  const newStatus = statusname.map((i) => i);
  const newExamOption=examoption.map((i)=>i.id)
  const enquiryBranch=branchofEnquiry.map((i)=>i.id)
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();

  const handleFilter = async (event) => {
    setCurrentPage(1)
setIsOpen(false)

const token = getTokenOrLogout();
if (!token) return;
let headers = { 
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json" // Make sure to keep the content type
};

    const requestData = {
      // first_name : name,
      enrollment_no : parseInt(enrollmentNo) || null,
      enrolled_from_date:enrollmentDateFrom || null,
      enrolled_to_date:enrollmentDateTo || null,
      status :newStatus,
      branch_id:newBranch,
      // test_type_id:newExamOption,
      // enquiry_branch_id:branchofEnquiry,
      user_id: parseInt(userId)
    }

    setIsLoading(true)
    try {
      const response = await fetch(`${API_ENDPOINT}route/studentfilter`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
      
        const result = await response.json();
        const sortedStudents = result.sort(
          (a, b) => new Date(b.joining_date) - new Date(a.joining_date)
        );
        setStudents(sortedStudents)
        setFilteredData(sortedStudents)

        setIsLoading(false)
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      }  else if (response.status === 440) {
        navigate("/page440");
      }
      else {
        navigate("/page500");
      }
      
     

    } catch (error) {
      console.error("Error:", error);
      navigate("/page500");
    
    }
  };

  const resetFilter = () => {
    setCurrentPage(1)
    setName("");
  
    setBranch([]);
    setstatusname([]);
  setEnrollmentNo('')
 
setStudents(OriginalStudentData)
setIsOpen(false)
setexamoption([])
   
  };

  const handelchangename = (e) => {
    setName(e.target.value);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalltesttype`);
        if (response.ok) {
          const data = await response.json();

          setExamtype(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // The empty dependency array ensures this effect runs once on mount

  const allexamList = [{ name: "Select All" }, ...examtype];

  const handleChangeexam = (event, value) => {
    if(value.some((option)=>option.name==="Select All")){
      setexamoption(examtype)
    }
    else{
      setexamoption(value);
    }
    
    
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();

          setBranchlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allbranchList = [{ branch_name: "Select All" }, ...branchlist];

  const handelbranchchange = (event, value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchlist);
    } else {
      setBranch(value);
    }
  };

  const handelEnquirybranchchange = (event, value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranchOfEnquiry(branchlist);
    } else {
      setBranchOfEnquiry(value);
    }
  };

  console.log("branch", branch);
  const handleEnrollmentDateFromChange = (e) => {
    setEnrollmentDateFrom(e.target.value);
    console.log(`start date is : ${e.target.value}`);
  };

  const handleEnrollmentDateToChange = (e) => {
    setEnrollmentDateTo(e.target.value);
    console.log(`End date is : ${e.target.value}`);
  };

  const status = ["active", "inactive"];

  const handelstatuschange = (event, value) => {
    setstatusname(value);
  };


   // Add default 1 month start and end date
   const formatDate = (date) => date.toISOString().split('T')[0];
   useEffect(() => {
     const today = new Date();
     let oneMonthAgo = new Date();
     oneMonthAgo.setDate(today.getDate() - 30);
 
     setEnrollmentDateTo(formatDate(today));
     setEnrollmentDateFrom(formatDate(oneMonthAgo));
   }, []);
 
  return (
    <>
      <Drawer
        open={isOpen}
        anchor="right"
        onClose={() => setIsOpen(false)}
        sx={{ width: "500px", "& .MuiDrawer-paper": { width: "500px" } }}
      >
        <DrawerHeader>
          <Typography variant="h4">Filter Students</Typography>
          <IconButton onClick={() => setIsOpen(false)}>
            <ChevronLeft fontSize="large" />
          </IconButton>
        </DrawerHeader>
      
         
         <Box sx={{padding:'16px'}}>
    
        
            <Grid container spacing={3}>

              {/* <Grid item xs={12} lg={12} sm={12} md={12}>
                <Typography sx={{ fontWeight: 600 }}>First Name</Typography>
                <TextField
                  size="small"
                  type="text"
                  fullWidth
                  value={name}
                  onChange={handelchangename}
                  InputProps={{ style: { borderRadius: "15px" } }}
                />
              </Grid> */}

              <Grid item xs={12} lg={12} sm={12} md={12}>
                <Typography sx={{ fontWeight: 600 }}>Enrollment No</Typography>
                <TextField
                  size="small"
                  type="number"
                  fullWidth
                  value={enrollmentNo}
                  onChange={(e) => setEnrollmentNo(e.target.value)}
                  InputProps={{ style: { borderRadius: "15px" } }}
                />
              </Grid>

              <Grid item xs={12} lg={6} sm={6} md={6}>
                <Typography sx={{ fontWeight: 600 }}>
                  {" "}
                  Enrollment Date From
                </Typography>
                <TextField
                  size="small"
                  type="date"
                  fullWidth
                  value={enrollmentDateFrom}
                  onChange={handleEnrollmentDateFromChange}
                  InputProps={{ style: { borderRadius: "15px" } }}
                />
              </Grid>

              <Grid item xs={12} lg={6} sm={6} md={6}>
                <Typography sx={{ fontWeight: 600 }}>
                  Enrollment Date To
                </Typography>
                <TextField
                  size="small"
                  type="date"
                  fullWidth
                  value={enrollmentDateTo}
                  onChange={handleEnrollmentDateToChange}
                  InputProps={{ style: { borderRadius: "15px" } }}
                />
              </Grid>

              <Grid item xs={12} lg={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <Grid>
                    <Typography sx={{ fontWeight: 600 }}>Status</Typography>

                    <Autocomplete
                    disablePortal
                      size="small"
                      fullWidth
                      value={statusname}
                      multiple
                      onChange={handelstatuschange}
                      options={status}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "15px" }, // Add borderRadius here
                          }}
                        />
                      )}
                    />
                  </Grid>
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={12} sm={12} md={12}>
                <Typography sx={{ fontWeight: 600 }}>Enrolled Branch</Typography>

                <Autocomplete
                  size="small"
                  disablePortal
                  fullWidth
                  value={branch}
                  multiple
                  onChange={handelbranchchange}
                  options={allbranchList}
                  getOptionLabel={(option) => option.branch_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "15px" }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid>
              
              {/* <Grid item xs={12} lg={12} sm={12} md={12}>
                <Typography sx={{ fontWeight: 600 }}>Enquiry Branch</Typography>

                <Autocomplete
                  size="small"
                  disablePortal
                  fullWidth
                  value={branchofEnquiry}
                  multiple
                  onChange={handelEnquirybranchchange}
                  options={allbranchList}
                  getOptionLabel={(option) => option.branch_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "15px" }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid>
            */}

              {/* <Grid item xs={12} lg={12} sm={12} md={12}>
                <Typography sx={{ fontWeight: 600 }}>Test Type</Typography>

                <Autocomplete
                disablePortal
                  size="small"
                  fullWidth
                  value={examoption}
                  multiple
                  onChange={handleChangeexam}
                  options={allexamList}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "15px" }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid> */}
      

        <Grid
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          width="500px"
       
        position='absolute'
          bottom={10}
        >
          <Button
            variant="contained"
            style={{ background: "#4242f5", width: "150px" }}
            onClick={handleFilter}
          >
            {" "}
            Filter
          </Button>
          <Button
            variant="contained"
            style={{ background: "#f7690a", width: "150px" }}
            onClick={() => setIsOpen(false)}
          >
            {" "}
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ width: "150px" }}
            onClick={resetFilter}
          >
            {" "}
            Reset
          </Button>
        </Grid>
        </Grid>
        </Box>
      </Drawer>
    </>
  );
}
