import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { Person as PersonIcon } from "@mui/icons-material";
import RecycleRequestFilter from "./RecycleRequestFilter";
import { CSVExport } from "../../components/ui-component/table/CSVExport";
import EmailIcon from "@mui/icons-material/Email";

import CustomPopup from "../../../components/CustomPopup";

import NoTaskPopup from "../enquiry/tasks/NoTaskPopup";
import { fDate } from "../../../utils/formatTime";

import RecycleEnquiryTransfer from "./RecycleEnquiryTransfer";
import RecycleMaskTaskAssign from "./RecyCleRequestMassTaskAssign";
import { useGetTokenOrLogout } from "../../../utils/token";
import NoenquiryForm from "../../components/DataTransferComp.jsx/NoenquiryForm";
import NoeMailForm from "../../components/DataTransferComp.jsx/NoMailForm";
import BulkMailform from "../../components/BulkEmail/BulkMailform";
import BulkEmailPopup from "../../components/BulkEmail/BulkEmailPopup";

export default function RecycleRequest() {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const getTokenOrLogout = useGetTokenOrLogout();

  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const [recycleData, setRecycleData] = useState([]);
  const [SearchData, setSearchData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [open, setopen] = useState(false);
  const [isDownloadAccess, setIsDownloadAccess] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [openTask, setOpenTask] = useState(false);
  const [openEmailPopup, setOpenEmailPopup] = useState(false);
  const [openTaskTransfer, setOpenTaskTransfer] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedEnquiryids, setSelectedEnquiryids] = useState([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 100,
  });

  const [visibleRowIds, setVisibleRowIds] = useState([]); // Store current page's row IDs
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [prevPageSize, setPrevPageSize] = useState(pagination.pageSize);

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const rows = [];

  useEffect(() => {
    setLoading(true);

    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route2.0/recyclerequest/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setRecycleData(sortedData);
          setOriginalData(sortedData);
          setSearchData(sortedData);
          setLoading(false);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch {
        navigate("/page500");
      }
    };

    fetchData();
  }, [userId]);

  const handleViewMore = (row) => {
    // Handle the "View More" action here
    // console.log('View More:', row);

    const url = `/enquirydetails/${row.enquiry_id.id}`;
    const newState = { enquiryDetails: row };

    // Open link in new tab
    const newTab = window.open(url, "_blank");

    // If new tab opened successfully, navigate with state data
    if (newTab) {
      newTab.onload = () => {
        newTab.history.replaceState(newState, "", url);
      };
    } else {
      // If new tab was blocked by browser, navigate with state data in current tab
      navigate(url, { state: newState });
    }
  };

  useEffect(() => {
    const exportData = recycleData.map((row) => ({
      enquiry_name: `${row.enquiry_id?.first_name} ${
        row?.enquiry_id?.last_name ? row?.enquiry_id?.last_name : ""
      }`,
      mobile: row.enquiry_id?.mobile,
      //email: row.enquiry_id?.email || "",
      branch_name: row.enquiry_id?.branch_id?.branch_name,
      sub_service: row.enquiry_id?.sub_service_id?.name || "",
      lead_source: row.enquiry_id?.lead_source_type_id?.name || "",
      sub_lead_source:
        row.enquiry_id?.sub_lead_source_type_id?.source_type || "",
      stage: row.enquiry_id?.stage_id?.stage_name || "",
      recycled_by: row.recycle_by?.first_name || "",
      recycle_reason: row.request_reason_id?.recycle_reason || "",
      recycled_request_date: fDate(row.requested_date),
      status: row?.status,
    }));
    setExportData(exportData);
  }, [recycleData]);

  const columns = [
    // {
    //   field: "selection",
    //   sortable: false,
    //   renderHeader: (params) => {
    //     return (
    //       <>
    //         <input
    //           type="checkbox"
    //           checked={isSelectAllChecked}
    //           onChange={handleSelectAllChange}
    //         />
    //       </>
    //     );
    //   },
    //   headerClassName: "header-cell",
    //   width: 70,
    //   renderCell: (params) => {
    //     const isChecked = selectedid.includes(params.row.enquiry_id?.id);
    //     return (
    //       <>
    //         <input
    //           type="checkbox"
    //           checked={isChecked}
    //           onChange={(event) =>
    //             handleCheckboxChange(event, params.row?.enquiry_id?.id)
    //           }
    //         />
    //       </>
    //     );
    //   },
    // },
    {
      field: "selection",
      sortable: false,
      renderHeader: (params) => (
        <Checkbox
          size="small"
          checked={isSelectAllChecked}
          indeterminate={isIndeterminate}
          onChange={handleSelectAllChange}
        />
      ),
      headerClassName: "header-cell",
      width: 70,
      renderCell: (params) => {
        const isChecked = selectedIds.includes(params.row?.id);
        return (
          <Checkbox
            size="small"
            checked={isChecked}
            onChange={(event) => handleCheckboxChange(event, params.row?.id)}
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",

      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {" "}
          <div>
            <Button size="small" onClick={() => handleViewMore(params.row)}>
              <PersonIcon /> {params.row?.enquiry_id?.first_name}{" "}
              {params.row?.enquiry_id?.last_name
                ? params.row?.enquiry_id?.last_name
                : ""}
            </Button>
          </div>
        </div>
      ),
      headerAlign: "center",
      align: "center",
    },

    {
      field: "branchName",
      headerName: " Branch Name",

      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.branch_id?.branch_name}</div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "service",
      headerName: "Service",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.service_id?.service_name}</div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "subService",
      headerName: "Sub Service",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.sub_service_id?.name}</div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "leadSource",
      headerName: "Lead Source",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.lead_source_type_id?.name}</div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "sublead",
      headerName: "Sub Lead Source",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params.row?.enquiry_id?.sub_lead_source_type_id?.source_type}
        </div>
      ),
      headerAlign: "center",
      align: "center",
    },

    {
      field: "stage",
      headerName: "Stages",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.stage_id?.stage_name}</div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "recycleBy",
      headerName: "Recycled By",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params.row?.recycle_by?.first_name}{" "}
          {params.row?.recycle_by?.last_name}
        </div>
      ),
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "requestedTo",
    //   headerName: "Requested To",
    //   width: 200,
    //   headerClassName: "header-cell",
    //   renderCell: (params) => (
    //     <div>
    //       {params.row?.request_to?.first_name}{" "}
    //       {params.row?.request_to?.last_name}
    //     </div>
    //   ),
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "recycleReson",
      headerName: "Recycle Reason",
      width: 300,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>{params.row?.request_reason_id?.recycle_reason}</div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "rdate",
      headerName: "Requested Date",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params.row?.requested_date}</div>,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params.row?.status === "pending" ? (
            <Chip
              size="small"
              label="Pending"
              sx={{ background: "#CFA31C", color: "white" }}
            />
          ) : params.row?.status === "accepted" ? (
            <Chip size="small" label="Accepted" color="secondary" />
          ) : params.row?.status === "cancelled" ? (
            <Chip size="small" label="Cancelled" color="error" />
          ) : (
            " "
          )}
        </div>
      ),
      headerAlign: "center",
      align: "center",
    },
  ];
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Available</Box>
      </StyledGridOverlay>
    );
  }

  useEffect(() => {
    fetch(`${API_ENDPOINT}route/downloadaccess/${userId}`)
      .then((response) => {
        if (response.ok) {
          setIsDownloadAccess(true);
        } else {
          setIsDownloadAccess(false);
        }
      })
      .catch((err) => {
        setIsDownloadAccess(false);
      });
  }, [userId]);

  const handleOpenTask = () => {
    setOpenTask(true);
  };

  const handleCloseTask = () => {
    setOpenTask(false);
  };
  const handleOpenEmail = () => {
    setOpenEmailPopup(true);
  };

  const handleCloseEmail = () => {
    setOpenEmailPopup(false);
  };

  const handleOpenTaskTransfer = () => {
    setOpenTaskTransfer(true);
  };

  const handleCloseTaskTransfer = () => {
    setOpenTaskTransfer(false);
  };

  // Upadate current page ids when pagination is chnages
  useEffect(() => {
    console.log("pagination", pagination, selectedIds);
    setPrevPageSize(pagination.pageSize);

    // new pagesize is less than prev page size then deselct all selected ids first
    if (pagination.pageSize !== prevPageSize) {
      setSelectedIds([]);
      return;
    }

    // Update visible row IDs when pagination changes
    const currentPageRows = recycleData.slice(
      pagination.page * pagination.pageSize,
      (pagination.page + 1) * pagination.pageSize
    );
    const visibleIds = currentPageRows.map((row) => row.id);
    setVisibleRowIds(visibleIds);

    // If there are no visible rows, set SelectAllChecked to false
    if (visibleIds.length === 0) {
      setIsSelectAllChecked(false);
      setIsIndeterminate(false);
      return;
    }

    // Check if all visible rows are selected
    const allVisibleSelected = visibleIds.every((id) =>
      selectedIds.includes(id)
    );
    const someVisibleSelected = visibleIds.some((id) =>
      selectedIds.includes(id)
    );

    setIsSelectAllChecked(allVisibleSelected);
    setIsIndeterminate(someVisibleSelected && !allVisibleSelected);
  }, [pagination, recycleData, selectedIds]);

  // HandleSelectAllCheckBox
  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    setIsSelectAllChecked(checked);

    if (checked) {
      // Select all visible row IDs
      setSelectedIds((prevSelectedIds) => [
        ...new Set([...prevSelectedIds, ...visibleRowIds]),
      ]);
    } else {
      // Deselect all visible row IDs
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((id) => !visibleRowIds.includes(id))
      );
    }
  };

  // Individual CheckBox
  const handleCheckboxChange = (event, id) => {
    const checked = event.target.checked;

    setSelectedIds((prevSelectedIds) => {
      if (checked) {
        const newSelected = [...prevSelectedIds, id];
        // Check if all visible rows are selected after this checkbox is checked
        const allVisibleSelected = visibleRowIds.every((rowId) =>
          newSelected.includes(rowId)
        );
        setIsSelectAllChecked(allVisibleSelected);
        setIsIndeterminate(!allVisibleSelected);

        return newSelected;
      } else {
        // Deselect the row ID
        const newSelected = prevSelectedIds.filter((rowId) => rowId !== id);
        setIsSelectAllChecked(false);
        setIsIndeterminate(
          visibleRowIds.some((rowId) => newSelected.includes(rowId))
        );

        return newSelected;
      }
    });
  };

  // Select EnquiryIds
  useEffect(() => {
    if (selectedIds.length > 0) {
      console.log("taskIds", selectedIds);
      const enquiryIds = recycleData.filter((id) =>
        selectedIds.includes(id?.id)
      );
      const selectedEnquiryids = enquiryIds.map((i) => i.enquiry_id?.id);
      setSelectedEnquiryids(selectedEnquiryids);
    }
  }, [selectedIds]);

  // Search By Name
  const handleSearchByName = (e) => {
    const value = e.target.value;
    let filteredData1 = SearchData.filter((data) =>
      `${data?.enquiry_id?.first_name} ${data?.enquiry_id?.last_name}`
        ?.toLowerCase()
        .replace(/\s+/g, "")
        .includes(value.toLowerCase().replace(/\s+/g, ""))
    );
    setRecycleData(filteredData1);
  };
  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid Grid container spacing={2}>
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <RecycleRequestFilter
            setRecycleData={setRecycleData}
            originalData={originalData}
            loading={loading}
            setLoading={setLoading}
            showSnackbar={showSnackbar}
            setSearchData={setSearchData}
          />
        </Grid>
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <MainCard>
            <Grid item xs={12} textAlign="end">
              {(userRole === "center_admin" ||
                userRole === "data_entry" ||
                userRole === "admin") && (
                <>
                  <Button
                    variant="contained"
                    onClick={() => handleOpenTaskTransfer()}
                    style={{ borderRadius: "30px", background: "#CFA31C" }}
                  >
                    Mass Enquiry Transfer
                  </Button>

                  <Button
                    variant="contained"
                    style={{ borderRadius: "24px", marginLeft: "10px" }}
                    onClick={handleOpenTask}
                  >
                    Mass Task Assign{" "}
                  </Button>

                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "24px",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                    startIcon={<EmailIcon />}
                    color="secondary"
                    onClick={handleOpenEmail}
                  >
                    Send Email{" "}
                  </Button>
                </>
              )}
              {isDownloadAccess && (
                <CSVExport
                  data={exportData}
                  filename="recycled-leads-data.csv"
                />
              )}
            </Grid>

            <Box display="flex" justifyContent="space-between" mt={2}>
              <Box>
                <Button
                  style={{ borderRadius: "25px" }}
                  variant="contained"
                  size="small"
                >
                  {" "}
                  Selected Items : {selectedIds.length}{" "}
                </Button>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: 600 }}>Search:{"  "}</Typography>
                <TextField
                  // value={value}
                  onChange={handleSearchByName}
                  size="small"
                  InputProps={{ style: { borderRadius: "15px" } }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                height: "100%",
                width: "100%",
                "& .header-cell": {
                  backgroundColor: "#abf7b1",
                  fontWeight: 600,
                  borderRight: "0.1px solid #C3C3C3",
                },
              }}
            >
              <DataGrid
                rows={recycleData}
                columns={columns}
                autoHeight
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                pageSize={pagination.pageSize}
                onPaginationModelChange={(model) => setPagination(model)}
                sx={{ marginTop: "20px" }}
                pageSizeOptions={[10, 25, 50, 100]}
              />
            </Box>
          </MainCard>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      {selectedIds.length === 0 ? (
        <CustomPopup
          open={openTask}
          onClose={handleCloseTask}
          component={<NoenquiryForm onClose={handleCloseTask} />}
          maxWidth="xs"
        />
      ) : (
        <CustomPopup
          open={openTask}
          onClose={handleCloseTask}
          component={
            <RecycleMaskTaskAssign
              onClose={handleCloseTask}
              selectedEnquiryids={selectedEnquiryids}
              setSelectedEnquiryids={setSelectedEnquiryids}
              selectedId={selectedIds}
              setSelectedIds={setSelectedIds}
            />
          }
          title="Mass Task Assign"
          maxWidth="md"
          showDivider={true}
        />
      )}

      {selectedIds.length === 0 ? (
        <CustomPopup
          open={openEmailPopup}
          onClose={handleCloseEmail}
          component={<NoeMailForm onClose={handleCloseEmail} />}
          maxWidth="xs"
        />
      ) : (
        <BulkEmailPopup
          open={openEmailPopup}
          handelclose={handleCloseEmail}
          component={
            <BulkMailform
              handelclose={handleCloseEmail}
              selectedId={selectedEnquiryids}
            />
          }
          title="Send Bulk Emails"
          maxWidth="md"
          showDivider={true}
        />
      )}

      {selectedEnquiryids.length === 0 ? (
        <CustomPopup
          open={openTaskTransfer}
          onClose={handleCloseTaskTransfer}
          component={<NoTaskPopup onClose={handleCloseTaskTransfer} />}
          maxWidth="xs"
        />
      ) : (
        <CustomPopup
          open={openTaskTransfer}
          onClose={handleCloseTaskTransfer}
          component={
            <RecycleEnquiryTransfer
              onClose={handleCloseTaskTransfer}
              selectedEnquiryids={selectedEnquiryids}
              setRows={setRecycleData}
              rows={recycleData}
              setOriginalRows={setOriginalData}
              setSelectedEnquiryids={setSelectedEnquiryids}
              setIsSelectAllChecked={setIsSelectAllChecked}
              selectedId={selectedIds}
              setSelectedIds={setSelectedIds}
            />
          }
          title="Enquiry Transfer"
          maxWidth="sm"
          showDivider={true}
        />
      )}
    </>
  );
}
