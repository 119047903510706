import {
  Document,
  Font,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});
export default function AdvisorTargetReportPdf() {
  const [details, setDetails] = useState([]);
  const [advisorName, setAdvisorName] = useState([]);

  const styles = StyleSheet.create({
    document: { padding: 10 },
    page: {
      fontFamily: "Open Sans",

      paddingTop: 30,
      paddingLeft: 60,
      paddingRight: 60,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    table: {
      marginTop: 10,
      width: "100%",
      borderCollapse: "collapse",
      marginBottom: 10,
    },
    tableRow: { flexDirection: "row" ,},
    tableCell: {
      padding: 5,
      fontSize: 10,
      textAlign: "center",
      flex: 1,
      border: "0.5px solid black",
    },

    tableHeaderCell: {
      fontFamily: "Open Sans",
      padding: 5,
      fontSize: 11,
      textAlign: "center",
      fontWeight: "bold",
      flex: 1,
      border: "0.5px solid black",

      backgroundColor: "#7ff2ba",
      color: "#002e17",
    },

    tableHeader: { flexDirection: "row" },
    text: {
      fontSize: 17,
    },
    name: {
      marginTop: 10,
      flexDirection: "row",
      alignItems: "center",
    },
    title: {
      fontFamily: "Open Sans",
      fontWeight: "normal",
      fontSize: 14,
    },
    subtitle: {
      fontFamily: "Open Sans",
      fontWeight: "bold",
      fontSize: 14,
    },

    chipContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
      gap: 3, // Add spacing between chips
    },
    chip: {
      backgroundColor: "#7ff2ba",
      borderRadius: 16,
      paddingHorizontal: 6,
      paddingVertical: 2,
      marginRight: 1,
      marginBottom: 8,
    },
    chipText: {
      fontFamily: "Open Sans",
      fontSize: 9,
    },
  });

  useEffect(() => {
    // Retrieve the data from localStorage
    const storedData = localStorage.getItem("targetdata");

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        console.log("parsedData", parsedData);

        // Destructure the parsed object to extract individual values
        const { targetData, advisorName, selectedYear } = parsedData;

        // Update the state or perform actions with the retrieved data
        setDetails(parsedData);
      } catch (error) {
        console.error("Error parsing stored data:", error);
      }
    }
  }, []);

  console.log("details", details);

  const determineStatus = (row, currentMonth, currentYear) => {
    if (row?.targetRevenue === row?.collectedRevenue) {
      return "Achieved";
    } else if (
      currentMonth === row?.month &&
      row?.targetRevenue > row?.collectedRevenue
    ) {
      return "In Progress";
    } else if (
      currentMonth > row?.month &&
      row?.targetRevenue > row?.collectedRevenue
    ) {
      return "Incompleted";
    } else if (row?.year === currentYear + 1) {
      return "Upcoming";
    }
    return "Not Given"; // This case should rarely occur.
  };

  return (
    <>
      <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <Document style={styles.document}>
          <Page size="A4" style={styles.page}>
            <View>
              <Text
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: "20px",
                  marginBottom: "10px",
                }}
              >
                Advisor's Target Report
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: "20px",
                  marginBottom: "30px",
                }}
              >
                <Text style={styles.subtitle}>
                  Year : {details?.selectedYear?.year}
                </Text>
              </Text>

              {details?.advisorName?.some(
                (name) => name.first_name === "Select All"
              ) ? (
                <Text style={styles.title}>
                  Please refer the target report for{" "}
                  <Text style={styles.subtitle}>All</Text> advisors
                </Text>
              ) : (
                <>
                  <Text style={styles.title}>
                    Please refer the target report for
                  </Text>

                  <View style={styles.chipContainer}>
                    {details?.advisorName?.map((name, index) => (
                      <View key={index} style={styles.chip}>
                        <Text style={styles.chipText}>
                          {name.first_name} {name.last_name || ""}
                        </Text>
                      </View>
                    ))}
                  </View>
                </>
              )}

              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <Text style={styles.tableHeaderCell}>Month</Text>
                  <Text style={styles.tableHeaderCell}>Target</Text>
                  <Text style={styles.tableHeaderCell}>Collected</Text>
                  <Text style={styles.tableHeaderCell}>Achived Percentage</Text>
                  <Text style={styles.tableHeaderCell}>Status</Text>
                </View>
                {details?.targetData?.map((row, index) => {
                  const currentDate = new Date();
                  const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed
                  const currentYear = currentDate.getFullYear();
                  const status = determineStatus(
                    row,
                    currentMonth,
                    currentYear
                  );

                  // Determine the chip style
                  let textColor = "#9e9e9e"; // Default grey for Not Given
                  if (status === "Achieved") textColor = "#4caf50"; // Green
                  else if (status === "In Progress")
                    textColor = "#ff9800"; // Orange
                  else if (status === "Incompleted")
                    textColor = "#f44336"; // Red
                  else if (status === "Upcoming") textColor = "#2196f3"; // Blue

                  return (
                    <View style={styles.tableRow} key={index}>
                      <Text style={styles.tableCell}>{row?.monthName}</Text>
                      <Text style={styles.tableCell}>
                        {row?.targetRevenue === "No Target Found"
                          ? row?.targetRevenue
                          : `${row?.targetRevenue}/-`}
                      </Text>
                      <Text style={styles.tableCell}>
                        {row?.collectedRevenue}/-
                      </Text>
                      <Text style={styles.tableCell}>
                        {row?.percentageAchieved !== "N/A"
                          ? `${row?.percentageAchieved} %`
                          : row?.percentageAchieved}
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          { color: textColor, fontWeight: "bold" },
                        ]}
                      >
                        {status}
                      </Text>
                    </View>
                  );
                })}
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
}
