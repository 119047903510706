import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";

import FilterListIcon from "@mui/icons-material/FilterList";

import MainCard from "../../newTestPrep/components/ui-component/card/MainCard";
import CustomSnackbar from "../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import { useGetTokenOrLogout } from "../../utils/token";
import { useNavigate } from "react-router-dom";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const TasksDataGridFilter = ({
  setTasksdata,
  originaltaskData,
  isLoading,
  setIsLoading,
  setSearchData
}) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const navigate = useNavigate();

  const [isFilterOpen, setFilterOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [tasks, setTasks] = useState([]);

  const [dialogPayments, setDialogPayments] = useState([]);
  const [Taskowenerlist, settaskownerlist] = useState([]);
  const [leadNameFilter, setLeadNameFilter] = useState(null);
  const [ownerfilter, setownerfilter] = useState([]);
  const [typeoftasklist, settypeoftasklist] = useState([]);
  const [taskfilter, settaskfilter] = useState([]);
  const [statusfilter, setstatusfilter] = useState([]);
  const [stageslist, setstageslist] = useState([]);
  const [stagesfilter, setstagesfilter] = useState([]);
  const [examlist, setExamlist] = useState([]);
  const [examfilter, setexamfilter] = useState([]);
  const [createdbylist, setcreatedbylist] = useState([]);
  const [createdbyfilter, setcreatedbyfilter] = useState([]);
  const [duedatefrom, setDuedatefrom] = useState(null);
  const [duedateto, setDuedateto] = useState(null);
  const [createddatefrom, setCreateddatefrom] = useState(null);
  const [createddateto, setCreateddateto] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [followupReason, setFollowupReason] = useState([]);
  const [closeFromDate, setClosedFromDate] =useState("")
  const [closeToDate, setClosedToDate] =useState("")
  const [FollowUpReasonList, setFollowupReasonList] = useState([]);
  const [selectedFolloupId, setSelectedFollowupId] = useState([]);
  const [campaignList, setCampaignList] =useState([])
  const [campaignName,setCampaignName]=useState([])
  const [subServiceList, setSubSurviceList]=useState([])
const [subserviceName, setSubserviceName]=useState([])
const [prospectiveLevelList,setProspectiveLevelList] =useState([])
const [prospectiveLevel, setProspectiveLevel] =useState([])
const [branchList, setBrachList]=useState([])
const [brannchName,setBranchName] =useState([])


  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleFilterSection = () => {
    setFilterOpen(!isFilterOpen);
  };

  const handleApplyFilter = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = { 
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json" // Make sure to keep the content type
    };
    const requestData = {
      // enquiry_name:leadNameFilter,
      due_from_date: duedatefrom || null,
      due_to_date: duedateto || null,
      created_from_date: createddatefrom || null,
      created_to_date: createddateto || null,
      created_by: createdbyfilter.map((i) => i.id),
      type_of_task: taskfilter.map((i) => i.id),
      task_owner: ownerfilter.map((i) => i.id),
      // stage:stagesfilter.map((i)=>i.id),
      // test_type:examfilter.map((i)=>i.id),
      status: statusfilter.map((i) => i.id),
      user_id: parseInt(userId),
      followup_reason: followupReason.map((i) => i.id),
      closed_from_date:closeFromDate || null ,
      closed_to_date:closeToDate || null,
      stage_id:stagesfilter.map((i)=>i.id),
      sub_service_id:subserviceName.map((i)=>i.id),
      campaign_id:campaignName.map((i)=>i.id),
      branch_id:brannchName.map((i)=>i.id),
      prospective_level_id:prospectiveLevel.map((i)=>i.id)
    };

    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/taskfilter`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const result = await response.json();
        setIsLoading(false);
        setTasksdata(result);
        setSearchData(result)
      }else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      navigate("/page500");
      
    }
  };

  const resetfilter = () => {
    setTasksdata(originaltaskData);
    setSearchData(originaltaskData)
    // setLeadNameFilter("");
    settaskfilter([]);
    setexamfilter([]);
    setstagesfilter([]);
    setownerfilter([]);
    setcreatedbyfilter([]);
    setstatusfilter([]);
    setDuedatefrom("");
    setDuedateto("");
    setCreateddatefrom("");
    setCreateddateto("");
    setFollowupReason([]);
    setSelectedFollowupId([]);
    setClosedFromDate("")
    setClosedToDate("")
    setstagesfilter([])
      setSubserviceName([])
      setCampaignName([])
      setProspectiveLevel([])
      setBranchName([])
    
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route2.0/getuserbyuser/${userId}`
        );
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          settaskownerlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setcreatedbylist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}typeoftask`);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          settypeoftasklist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}campaign/event/withname`);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setCampaignList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allCampaignlist = [
    { campaign_name: "Select All" },
    ...campaignList,
  ];
  const handleCamapignChange = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.campaign_name === "Select All")) {
      setCampaignName(campaignList);
    } else {
      setCampaignName(value);
    }
  };


  const alltypeoftasklist = [
    { name_of_task_type: "Select All" },
    ...typeoftasklist,
  ];

  const handleTypeOfTaskChange = (event, value) => {
    // Check if "Select All" is selected
    const ids = value.map((i) => i.id);
    if (value.some((option) => option.name_of_task_type === "Select All")) {
      // If "Select All" is already selected, remove it from the selection
      if (
        stagesfilter.some((option) => option.name_of_task_type === "Select All")
      ) {
        settaskfilter([]);
      } else {
        // Otherwise, select all options
        settaskfilter(typeoftasklist);
        setSelectedFollowupId(ids);
      }
    } else {
      settaskfilter(value);
      setSelectedFollowupId(ids);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}taskfollowupreason`);
        if (response.ok) {
          const data = await response.json();
          setFollowupReasonList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const alltypeofReasonlist = [
    { follow_up_reason: "Select All" },
    ...FollowUpReasonList,
  ];
  const handleTypeOfReasonChange = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.follow_up_reason === "Select All")) {
      setFollowupReason(FollowUpReasonList);
    } else {
      setFollowupReason(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}stage`);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setstageslist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  console.log(stageslist);

  const enhancedStagesList = [{ stage_name: "Select All" }, ...stageslist];

  const handleStageChange = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.stage_name === "Select All")) {
      // If "Select All" is already selected, remove it from the selection
      if (stagesfilter.some((option) => option.stage_name === "Select All")) {
        setstagesfilter([]);
      } else {
        // Otherwise, select all options
        setstagesfilter(stageslist);
      }
    } else {
      setstagesfilter(value);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}subservice`);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setSubSurviceList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  const allSubServicelist = [
    { name: "Select All" },
    ...subServiceList,
  ];
  const handlesubServiceChange = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.name === "Select All")) {
      setSubserviceName(subServiceList);
    } else {
      setSubserviceName(value);
    }
  };


  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}prospectivelevel`);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setProspectiveLevelList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allprospectiveLevelList = [
    { prospective_level: "Select All" },
    ...prospectiveLevelList,
  ];
  const handleProspectiveLevelChange = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.prospective_level === "Select All")) {
      setProspectiveLevel(prospectiveLevelList);
    } else {
      setProspectiveLevel(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setBrachList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  
  const allBranchList = [
    { branch_name: "Select All" },
    ...branchList,
  ];
  const handleBranchChange = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranchName(branchList);
    } else {
      setBranchName(value);
    }
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalltesttype`);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setExamlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const statuslist = [
    { name: "Open", id: 1 },
    { name: "Closed", id: 0 },
  ];

  console.log("status", statusfilter);
  console.log();

  const alltaskOwnerlist = [{ first_name: "Select All" }, ...Taskowenerlist];

  const handeltaskowner = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.first_name === "Select All")) {
      // If "Select All" is already selected, remove it from the selection
      if (ownerfilter.some((option) => option.first_name === "Select All")) {
        setownerfilter([]);
      } else {
        // Otherwise, select all options
        setownerfilter(Taskowenerlist);
      }
    } else {
      setownerfilter(value);
    }
  };

  const allcreatedBylist = [{ first_name: "Select All" }, ...createdbylist];

  const handelcreatedby = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.first_name === "Select All")) {
      // If "Select All" is already selected, remove it from the selection
      if (
        createdbyfilter.some((option) => option.first_name === "Select All")
      ) {
        setcreatedbyfilter([]);
      } else {
        // Otherwise, select all options
        setcreatedbyfilter(createdbylist);
      }
    } else {
      setcreatedbyfilter(value);
    }
  };

  const formatDate = (date) => date.toISOString().split('T')[0];

  useEffect(() => {
    const today = new Date();
    let oneMonthAgo = new Date();
    oneMonthAgo.setDate(today.getDate() - 30);

    setCreateddateto(formatDate(today));
    setCreateddatefrom(formatDate(oneMonthAgo));
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MainCard>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="h3">Tasks</Typography>
              </Box>
              <Box>
                <IconButton onClick={toggleFilterSection}>
                  <FilterListIcon />
                </IconButton>
              </Box>
            </Box>
            <Collapse in={isFilterOpen}>
              <Grid container spacing={2}>
                {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography sx={{ fontWeight: 600 }}>Enquiry Name</Typography>
                  <TextField
                    variant="outlined"
                    value={leadNameFilter}
                    onChange={(e) => setLeadNameFilter(e.target.value)}
                    fullWidth
                    InputProps={{
                      style: { borderRadius: "15px" },
                    }}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography sx={{ fontWeight: 600 }}>Type of Task</Typography>

                  <Autocomplete
                    size="small"
                    fullWidth
                    value={taskfilter}
                    multiple
                    onChange={handleTypeOfTaskChange}
                    options={alltypeoftasklist}
                    getOptionLabel={(option) => option.name_of_task_type}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid>

                {selectedFolloupId.includes(2) && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography sx={{ fontWeight: 600 }}>Follow Up Reasons</Typography>

                    <Autocomplete
                      value={followupReason}
                      size="small"
                      multiple
                      onChange={handleTypeOfReasonChange}
                      options={alltypeofReasonlist}
                      getOptionLabel={(option) => option.follow_up_reason}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "15px" }, // Add borderRadius here
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}

{selectedFolloupId.includes(1) && (
<Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography sx={{ fontWeight: 600 }}>Campaign Name</Typography>

                    <Autocomplete
                      value={campaignName}
                      size="small"
                      multiple
                      onChange={handleCamapignChange}
                      options={allCampaignlist}
                      getOptionLabel={(option) => option.campaign_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "15px" }, // Add borderRadius here
                          }}
                        />
                      )}
                    />
                  </Grid>
)}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography sx={{ fontWeight: 600 }}>
                    Due Date From
                  </Typography>
                  <TextField
                    size="small"
                    variant="outlined"
                    value={duedatefrom}
                    onChange={(e) => setDuedatefrom(e.target.value)}
                    fullWidth
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: { borderRadius: "15px" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography sx={{ fontWeight: 600 }}>Due Date To</Typography>
                  <TextField
                    size="small"
                    variant="outlined"
                    value={duedateto}
                    onChange={(e) => setDuedateto(e.target.value)}
                    fullWidth
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: { borderRadius: "15px" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography sx={{ fontWeight: 600 }}>Stages</Typography>

                  <Autocomplete
                    fullWidth
                    size="small"
                    value={stagesfilter}
                    multiple
                    onChange={handleStageChange}
                    options={enhancedStagesList}
                    getOptionLabel={(option) => option.stage_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid>


                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography sx={{ fontWeight: 600 }}>Sub Service</Typography>

                  <Autocomplete
                    fullWidth
                    size="small"
                    value={subserviceName}
                    multiple
                    onChange={handlesubServiceChange}
                    options={allSubServicelist}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography sx={{ fontWeight: 600 }}>Prospective Level</Typography>

                  <Autocomplete
                    fullWidth
                    size="small"
                    value={prospectiveLevel}
                    multiple
                    onChange={handleProspectiveLevelChange}
                    options={allprospectiveLevelList}
                    getOptionLabel={(option) => option.prospective_level}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography sx={{ fontWeight: 600 }}>Branch</Typography>

                  <Autocomplete
                    fullWidth
                    size="small"
                    value={brannchName}
                    multiple
                    onChange={handleBranchChange}
                    options={allBranchList}
                    getOptionLabel={(option) => option.branch_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid>

                {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography sx={{ fontWeight: 600 }}>Exam Type</Typography>

                  <Autocomplete
                    fullWidth
                    value={examfilter}
                    multiple
                    onChange={(event, value) => {
                      setexamfilter(value);
                    }}
                    options={examlist}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid> */}
                {(userRole === "center_admin" ||
                  userRole === "admin" ||
                  userRole === "data_entry") && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography sx={{ fontWeight: 600 }}>Task Owner</Typography>

                    <Autocomplete
                      size="small"
                      fullWidth
                      value={ownerfilter}
                      multiple
                      onChange={handeltaskowner}
                      options={alltaskOwnerlist}
                      getOptionLabel={(option) =>
                        `${option.first_name ? option.first_name : ""}${
                          option.first_name && option.last_name ? " " : ""
                        }${option.last_name ? option.last_name : ""}`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "15px" }, // Add borderRadius here
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography sx={{ fontWeight: 600 }}>Created By </Typography>

                  <Autocomplete
                    size="small"
                    fullWidth
                    value={createdbyfilter}
                    multiple
                    onChange={
                      handelcreatedby // Passing only the value
                    }
                    options={allcreatedBylist}
                    getOptionLabel={(option) =>
                      `${option.first_name ? option.first_name : ""}${
                        option.first_name && option.last_name ? " " : ""
                      }${option.last_name ? option.last_name : ""}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography sx={{ fontWeight: 600 }}>
                    Date Created From
                  </Typography>
                  <TextField
                    size="small"
                    variant="outlined"
                    value={createddatefrom}
                    onChange={(e) => setCreateddatefrom(e.target.value)}
                    fullWidth
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: { borderRadius: "15px" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography sx={{ fontWeight: 600 }}>
                    Date Created To{" "}
                  </Typography>
                  <TextField
                    size="small"
                    variant="outlined"
                    value={createddateto}
                    onChange={(e) => setCreateddateto(e.target.value)}
                    fullWidth
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: { borderRadius: "15px" },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography sx={{ fontWeight: 600 }}>
                 Closed From Date
                  </Typography>
                  <TextField
                    size="small"
                    variant="outlined"
                    value={closeFromDate}
                    onChange={(e) => setClosedFromDate(e.target.value)}
                    fullWidth
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: { borderRadius: "15px" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography sx={{ fontWeight: 600 }}>
                  Closed To Date{" "}
                  </Typography>
                  <TextField
                    size="small"
                    variant="outlined"
                    value={closeToDate}
                    onChange={(e) => setClosedToDate(e.target.value)}
                    fullWidth
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: { borderRadius: "15px" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography sx={{ fontWeight: 600 }}>Status </Typography>

                  <Autocomplete
                    size="small"
                    fullWidth
                    value={statusfilter}
                    multiple
                    onChange={(event, value) => {
                      setstatusfilter(value);
                    }}
                    options={statuslist}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  md={12}
                  sx={{ textAlign: "end" }}
                >
                  <Button
                    variant="contained"
                    style={{
                      background: "#4242f5",

                      marginRight: "7px",
                      borderRadius: "25px",
                    }}
                    onClick={handleApplyFilter}
                  >
                    {" "}
                    Apply Filter
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      background: "#f7690a",

                      marginRight: "7px",
                      borderRadius: "25px",
                    }}
                    onClick={toggleFilterSection}
                  >
                    {" "}
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={{ borderRadius: "25px" }}
                    onClick={resetfilter}
                  >
                    {" "}
                    Reset Filter
                  </Button>
                </Grid>
              </Grid>
            </Collapse>
          </MainCard>
        </Grid>

        <Grid item xs={12}>
          {/* <AdvisorTaskDataGrid appliedFilters={appliedFilters} /> */}
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default TasksDataGridFilter;
