import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';

export default function AdminRevenueTable({data}) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  return (
   <>
     <TableContainer  sx={{borderRadius:'15px' ,padding:'10px' ,height:'320px' }}>
    <Table>
      <TableHead >
        <TableRow >
          <TableCell sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 }}>Center Name</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 ,}}>No. of Enrollment</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 ,}}>Online Enrollment</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 ,}}>Offline Enrollment</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 ,}}>Total Revenue</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600,}}>Collected</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600}}>Pending</TableCell>
        </TableRow>
      </TableHead>
      <TableBody >
        { data.length === 0 ? (
          <Box display="flex" justifyContent="center" alignItems='center' mt={2}> 
          <Typography variant='h4'>No data available</Typography>
          </Box>
        ) : (
          data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            <TableRow key={index}  style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'inherit' ,width: '6%' }}>
              <TableCell >{row?.branch_name}</TableCell>
              <TableCell >{row?.total_enrollment}</TableCell>
              <TableCell>{row?.online_enrollment}</TableCell>
              <TableCell>{row?.offline_enrollment}</TableCell>
              <TableCell>{row?.total_revenue}/-</TableCell>
              <TableCell>{row?.collected_revenue}/-</TableCell>
              <TableCell >{row?.pending_revenue}/-</TableCell>
            </TableRow>
          ))
        )}
      
      </TableBody>
    </Table>
   
  </TableContainer>
  <Box sx={{textAlign:'center'}}>
                <Link to='/centerreport'>
                <Button style={{color:'#019E6F' , textAlign:'center' , fontWeight:'600'}}>Show Centers</Button>
                </Link>
                </Box>
   </>
  )
}
