// Author : Ayesha Shaikh
//  Date : 17 sep 2024

import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,

  Slide,

  Stack,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CustomPopup from "../../../components/CustomPopup";
import AddEmployeeForm from "./AddEmployeeForm";
import { FlashOnRounded } from "@mui/icons-material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import ViewEmployeeDetails from "./ViewEmployeeDetails";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EmployeeFilter from "./EmployeeFilter";
import { fDate } from "../../../utils/formatTime";
import CloseIcon from "@mui/icons-material/Close";

import EmployeeEnquiryTaskData from "./EmployeeEnquiryTaskData";
import { useGetTokenOrLogout } from "../../../utils/token";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
})

export default function EmployeeProfile() {
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
 
  const userId = localStorage.getItem("userId");
  const [employeeData, setEmployeeData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [showSwitch, setShowSwitch] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [checkedState, setCheckedState] = useState({});
  const [viewPopup, setViewPopup] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [searchData, setSearchData] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [viewTaskData, setViewTaskData] = useState(false);
  const [selectedRow, setSelectedRow] =useState(null)

;
  const handleOpenView = (row) => {
    console.log(row);
    setViewPopup(true);
    setSelectedId(row?.user_id);
  };

  const handleCloseView = () => {
    setViewPopup(false);
  };

  // Handle the switch change for a specific row
  const handleChange = async (event, id, row) => {
    const isChecked = event.target.checked;
    console.log("updatedData", isChecked);
    setCheckedState((prevState) => ({
      ...prevState,
      [id]: isChecked, // Update the state for the specific row ID
    }));

    try {
      const requestData = {
        is_active: isChecked,
        user_id: parseInt(userId),
      };
      const response = await fetch(
        `${API_ENDPOINT}employee/inactive/profile/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      if (response.ok) {
        showSnackbar(
          isChecked === true
            ? "User acount is activated successfully"
            : "User acount is deactivated successfully",
          "success"
        );

        const updatedData = employeeData.map((item) =>
          item?.id === row.id
            ? { ...item, is_active: isChecked === true ? 1 : 0 }
            : item
        );

        setEmployeeData(updatedData);
        console.log("updatedData", employeeData);
      } else if (response.status === 401) {
        showSnackbar("Unknown User", "error");
      } else if (response.status === 404) {
        showSnackbar("Error to Updating Status", "error");
        setCheckedState((prevState) => ({
          ...prevState,
          [id]: !isChecked, // Revert the state if the request fails
        }));
      } else if (response.status === 401) {
        showSnackbar("Unathorized User", "error");
      } else {
        showSnackbar("Error to Updating Status", "error");
        setCheckedState((prevState) => ({
          ...prevState,
          [id]: !isChecked, // Revert the state if the request fails
        }));
        throw new Error("Error to Updating Status");
      }
    } catch (error) {
      setCheckedState((prevState) => ({
        ...prevState,
        [id]: !isChecked, // Revert the state if the request fails
      }));
      showSnackbar("Error to Updating Status", "error");
    }
  };

  console.log("checkedState", checkedState);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedDemos = employeeData.slice(startIndex, endIndex);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const token = getTokenOrLogout();
    if (!token) return;
    let headers = { 
      Authorization: `Bearer ${token}`,
    
    };


      try {
        const response = await fetch(`${API_ENDPOINT}employee`,{headers});
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setEmployeeData(sortedData);
          setSearchData(sortedData);
          setOriginalData(sortedData);
          const initialState = sortedData.reduce((acc, row) => {
            acc[row.id] = row.is_active === 1;
            return acc;
          }, {});
          setCheckedState(initialState);

          setLoading(false);
        }else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      }catch (error) {
        navigate("/page500");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    fetch(`${API_ENDPOINT}route/downloadaccess/${userId}`)
      .then((response) => {
        if (response.ok) {
          setShowSwitch(true);
        } else {
          setShowSwitch(false);
        }
      })
      .catch((err) => {
        setShowSwitch(false);
      });
  }, [userId]);

  const handleEdit = (row) => {
    const newState = { employeedetails: row };
    localStorage.setItem("employeeState", JSON.stringify(newState));

    const url = `/view-profile/${row?.id}`;
    const newTab = window.open(url, "_blank");

    if (!newTab) {
      navigate(url, { state: newState });
    }
  };

  // Styled components
  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({}));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: "#abf7b1",
    fontWeight: 600,
  }));

  const ActionButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: "#E5E4E2",
  }));

  const handleSearchByName = (e) => {
    const value = e.target.value;
    let filteredData1 = searchData.filter((data) =>
      `${data?.first_name} ${data?.last_name}`
        ?.toLowerCase()
        .replace(/\s+/g, "")
        .includes(value.toLowerCase().replace(/\s+/g, ""))
    );
    setEmployeeData(filteredData1);
  };

  const handleFilterToggel = () => {
    setFilterOpen(!filterOpen);
  };

  const handleViewTaskDataPopup = (row) => {
    setViewTaskData(true);
    setSelectedId(row?.user_id);
    setSelectedRow(row)
  };
  const handlecloseTaskDataPopup = () => {
    setViewTaskData(false);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} lg={6} sm={6} md={6}>
          <Paper
            sx={{
              width: "200px",
              fontSize: "22px",
              fontWeight: 600,
              height: "50px",
              borderRadius: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Employees
          </Paper>
        </Grid>
        {/* <Grid item xs={4} lg={8}></Grid> */}
     
          <Grid
            item
            xs={12}
            lg={6}
            sm={6}
            md={6}
            container
            direction="row"
            justifyContent="end"
          >
            <Paper
              sx={{
                width: "100px",
                fontSize: "22px",
                fontWeight: 600,
                height: "30px",
                borderRadius: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={handleFilterToggel}
            >
              <FilterAltIcon
                fontSize="small"
                // onClick={handleOpen}
                color="orange"
              />
              <Typography
                sx={{ fontSize: "14px", fontWeight: 600, marginLeft: "5px" }}
              >
                Filter
              </Typography>
              {!filterOpen ? (
                <KeyboardArrowDownIcon fontSize="small" />
              ) : (
                <KeyboardArrowUpIcon fontSize="small" />
              )}
            </Paper>

            {showSwitch && (
            <Paper
              sx={{
                width: "200px",
                fontSize: "22px",
                fontWeight: 600,
                height: "30px",
                borderRadius: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "10px",
              }}
              onClick={handleOpen}
            >
              <ControlPointIcon fontSize="small" color="orange" />
              <Typography
                sx={{ fontSize: "14px", fontWeight: 600, marginLeft: "5px" }}
              >
                Add Employee
              </Typography>
            </Paper>
             )}
          </Grid>
       

        {filterOpen && (
          <Grid item xs={12}>
            <EmployeeFilter
              loading={loading}
              setLoading={setLoading}
              originalData={originalData}
              showSnackbar={showSnackbar}
              setEmployeeData={setEmployeeData}
              setSearchData={setSearchData}
            />
          </Grid>
        )}
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <MainCard>
            <Grid
              item
              xs={12}
              lg={12}
              sm={12}
              md={12}
              textAlign="end"
              mt={2}
              mb={2}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                  Search:{"  "}
                </Typography>
                <TextField
                  // value={value}
                  onChange={handleSearchByName}
                  size="small"
                  InputProps={{ style: { borderRadius: "15px" } }}
                />
              </Box>
            </Grid>
            <StyledTableContainer component={Paper}>
              <Box>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Action</StyledTableCell>
                      <StyledTableCell align="center">
                        Employee Name
                      </StyledTableCell>
                      <StyledTableCell align="center">Branch</StyledTableCell>
                      <StyledTableCell align="center">Email</StyledTableCell>

                      <StyledTableCell align="center">
                        Branch Manager
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Reporting Manager
                      </StyledTableCell>
                      <StyledTableCell align="center">Role</StyledTableCell>
                      <StyledTableCell align="center">
                        Created Date
                      </StyledTableCell>
                      <StyledTableCell align="center">Staus</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {loading ? (
                    <Backdrop open={loading} style={{ zIndex: 9999 }}>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          variant="h4"
                          color="secondary"
                          fontWeight={800}
                          marginRight={2}
                        >
                          Fetching
                        </Typography>
                        <CircularProgress color="primary" />
                      </Stack>
                    </Backdrop>
                  ) : paginatedDemos?.length === 0 ? (
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h5">No data available</Typography>
                    </Grid>
                  ) : (
                    paginatedDemos?.map((row, index) => (
                      <>
                        <TableBody>
                          <TableRow
                            key={index}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#f0f0f0" : "inherit",
                            }}
                          >
                            <TableCell
                              align="center"
                              style={{ width: "300px" }}
                            >
                              <ActionButton onClick={() => handleOpenView(row)}>
                                <Tooltip title="View">
                                  <VisibilityOutlinedIcon
                                    fontSize="small"
                                    style={{ color: "#2196F3" }}
                                  />
                                </Tooltip>
                              </ActionButton>

                              {showSwitch && (
                                <>
                                  <ActionButton
                                    onClick={() => handleEdit(row)}
                                    style={{ marginLeft: "7px" }}
                                  >
                                    <Tooltip title="Edit">
                                      <EditIcon fontSize="small" />
                                    </Tooltip>
                                  </ActionButton>

                                  <Tooltip
                                    title={
                                      row?.is_active === 0
                                        ? "Make Active"
                                        : "Make Inactive"
                                    }
                                  >
                                    <Switch
                                      checked={!!checkedState[row.id]}
                                      onChange={(event) =>
                                        handleChange(event, row.id, row)
                                      }
                                      size="small"
                                      color="secondary"
                                    />
                                  </Tooltip>
                             
                              <Chip
                                label="Data Transfer"
                                variant="outlined"
                                style={{
                                  background: "white",
                                  marginLeft: "7px",
                                }}
                                onClick={() => handleViewTaskDataPopup(row)}
                              />
                                 </>
                              )}

                            </TableCell>
                            <TableCell align="center">
                              {row?.first_name} {row?.last_name}
                            </TableCell>
                            <TableCell align="center">
                              {row?.branch?.branch_name}
                            </TableCell>
                            <TableCell align="center">{row?.email}</TableCell>

                            <TableCell align="center">
                              {row?.branch_manager}
                            </TableCell>
                            <TableCell align="center">
                              {row.reporting_manager ? row.reporting_manager.join(', '):"" }
                            </TableCell>
                            <TableCell align="center">
                              {row?.role.map((role) => {
                                return (
                                  (role === "developer" && (
                                    <Chip label="Developer" color="primary" />
                                  )) ||
                                  (role === "advisor" && (
                                    <Chip label="Advisor" color="info" />
                                  )) ||
                                  (role === "admin" && (
                                    <Chip label="Admin" color="error" />
                                  )) ||
                                  (role === "center_admin" && (
                                    <Chip label="Center Admin" color="error" />
                                  )) ||
                                  (role === "data_entry" && (
                                    <Chip label="Data Entry" color="warning" />
                                  )) ||
                                  (role === "trainer" && (
                                    <Chip label="Trainer" color="success" />
                                  )) ||
                                  (role === "accountant" && (
                                    <Chip
                                      label="Accountant"
                                      color="secondary"
                                    />
                                  )) ||
                                  (role === "frontdesk_advisor" && (
                                    <Chip
                                      label="FrontDesk Advisor"
                                      color="info"
                                    />
                                  )) ||
                                  (role === "svo_coordinator" && (
                                    <Chip
                                      label="SVO CoOrdinator"
                                      color="secondary"
                                    />
                                  ))
                                );
                              })}
                            </TableCell>
                            <TableCell>{fDate(row?.created_date)}</TableCell>
                            <TableCell align="center">
                              {row?.is_active === 1 ? (
                                <Chip label="Active" color="secondary" />
                              ) : row?.is_active === 0 ? (
                                <Chip label="InActive" color="error" />
                              ) : (
                                ""
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </>
                    ))
                  )}
                </Table>
              </Box>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={employeeData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </MainCard>
        </Grid>
      </Grid>

      <CustomPopup
        open={open}
        onClose={handleClose}
        component={<AddEmployeeForm onClose={handleClose} />}
        title="Add Employee"
        maxWidth="md"
        showDivider={true}
      />

      <CustomPopup
        open={viewPopup}
        onClose={handleCloseView}
        component={
          <ViewEmployeeDetails
            onClose={handleCloseView}
            selectedId={selectedId}
          />
        }
        title="Employee Details"
        maxWidth="md"
        showDivider={true}
      />

      {/* <CustomPopup
        open={viewTaskData}
        onClose={handlecloseTaskDataPopup}
        component={
          <EmployeeTaskData
            onClose={handlecloseTaskDataPopup}
            selectedId={selectedId}
          />
        }
        title="Leads and Task Transfers"
        maxWidth="xl"
        showDivider={true}
      /> */}

      <Dialog
         fullScreen
        open={viewTaskData}
        onClose={handlecloseTaskDataPopup}
         TransitionComponent={Transition}
       
           aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" fontWeight={800}>
            Leads and Task Transfers
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handlecloseTaskDataPopup}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          
              <EmployeeEnquiryTaskData
                onClose={handlecloseTaskDataPopup}
                selectedId={selectedId}
            
                setCheckedState={setCheckedState}
                employeeData={employeeData}
                setEmployeeData={setEmployeeData}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
              
              />
          
           
          
        </DialogContent>
      </Dialog>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
