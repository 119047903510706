import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { setUser } from "../../../redux/features/user/userSlice";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function EditEmployeeStatus({ selectedData }) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    setError,
  } = useForm();
  const [changes, setChanges] = useState({});
  const handleFieldChange = (fieldName, value) => {
    setChanges((prevChanges) => ({
      ...prevChanges,
      [fieldName]: value,
    }));
    setValue(fieldName, value); // Update form value
  };

  console.log("selectedData", selectedData);
  const userId = localStorage.getItem("userId");
  const [userList, setUserList] = useState([]);

  const [branchList, setBranchList] = useState([]);

  const [subServiceList, setSubServiceList] = useState([]);

  const [statusList, setStustList] = useState([
    { id: 1, name: "Leave", value: "leave" },
    { id: 2, name: "Active", value: "active " },
    { id: 3, name: "InActive", value: "inactive " },
  ]);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [selectedUser, setSelectedUser] = useState(null);
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setUserList(sortedData);

          const defaultValue = sortedData.find(
            (i) => i.id === selectedData?.advisor_name?.id
          );
          console.log("defaultValue", defaultValue);
          setValue("advisor_name", defaultValue?.id || null);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  console.log("selectedUser", selectedUser);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setBranchList(sortedData);

          const defaultValue = sortedData.filter((branch) =>
            selectedData?.branch_id.includes(branch.branch_name)
          );
          console.log("defaultValuee", defaultValue);
          setValue(
            "branch_id",
            defaultValue.map((i) => i.id)
          );
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}subservice`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setSubServiceList(sortedData);
          const defaultValue =
            sortedData.filter((service) =>
              selectedData?.sub_service_id?.includes(service.name)
            ) || [];
          setValue(
            "sub_service_id",
            defaultValue.map((i) => i.id)
          );
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const onSubmit = async (data) => {
    try {
      const requestData = {
        ...changes,
        user_id: parseInt(userId),
      };
      const response = await fetch(
        `${API_ENDPOINT}branch-testtype-wise-advisor/${selectedData?.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      if (response.ok) {
        showSnackbar("Auto-Assign Advisor Updated Successfully", "success");

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        showSnackbar("Failed to update Auto-Assign Advisor  ", "error");
        throw new Error("Failed to update  Camapign");
      }
    } catch (error) {
      showSnackbar("Failed to update Auto-Assign Advisor ", "error");
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <FormControl fullWidth error={errors.advisor_name}>
            <Typography sx={{ fontWeight: 600 }}>
              Name <span style={{ color: "red", fontSize: "18px" }}>*</span>
            </Typography>
            <Controller
              name="advisor_name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  value={
                    userList.find((user) => user.id === field.value) || null
                  }
                  options={userList}
                  getOptionLabel={(option) =>
                    `${option.first_name || ""} ${option.last_name || ""}`
                  }
                  onChange={(event, value) =>
                    handleFieldChange("advisor_name", value?.id)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "12px" },
                      }}
                      fullWidth
                    />
                  )}
                />
              )}
            />
            {errors.advisor_name && (
              <FormHelperText>Name is required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormControl fullWidth error={errors.branch_id}>
            <Typography sx={{ fontWeight: 600 }}>
              Branch <span style={{ color: "red", fontSize: "18px" }}>*</span>
            </Typography>
            <Controller
              name="branch_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                console.log("Field object for branch_id:", field); // Log the field object
                return (
                  <Autocomplete
                    {...field}
                    size="small"
                    multiple
                    value={branchList.filter((branch) =>
                      field.value?.includes(branch.id)
                    )}
                    options={branchList}
                    getOptionLabel={(option) => option.branch_name}
                    onChange={(event, value) =>
                      handleFieldChange(
                        "branch_id",
                        value.map((i) => i.id)
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "12px" },
                        }}
                        fullWidth
                      />
                    )}
                  />
                );
              }}
            />
            {errors.branch_id && (
              <FormHelperText>Branch Name is required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormControl fullWidth error={errors.sub_service_id}>
            <Typography sx={{ fontWeight: 600 }}>
              Sub Service{" "}
              <span style={{ color: "red", fontSize: "18px" }}>*</span>
            </Typography>

            <Controller
              name="sub_service_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  size="small"
                  {...field}
                  multiple
                  value={subServiceList.filter((service) =>
                    field.value?.includes(service.id)
                  )}
                  options={subServiceList}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) =>
                    handleFieldChange(
                      "sub_service_id",
                      value.map((i) => i.id)
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "12px" },
                      }}
                      fullWidth
                    />
                  )}
                />
              )}
            />

            {errors.sub_service_id && (
              <FormHelperText>Sub Service is required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* <Grid item xs={12} lg={6}>
          <FormControl fullWidth error={errors.status}>
            <Typography sx={{ fontWeight: 600 }}>
              Status <span style={{ color: "red", fontSize: "18px" }}>*</span>
            </Typography>

            <Controller
              name="status"
              control={control}
              rules={{ required: true }}
            
              render={({ field }) => (
                <Autocomplete
                  size="small"
                  {...field}
               
                  value={statusList.find(status => status.name === field.name) || null}
                  options={statusList}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) =>
                    handleFieldChange("sub_service_id", value?.value)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "12px" },
                      }}
                      fullWidth
                    />
                  )}
                />
              )}
            />

            {errors.status && (
              <FormHelperText>Status is required</FormHelperText>
            )}
          </FormControl>
        </Grid> */}

        <Grid item xs={12} textAlign="end">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
