import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import MainCard from "../../components/Maincard";
export default function Discountfilter({ onApplyFilter, onResetFilter ,setGridKey,handleDrawerClose,isDrawerOpen}) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [reason, setReason] = useState([]);
  const [branchlist, setBranchlist] = useState([]);
  const [branch, setBranch] = useState([]);
  const [testtypelist, setTesttypeList] = useState([]);
  const [testtype, setTesttype] = useState([]);
  const [packageslist, setPackagesList] = useState([]);
  const [packagename, setpackagename] = useState([]);
  const [startfromdate, setStartfromdate] = useState("");
  const [startenddate, setStartenddate] = useState("");
  const [status, setStatus] = useState([]);
  const [endfromdate, setEndfromdate] = useState("");
  const [endtodate, setEndtodate] = useState("");
  const [createfrom, setCreatedfrom] = useState("");
  const [createdto, setCreatedto] = useState("");
  const [createdbylist, setCreatedbylist] = useState([]);
  const [createdby, setCreatedby] = useState([]);

const [discountstartdate, setDiscountStartdate] =useState("")
const [discountenddate, setDiscountenddate] =useState("")
const [showstartDateFields, setShowstartDateFields] = useState(false);
const [showEndDateFields, setShowEndDateFields] = useState(false);
const [showcreatedDateFields, setShowsCreatedDateFields] = useState(true);
 

  const newReason=reason.map((i)=>i.name)
  const newBranch=branch.map((i)=>i.branch_name)
const newTestType=testtype.map((i)=>i.name)
const newPackage=packagename.map((i)=>i.package_name)
const newStatus=status.map((i)=>i.value)
const newCreatedby=createdby.map((i)=>i.id)
  const handleApplyFilter = () => {
    setGridKey((prev)=>prev+1)
    onApplyFilter({
      name,
      newReason,
      newBranch,
      newTestType,
      newPackage,
      newStatus,
      startfromdate,
      startenddate,
      endfromdate,
      endtodate,
      createfrom,
      createdto,
      newCreatedby,
      discountstartdate,
      discountenddate
    });
    handleDrawerClose();
  };

  const resetfilter = () => {
    setGridKey((prev)=>prev+1)
    onResetFilter();
    setName("");
    setReason([]);
    setBranch([]);
    setTesttype([]);
    setpackagename([]);
    setStatus([]);
    setStartfromdate("");
    setStartenddate("");
    setEndfromdate("");
    setEndtodate("");
    setCreatedfrom("");
    setCreatedto("");
    setCreatedby([]);
    handleDrawerClose();
    setDiscountStartdate("");
    setDiscountenddate("");
  };

  const handelnamechange = (e) => {
    setName(e.target.value);
    console.log(e.target.value);
  };
  const reasons = [
 
    { id: 1, name: "Educational Workshop" },
    { id: 2, name: "Student Discount" },
    { id: 3, name: "Teacher Appreciation Week" },
    { id: 4, name: "Graduation Special" },
    { id: 5, name: "Educational Conference" },
    { id: 6, name: "Scholarship Program" },
  ];

  const reasonList=[{name:"Select All"},...reasons]
  const handleReasonchange = (event,value) => {
    if (value.some((option) => option.name === "Select All")) {
      setReason(reasons);
    } else {
      setReason(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();

          setBranchlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const allbranchList=[{branch_name:"Select All"},...branchlist]
  const handelbranchchange = (event,value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchlist);
    } else {
      setBranch(value);
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalltesttype`);
        if (response.ok) {
          const data = await response.json();

          setTesttypeList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const alltestTypeList=[{name:"Select All"},...testtypelist]
  const handeltestTypechange = (event,value) => {
    if (value.some((option) => option.name === "Select All")) {
      setTesttype(testtypelist);
    } else {
      setTesttype(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}package`);
        if (response.ok) {
          const data = await response.json();

          setPackagesList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allPackagelist=[{package_name:"Select All"},...packageslist]
  const handelpackagechange = (event,value) => {
    if (value.some((option) => option.package_name === "Select All")) {
      setpackagename(packageslist);
    } else {
      setpackagename(value);
    }
  };

  const statuses = [
    { id: 1, name: "Pending", value: "pending" },
    { id: 2, name: "Accepted", value: "accepted" },
    { id: 3, name: "Denied", value: "denied" },
    { id: 4, name: "Completed", value: "completed" },
    { id: 5, name: "Cancelled", value: "cancelled" },
  ];

  const allstatusList=[{name:"Select All"},...statuses]
  const handleStatusChange = (event,value) => {
    if (value.some((option) => option.name === "Select All")) {
      setStatus(statuses);
    } else {
      setStatus(value);
    }
  };

  const handdelstartfromdate = (e) => {
    setStartfromdate(e.target.value);
  };

  const handdelstartenddate = (e) => {
    setStartenddate(e.target.value);
  };

  const handdelendfromdate = (e) => {
    setEndfromdate(e.target.value);
  };

  const handdelendtodate = (e) => {
    setEndtodate(e.target.value);
  };
  const handlecratedfrom = (e) => {
    setCreatedfrom(e.target.value);
  };

  const handlecreatedto = (e) => {
    setCreatedto(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          setCreatedbylist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const allCreatedByList=[{first_name:"Select All"} ,...createdbylist]
  const handelcretedby = (event, value) => {
    if (value.some((option) => `${option.first_name?option.first_name:""}`=== "Select All" ||   `${option.last_name?option.last_name:""}`=== "Select All" )) {
      setCreatedby(createdbylist);
    } else {
      setCreatedby(value);
    }
  };
  const discountstart=(e)=>{
    setDiscountStartdate(e.target.value)
  }

  const discountend=(e)=>{
   setDiscountenddate(e.target.value)
  }

  const handleCheckboxChange = (event) => {
    setShowstartDateFields(event.target.checked);
  };

  const handleCheckboxChangeend = (event) => {
    setShowEndDateFields(event.target.checked);
  };

  const handleCheckboxChangecreated = (event) => {
   setShowsCreatedDateFields(event.target.checked);
  };


  // Add default 1 month start and end date
const formatDate = (date) => date.toISOString().split('T')[0];
useEffect(() => {
  let oneMonthAgo = new Date();
  oneMonthAgo.setDate(oneMonthAgo.getDate()-30);
  
  
  let today=new Date()
 
  setCreatedto(formatDate(today));
  setCreatedfrom(formatDate(oneMonthAgo));
}, []);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/* <MainCard>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="h3">Discounts</Typography>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="error"
                  style={{
                    width: "150px",
                    borderRadius: "24px",
                    fontWeight: 500,
                    fontSize: "14px",
                    marginRight: "8px",
                  }}
                  onClick={resetfilter}
                >
                  Reset Filter
                </Button>
                <Button
                  xs={12}
                  lg={12}
               variant="contained"
                  style={{   width: "150px",
                  borderRadius: "24px",
                  fontWeight: 500,
                  fontSize: "14px", }}
                  onClick={handleDrawerOpen}
                >
                  <FilterAltOutlinedIcon
                   
                    fontSize="medium"
                  />
                  <Typography>Apply Filter</Typography>
                </Button>
              </Box>
            </Box>
          </MainCard> */}
        </Grid>
      </Grid>
      <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          width={500}
          m={2}
        >
          <Grid item xs={12}>
            <Typography variant="h2" textAlign="center">
              Filter Options
            </Typography>
          </Grid>
          <Grid item xs={6} lg={6} sm={4} md={4} mt={2}>
            <Typography sx={{ fontWeight: 600 }}>Name Of Discount </Typography>
            <TextField
            size="small"
              value={name}
              onChange={handelnamechange}
              InputProps={{
                style: { borderRadius: "15px" },
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={6} sm={6} md={6} mt={2}>
        
              <Typography sx={{ fontWeight: 600 }}>
                Reason For Creating Discount{" "}
              </Typography>
              {/* <Select
                multiple
                style={{ borderRadius: "15px" }}
                value={reason}
                onChange={handleReasonchange}
                renderValue={(selected) =>
                  selected
                    .map((value) => {
                      const packages = reasons.find(
                        (status) => status.name === value
                      );
                      return packages ? packages.name : "";
                    })
                    .join(", ")
                }
              >
                {reasons.map((name) => (
                  <MenuItem key={name.id} value={name.name}>
                    <Checkbox checked={reason.indexOf(name.name) > -1} />
                    <ListItemText primary={name.name} />
                  </MenuItem>
                ))}
              </Select> */}
               <Autocomplete
                     size="small"
                    multiple
                    value={reason}
                   onChange={handleReasonchange}
                    options={ reasonList}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params}    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: '15px' }
                    }}   />}
                  />
         
          </Grid>

          <Grid item xs={12} lg={6} sm={6} md={6}>
        
              <Typography sx={{ fontWeight: 600 }}> Select Branch </Typography>
              <Autocomplete
                size="small"
                value={branch}
                multiple
                onChange={handelbranchchange}
                options={allbranchList}
                getOptionLabel={(option) =>
                 option.branch_name
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" },
                    }}
                  />
                )}
              />
         
          </Grid>

          <Grid item xs={12} lg={6} sm={6} md={6}>
         
              <Typography sx={{ fontWeight: 600 }}> Test Type </Typography>
              {/* <Select
                multiple
                value={testtype}
                onChange={handeltestTypechange}
                MenuProps={MenuProps}
                fullWidth
                renderValue={(selected) =>
                  selected
                    .map((value) => {
                      const testtype = testtypelist.find(
                        (status) => status.name === value
                      );
                      return testtype ? testtype.name : "";
                    })
                    .join(", ")
                }
                style={{ borderRadius: "15px" }}
              >
                {" "}
                {testtypelist.map((item) => (
                  <MenuItem key={item.id} value={item.name}>
                    <Checkbox checked={testtype.indexOf(item.name) > -1} />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select> */}
          <Autocomplete
                size="small"
                value={testtype}
                multiple
                onChange={handeltestTypechange}
                options={alltestTypeList}
                getOptionLabel={(option) =>
                 option.name
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" },
                    }}
                  />
                )}
              />

          </Grid>

          <Grid item xs={12} lg={6} sm={6} md={6}>
         
              <Typography sx={{ fontWeight: 600 }}>
                Select Package / Product
              </Typography>
              {/* <Select
                multiple
                value={packagename}
                onChange={handelpackagechange}
                MenuProps={MenuProps}
                fullWidth
                renderValue={(selected) =>
                  selected
                    .map((value) => {
                      const packages = packageslist.find(
                        (status) => status.package_name === value
                      );
                      return packages ? packages.package_name : "";
                    })
                    .join(", ")
                }
                style={{ borderRadius: "15px" }}
              >
                {packageslist.map((name) => (
                  <MenuItem key={name.id} value={name.package_name}>
                    <Checkbox
                      checked={packagename.indexOf(name.package_named) > -1}
                    />
                    <ListItemText primary={name.package_name} />
                  </MenuItem>
                ))}
              </Select> */}

<Autocomplete
                size="small"
                value={packagename}
                multiple
                onChange={handelpackagechange}
                options={allPackagelist}
                getOptionLabel={(option) =>
                 option.package_name
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" },
                    }}
                  />
                )}
              />

         
          </Grid>

          <Grid item xs={12} lg={6} sm={6} md={6}>
       
              <Typography sx={{ fontWeight: 600 }}> Status </Typography>
              {/* <Select
                multiple
                value={status}
                onChange={handleStatusChange}
                MenuProps={MenuProps}
                renderValue={(selected) =>
                  selected
                    .map((value) => {
                      const packages = statuses.find(
                        (status) => status.value === value
                      );
                      return packages ? packages.name : "";
                    })
                    .join(", ")
                }
                style={{ borderRadius: "15px" }}
              >
                {statuses.map((statuses) => (
                  <MenuItem key={statuses.id} value={statuses.value}>
                    <Checkbox checked={status.indexOf(statuses.value) > -1} />
                    <ListItemText primary={statuses.name} />
                  </MenuItem>
                ))}
              </Select> */}
          <Autocomplete
                size="small"
                value={status}
                multiple
                onChange={handleStatusChange}
                options={allstatusList}
                getOptionLabel={(option) =>
                 option.name
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" },
                    }}
                  />
                )}
              />

          </Grid>

          <Grid item xs={12} lg={6} sm={6} md={6}>
            <Typography sx={{ fontWeight: 600 }}>Discount Start Date </Typography>
            <TextField
               size="small"
              value={discountstartdate}
              onChange={discountstart}
              type="date"
              InputProps={{
                style: { borderRadius: "15px" },
              }}
              fullWidth
            />
          </Grid>

      
          <Grid item xs={12} lg={6} sm={6} md={6}>
            <Typography sx={{ fontWeight: 600 }}>Discount End Date </Typography>
            <TextField
               size="small"
              value={discountenddate}
              onChange={discountend}
              type="date"
              InputProps={{
                style: { borderRadius: "15px" },
              }}
              fullWidth
            />
          </Grid>

<Grid item xs={12} lg={12}>
          <FormGroup>
      <FormControlLabel control={<Checkbox checked={showstartDateFields} onChange={handleCheckboxChange}/>}             label={<Typography sx={{ fontWeight: 600 }}>Filter With Start Date Only</Typography>} />
      </FormGroup> 

      </Grid>

      {showstartDateFields && (
        <>
        <Grid item xs={12} lg={12}>
        <Typography textAlign="center" sx={{fontWeight:600, fontSize:'16px'}} >Start Date</Typography>
      </Grid>
          <Grid item xs={12} lg={6} sm={6} md={6}>
            <Typography sx={{ fontWeight: 600 }}> From Date </Typography>
            <TextField
               size="small"
              value={startfromdate}
              onChange={handdelstartfromdate}
              type="date"
              InputProps={{
                style: { borderRadius: "15px" },
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={6} sm={6} md={6}>
            <Typography sx={{ fontWeight: 600 }}> To Date </Typography>
            <TextField
               size="small"
              value={startenddate}
              onChange={handdelstartenddate}
              type="date"
              InputProps={{
                style: { borderRadius: "15px" },
              }}
              fullWidth
            />
          </Grid>
          </>
      )}

<Grid item xs={12} lg={12}>
          <FormGroup>
      <FormControlLabel control={<Checkbox checked={showEndDateFields} onChange={handleCheckboxChangeend}/>}             label={<Typography sx={{ fontWeight: 600 }}>Filter With End Date Only</Typography>} />
      </FormGroup> 
      </Grid>
{showEndDateFields && (
  
      <>
      <Grid item xs={12} lg={12}>
        <Typography textAlign="center" sx={{fontWeight:600, fontSize:'16px'}} >End Date</Typography>
      </Grid>
          <Grid item xs={12} lg={6} sm={6} md={6}>
            <Typography sx={{ fontWeight: 600 }}> From Date </Typography>
            <TextField
               size="small"
              value={endfromdate}
              onChange={handdelendfromdate}
              type="date"
              InputProps={{
                style: { borderRadius: "15px" },
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={6} sm={6} md={6}>
            <Typography sx={{ fontWeight: 600 }}> To Date </Typography>
            <TextField
               size="small"
              value={endtodate}
              onChange={handdelendtodate}
              type="date"
              InputProps={{
                style: { borderRadius: "15px" },
              }}
              fullWidth
            />
          </Grid>
          </>
)}

<Grid item xs={12} lg={12}>
          <FormGroup>
      <FormControlLabel control={<Checkbox checked={showcreatedDateFields} onChange={handleCheckboxChangecreated}/>}             label={<Typography sx={{ fontWeight: 600 }}>Filter With Created Date Only</Typography>} />
      </FormGroup> 
      </Grid>

{showcreatedDateFields && (
  <>

<Grid item xs={12} lg={12}>
        <Typography textAlign="center" sx={{fontWeight:600, fontSize:'16px'}} >Created Date</Typography>
      </Grid>
          <Grid item xs={12} lg={6} sm={6} md={6}>
            <Typography sx={{ fontWeight: 600 }}>From Date </Typography>
            <TextField
               size="small"
              value={createfrom}
              onChange={handlecratedfrom}
              type="date"
              InputProps={{
                style: { borderRadius: "15px" },
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={6} sm={6} md={6}>
            <Typography sx={{ fontWeight: 600 }}> To Date </Typography>
            <TextField
               size="small"
              value={createdto}
              onChange={handlecreatedto}
              type="date"
              InputProps={{
                style: { borderRadius: "15px" },
              }}
              fullWidth
            />
          </Grid>
          </>
)}

          <Grid item xs={12} lg={6} sm={6} md={6}>
        
              <Typography sx={{ fontWeight: 600 }}>Created By </Typography>
              {/* <Select
                multiple
                value={createdby}
                onChange={handelcretedby}
                fullWidth
                MenuProps={MenuProps}
                style={{ borderRadius: "15px" }}
                renderValue={(selected) =>
                  selected
                    .map((value) => {
                      const createdby = createdbylist.find(
                        (status) => status.id === value
                      );
                      return createdby
                        ? `${createdby.first_name} ${createdby.last_name}`
                        : "";
                    })
                    .join(", ")
                }
              >
                {createdbylist.map((name) => (
                  <MenuItem key={name.id} value={name.id}>
                    <Checkbox checked={createdby.indexOf(name.id) > -1} />
                    <ListItemText
                      primary={`${name.first_name} ${name.last_name}`}
                    />
                  </MenuItem>
                ))}
              </Select> */}
          
          <Autocomplete
                size="small"
                value={createdby}
                multiple
                onChange={handelcretedby}
                options={allCreatedByList}
                getOptionLabel={(option) =>
               `${option.first_name?option.first_name:""} ${option.last_name?option.last_name:""}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" },
                    }}
                  />
                )}
              />
          </Grid>
          <Grid item xs={12} lg={6} sm={6} md={6}></Grid>
          <Grid item xs={12} lg={12} textAlign="end">
            <Button
              variant="contained"
              color="error"
              style={{
                width: "150px",
                borderRadius: "24px",
                fontWeight: 500,
                fontSize: "14px",
                marginRight: "8px",
              }}
              onClick={resetfilter}
            >
              Reset Filter
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{
                width: "150px",
                borderRadius: "24px",
                fontWeight: 500,
                fontSize: "14px",
              }}
              onClick={handleApplyFilter}
            >
              Apply Filter
            </Button>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
}
