import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import styled from '@emotion/styled';
import { Box, Divider, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

const CardWidget = styled("Card")({
    backgroundColor: `rgba(255, 255, 255, 1)`,
    boxShadow: `0px 0px 15px rgba(0, 0, 0, 0.05)`,
    borderRadius: `23px`,
    //width: `463px`,
   // height: `497px`,
    //position: `absolute`,
    //left: `0px`,
    //top: `0px`,
    width: '100%', // Set the width to 90% of the parent container
    height: '100%', // Automatically adjust the height based on content
    margin: '0 auto', // Center the card horizontally
    padding: '16px',
  });

  const Card = styled("div")({
    backgroundColor: `rgba(255, 255, 255, 1)`,
    boxShadow: `0px 0px 15px rgba(0, 0, 0, 0.05)`,
    borderRadius: `34px`,
    width: '100%', // Set the width to 90% of the parent container
    height: '100%', // Automatically adjust the height based on content
    margin: '0 auto',
    padding: '10px',
  });

  const Header = styled("div")({
    textAlign: `left`,
    whiteSpace: `pre-wrap`,
    fontSynthesis: `none`,
    color: `rgba(0, 0, 0, 1)`,
    fontStyle: `normal`,
    fontFamily: `Poppins`,
    fontWeight: `600`,
    fontSize: `26px`,
    letterSpacing: `0px`,
    textDecoration: `none`,
    textTransform: `none`,
    //position: `absolute`,
    //left: `35px`,
    //top: `0px`,
  });

  const Icon = styled("img")({
    height: `25px`,
    width: `25px`,
    //position: `absolute`,
    //left: `50px`,
    //top: `32px`,
  });
  

export default function AdvisorCalendarDashboard() {
  return (
    <Card>
       <Box display='flex' alignItems="center" justifyContent="center" mt={2}>
            <Icon alt='notification-icon' src={'/wetransfer_add-enquiry-svg_2023-07-25_1047/Notification.svg'}></Icon>
            <Header>Calendar</Header>
        </Box>
        <Divider/>
        
    <LocalizationProvider dateAdapter={AdapterDayjs}>
   
      <DateCalendar />
     
      <Divider />
      <Box textAlign="center" mt={2}>
        <Typography variant="subtitle1" color="primary" style={{ cursor: 'pointer' }}>
            See your Schedule <ArrowCircleDownIcon fontSize="small" />
        </Typography>
        <Box mt={2}>
        <Typography variant='h5'>No Events Found</Typography>
        </Box>
  </Box> 
    </LocalizationProvider>
    </Card>
  );
}