import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { setUser } from "../../../redux/features/user/userSlice";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function AddEmployeeStatus({ onClose }) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    setError,
  } = useForm();

  const userId = localStorage.getItem("userId");
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [branchList, setBranchList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [subServiceList, setSubServiceList] = useState([]);
  const [selectedSubservice, setSelectedSubservice] = useState([]);
  const [statusList, setStustList] = useState([
    { id: 1, name: "Leave", value: "leave" },
    { id: 2, name: "Active", value: "active " },
    { id: 3, name: "InActive", value: "inactive " },
  ]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setUserList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setBranchList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}subservice`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setSubServiceList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleNamechange = (event, value) => {
    const name = value?.id;
    setSelectedUser(value);
    setValue("advisor_name", name);
  };

  const handleBranchchange = (event, value) => {
    const branchname = value.map((i) => i.id);
    setSelectedBranch(value);
    setValue("branch_id", branchname);
  };

  const handleSubservicechange = (event, value) => {
    const branchname = value.map((i) => i.id);
    setSelectedSubservice(value);
    setValue("sub_service_id", branchname);
  };

  const handleStatuschange = (event, value) => {
    const statusName = value?.value;
    setSelectedStatus(value);
    setValue("status", statusName);
  };

  const onSubmit = async (data) => {
    try {
      const requestData = {
        ...data,
        user_id: parseInt(userId),
      };
      const response = await fetch(
        `${API_ENDPOINT}branch-testtype-wise-advisor`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      if (response.ok) {
        showSnackbar("Auto-Assign Advisor  Added Successfully", "success");
       
        setTimeout(() => {
    window.location.reload()
        }, 1500);
      } else {
        showSnackbar("Failed to add Auto-Assign Advisor ", "error");
        throw new Error("Failed to add  employee status");
      }
    } catch (error) {
        showSnackbar("Failed to add Auto-Assign Advisor ", "error");
    
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <FormControl fullWidth error={errors.advisor_name}>
            <Typography sx={{ fontWeight: 600 }}>
              Name <span style={{ color: "red", fontSize: "18px" }}>*</span>
            </Typography>

            <Controller
              name="advisor_name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  size="small"
                  {...field}
                  // multiple
                  value={selectedUser}
                  options={userList}
                  getOptionLabel={(option) =>
                    `${option.first_name ? option.first_name : ""} ${
                      option.last_name ? option.last_name : ""
                    }`
                  }
                  onChange={handleNamechange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "12px" },
                      }}
                      fullWidth
                    />
                  )}
                />
              )}
            />

            {errors.advisor_name && (
              <FormHelperText>Name is required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormControl fullWidth error={errors.branch_id}>
            <Typography sx={{ fontWeight: 600 }}>
              Branch <span style={{ color: "red", fontSize: "18px" }}>*</span>
            </Typography>

            <Controller
              name="branch_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  size="small"
                  {...field}
                  multiple
                  value={selectedBranch}
                  options={branchList}
                  getOptionLabel={(option) => option.branch_name}
                  onChange={handleBranchchange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "12px" },
                      }}
                      fullWidth
                    />
                  )}
                />
              )}
            />

            {errors.branch_id && (
              <FormHelperText>Branch is required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormControl fullWidth error={errors.sub_service_id}>
            <Typography sx={{ fontWeight: 600 }}>
              Sub Service{" "}
              <span style={{ color: "red", fontSize: "18px" }}>*</span>
            </Typography>

            <Controller
              name="sub_service_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  size="small"
                  {...field}
                  multiple
                  value={selectedSubservice}
                  options={subServiceList}
                  getOptionLabel={(option) => option.name}
                  onChange={handleSubservicechange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "12px" },
                      }}
                      fullWidth
                    />
                  )}
                />
              )}
            />

            {errors.sub_service_id && (
              <FormHelperText>Sub Service is required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* <Grid item xs={12} lg={6}>
          <FormControl fullWidth error={errors.status}>
            <Typography sx={{ fontWeight: 600 }}>
              Status <span style={{ color: "red", fontSize: "18px" }}>*</span>
            </Typography>

            <Controller
              name="status"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  size="small"
                  {...field}
                  value={selectedStatus}
                  options={statusList}
                  getOptionLabel={(option) => option.name}
                  onChange={handleStatuschange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "12px" },
                      }}
                      fullWidth
                    />
                  )}
                />
              )}
            />

            {errors.status && (
              <FormHelperText>Status is required</FormHelperText>
            )}
          </FormControl>
        </Grid> */}

        <Grid item xs={12} textAlign="end">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
