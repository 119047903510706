import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useGetTokenOrLogout } from "../../../utils/token";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function EditEmployeeTarget({ selectedData }) {
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const [changes, setChanges] = useState({});
  const [advisorList, setAdvisorList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  console.log("selectedData", selectedData);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    setError,
  } = useForm();

  // Edit all Fields
  const handleFieldChange = (fieldName, value) => {
    setChanges((prevChanges) => ({
      ...prevChanges,
      [fieldName]: value,
    }));
    setValue(fieldName, value); // Update form value
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}advisor`);
        if (response.ok) {
          const data = await response.json();

          setAdvisorList(data);

          const defaultValue = data.find(
            (i) => i.user_id === selectedData?.adv_user_id?.id
          );
          console.log("defaultValue", defaultValue);
          setValue("adv_user_id", defaultValue?.user_id || null);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}month`);
        if (response.ok) {
          const data = await response.json();

          setMonthList(data);
          const defaultValue = data.find(
            (i) => i.id === selectedData?.month_id?.id
          );
          console.log("defaultValue1", defaultValue);
          setValue("month_id", defaultValue?.id || []);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const onSubmit = async (data) => {
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const requestData = {
        ...changes,
        user_id: parseInt(userId),
      };
      const response = await fetch(
        `${API_ENDPOINT}advisor-rev-target/${selectedData?.id}`,
        {
          method: "PUT",
          headers,
          body: JSON.stringify(requestData),
        }
      );
      if (response.ok) {
        showSnackbar("Employee Target Updated Successfully", "success");

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        showSnackbar("Error in update employee target", "error");
      }
    } catch (error) {
      showSnackbar("Error in update employee target", "error");
    }
  };


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography sx={{ fontWeight: 600 }}>
            Employee Name{" "}
            <span style={{ color: "red", fontSize: "18px" }}>*</span>
          </Typography>
          <Controller
            name="adv_user_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                readOnly
                size="small"
                value={
                  advisorList.find((user) => user.user_id === field.value) ||
                  null
                }
                options={advisorList}
                getOptionLabel={(option) =>
                  `${option.first_name || ""} ${option.last_name || ""}`
                }
                onChange={(event, value) =>
                  handleFieldChange("adv_user_id", value?.user_id)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "12px" },
                    }}
                    fullWidth
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography>
            Add Target{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.2em",
              }}
            >
              *
            </span>
          </Typography>

          <Controller
            name="rev_target"
            control={control}
            rules={{ required: true }}
            defaultValue={selectedData?.rev_target}
            render={({ field }) => (
              <TextField
                size="small"
                {...field}
                InputProps={{
                  style: { borderRadius: "14px", height: "37px" },  
                }}
                onChange={(e) =>
                  handleFieldChange("rev_target", parseInt(e.target.value))
                } // Ensure it's an integer
                fullWidth
                error={errors.rev_target}
                helperText={errors.rev_target && "Target is required"}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={{ fontWeight: 600 }}>
            Select Month{" "}
            <span style={{ color: "red", fontSize: "18px" }}>*</span>
          </Typography>
          <Controller
            name="month_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                readOnly
                {...field}
                size="small"
                value={
                  monthList.find((month) => month.id === field.value) || null
                }
                options={monthList}
                getOptionLabel={(option) => option.month_full_name}
                onChange={(event, value) =>
                  handleFieldChange("month_id", value?.user_id)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "12px" },
                    }}
                    fullWidth
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography>
            Year{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.2em",
              }}
            >
              *
            </span>
          </Typography>

          <Controller
            name="year"
            control={control}
            rules={{ required: true }}
            defaultValue={selectedData?.year}
            render={({ field }) => (
              <TextField
                size="small"
                type="number"
                {...field}
                onChange={(e) =>
                  handleFieldChange("year", parseInt(e.target.value))
                }
                InputProps={{
                  style: { borderRadius: "14px", height: "37px" },
                  readOnly: true,
                }}
                fullWidth
                error={errors.year}
                helperText={errors.year && "Year is required"}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} textAlign="end">
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
