import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
import { SettingsOutlined, Today } from "@mui/icons-material";
import CustomPopup from "../../../components/CustomPopup";
import SpecificTaskPage from "../../../section/tasks/SpecificTaskPage";
import SpecificTaskTable from "./SpecificTaskTable";
import { useGetTokenOrLogout } from "../../../utils/token";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function TaskReports() {
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const getTokenOrLogout = useGetTokenOrLogout();

  const [userlist, setUserlist] = useState([]);
  const [username, setUserName] = useState(null);
  const [filterType, setFilterType] = useState("today");
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [todayTaskReportData, setTodayTaskReportData] = useState([]);
  const [taskReportData, setTaskReportData] = useState([]);
  const [namefilter, setNameFilter] = useState("");
  const [originalData, setOriginalData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedrowId, setSelectedRowId] = useState(null);
  const [filter, setFilter] = useState(null);
  const [filterData, setfilteredData] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  const [gridKey, setGridKey] = useState(0);

  console.log("data1", selectedrowId, filter);

  const [taskloading, setTaskLoading] = useState(true);

  const handleOpenPopup = (id, filter) => {
    setOpenPopup(true);
    setSelectedRowId(id);
    setFilter(filter);
  };

  console.log("username", username);
  const handleClosePopup = () => {
    setOpenPopup(false);
    setFetchedData([]);
  };
  const navigate = useNavigate();

  const totalNotStarted = originalData.reduce((total, item) => {
    console.log("item", item.not_started);
    return total + item.not_started;
  }, 0);

  const totalCompleted = originalData.reduce((total, item) => {
    return total + item.completed;
  }, 0);

  const totalOverdue = originalData.reduce((total, item) => {
    return total + item.over_due;
  }, 0);

  const allTaskList = [
    ...originalData,
    {
      task_subject: "Total",
      not_started: totalNotStarted,
      completed: totalCompleted,
      over_due: totalOverdue,
    },
  ];

  const columns = [
    {
      field: "tasks",
      headerName: "Tasks",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography
          style={{
            fontWeight: params.row?.task_subject === "Total" ? "bold" : 600,
            color: params.row?.task_subject === "Total" ? "Black" : "",
            fontSize: params.row?.task_subject === "Total" ? "16px" : "14px",
          }}
        >
          {params.row?.task_subject}
        </Typography>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "notStarted",
      headerName: "Not Started",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div
          onClick={() => {
            if (params.row?.task_subject !== "Total") {
              handleOpenPopup(params.row?.id_of_task_type, "notstarted");
            }
          }}
          style={{
            cursor: "pointer",
            fontWeight: params.row?.task_subject === "Total" ? "bold" : 600,
            color: params.row?.task_subject === "Total" ? "Black" : "blue",
            fontSize: "14px"
          }}
        >
          {params.row?.not_started}
        </div>
      ),
      headerAlign: "center",
      align: "center",
    },

    {
      field: "completed",
      headerName: "Completed",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div
          onClick={() => {
            if (params.row?.task_subject !== "Total") {
              handleOpenPopup(params.row?.id_of_task_type, "completed");
            }
          }}
          style={{
            cursor: "pointer",
            fontWeight: params.row?.task_subject === "Total" ? "bold" : 600,
            color: params.row?.task_subject === "Total" ? "Black" : "blue",
            fontSize:"14px"
          }}
        >
          {params.row?.completed}
        </div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "overdue",
      headerName: "Overdue",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div
          onClick={() => {
            if (params.row?.task_subject !== "Total") {
              handleOpenPopup(params.row?.id_of_task_type, "overdue");
            }
          }}
          style={{
            cursor: "pointer",
            fontWeight: params.row?.task_subject === "Total" ? "bold" : 600,
            color: params.row?.task_subject === "Total" ? "Black" : "blue",
            fontSize: "14px"
          }}
        >
          {params.row?.over_due}
        </div>
      ),
      headerAlign: "center",
      align: "center",
    },
  ];

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Available</Box>
      </StyledGridOverlay>
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setUserlist(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleUserchange = (event, value) => {
    setUserName(value);
  };

  const handleStartDateChange = (e) => {
    setFromDate(e.target.value);
  };
  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
  };

  console.log("data1", todayTaskReportData);
  console.log("data1", taskReportData);
  const fetchTodayTaskReport = async () => {
    setIsLoading(true);
    // Simulate API fetch or set your actual data

    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const apiUrl = `${API_ENDPOINT}route/todaytask`;

    try {
      // const response = await fetch(apiUrl);
      const response = await fetch(apiUrl, {
        method: "POST",
        headers,
        body: JSON.stringify({
          user_id: parseInt(userId),
          filter: filterType || null,
          to_date: fromDate || null,
          from_date: toDate || null,
          filter_for: username?.id || null,
        }),
      });

      if (response.ok) {
        const data = await response.json();

        console.log(data);

        if (filterType === "today" && username === null) {
          setTodayTaskReportData(data);
        }

        setTaskReportData(data);
        setOriginalData(data);
        setfilteredData(data);
        setIsLoading(false);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      navigate("/page500");
    }
  };

  useEffect(() => {
    fetchTodayTaskReport();
  }, []);

  const handleApplyFilter = () => {
    fetchTodayTaskReport();
    setGridKey((prev) => prev + 1);
  };

  const handleResetFilter = () => {
    setUserName(null);
    setFromDate("");
    setToDate("");
    setFilterType("today");

    setOriginalData(todayTaskReportData);

    setNameFilter("");
  };

  const handleChangeNmae = (e) => {
    const value = e.target.value;
    setNameFilter(value);

    // Filter data immediately
    let filteredData = filterData.filter((task) =>
      task.task_subject.toLowerCase().includes(value.toLowerCase())
    );
    setOriginalData(filteredData);
    setGridKey((prev) => prev + 1);
  };

  useEffect(() => {
    if (selectedrowId !== null && filter !== null) {
      const requestData = {
        user_id: parseInt(userId),
        type_of_task: selectedrowId,
        filter: filterType,
        to_date: fromDate,
        from_date: toDate,
        filter_for: username?.id || null,
        task_status: filter,
      };
      setTaskLoading(true);
      // Fetch data using selectedRowId and set it in the state
      console.log(selectedrowId);
      console.log(requestData);
      fetch(`${API_ENDPOINT}route/specifictask`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => {
          if (response.status === 200) {
            // setTaskLoading(false);
            return response.json();
          } else if (response.status === 403) {
            setTaskLoading(false);
            throw new Error(
              "Couldn't find any slot for this test type, try next day!!"
            );
          } else if (response.status === 404) {
            setTaskLoading(false);
            throw new Error("Cannot return data, 404");
          } else {
            setTaskLoading(false);
            throw new Error("Unexpected error occurred");
          }
        })
        .then((data) => {
          setFetchedData(data);

          setTaskLoading(false);
          setSelectedRowId(null);
          setFilter(null);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setTaskLoading(false);
          setFetchedData([]);
        });
    }
  }, [selectedrowId, filter]);

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <Paper
            sx={{
              width: "200px",
              fontSize: "24px",
              fontWeight: 600,
              height: "50px",
              borderRadius: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Task Report
          </Paper>
        </Grid>

        <Grid item xs={12} lg={12} md={12} sm={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12} md={12} sm={12}>
                <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>
                  All Users Task
                </Typography>
              </Grid>
              {(userRole === "center_admin" || userRole === "data_entry") && (
                <Grid item xs={12} lg={3} md={6} sm={6}>
                  <Typography sx={{ fontWeight: 600 }}>Select Users</Typography>

                  <Autocomplete
                    size="small"
                    value={username}
                    onChange={handleUserchange}
                    options={userlist}
                    getOptionLabel={(option) =>
                      `${option.first_name ? option.first_name : ""} ${
                        option.last_name ? option.last_name : ""
                      }`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} lg={2} md={3} sm={3}>
                <Typography sx={{ fontWeight: 600 }}>Select For</Typography>
                <Select
                  value={filterType}
                  onChange={handleFilterChange}
                  fullWidth
                  style={{ borderRadius: "15px" }}
                  size="small"
                >
                  <MenuItem value="today">Today</MenuItem>
                  <MenuItem value="1week">Last 7 Days</MenuItem>
                  <MenuItem value="1month">Last 30 Days</MenuItem>
                  <MenuItem value="custom">Custom</MenuItem>
                </Select>
              </Grid>

              {filterType === "custom" && (
                <>
                  <Grid item xs={12} lg={2} md={3} sm={3}>
                    <Typography sx={{ fontWeight: 600 }}>From Date</Typography>
                    <TextField
                      value={fromDate}
                      onChange={handleStartDateChange}
                      fullWidth
                      type="date"
                      size="small"
                      InputProps={{ style: { borderRadius: "15px" } }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={2} md={3} sm={3}>
                    <Typography sx={{ fontWeight: 600 }}>To Date</Typography>
                    <TextField
                      value={toDate}
                      onChange={handleToDateChange}
                      fullWidth
                      type="date"
                      size="small"
                      InputProps={{ style: { borderRadius: "15px" } }}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} md={12} lg={3} sm={12}>
                <Typography sx={{ fontWeight: 600, visibility: "hidden" }}>
                  for spacing
                </Typography>
                <Button
                  variant="contained"
                  sx={{ borderRadius: "25px" }}
                  onClick={handleApplyFilter}
                  startIcon={<SearchIcon />}
                >
                  Search
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ marginLeft: "10px", borderRadius: "25px" }}
                  onClick={handleResetFilter}
                  startIcon={<ClearIcon />}
                >
                  Clear{" "}
                </Button>
              </Grid>

              <Grid
                item
                xs={12}
                lg={12}
                md={12}
                sm={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
                mt={2}
              >
                <Typography variant="h4" mr={1}>
                  Search :
                </Typography>
                <TextField
                  value={namefilter}
                  onChange={handleChangeNmae}
                  size="small"
                  InputProps={{ style: { borderRadius: "15px" } }}
                />
              </Grid>

              <Grid item xs={12} lg={12} md={12} sm={12}>
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    "& .header-cell": {
                      backgroundColor: "#abf7b1",
                      fontWeight: 600,
                      borderRight: "0.1px solid #C3C3C3",
                    },
                  }}
                >
                  <DataGrid
                    key={gridKey}
                    rows={allTaskList}
                    columns={columns}
                    autoHeight
                    getRowId={() => Math.floor(Math.random() * 100000000)}
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 50,
                        },
                      },
                    }}
                    sx={{ marginTop: "20px" }}
                    pageSizeOptions={[25, 50, 100]}
                  />
                </Box>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>

      <CustomPopup
        open={openPopup}
        onClose={handleClosePopup}
        component={
          <SpecificTaskTable
            onClose={handleClosePopup}
            fetchedData={fetchedData}
            taskloading={taskloading}
          />
        }
        title="My Task"
        showDivider="true"
        maxWidth="lg"
      />
    </>
  );
}
