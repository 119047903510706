import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, Card, Grid, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';

// assets
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import PersonAddTwoToneIcon from '@mui/icons-material/PersonAddTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import PinDropTwoToneIcon from '@mui/icons-material/PinDropTwoTone';

import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import ButtonComponent from '../../../../components/button/ButtonComponent';

import CancelIcon from '@mui/icons-material/Cancel';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';



// ==============================|| SOCIAL PROFILE - FOLLOWER CARD ||============================== //

const TestTypeCard = ({ avatar, follow, location, name, is_active, onActivate, onDeactivate }) => {
    const theme = useTheme();
    const userRole=localStorage.getItem("userRole")

    const handleActivate = () => {
        if (onActivate) {
          onActivate();
        }
      };
    
      const handleDeactivate = () => {
        if (onDeactivate) {
          onDeactivate();
        }
      };
    
    return (
        <Card
            sx={{
                padding: '16px',
                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
                border: '1px solid',
                borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100],
                '&:hover': {
                    border: `1px solid${theme.palette.primary.main}`
                }
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        
                        <Grid item xs zeroMinWidth>
                            <Typography
                                variant="h2"
                                component="div"
                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'flex', justifyContent: 'center' }}
                            >
                                {name}
                            </Typography>
                            
                        </Grid>
                       
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {is_active === 0 ? (
                        <Button variant="contained" fullWidth startIcon={<DoneOutlineIcon />} onClick={handleActivate} >
                            Make Active
                        </Button>
                       
                    ) : (
                        userRole==="center_admin" && (
                        <Button variant="outlined" fullWidth startIcon={<CancelIcon />} onClick={handleDeactivate}>
                            Make InActive
                        </Button>
                        )
                    )}
                </Grid>
            </Grid>
        </Card>
    );
};



export default TestTypeCard;