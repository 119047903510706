import {
  Backdrop,
  Box,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import InfoCard from "./InfoCard";
import EventFilter from "./EventFilter";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { fDate } from "../../../utils/formatTime";
import DetailedReport from "./DetailedReport";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import EventIcon from '@mui/icons-material/Event';
import UserWiseReport from "./UserWiseReport";
import PeopleIcon from "@mui/icons-material/People";
import SummarizeIcon from "@mui/icons-material/Summarize";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"; 
import EventWiseReport from "./EventWiseReport";

export default function RegistrationStatus() {
  const [selectedChip, setSelectedChip] = useState("detailedreport");

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (selectedChip === "userwisereport"|| selectedChip === "eventwisereport" ) {
      setOpen(true);
    }
  }, [selectedChip]);
  
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleChipClick = (criteria) => {
    setSelectedChip(criteria);
    setOpen(false)
  };

  return (
    <>
      <Grid Grid container spacing={2}>
        <Grid item lg={2.5}>
          {" "}
          <Paper
            sx={{
              height: "40px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "24px",
            }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
              Event Report
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} sm={12} lg={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" spacing={2} m={2}>
              <Chip
                icon={<SummarizeIcon />}
                label="Detailed Report"
                onClick={() => handleChipClick("detailedreport")}
                variant={
                  selectedChip === "detailedreport" ? "filled" : "outlined"
                } // Change variant based on selection
                color={
                  selectedChip === "detailedreport" ? "secondary" : "default"
                } // Change color based on selection
                sx={{
                  fontSize: "15px",
                  height: "40px",
                  minWidth: "120px",
                  borderRadius: "20px",
                }}
              />

              <Chip
                label="User Wise Specific Report"
                icon={<PeopleIcon />}
                onClick={() => handleChipClick("userwisereport")}
                variant={
                  selectedChip === "userwisereport" ? "filled" : "outlined"
                } // Change variant based on selection
                color={
                  selectedChip === "userwisereport" ? "secondary" : "default"
                } // Change color based on selection
                sx={{
                  fontSize: "15px",
                  height: "40px",
                  minWidth: "120px",
                  borderRadius: "20px",
                }}
              />

<Chip
                label="Event Wise Specific Report"
                icon={<EventIcon />}
                onClick={() => handleChipClick("eventwisereport")}
                variant={
                  selectedChip === "eventwisereport" ? "filled" : "outlined"
                } // Change variant based on selection
                color={
                  selectedChip === "eventwisereport" ? "secondary" : "default"
                } // Change color based on selection
                sx={{
                  fontSize: "15px",
                  height: "40px",
                  minWidth: "120px",
                  borderRadius: "20px",
                }}
              />
            </Stack>

            <Stack direction="column">
              <Paper
                onClick={handleToggle}
                sx={{
                  borderRadius: "25px",
                  width: "120px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                  cursor: "pointer",
                }}
              >
                <FilterAltIcon fontSize="medium" color="orange" />
                <Typography variant="h4" marginLeft={1}>
                  Filter
                </Typography>
                {open === false ? (
              <KeyboardArrowDownIcon fontSize="small" />
            ) : (
              <KeyboardArrowUpIcon fontSize="small" />
            )}
              </Paper>
            </Stack>
          </Stack>
        </Grid>

        {selectedChip === "detailedreport" && <DetailedReport open={open} />}
        {selectedChip === "userwisereport" && <UserWiseReport open={open} />}
        {selectedChip === "eventwisereport" && <EventWiseReport open={open} />}
      </Grid>
    </>
  );
}
