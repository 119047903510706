// Author : Ayesha Shaikh
//  Date : 13 Nov 2024

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function DeleteLeaveRequest({
  onClose,
  selectedId,
  showSnackbar,
  leaveData,
  setLeaveData,
}) {
  const userId = localStorage.getItem("userId");
  const handleCancel = () => {
    onClose();
  };

  const handleDelete = async () => {
    try {
      const requestData = {
        user_id: parseInt(userId),
        is_deleted: true,
        is_active:false
      };
      const response = await fetch(
        `${API_ENDPOINT}advisor-leave-detail/${selectedId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      if (response.ok) {
        showSnackbar("Employee leave request deleted Successfully", "success");
        const filterdData = leaveData.filter((i) => i.id !== selectedId);
        setLeaveData(filterdData);

        setTimeout(() => {
          onClose();
        }, 1000);
      } else if (response.status === 401) {
        showSnackbar("Unknown User", "error");
      } else if (response.status === 403) {
        showSnackbar("Unathorized User", "error");
      } else {
        showSnackbar("Error to delete employee leave request", "error");
        throw new Error("Error to delete employee leave request");
      }
    } catch (error) {
      showSnackbar("Error to delete employee leave request", "error");
    }
  };

  return (
    <>
      <Grid container spacing={0.5}>
        <Grid item>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ErrorOutlineIcon fontSize="large" sx={{ color: "#D22B2B" }} />

            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "black",
                marginLeft: "10px",
              }}
            >
              Confirmation
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} ml={6}>
          <Typography>
            Are you sure you want to delete this leave request?
          </Typography>
        </Grid>

        <Grid xs={12} textAlign="end" mt={2}>
          <Button onClick={handleCancel}>Cancel</Button>

          <Button
            color="error"
            variant="contained"
            size="small"
            style={{ borderRadius: "25px" }}
            onClick={handleDelete}
          >
            {" "}
            Delete{" "}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
