import {
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useGetTokenOrLogout } from "../../../utils/token";
import { useNavigate } from "react-router-dom";
export default function CampainsFilter({
  setCapaigndata,
  openFilter,
  sortedCampaignData,
  setLoading,
  setGridKey
}) {
  const today = new Date().toISOString().split("T")[0];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");

  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
  const [campaigntypelist, setCampaigntypelist] = useState([]);
  const [campaigntype, setCampaigntype] = useState([]);
  const [paid, setPaid] = useState([]);
  const [branchlist, setBranchlist] = useState([]);
  const [branch, setBranch] = useState([]);
  const [countrylist, setcountrylist] = useState([]);
  const [country, setCountry] = useState([]);
  const [createdbylist, setCreatedbylist] = useState([]);
  const [createdby, setCreatedby] = useState([]);
  const [campaignList, setcampaignList] = useState([]);
  const [campaignnname, setCampaignname] = useState([]);
  const [startfromdate, setStartfromdate] = useState("");
  const [startenddate, setStartenddate] = useState("");
  const [endfromdate, setEndfromdate] = useState("");
  const [endtodate, setEndtodate] = useState("");
  const [createfrom, setCreatedfrom] = useState("");
  const [createdto, setCreatedto] = useState("");

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const newCampaignType = campaigntype.map((i) => i.id);
  const newPaidStatus = paid.map((i) => i.value);
  const newBranch = branch.map((i) => i.id);
  const newCreatedby = createdby.map((i) => i.id);
  const newCampaignName = campaignnname.map((i) => i.id);
  const handleApplyFilter = async (event) => {
    event.preventDefault();
    setGridKey((prev)=>prev+1)

    setLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };

    const requestData = {
      user_id: parseInt(userId),
      campaign_name: newCampaignName,
      start_from_date: startfromdate || null,
      start_to_date: startenddate || null,
      end_from_date: endfromdate || null,
      end_to_date: endtodate || null,
      campaign_type_id: newCampaignType,
      branch_id: newBranch,
      created_by: newCreatedby,
      paid_status: newPaidStatus,
    };

    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/camapignfilter`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        setLoading(false);
        const result = await response.json();
        setCapaigndata(result);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      navigate("/page500");
    }
  };

  const resetfilter = () => {
    setGridKey((prev)=>prev+1)
    setCampaignname([]);
    setCampaigntype([]);
    setPaid([]);
    setBranch([]);
    setCountry([]);
    setCreatedby([]);
    setStartfromdate("");
    setStartenddate("");
    setEndfromdate("");
    setEndtodate("");

    setCapaigndata(sortedCampaignData);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}campaign/event/withname`);
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setcampaignList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allCampaignNAme = [{ campaign_name: "Select All" }, ...campaignList];
  const namechange = (event, value) => {
    if (value.some((option) => option.campaign_name === "Select All")) {
      setCampaignname(campaignList);
    } else {
      setCampaignname(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}campaigntype`);
        if (response.ok) {
          const data = await response.json();
          setCampaigntypelist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allCampaignList = [{ name: "Select All" }, ...campaigntypelist];
  const handleCampaigntypeChange = (event, value) => {
    if (value.some((option) => option.name === "Select All")) {
      setCampaigntype(campaigntypelist);
    } else {
      setCampaigntype(value);
    }
  };

  const [paidstatus, setPaidStatus] = useState([
    { id: 1, value: "Paid" },
    { id: 2, value: "Unpaid" },
  ]);

  const handelpaidstatus = (event, value) => {
    setPaid(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();

          setBranchlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allbranchList = [{ branch_name: "Select All" }, ...branchlist];
  const handelbranchchange = (event, value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchlist);
    } else {
      setBranch(value);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}targetcountries`);
        if (response.ok) {
          const data = await response.json();

          setcountrylist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handelcountrychange = (e) => {
    setCountry(e.target.value);
    console.log("country is", e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          setCreatedbylist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allCreatedByList = [{ first_name: "Select All" }, ...createdbylist];
  const handelcretedby = (event, value) => {
    if (
      value.some(
        (option) =>
          `${option.first_name ? option.first_name : ""}` === "Select All" ||
          `${option.last_name ? option.last_name : ""}` === "Select All"
      )
    ) {
      setCreatedby(createdbylist);
    } else {
      setCreatedby(value);
    }
  };

  const handdelstartfromdate = (e) => {
    setStartfromdate(e.target.value);
  };

  const handdelstartenddate = (e) => {
    setStartenddate(e.target.value);
  };

  const handdelendfromdate = (e) => {
    setEndfromdate(e.target.value);
  };

  const handdelendtodate = (e) => {
    setEndtodate(e.target.value);
  };

  const handlecratedfrom = (e) => {
    setCreatedfrom(e.target.value);
  };

  const handlecreatedto = (e) => {
    setCreatedto(e.target.value);
  };


  // Add default 1 month start and end date
const formatDate = (date) => date.toISOString().split('T')[0];
useEffect(() => {
  const today = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(today.getMonth() - 1);

  setCreatedto(formatDate(today));
  setCreatedfrom(formatDate(oneMonthAgo));
}, []);

  return (
    <>
      <Collapse in={openFilter} sx={{ marginTop: "5px" }}>
        <Grid container spacing={2} mt={1} mb={2}>
          <Grid item xs={6} lg={3} sm={4} md={4}>
            <Typography sx={{ fontWeight: 600 }}>Campaign Name </Typography>
            <Autocomplete
              size="small"
              value={campaignnname}
              multiple
              onChange={namechange}
              options={allCampaignNAme}
              getOptionLabel={(option) => option.campaign_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} lg={3} sm={4} md={4}>
            <Typography sx={{ fontWeight: 600 }}>Campaign Type </Typography>
            {/* <Select
                multiple
                value={campaigntype}
                onChange={handleCampaigntypeChange}
                fullWidth
                MenuProps={MenuProps}
                style={{ borderRadius: "15px" }}
                renderValue={(selected) =>
                  selected
                    .map((value) => {
                      const campaigntype = campaigntypelist.find(
                        (status) => status.id === value
                      );
                      return campaigntype ? campaigntype.name : "";
                    })
                    .join(", ")
                }
              >
                {campaigntypelist.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <Checkbox checked={campaigntype.indexOf(item.id) > -1} />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select> */}

            <Autocomplete
              size="small"
              value={campaigntype}
              multiple
              onChange={handleCampaigntypeChange}
              options={allCampaignList}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} lg={3} sm={4} md={4}>
            <Typography sx={{ fontWeight: 600 }}>Start From Date </Typography>
            <TextField
              size="small"
              value={startfromdate}
              onChange={handdelstartfromdate}
              type="date"
              InputProps={{
                style: { borderRadius: "15px" },
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={6} lg={3} sm={4} md={4}>
            <Typography sx={{ fontWeight: 600 }}>Start End Date </Typography>
            <TextField
              size="small"
              value={startenddate}
              onChange={handdelstartenddate}
              type="date"
              InputProps={{
                style: { borderRadius: "15px" },
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={6} lg={3} sm={4} md={4}>
            <Typography sx={{ fontWeight: 600 }}>End From Date </Typography>
            <TextField
              size="small"
              value={endfromdate}
              onChange={handdelendfromdate}
              type="date"
              InputProps={{
                style: { borderRadius: "15px" },
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={6} lg={3} sm={4} md={4}>
            <Typography sx={{ fontWeight: 600 }}>End To Date </Typography>
            <TextField
              size="small"
              value={endtodate}
              onChange={handdelendtodate}
              type="date"
              InputProps={{
                style: { borderRadius: "15px" },
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} lg={3} sm={4} md={4}>
            <Typography sx={{ fontWeight: 600 }}>paid Status </Typography>

            <Autocomplete
              size="small"
              value={paid}
              multiple
              onChange={handelpaidstatus}
              options={paidstatus}
              getOptionLabel={(option) => option.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} lg={3} sm={4} md={4}>
            <Typography sx={{ fontWeight: 600 }}>Branch </Typography>
            {/* <Select
                multiple
                value={branch}
                onChange={handelbranchchange}
                MenuProps={MenuProps}
                fullWidth
                renderValue={(selected) =>
                  selected
                    .map((value) => {
                      const branches = branchlist.find(
                        (status) => status.branch_name === value
                      );
                      return branches ? branches.branch_name : "";
                    })
                    .join(", ")
                }
                style={{ borderRadius: "15px" }}
              >
                 <MenuItem key="select-all" value="all">
                    <Checkbox checked={branch.length === branchlist.length} />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                {branchlist.map((name) => (
                  <MenuItem key={name.id} value={name.branch_name}>
                    <Checkbox checked={branch.indexOf(name.branch_name) > -1} />
                    <ListItemText primary={name.branch_name} />
                  </MenuItem>
                ))}
              </Select> */}

            <Autocomplete
              size="small"
              value={branch}
              multiple
              onChange={handelbranchchange}
              options={allbranchList}
              getOptionLabel={(option) => option.branch_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" },
                  }}
                />
              )}
            />
          </Grid>

          {/* <Grid item xs={6} lg={3} sm={4} md={4}>
            <FormControl sx={{ mb: 1, width: "100%" }}>
              <Typography sx={{ fontWeight: 600 }}>Country </Typography>
              <Select
                multiple
                fullWidth
                style={{ borderRadius: "15px" }}
                value={country}
                onChange={handelcountrychange}
                MenuProps={MenuProps}
                renderValue={(selected) =>
                  selected
                    .map((value) => {
                      const countries = countrylist.find(
                        (status) => status.country_name === value
                      );
                      return countries ? countries.country_name : "";
                    })
                    .join(", ")
                }
              >
                {countrylist.map((name) => (
                  <MenuItem key={name.id} value={name.country_name}>
                    <Checkbox
                      checked={country.indexOf(name.country_name) > -1}
                    />
                    <ListItemText primary={name.country_name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}

          {/* <Grid item xs={6} lg={3} sm={4} md={4}>
            <Typography sx={{ fontWeight: 600 }}>Created From Date </Typography>
            <TextField
               size="small"
              value={createfrom}
              onChange={handlecratedfrom}
              type="date"
              InputProps={{
                style: { borderRadius: "15px" },
              }}
              fullWidth
            />
          </Grid> */}

          {/* <Grid item xs={6} lg={3} sm={4} md={4}>
            <Typography sx={{ fontWeight: 600 }}>Created To Date </Typography>
            <TextField
               size="small"
              value={createdto}
              onChange={handlecreatedto}
              type="date"
              InputProps={{
                style: { borderRadius: "15px" },
              }}
              fullWidth
            />
          </Grid> */}

          <Grid item xs={6} lg={3} sm={4} md={4}>
            <Typography sx={{ fontWeight: 600 }}>Created By </Typography>

            <Autocomplete
              size="small"
              value={createdby}
              multiple
              onChange={handelcretedby}
              options={allCreatedByList}
              getOptionLabel={(option) =>
                `${option.first_name ? option.first_name : ""} ${
                  option.last_name ? option.last_name : ""
                }`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={12} textAlign="end">
            <Button
              variant="contained"
              color="error"
              style={{
                width: "150px",
                borderRadius: "24px",
                fontWeight: 500,
                fontSize: "14px",
                marginRight: "8px",
              }}
              onClick={resetfilter}
            >
              Reset Filter
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{
                width: "150px",
                borderRadius: "24px",
                fontWeight: 500,
                fontSize: "14px",
              }}
              onClick={handleApplyFilter}
            >
              Apply Filter
            </Button>
          </Grid>
        </Grid>
      </Collapse>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
