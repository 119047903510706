import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Chip, CircularProgress, Grid } from '@mui/material';
import AddTaskFormPopup from '../../tasks/AddTaskPopup';
import { fDate } from '../../../../utils/formatTime';
import CloseTaskDialog from '../../tasks/CloseTaskPopup';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

function ViewTaskDataGrid({ isDialogOpen, openDialog, closeDialog, tasksData, studentId, enquiryData }) {
  const enquiryId = studentId;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const tasksPerPage = 10;

  const [tasksData2, setTasksData2] = useState([])
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState(tasks);
  const [isLoading, setIsLoading] = useState(false)

  const [isCloseDialogOpen, setIsCloseDialogOpen] = useState(false);
const [selectedTaskId, setSelectedTaskId] = useState(null);

  const [globalFilter, setGlobalFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [isFormOpen, setIsFormOpen] = useState(false);

  console.log(selectedTaskId)

  const columns = [
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => {
        let chipColor;
        let statusLabel;
        let closeButton;

        switch (params.value) {
          case 0:
            chipColor = 'secondary'; // Red color
            statusLabel = 'Closed';
            closeButton = null; // No button for closed tasks
            break;
          case 1:
            chipColor = 'error'; // Green color
            statusLabel = 'Open';
            closeButton = (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => handleTaskClose(params.row.id)}
              >
                Close Task
              </Button>
            );
            break;
          default:
            chipColor = 'default';
            statusLabel = 'Unknown';
            closeButton = null;
        }

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Chip
              label={statusLabel}
              color={chipColor}
              variant="contained"
              style={{ fontSize: '10px', marginRight: '8px' }}
            />
            {closeButton}
          </div>
        );
      },
    },
    { 
      field: 'type_of_task', 
      headerName: 'Type of Task', 
      //flex: 1, 
      width: 150,
      filterable: true,
      renderCell: (params) => <div>{params.row.type_of_task?.name_of_task_type}</div>,
     },
     { 
      field: 'campaign_id', 
      headerName: 'Campaign Name', 
      //flex: 1, 
      width: 250,
      filterable: true,
      renderCell: (params) => <div>{params.row.campaign_id?.campaign_name}</div>,
     },
    {
      field: 'due_date',
      headerName: 'Due Date',
      //flex: 1,
      width: 100,
      filterable: true,
      renderCell: (params) => <div>{fDate(params?.value)}</div>,
    },
    { 
      field: 'task_owner', 
      headerName: 'Task Owner', 
      //flex: 1, 
      width: 100,
      filterable: true,
      renderCell: (params) => <div>{params.row.task_owner?.first_name}</div>,
      
     },
    { 
      field: 'created_by', 
      headerName: 'Created By', 
      //flex: 1, 
      width: 100,
      filterable: true,
      renderCell: (params) => <div>{params.row.created_by?.first_name}</div>,
     },
    {
      field: 'createdAt',
      headerName: 'Created At ',
      //flex: 1,
      width: 100,
      filterable: true,
      renderCell: (params) => <div>{fDate(params?.value)}</div>,
    },
    { 
      field: 'closed_by', 
      headerName: 'Closed By', 
      //flex: 1, 
      width: 100,
      filterable: true,
      renderCell: (params) => <div>{params.row.closed_by?.first_name}</div>,
     },
     { 
      field: 'closed_date', 
      headerName: 'Closed At', 
      //flex: 1, 
      width: 150,
      filterable: true,
      renderCell: (params) => <div>{fDate(params.row?.closed_date)} {(params.row?.task_close_time)}</div>,
     },
     { 
      field: 'contact_mode_id', 
      headerName: 'Contact Mode', 
     // flex: 1, 
      width: 150,
      filterable: true,
      renderCell: (params) => <div>{params.row?.contact_mode_id?.contact_mode}</div>,
     },
     { 
      field: 'response_status', 
      headerName: 'Response Status', 
      //flex: 1, 
      width: 150,
      filterable: true,
      renderCell: (params) => <div>{params.value}</div>,
     },
     { 
      field: 'remarks', 
      headerName: 'Remarks', 
      //flex: 1, 
      width: 100,
      filterable: true,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {params.value}
        </div>
    ),
     },

  ];

  
  const handlePageChange = (params) => {
    setCurrentPage(params.page + 1);
  };

  const handleStatusFilterChange = (newStatusFilter) => {
    setStatusFilter(newStatusFilter);
  };

  const handleAddTask = () => {
    setIsFormOpen(true); // Open the form modal
  };

  const handleCloseForm = () => {
    setIsFormOpen(false); // Close the form modal
  };

  const handleFormSubmit = (taskData) => {
    // Handle form submission here (e.g., send data to the server)
    taskData.enquiry_id = studentId;

    console.log('Submitted task data:', taskData);

    // Close the form modal
    // setIsFormOpen(false);
  };

  useEffect(() => {
    // Define a function to fetch the task data
    const fetchTaskData = () => {
      setIsLoading(true)
      fetch(`${API_ENDPOINT}advisordashboard/taskbystudent/${studentId}`)
        .then(response => response.json())
        .then(data => {
          // Filter tasks with due dates that are today or in the past
        //  const filteredTasks = data.filter(task => new Date(task.due_date) <= new Date());
          // Sort the filtered tasks by due date
          const sortedTasks = data.sort((a, b) => new Date(b.due_date) - new Date(a.due_date));
          setTasks(sortedTasks);
          setIsLoading(false)
        })
        .catch(error => {
          console.error('Error fetching task items:', error);
          setIsLoading(false);
        });
    };
  
    // Fetch data initially when the component mounts
    fetchTaskData();
  
    // Set up a interval to fetch data every 10 seconds
    /*const intervalId = setInterval(fetchTaskData, 10000);
  
    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);*/
  }, [studentId]); 
  
  console.log(tasks);
  

useEffect(() => {
  if (statusFilter === 'all') {
    setFilteredTasks(tasks);
  } else if (statusFilter === 'open') {
    const openTasks = tasks.filter((task) => task.status === 1);
    setFilteredTasks(openTasks);
  } else if (statusFilter === 'closed') {
    const closedTasks = tasks.filter((task) => task.status === 0);
    setFilteredTasks(closedTasks);
  }
}, [statusFilter, tasks]);

console.log(filteredTasks)

const handleTaskClose = (taskId) => {
  // Set the selected task ID and open the CloseDialogForm
  setSelectedTaskId(taskId);
  setIsCloseDialogOpen(true);
};


  return (
    <div>
    {isLoading ? (
      <Box display="flex"
      justifyContent="center"
      //alignItems="center"
      //minHeight="100vh"
      >
      <CircularProgress />
      </Box>
    ) : (
    <div>
        <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box display="flex" alignItems="center">
            <Chip
              label="All Tasks"
              variant={statusFilter === 'all' ? 'outlined' : 'contained'}
              color='primary'
              style={{ fontSize: '12px', marginRight: '8px' }}
              onClick={() => handleStatusFilterChange('all')}
            />
            <Chip
              label="Open Tasks"
              variant= {statusFilter === 'open' ? 'outlined' : 'contained'}
              color='error'
              style={{ fontSize: '12px', marginRight: '8px' }}
              onClick={() => handleStatusFilterChange('open')}
            />
            <Chip
              label="Closed Tasks"
              variant={statusFilter === 'closed' ? 'outlined' : 'contained'}
              color='secondary'
              style={{ fontSize: '12px' }}
              onClick={() => handleStatusFilterChange('closed')}
            />
          </Box>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={handleAddTask} disabled={enquiryData.is_active===0}>
            Add Task
          </Button>
        </Grid>
      </Grid>
     {/*} <div className="filter-inputs">
        <input
          type="text"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Filter All Columns"
        />
    </div>*/}
      <div style={{ height: '400', width: '100%' }}>
        <DataGrid
          columns={columns}
          rows={filteredTasks}
          getRowId={(row) => row.id}
          autoHeight
          pagination
          pageSize={tasksPerPage}
          rowCount={tasksPerPage * totalPages}
          paginationMode="server"
          onPageChange={handlePageChange}
          components={{
            Toolbar: () => (
              <div className="grid-toolbar">
                
              </div>
            ),
          }}
          filterModel={{
            items: [
              {
                columnField: 'leadName',
                operatorValue: 'contains',
                value: globalFilter,
              },
              {
                columnField: 'taskType',
                operatorValue: 'contains',
                value: globalFilter,
              },
              {
                columnField: 'dueDateTime',
                operatorValue: 'contains',
                value: globalFilter,
              },
              {
                columnField: 'taskOwner',
                operatorValue: 'contains',
                value: globalFilter,
              },
              {
                columnField: 'createdBy',
                operatorValue: 'contains',
                value: globalFilter,
              },
              {
                columnField: 'createdTime',
                operatorValue: 'contains',
                value: globalFilter,
              },
              {
                columnField: 'closedBy',
                operatorValue: 'contains',
                value: globalFilter,
              },
              {
                columnField: 'status',
                operatorValue: 'equals',
                value: statusFilter === 'all' ? '' : statusFilter,
              },
            ],
          }}
        />
         <AddTaskFormPopup open={isFormOpen} onClose={handleCloseForm} onSubmit={handleFormSubmit} studentId={studentId} />
         {isCloseDialogOpen && (
      <CloseTaskDialog
        open={isCloseDialogOpen}
        onClose={() => setIsCloseDialogOpen(false)}
        taskId={selectedTaskId}
        enquiryId={enquiryId}
      />
    )}
      </div>
    </div>
  )}
  </div>
  )
}

export default ViewTaskDataGrid;
