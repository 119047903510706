// Author : Ayesha Shaikh
//  Date : 18 sep 2024
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MainCard from "../../../components/Maincard";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useGetTokenOrLogout } from "../../../utils/token";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function AddEmployeeForm() {
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const today = new Date().toISOString().split('T')[0];
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const [codeList, setCodeList] = useState([]);
  const [BranchList, setBranchList] = useState([]);
  const [branchName, setBranchName] = useState(null);

  const [roleList, setRoleList] = useState([]);
  const [role, setrole] = useState([]);
  const [country, setCountry] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [service, setService] = useState([]);
  const [subServiceList, setSubserviceList] = useState([]);
  const [subService, setSubService] = useState([]);
  const [workBranch, setWorkBranch] = useState([]);
  const [userList, setUserList] = useState([]);
 
  const [branchManager, setBranchManager] = useState([]);
  const [reportingManagerList, setReportingManagerList] =useState([])
  const [reportingManager, setReportingManager] = useState([]);
  const [reportUser, setReportUser] = useState([]);
  const [reportUser1, setReportUser1] = useState([]);
  
  const [loading, setLoading] = useState(false);

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };


  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  const ccField = reportUser.map((i) => i.email);
  const ccField1 = reportUser1.map((i) => i.email);

  const onSubmit = async (data) => {
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const requestData = {
        ...data,
        role: role.map((i)=>i.id),
        branch: branchName?.id,
        branch_manager: branchManager?.first_name,
        reporting_manager: reportingManager.map((i)=>i.id),
        add_report_cc_user: [...ccField, ...ccField1],
        user_id: parseInt(userId),
      };
      const response = await fetch(`${API_ENDPOINT}employee`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });
      if (response.ok) {
        showSnackbar("Employee Added Successfully", "success");

        setTimeout(() => {
         window.location.reload()
        }, 1500);
      }else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      navigate("/page500");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}country`);
        if (response.ok) {
          const data = await response.json();
          setCountryList(data);
          const sortedData = [...data].sort(
            (a, b) => a.phone_code - b.phone_code
          );
          const uniqueArray = sortedData.filter(
            (obj, index, self) =>
              index === self.findIndex((t) => t.phone_code === obj.phone_code)
          );

          setCodeList(uniqueArray);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();
          setBranchList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}role`);
        if (response.ok) {
          const data = await response.json();
          const filterdData= data.filter((i)=>i.role_name !=="center_admin")
          setRoleList(filterdData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}service`);
        if (response.ok) {
          const data = await response.json();
          setServiceList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}subservice`);
        if (response.ok) {
          const data = await response.json();
          setSubserviceList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          setUserList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}reportingmanager`);
        if (response.ok) {
          const data = await response.json();
          setReportingManagerList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleBranchChange = (event, value) => {
    setBranchName(value);
    setValue("branch", value);
  };

  useEffect(() => {
    const value = branchName?.admin_id?.user_id;
    const defaultBranchManager = userList.find((i) => i.id === value);
    setBranchManager(defaultBranchManager);
  }, [branchName]);

  console.log("branchManager", branchName);
  const handleRoleChange = (event, value) => {
    setrole(value);
    setValue("role", value);
  };

  const handleCountryChange = (event, value) => {
    setCountry(value);
  };

  const handleServiceChange = (event, value) => {
    setService(value);
  };

  const handleSubServiceChange = (event, value) => {
    setSubService(value);
  };

  const handleWorkBranchChange = (event, value) => {
    setWorkBranch(value);
  };

  const handleBranchManagerChange = (event, value) => {
    setBranchManager(value);
  };

  const handlereportManagerChange = (event, value) => {
    setReportingManager(value);
    setValue("reporting_manager", value);
  };

  const alluserList = [{ first_name: "Other", email: null }, ...userList];
  const handlereportCCChange = (event, value) => {
    setReportUser(value);
  };

  const selectedccUser = reportUser.map((i) => i.first_name);
  const handlereportCCChange1 = (event, value) => {
    setReportUser1(value);
  };
  const today1 = new Date();
  const birthDate = new Date("02-06-2002");
  const age = today1.getFullYear() - birthDate.getFullYear();
  console.log(age)

  return (
    <>
      <Grid container spacing={2}>
        {/* Employee Name  */}
        <Grid item xs={12} lg={4} md={6} sm={6}>
          <Typography variant="h5">
            First Name{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.2em",
              }}
            >
              *
            </span>
          </Typography>

          <Controller
            name="first_name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                size="small"
                {...field}
                InputProps={{
                  style: { borderRadius: "14px", height: "37px" },
                }}
                fullWidth
                error={errors.first_name}
                helperText={errors.first_name && "First name is required"}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} lg={4} md={6} sm={6}>
          <Typography variant="h5">Last Name </Typography>
          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                {...field}
                InputProps={{
                  style: { borderRadius: "14px", height: "37px" },
                }}
                fullWidth
              />
            )}
          />
        </Grid>

        {/* Email */}
        <Grid item xs={12} lg={4} md={6} sm={6}>
          <Typography variant="h5">
            Email{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.2em",
              }}
            >
              *
            </span>
          </Typography>

          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Please enter a valid email address",
              },
            }}
            render={({ field }) => (
              <TextField
                size="small"
                {...field}
                InputProps={{
                  style: { borderRadius: "14px", height: "37px" },
                }}
                fullWidth
                error={!!errors.email}
                helperText={errors.email && errors.email.message}
              />
            )}
          />
        </Grid>

        {/* Mobile */}
        <Grid item xs={12} lg={4} md={6} sm={6}>
          <Grid container>
            <Grid item xs={12} lg={4} md={4} sm={6}>
              <Typography variant="h5">
                ISD Code{" "}
              
              
              </Typography>
              <Controller
                name="mobile_country_code"
                control={control}
                defaultValue={91}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "14px",
                        height: "37px",
                      },
                    }}
                    SelectProps={{
                      MenuProps: {
                        disableScrollLock: true,
                        sx: { maxHeight: "50%" },
                      },
                    }}
                  >
                    {codeList.map((code) => (
                      <MenuItem key={code?.phone_code} value={code?.phone_code}>
                        {code?.phone_code}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              ></Controller>
            </Grid>
            <Grid item xs={12} lg={8} md={8} sm={6}>
              <Typography variant="h5">
                Mobile No{" "}
               
               
              </Typography>
              <Controller
                name="mobile"
                control={control}
                rules={{
                 
                  pattern: {
                    value: /^[0-9]{10}$/, // Example regex for 10-digit mobile numbers
                    message: "Please enter a valid 10-digit mobile number",
                  },
                }}
               
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "14px",
                        height: "37px",
                      },
                    }}
                    error={!!errors.mobile}
                    helperText={errors.mobile && errors.mobile.message}
                  />
                )}
              ></Controller>
            </Grid>
          </Grid>
        </Grid>

        {/* Gender */}
        <Grid item xs={12} lg={4} md={3} sm={4}>
          <Typography variant="h5">Gender </Typography>
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                size="small"
                select
                InputProps={{
                  style: {
                    borderRadius: "14px",
                    height: "37px",
                  },
                }}
              >
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </TextField>
            )}
          ></Controller>
        </Grid>

        {/*Date of Birth */}
        <Grid item xs={12} lg={4} md={3} sm={4}>
          <Typography variant="h5">Date of Birth </Typography>
          <Controller
            name="dob"
            control={control}
            rules={{
              
              validate: (value) => {
                const today = new Date();
                const birthDate = new Date(value);
                const age = today.getFullYear() - birthDate.getFullYear();
                const monthDiff = today.getMonth() - birthDate.getMonth();
                const dayDiff = today.getDate() - birthDate.getDate();
          
                // Check if user is less than 18 years old
                if (age < 18 || (age === 18 && (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)))) {
                  return "You must be at least 18 years old";
                }
                return true;
              }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                size="small"
                type="date"
                InputProps={{
                  style: {
                    borderRadius: "14px",
                    height: "37px",
                  },
                }}
                error={!!errors.dob}
                helperText={errors.dob ? errors.dob.message : ""}
              />
            )}
          >
            {" "}
          </Controller>
        </Grid>

        <Grid item xs={12} lg={4} md={3} sm={4}>
          <Typography variant="h5">Date of Joining </Typography>
          <Controller
            name="joining_date"
            control={control}
            defaultValue={today}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                size="small"
                type="date"
                InputProps={{
                  style: {
                    borderRadius: "14px",
                    height: "37px",
                  },
                }}
              />
            )}
          ></Controller>
        </Grid>

        {/* Designation */}
        <Grid item xs={12} lg={4} md={3} sm={4}>
          <Typography variant="h5">
            Designation{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.2em",
              }}
            >
              *
            </span>
          </Typography>

          <Controller
            name="designation"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "14px",
                    height: "37px",
                  },
                }}
                error={errors.designation}
                helperText={errors.designation && "Designation is required"}
              />
            )}
          ></Controller>
        </Grid>

        {/* Select Role */}
        <Grid item xs={12} lg={4} md={3} sm={4}>
          <FormControl fullWidth error={errors.role}>
            <Typography variant="h5">
              Select Role{" "}
              <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "4px",
                  fontSize: "1.2em",
                }}
              >
                *
              </span>
            </Typography>
            <Controller
              name="role"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  multiple
                  disablePortal
                  value={role}
                  onChange={handleRoleChange}
                  options={roleList}
                  getOptionLabel={(option) => option.role_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "14px" }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              )}
            ></Controller>
            {errors.role && <FormHelperText>Role is required</FormHelperText>}
          </FormControl>
        </Grid>
        {/* Branch  */}
        <Grid item xs={12} lg={4} md={3} sm={4}>
          <FormControl fullWidth error={errors.branch}>
            <Typography variant="h5">
              Branch{" "}
              <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "4px",
                  fontSize: "1.2em",
                }}
              >
                *
              </span>
            </Typography>

            <Controller
              name="branch"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  disablePortal
                  value={branchName}
                  onChange={handleBranchChange}
                  options={BranchList}
                  getOptionLabel={(option) => option.branch_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "14px" }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              )}
            ></Controller>
            {errors.branch && (
              <FormHelperText>Branch is required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* Branch  Manager   */}
        <Grid item xs={12} lg={4} md={3} sm={4}>
        <FormControl fullWidth >
          <Typography variant="h5">Branch Manager </Typography>

          <Controller
            name="branch_manager"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                size="small"
                disablePortal
                value={branchManager}
                onChange={handleBranchManagerChange}
                options={userList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : " "} ${
                    option.last_name ? option.last_name : " "
                  }`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "14px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            )}
          ></Controller> </FormControl>
        </Grid>

        {/* Reporting  Manager  */}
        <Grid item xs={12} lg={4} md={3} sm={4}>
          <Typography variant="h5">Reporting Manager  <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "4px",
                  fontSize: "1.2em",
                }}
              >
                *
              </span></Typography>

          <Controller
            name="reporting_manager"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                size="small"
                multiple
                disablePortal
                value={reportingManager}
                onChange={handlereportManagerChange}
                options={reportingManagerList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : " "} ${
                    option.last_name ? option.last_name : " "
                  }`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "14px" }, // Add borderRadius here
                    }}

                    error={errors.reporting_manager}
                helperText={errors.reporting_manager && "Reporting manager is required"}
                  />
                )}
              />
            )}
          ></Controller>
        </Grid>

        {/* Work On Country */}
        {/* <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Work On Country{" "}
                </Typography>

                <Autocomplete
                  size="small"
                  multiple
                  disablePortal
                  value={country}
                  onChange={handleCountryChange}
                  options={countryList}
                  getOptionLabel={(option) => option.country_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "14px" }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid> */}

        {/* Work On Service */}
        {/* <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Work On Service{" "}
                </Typography>
               
                <Autocomplete
                  size="small"
                  multiple
                  disablePortal
                  value={service}
                  onChange={handleServiceChange}
                  options={serviceList}
                  getOptionLabel={(option) => option.service_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "14px",  }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid> */}

        {/* Work On SubService */}
        {/* <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Work On SubService{" "}
                </Typography>
                
                <Autocomplete
                  size="small"
                  multiple
                  disablePortal
                  value={subService}
                  onChange={handleSubServiceChange}
                  options={subServiceList}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "14px",  }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid> */}

        {/*  Branch Type */}
        {/* <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Branch Type{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  InputProps={{
                    style: {
                      borderRadius: "14px",
                    },
                  }}
                />
              </Grid> */}

        {/*Work On Branch */}
        {/* <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Work On Branch{" "}
                </Typography>
                
                  
                <Autocomplete
                  size="small"
                  multiple
                  disablePortal
                  value={workBranch}
                  onChange={handleWorkBranchChange}
                  options={BranchList}
                  getOptionLabel={(option) => option.branch_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "14px",  }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid> */}

        {/*Report CC User */}
        <Grid item xs={12} lg={4} md={3} sm={4}>
          <Typography variant="h5">Select Report CC User </Typography>
          <Controller
            name="add_report_cc_user"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                size="small"
                disablePortal
                multiple
                value={reportUser}
                onChange={handlereportCCChange}
                options={alluserList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : " "} ${
                    option.last_name ? option.last_name : " "
                  } ${option.email ? `:- ${option.email}` : " "}`
                }
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      {`${option.first_name ? option.first_name : " "} ${
                        option.last_name ? option.last_name : " "
                      } ${option.email ? `:- ${option.email}` : " "}`}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "14px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            )}
          ></Controller>
        </Grid>
        {selectedccUser.includes("Other") && (
          <Grid item xs={12} lg={4} md={3} sm={4}>
            <Typography variant="h5">Add Report CC User </Typography>
            <Controller
              name="add_report_cc_user"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  value={reportUser1}
                  onChange={handlereportCCChange1}
                  InputProps={{
                    style: {
                      borderRadius: "14px",
                      height: "37px",
                    },
                  }}
                />
              )}
            ></Controller>
          </Grid>
        )}

        <Grid item xs={12} textAlign="end">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
