import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  Input,
  InputBase,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import JoditEditor from "jodit-react";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import BulkEmailPopup from "./BulkEmailPopup";
import CloseIcon from '@mui/icons-material/Close';
import BulkSubjectForm from "./BulkSubjectForm";
import BulkTemplatepopup from "./BulkTemplatepopup";
import BulkTemplateForm from "./BulkTemplateForm";


import { useSelector } from "react-redux";
import MainCard from "../../../components/Maincard";
import CustomSnackbar from "../ui-component/snackbar/CustomSnackbar";

const PATH_URL = process.env.REACT_APP_API_UPLOADS;

export default function BulkMailform({ selectedId, handelclose }) {
  console.log("selectedid",selectedId)
  const totalrecipients=selectedId.reduce((a,b)=>a+1 ,0)
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  // const enquiryid = enquiryData.id;
  // const receiverMail = enquiryData.email;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [userlist, setUserlist] = useState([]);
const [senderEmail,setSenderEmail]=useState(null)
  const [ccFields, setCCFields] = useState([]);
  const [bccFields, setBccFields] = useState([]);
  const [selectedData, setSelectedData] = useState("");
  const [sendermail, setSendermail] = useState("");
  const [recvmail, setRecvmail] = useState([]);
  const [subject, setSubject] = useState(null);
  const [ccAddresses, setCcAddresses] = useState([]);
  const [bccAddresses, setBccAddresses] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [subjectpopup, setSubjectpopup] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const [files, SetFiles] = useState([]);

  const [isEditing, setIsEditing] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
   
  };

  const handleEmailChange = (event) => {
    setSelectedEmail(event.target.value);
  };
  
 useEffect(()=> {
     
    fetch(`${API_ENDPOINT}route/profile/${userId}`)
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 404) {
          throw new Error("Couldn't find profile!!");
        } else if (res.status === 400) {
          throw new Error("Oops! This is a student!!");
        } else {
          throw new Error(`Unexpected status code: ${res.status}`);
        }
      })
      .then(data => {
        console.log(data);
        setSenderEmail(data);
        setSelectedEmail(data?.email)
      })
      .catch(error => console.error('Error fetching advisor data:', error));
    },[userId])

   


    const { first_name, last_name, role, branch_id ,email} = senderEmail || {};

  console.log(files);
  // Function to handle file selection
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Function to handle file upload
  // Function to handle file upload
  const handleFileUpload = () => {
    // Perform upload logic here

    if (!selectedFile) {
      showSnackbar("Please choose a file before uploading.", "error");
      return; // Exit the function if no file is selected
    }

    SetFiles([...files, selectedFile]);
    const fileInput = document.getElementById("file-input");
    if (fileInput) {
      fileInput.value = ""; // Reset the value to clear the selected file
    }
    setSelectedFile(null)
  };

  const handleFileDownload = (fileName) => {
    // Create a Blob object for the file data

    console.log([fileName.name]);
    const blob = new Blob([fileName], { type: fileName.type }); // Replace "Sample file content" with your actual file content

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName.name;
    document.body.appendChild(link);

    // Trigger the click event
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleFileRemove = (fileName) => {
    // Filter out the file to be removed from the files array
    const updatedFiles = files.filter(file => file !== fileName);
    // Update the files state with the updated array
    SetFiles(updatedFiles);
  };
  
  const handlesubjectopen = () => {
    setSubjectpopup(true);
  };

  const handlesubjectclose = () => {
    setSubjectpopup(false);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  // add 1 more cc text field
  const addTextField = () => {
    setCcAddresses([...ccAddresses, ""]);
  };

  const handleCcAddressChange = (index, value) => {
    const updatedCcAddresses = [...ccAddresses];
    updatedCcAddresses[index] = value;
    setCcAddresses(updatedCcAddresses);
  };

  // add 1 more Bcc text field

  const addbccTextField = () => {
    setBccAddresses([...bccAddresses, ""]);
  };

  const handleBccAddressChange = (index, value) => {
    const updatedBccAddresses = [...bccAddresses];
    updatedBccAddresses[index] = value;
    setBccAddresses(updatedBccAddresses);
  };

  const handelsender = (e) => {
    setSendermail(e.target.value);
  };
  const handelreceiver = (e) => {
    setRecvmail(e.target.value);
  };
  const handleSelectedData = async (data, subject, attachments) => {
    if (Array.isArray(data)) {
      setSelectedData(data.join(""));
    } else {
      setSelectedData(data);
    }
    if (subject) {
      setSubject(subject); // Set the subject to email_subject
    }

     // Handle attachments (assume attachments is an array of file paths or URLs)
  if (attachments && attachments.length > 0) {
    const attachmentFiles = await Promise.all(
      attachments.map(async (attachment) => {
        try {
          // Construct the full path for each attachment
          const fileUrl = `${PATH_URL}emailTemplateAttachments/${attachment.file_uploaded}`;
          
          const response = await fetch(fileUrl);
          const blob = await response.blob();
          
          // Create a new file object from the blob
          const file = new File([blob], attachment.file_name, { type: blob.type });
          
          return file;
        } catch (error) {
          console.error("Error fetching attachment:", error);
          return null; // Handle error or return null
        }
      })
    );

    // Filter out any nulls in case fetching an attachment failed
    const validFiles = attachmentFiles.filter(file => file !== null);

    // Append the files to the SetFiles array
    SetFiles([...files, ...validFiles])
  }
  };

 

  const handlesubject = (e) => {
    setSubject(e.target.value);
  };
  const [opentemplatePopup, setOpentemplatepopup] = useState(false);
  const handelopentemplate = () => {
    setOpentemplatepopup(true);
  };

  const handelclosetemplatepopup = () => {
    setOpentemplatepopup(false);
  };

  console.log(selectedData);
  const [editorStates, setEditorStates] = useState("");

  const editor = useRef(null);

  const parser = new DOMParser();
  const htmlString = "<strong>Beware of the leopard</strong>";
  const doc3 = parser.parseFromString(htmlString, "text/html");
  const defaultEmail = doc3.body.firstChild.textContent;

  const config = {
    readonly: false,
    toolbarAdaptive: false,
    buttons: [
      "undo",
      "redo",
      "font",
      "fontsize",
      "bold",
      "italic",
      "underline",
      "eraser",
      "brush",
      "link",
      "ul",
      "ol",
      "indent",
      "outdent",
      "left",
      "fullsize",
      "source",
    ],
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
  };
  const handleEditorChange = (newContent) => {
    setEditorStates(newContent); // Update the editorStates
  };

  useEffect(() => {
    if (selectedData !== "") {
      if (Array.isArray(selectedData)) {
        setEditorStates(selectedData.join("")); // Join array elements into a string
      } else {
        setEditorStates(selectedData); // Otherwise, set it directly
      }
    }
  }, [selectedData]);

  console.log(editorStates);
  const editorComponents = useMemo(
    () => (
      <Box>
        <Typography
          sx={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: 500 }}
        >
          Body
        </Typography>
        <JoditEditor
          // key={index}
          ref={editor}
          value={editorStates}
          config={config}
          tabIndex={1}
          onBlur={(newContent) => handleEditorChange(newContent)}
        />
      </Box>
    ),
    [editorStates]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();

          setUserlist(data);
          console.log(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleccchange = (e) => {
    setCCFields(e.target.value);
  };

  const handleBccchange = (e) => {
    setBccFields(e.target.value);
  };

  console.log("ids", ...selectedId)
  console.log("ids",...ccFields)
  const handleSubmitEmail = () => {
    if (!subject) {
      handlesubjectopen();
    } else {
      sendEmail();
    }
  };

  

  const sendEmail = () => {
    if(editorStates===""){
      showSnackbar("Email body is required", 'error')
      return
    }

    setIsLoading(true)

    const formdata = new FormData();
  formdata.append("sender_email", selectedEmail);
 
   formdata.append("subject", subject);
  formdata.append("email_body", editorStates);
  // formdata.append("enquiry_id", enquiryid);
  formdata.append("user_id", userId);

  // Push ccFields and ccAddresses into cc[] in FormData
[...ccFields, ...ccAddresses].forEach((ccEmail) => {
  console.log(ccEmail,"ids")
  formdata.append('cc[]', ccEmail);
});

// Append bcc if not empty and valid
[...bccFields, ...bccAddresses].forEach((bccEmail) => {
  formdata.append('bcc[]', bccEmail);
});

[...selectedId].forEach((ids) => {
  console.log(ids,"ids")
  formdata.append('receiver_email[]', ids);
});

  // Append uploaded files to FormData
  files.forEach((file) => {
    formdata.append("attachments", file);
  });


  fetch(`${API_ENDPOINT}route/sendingbulkemail`, {
    method: "POST",
    body: formdata,
  })
      .then((response) => {
        if (response.ok) {
          console.log("Email sent successfully.");
          setIsLoading(false)
          showSnackbar("Email sent successfully");

          setTimeout(() => {
            handelclose()
          }, 1500);
       
          /*setTimeout(() => {
            window.location.reload(); // Call your handleClose function here
          }, 1500);*/
        } else if (response.status === 404) {
          console.error("Error: Not Found");
          setIsLoading(false)
          // Handle 404 error
          showSnackbar("Error Sending Mail", 'error');
        } 
        else if (response.status === 500) {
          console.error("Error: Internal Server Error", "error");
          setIsLoading(false)
          // Handle 500 error
          showSnackbar("Internal Server Error", "error");
        } else {
          console.error("Unknown Error");
          setIsLoading(false)
          // Handle other errors
          showSnackbar("Error Sending Mail", 'error');
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false)
        // Handle network errors or other issues
        showSnackbar("Server Error", 'error');
      });
  };

       

  return (
    <>
     <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Stack direction="row" spacing={1}>
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={800}
            marginRight={2}
          >
            Sending Bulk Emails...
          </Typography>
          <CircularProgress color="primary" />
        </Stack>
      </Backdrop>
      <Stack direction="column" spacing={2}>
        <MainCard>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column">
            <Stack display="flex" flexDirection="row" alignItems="center">
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            marginLeft: "10px",
          }}
        >
          Sender's Email:
        </Typography>

        {isEditing ? (
          <Select
            value={selectedEmail}
            onChange={handleEmailChange}
            sx={{ marginLeft: "10px" }}
          >
            {userlist.map((item, index) => (
              <MenuItem key={index} value={item.email}>
                 {`${item.first_name} ${item.last_name ? item.last_name : ''} :- ${item.email}`}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Typography
            color="error"
            sx={{ marginLeft: "10px" }}
            variant="h4"
          >
            {selectedEmail}
          </Typography>
        )}

        {userRole === "data_entry" && (
          <Button
              variant="contained"
              style={{ borderRadius: "24px" }}
              onClick={isEditing ? handleSaveClick : handleEditClick} 
              sx={{ marginLeft: "10px" }}>
            {isEditing ? "Save" : "Edit Email"}
          </Button>
        )}
      </Stack>
              <Stack display="flex" flexDirection="row" alignItems="center">
               
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: 600,
                    marginLeft: "10px",
                  }}
                >
                  Total Recipients :{" "}
                </Typography>

                <Typography
                  color="error"
                  sx={{ marginLeft: "10px" }}
                  variant="h4"
                >
                {totalrecipients}
                </Typography>
              </Stack>
            </Stack>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                style={{ borderRadius: "24px" }}
                endIcon={<InsertDriveFileOutlinedIcon />}
                onClick={handelopentemplate}
              >
                Select a Template
              </Button>

              {/* <Button
                variant="contained"
                style={{ borderRadius: "24px", marginTop: "10px" }}
                endIcon={<InsertDriveFileOutlinedIcon />}
              >
                Add Calendar Instruction
              </Button> */}
            </Box>
          </Stack>
        </MainCard>

        <MainCard>
          <Stack spacing={2}>
            <Typography
              sx={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: 500 }}
            >
              Email CC
            </Typography>

            <FormControl sx={{ mb: 1, width: "100%" }}>
              <InputLabel htmlFor="outlined-select-branch">
                Add User EmailCC
              </InputLabel>
              <Select
                multiple
                input={<OutlinedInput label="Add User EmailCC" />}
                renderValue={(selected) =>
                  selected
                    .map((value) => {
                      const statusValue = userlist.find(
                        (status) => status.email === value
                      );
                      return statusValue ? statusValue.email : "";
                    })
                    .join(", ")
                }
                value={ccFields}
                MenuProps={MenuProps}
                onChange={handleccchange}
                style={{ borderRadius: "30px" }}
              >
                {userlist.map((item) => (
                  <MenuItem key={item.id} value={item.email}>
                    <Checkbox checked={ccFields.indexOf(item.email) > -1} />
                    <ListItemText
                      primary={`${item.first_name} ${item.last_name} :- ${item.email}`}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {ccAddresses.map((value, index) => (
              <TextField
                key={index}
                id={`outlined-basic-${index}`}
                label={`Email CC`}
                variant="outlined"
                fullWidth
                value={value}
                onChange={(e) => handleCcAddressChange(index, e.target.value)}
                sx={{ marginTop: index === 0 ? "10px" : "5px" }}
                InputProps={{
                  style: {
                    borderRadius: "30px",
                  },
                }}
              />
            ))}
            <Box textAlign="right">
              <Button
                variant="contained"
                color="secondary"
                style={{ borderRadius: "24px", marginTop: "5px" }}
                onClick={addTextField}
              >
                Add CC Address
              </Button>
            </Box>
          </Stack>

          <Stack spacing={2}>
            <Typography
              sx={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: 500 }}
            >
              Email BCC
            </Typography>
            <FormControl sx={{ mb: 1, width: "100%" }}>
              <InputLabel htmlFor="outlined-select-branch">
                Add User EmailBCC
              </InputLabel>
              <Select
                multiple
                input={<OutlinedInput label="Add User EmailBCC" />}
                renderValue={(selected) =>
                  selected
                    .map((value) => {
                      const statusValue = userlist.find(
                        (status) => status.email === value
                      );
                      return statusValue ? statusValue.email : "";
                    })
                    .join(", ")
                }
                value={bccFields}
                onChange={handleBccchange}
                MenuProps={MenuProps}
                style={{ borderRadius: "30px" }}
              >
                {userlist.map((item) => (
                  <MenuItem key={item.id} value={item.email}>
                    <Checkbox checked={bccFields.indexOf(item.email) > -1} />
                    <ListItemText
                      primary={`${item.first_name} ${item.last_name} :- ${item.email}`}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {bccAddresses.map((value, index) => (
              <TextField
                key={index}
                id={`outlined-basic-${index}`}
                label={`Email BCC`}
                variant="outlined"
                fullWidth
                value={value}
                onChange={(e) => handleBccAddressChange(index, e.target.value)}
                sx={{ marginTop: index === 0 ? "10px" : "5px" }}
                InputProps={{
                  style: {
                    borderRadius: "30px",
                  },
                }}
              />
            ))}
            <Box textAlign="right">
              <Button
                variant="contained"
                color="secondary"
                style={{ borderRadius: "24px", marginTop: "5px" }}
                onClick={addbccTextField}
              >
                Add BCC Address
              </Button>
            </Box>
          </Stack>
        </MainCard>

        <MainCard>
          <Typography
            sx={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: 500 }}
          >
            Email Attachment
          </Typography>
          <InputBase
            type="file"
            id="file-input"
            inputProps={{
              onChange: handleFileSelect,
              style: { outline: "none", border: "none" },
            }}
            fullWidth
          />

          <Button
            variant="contained"
            color="secondary"
            style={{ borderRadius: "20px", marginTop: "10px" }}
            startIcon={<FileUploadIcon />}
            onClick={handleFileUpload}
          >
            Upload Attachment
          </Button>

          <Box>
      {files && (
        <>
          {files.map((file) => (
            <Box
              key={file}
              sx={{
                position: 'relative', // Ensure positioning context
                height: '40px',
                margin: '10px',
                border: '1px solid #D5D5D5',
                display: 'inline-block',
                padding: '5px',
                
              }}
            >
              {/* Close icon positioned at the top left corner */}
              <CloseIcon
                sx={{
                  position: 'absolute',
                  top: 0,
                 right: 0,
                  cursor: 'pointer',
                  zIndex: 1, 
                  fontSize:'small'
                }}
                 onClick={() => handleFileRemove(file)} // Adjust the function to handle file removal
              />
              <Typography onClick={() => handleFileDownload(file)} mt={1}>
                {file.name}
              </Typography>
            </Box>
          ))}
        </>
      )}
    </Box>

          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 500,
              marginTop: "10px",
            }}
          >
            Subject
          </Typography>

          <TextField
            variant="outlined"
            fullWidth
            value={subject}
            onChange={handlesubject}
            sx={{ marginTop: "10px" }}
            InputProps={{
              style: {
                borderRadius: "30px",
              },
            }}
          />
        </MainCard>

        <MainCard>
          {/* <Button
            variant="contained"
            color="primary"
            style={{ borderRadius: "20px", marginTop: "10px", color: "white" }}
            startIcon={<EditIcon />}
          >
            Add Signature
          </Button> */}

          <Box marginTop={5}>{editorComponents}</Box>
        </MainCard>

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            endIcon={<DeleteIcon />}
            variant="contained"
            color="error"
            style={{ borderRadius: "24px", marginRight: "7px" }}
          >
            Clear
          </Button>

          <Button
            endIcon={<SendIcon />}
            variant="contained"
            color="primary"
            style={{ borderRadius: "24px" }}
            onClick={handleSubmitEmail}
          >
            Send Email
          </Button>
        </Box>
      </Stack>

      <BulkTemplatepopup
        open={opentemplatePopup}
        handelopentemplate={handelopentemplate}
        handelclosetemplatepopup={handelclosetemplatepopup}
        component={
          <BulkTemplateForm
            onSelectedData={handleSelectedData}
            handelclosetemplatepopup={handelclosetemplatepopup}
            showSnackbar={showSnackbar}
          />
        }
      />
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      <BulkEmailPopup
        handelopen={handlesubjectopen}
        open={subjectpopup}
        handelclose={handlesubjectclose}
        component={
          <BulkSubjectForm
            // enquiryData={enquiryData}
            handelclose={handlesubjectclose}
            sendEmail={sendEmail}
          />
        }
        maxWidth="xs"
      />
    </>
  );
}
