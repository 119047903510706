// Author : Ayesha Shaikh
// Created on 22 aug 2024
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DownloadIcon from "@mui/icons-material/Download";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import MainCard from "../../../components/Maincard";
import SearchIcon from "@mui/icons-material/Search";
import CustomPopup from "../../../components/CustomPopup";
import SendAdvisorReport from "./SendAdvisorReport";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function AdvisorWorkReport({
  showSnackbar,
  setIsLoading,
  setIsDemoLoading,
  seteventLoadine,
  setRevenueLoading,
  setEnrolementLoading,
  savedEmailData
}) {
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const [typeofTask, setTypeofTask] = useState("created");
  const [reportForList, setReportForList] = useState([]);
  const [reportFor, setReportFor] = useState(null);
  const [taskData, setTaskData] = useState([]);
  const [taskcreateddata, setTaskCreatedData] = useState([]);
  const [completedData, setCompletedData] = useState([]);
  const [demoData, setDemoData] = useState([]);
  const [enrolementData, setEnrolementData] = useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [openWorkreport, setOpenWorkReport] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEventRegistration, setOpenEventRegistration] = useState(false);
  const [openRevenueCollected, setOpenRevenueCollected] = useState(false);
  const [openEnrolmentDetails, setOpenEnrolmentDetails] = useState(false);
  const [openClassExtension, setOpenClassExtension] = useState(false);
  const [openReportForm, setOpenReportForm] = useState(false);
 

  const [openDemoDetails, setOpenDemoDetails] = useState(false);
  const [filterType, setFilterType] = useState("today");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [errors, setErrors] = useState({
    reportFor: false,
  });

  const selectedData = { filterType, fromDate, toDate, reportFor };
  const handleToggleworkReport = () => {
    setOpenWorkReport(!openWorkreport);
  };




  //Reort For

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route2.0/getadvisors/${userId}`
        );
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          setReportForList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId]);

  const handleReportForChange = (event, value) => {
    setErrors({ ...errors, reportFor: !value });
    setReportFor(value);
  };

  // select for
  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
    setFromDate(null);
    setToDate(null);
  };

  const handleStartDateChange = (e) => {
    setFromDate(e.target.value);
  };
  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const handleViewreport = (id) => {
    if (
      !reportFor &&
      (userRole === "admin" ||
        userRole === "center_admin" ||
        userRole === "data_entry")
    ) {
      setErrors({ reportFor: !reportFor });
      return;
    }
    const url = `/work-report-details`;
    window.open(url, "_blank");
    localStorage.setItem("details", JSON.stringify(selectedData));
  };

  const handleSearchAll = () => {
    if (!open) {
      handleSubmitTask();
    } else if (!openDemoDetails) {
      handleSubmitDemo();
    } else if (!openEventRegistration) {
      handlesubmitevent();
    } else if (!openRevenueCollected) {
      handleSubmitRevenue();
    } else if (!openEnrolmentDetails) {
      handleSubmitEnrolement();
    }
    // else if (!openClassExtension) {
    //   handleopenClass();
    // }
  };

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    marginTop: "20px",
    "& .MuiTableCell-head": {
      backgroundColor: "#abf7b1",
      fontWeight: 600,
      padding: "8px 16px",

      borderRight: "0.1px solid #C3C3C3",
      borderLeft: "0.1px solid #C3C3C3",
      color: "#000",
    },
    "& .MuiTableCell-body": {
      fontSize: "14px",
      fontWeight: 600,
      padding: "8px 16px",
      borderRight: "0.1px solid #C3C3C3",
      borderLeft: "0.1px solid #C3C3C3",
      align: "center",
      "&:nth-of-type(n+2)": {
        color: "blue",
      },
    },
  }));

  const handleClearAll = () => {
    setFilterType("today");
    setFromDate(null);
    setToDate(null);
    setReportFor(null);
    handleClose();
    handleClosedemo();
    handleCloseevent();
    handleCloseRevenue();
    handleCloseEnrolement();
  };

  // task Details

  //open and close accordian

  const handleopen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    setCompletedData([]);
    setTaskCreatedData([]);
  };

  // function for created and completed button , set the type accordingly
  const handlechangeTask = (criteria) => {
    setTypeofTask(criteria);
  };

  //task created column data
  const columns = [
    { field: "type_of_task", headerName: "Tasks" },
    { field: "totalTasks", headerName: "Created" },
  ];

  //task  completed column data
  const columns1 = [
    { field: "type_of_task", headerName: "Tasks" },
    { field: "totalTasks", headerName: "Completed" },
    { field: "contactableTasks", headerName: "Contactable" },
    { field: "nonContactableTasks", headerName: "Uncontactable" },
  ];

  const taskrows = typeofTask === "created" ? taskcreateddata : completedData;
  const taskcolumnsToUse = typeofTask === "created" ? columns : columns1;

  const handleSubmitTask = async (event) => {
    // Handle form submission logic here
    setIsLoading(true);

    const requestData = {
      user_id: parseInt(userId),
      filter: filterType,
      from_date: fromDate || null,
      to_date: toDate || null,
      report_for: reportFor?.user_id || null,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}reports/gettaskdetailsreport`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log(result);
        setTaskData(result);
        const created = result.created?.map((i) => i);
        const completed = result.completed.map((i) => i);
        setTaskCreatedData(created);
        setCompletedData(completed);

        setIsLoading(false);
        handleopen();
      } else if (response.status === 400) {
        showSnackbar("Invalid filter date range", "error");

        setIsLoading(false);
      } else if (response.status === 404) {
        setIsLoading(false);
        showSnackbar("User not found", "error");
      } else if (response.status === 403) {
        setIsLoading(false);
        showSnackbar("Unknown User", "error");
      } else if (response.status === 500) {
        setIsLoading(false);
        showSnackbar("Failed to fetch data", "error");
      } else {
        setIsLoading(false);
        showSnackbar("Error", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
      showSnackbar("Error", "error");
    }
  };

  //demo Details
  // open accordian AND FETCH DEMO DATA
  const handleopendemo = () => {
    setOpenDemoDetails(true);
  };

  const handleSubmitDemo = async () => {
    setIsDemoLoading(true);
    const requestData = {
      user_id: parseInt(userId),
      filter: filterType,
      from_date: fromDate || null,
      to_date: toDate || null,
      report_for: reportFor?.user_id || null,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}reports/getdemodetailsreport`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log(result);
        setDemoData([result]);

        setIsDemoLoading(false);
        handleopendemo();
      } else if (response.status === 400) {
        showSnackbar("Invalid filter date range", "error");

        setIsDemoLoading(false);
      } else if (response.status === 404) {
        setIsDemoLoading(false);
        showSnackbar("User not found", "error");
      } else if (response.status === 403) {
        setIsDemoLoading(false);
        showSnackbar("Unknown User", "error");
      } else if (response.status === 500) {
        setIsDemoLoading(false);
        showSnackbar("Failed to fetch data", "error");
      } else {
        setIsDemoLoading(false);
        showSnackbar("Error", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      setIsDemoLoading(false);
      showSnackbar("Error", "error");
    }
  };

  // close demo accordian
  const handleClosedemo = () => {
    setOpenDemoDetails(false);
    setDemoData([]);
  };

  // demo column
  const demoColumns = [
    { field: "totalDemos", headerName: "Demo Scheduled" },

    { field: "completedDemos", headerName: "Demo Completed" },
    { field: "cancelledDemos", headerName: "Demo Cancelled" },
  ];

  //Event Registration

  const handleopenevent = () => {
    setOpenEventRegistration(true);
  };

  const handleCloseevent = () => {
    setOpenEventRegistration(false);
  };

  const handlesubmitevent = async () => {
    seteventLoadine(true);

    const requestData = {
      user_id: parseInt(userId),
      filter: filterType,
      from_date: fromDate || null,
      to_date: toDate || null,
      report_for: reportFor?.user_id || null,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}reports/geteventregistrationreport`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        let updatedResult;
        updatedResult = {
          ...result,
          registration: "Registration Count", // Add the enrolled key and value here
        };
        setEventData([updatedResult]);
        console.log(updatedResult);
        seteventLoadine(false);
        handleopenevent();
      } else if (response.status === 400) {
        showSnackbar("Invalid filter date range", "error");

        seteventLoadine(false);
      } else if (response.status === 404) {
        showSnackbar("User not found", "error");
        seteventLoadine(false);
      } else if (response.status === 403) {
        seteventLoadine(false);
        showSnackbar("Unknown User", "error");
      } else if (response.status === 500) {
        seteventLoadine(false);
        showSnackbar("Failed to fetch data", "error");
      } else {
        seteventLoadine(false);
        showSnackbar("Error", "error");
        showSnackbar("Error", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      seteventLoadine(false);
    }
  };
  const eventColumns = [
    {
      field: "registration",
      headerName: "Registrations",
    },

    {
      field: "registrationCount",
      headerName: "Total",
    },
  ];

  //Revenue Collected

  const handleopenRevenue = () => {
    setOpenRevenueCollected(true);
  };

  const handleCloseRevenue = () => {
    setOpenRevenueCollected(false);
    setRevenueData([]);
  };

  const handleSubmitRevenue = async () => {
    setRevenueLoading(true);

    const requestData = {
      user_id: parseInt(userId),
      filter: filterType,
      from_date: fromDate || null,
      to_date: toDate || null,
      report_for: reportFor?.user_id || null,
    };

    try {
      const response = await fetch(`${API_ENDPOINT}reports/getrevenuereport`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const result = await response.json();

        setRevenueData([result]);

        setRevenueLoading(false);
        handleopenRevenue();
      } else if (response.status === 400) {
        showSnackbar("Invalid filter date range", "error");

        setRevenueLoading(false);
      } else if (response.status === 404) {
        showSnackbar("User not found", "error");
        setRevenueLoading(false);
      } else if (response.status === 403) {
        seteventLoadine(false);
        showSnackbar("Unknown User", "error");
      } else if (response.status === 500) {
        setRevenueLoading(false);
        showSnackbar("Failed to fetch data", "error");
      } else {
        setRevenueLoading(false);
        showSnackbar("Error", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      setRevenueLoading(false);
      showSnackbar("Error", "error");
    }
  };

  const revenueColumns = [
    {
      field: "revenueCollected",
      headerName: "Revenue Collected",
    },

    {
      field: "revenueCount",
      headerName: "Revenue Count",
    },
  ];

  //Enrollment Details

  const handleopenEnrolement = () => {
    setOpenEnrolmentDetails(true);
  };

  const handleCloseEnrolement = () => {
    setOpenEnrolmentDetails(false);
    setEnrolementData([]);
  };

  const handleSubmitEnrolement = async () => {
    setEnrolementLoading(true);

    const requestData = {
      user_id: parseInt(userId),
      filter: filterType,
      from_date: fromDate || null,
      to_date: toDate || null,
      report_for: reportFor?.user_id || null,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}reports/getenrolmentdetailsreport`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        let updatedResult;
        updatedResult = {
          ...result,
          enrolled: "Enrolled Count", // Add the enrolled key and value here
        };
        setEnrolementData([updatedResult]);
        console.log(updatedResult);
        setEnrolementLoading(false);
        handleopenEnrolement();
      } else if (response.status === 400) {
        showSnackbar("Invalid filter date range", "error");

        setEnrolementLoading(false);
      } else if (response.status === 404) {
        showSnackbar("User not found", "error");
        setEnrolementLoading(false);
      } else if (response.status === 403) {
        setEnrolementLoading(false);
        showSnackbar("Unknown User", "error");
      } else if (response.status === 500) {
        setEnrolementLoading(false);
        showSnackbar("Failed to fetch data", "error");
      } else {
        setEnrolementLoading(false);
        showSnackbar("Error", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      setEnrolementLoading(false);
      showSnackbar("Error", "error");
    }
  };

  const enrolementColumns = [
    {
      field: "enrolled",
      headerName: "Enrolments",
    },

    {
      field: "enrolledCount",
      headerName: "Total",
    },
  ];

  //Class Extention Details

  const handleopenClass = () => {
    setOpenClassExtension(true);
  };

  const handleCloseClass = () => {
    setOpenClassExtension(false);
  };

  const handleOpenreportform = () => {
  
    setOpenReportForm(true);
  };

  const handleClosereportform = () => {
    setOpenReportForm(false);
  };

  return (
    <>
      <Paper
        elevation={3}
        style={{
          background: "#fffdf7",
          padding: "20px",
          borderRadius: "15px",
        }}
      >
        <Box style={{ marginTop: "-30px" }}>
          <img src="https://sieceducation.in/images/spring.png" width="50%" />

          <img src="https://sieceducation.in/images/spring.png" width="50%" />
        </Box>

        <Grid container spacing={userRole === "advisor" ? 4 : 2}>
          <Grid item xs={9} lg={9} sm={9} md={9} mt={4}>
            <Box
              sx={{
                display: "flex",
                direction: "row",
                alignItems: "center",
              }}
            >
              <img src="https://sieceducation.in/images/pin.png" width="2%" />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "20px",
                  color: "#445377",
                }}
                ml={1}
              >
                {userRole === "admin" ||
                userRole === "center_admin" ||
                userRole === "data_entry"
                  ? " Advisor Work Report"
                  : "My Work Diary"}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={3} lg={3} sm={3} md={3} mt={4} textAlign="end">
            {!openWorkreport ? (
              <KeyboardArrowDownIcon
                onClick={handleToggleworkReport}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <KeyboardArrowUpIcon
                onClick={handleToggleworkReport}
                style={{ cursor: "pointer" }}
              />
            )}
          </Grid>

          {openWorkreport && (
            <>
              {(userRole === "center_admin" ||
                userRole === "data_entry" ||
                userRole === "admin") && (
                <Grid item xs={12} lg={2.5} md={6} sm={6}>
                  <Typography sx={{ fontWeight: 600 }}>
                    Select Report For
                  </Typography>

                  <Autocomplete
                    size="small"
                    value={reportFor}
                    onChange={handleReportForChange}
                    options={reportForList}
                    getOptionLabel={(option) =>
                      `${option.first_name ? option.first_name : ""} ${
                        option.last_name ? option.last_name : ""
                      }`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                  {errors.reportFor && (
                    <Typography color="error" variant="caption">
                      Advisor Name is required
                    </Typography>
                  )}
                </Grid>
              )}

              <Grid item xs={12} lg={2} md={3} sm={3}>
                <Typography sx={{ fontWeight: 600 }}>Select For</Typography>
                <Select
                  value={filterType}
                  onChange={handleFilterChange}
                  fullWidth
                  style={{ borderRadius: "15px" }}
                  size="small"
                >
                  <MenuItem value="today">Today</MenuItem>
                  <MenuItem value="yesterday">Yesterday</MenuItem>

                  <MenuItem value="custom">Custom</MenuItem>
                </Select>
              </Grid>

              {filterType === "custom" && (
                <>
                  <Grid item xs={12} lg={2} md={3} sm={3}>
                    <Typography sx={{ fontWeight: 600 }}>From Date</Typography>
                    <TextField
                      value={fromDate}
                      onChange={handleStartDateChange}
                      fullWidth
                      type="date"
                      size="small"
                      InputProps={{ style: { borderRadius: "15px" } }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={2} md={3} sm={3}>
                    <Typography sx={{ fontWeight: 600 }}>To Date</Typography>
                    <TextField
                      value={toDate}
                      onChange={handleToDateChange}
                      fullWidth
                      type="date"
                      size="small"
                      InputProps={{ style: { borderRadius: "15px" } }}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} md={3.5} xl={2.5} sm={6} lg={2.8}>
                <Typography sx={{ fontWeight: 600, visibility: "hidden" }}>
                  for spacing
                </Typography>
                <Button
                  variant="contained"
                  sx={{ borderRadius: "25px" }}
                  onClick={handleSearchAll}
                  startIcon={<SearchIcon />}
                >
                  Search
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ marginLeft: "10px", borderRadius: "25px" }}
                  onClick={handleClearAll}
                  startIcon={<ClearIcon />}
                >
                  Clear{" "}
                </Button>
              </Grid>

              <Grid
                item
                xs={12}
                md={
                  filterType === "custom" && userRole === "advisor"
                    ? 8
                    : filterType === "custom" && userRole !== "advisor"
                    ? 12
                    : filterType !== "custom" && userRole === "advisor"
                    ? 5
                    : filterType !== "custom" && userRole !== "advisor"
                    ? 8
                    : 12
                }
                lg={
                  filterType === "custom" && userRole === "advisor"
                    ? 12
                    : filterType === "custom" && userRole !== "advisor"
                    ? 12
                    : filterType !== "custom" && userRole === "advisor"
                    ? 5
                    : filterType !== "custom" && userRole !== "advisor"
                    ? 4.3
                    : 12
                }
                xl={
                  filterType === "custom" && userRole === "advisor"
                    ? 3.5
                    : filterType === "custom" && userRole !== "advisor"
                    ? 12
                    : filterType !== "custom" && userRole === "advisor"
                    ? 7
                    : 5
                }
                sm={6}
                textAlign="end"
              >
                <Typography sx={{ fontWeight: 600, visibility: "hidden" }}>
                  for spacing
                </Typography>

                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    marginLeft: "10px",
                    borderRadius: "25px",
                    fontWeight: 600,
                  }}
                  startIcon={<DownloadIcon />}
                  onClick={handleViewreport}
                >
                  Download Report{" "}
                </Button>

                <Button
                  variant="contained"
                  sx={{
                    marginLeft: "10px",
                    borderRadius: "25px",
                    fontWeight: 600,
                  }}
                  endIcon={<img src="/icons8-send-64.png" width="20px" />}
                  onClick={() => handleOpenreportform()}
                >
                  Send Report{" "}
                </Button>
              </Grid>

              {/* Task Details */}

              <Grid item xs={12} lg={12} sm={12} md={12}>
                <MainCard style={{ border: "0.5px solid #e7e3e3" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={9} md={9} sm={9} lg={9}>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 600,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          open ? handleClose() : handleSubmitTask()
                        }
                      >
                        Task Details
                      </Typography>
                    </Grid>
                    <Grid item xs={3} md={3} sm={3} lg={3} textAlign="end">
                      {open ? (
                        <ArrowDropUpIcon
                          onClick={() => handleClose()}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <ArrowDropDownIcon
                          onClick={() => handleSubmitTask()}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </Grid>

                    {open && (
                      <>
                        <Grid item xs={12} md={12} sm={12} lg={12}>
                          <Button
                            style={{
                              borderRadius: "25px",
                              background:
                                typeofTask === "created" ? "#8ed994" : "",
                              color:
                                typeofTask === "created"
                                  ? "#2a603b"
                                  : "#7e7d7d",
                              fontWeight: 500,
                              fontSize: "16px",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                              border:
                                typeofTask === "completed"
                                  ? "1px solid #7e7d7d"
                                  : "",
                            }}
                            onClick={() => handlechangeTask("created")}
                          >
                            Created Task
                          </Button>
                          <Button
                            style={{
                              marginLeft: "10px",
                              borderRadius: "25px",
                              background:
                                typeofTask === "completed" ? "#8ed994" : "",
                              color:
                                typeofTask === "completed"
                                  ? "#2a603b"
                                  : "#7e7d7d",
                              fontWeight: 500,
                              fontSize: "16px",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                              border:
                                typeofTask === "created"
                                  ? "1px solid #7e7d7d"
                                  : "",
                            }}
                            onClick={() => handlechangeTask("completed")}
                          >
                            Completed Task
                          </Button>
                        </Grid>

                        <Grid item xs={12} lg={12} sm={12} md={12}>
                          {taskcreateddata.length === 0 ||
                          completedData.length === 0 ? (
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              NO Data Available
                            </Typography>
                          ) : (
                            <StyledTableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    {taskcolumnsToUse.map((column) => (
                                      <TableCell
                                        key={column.field}
                                        align="center"
                                      >
                                        {column.headerName}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {taskrows.map((row, index) => (
                                    <TableRow key={index}>
                                      {taskcolumnsToUse.map((column) => (
                                        <TableCell
                                          key={column.field}
                                          align="center"
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: "14px",
                                              fontWeight: 600,
                                            }}
                                          >
                                            {row[column.field]}
                                          </Typography>
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </StyledTableContainer>
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </MainCard>
              </Grid>

              {/* Demo Details */}
              <Grid item xs={12} lg={12} sm={12} md={12}>
                <MainCard style={{ border: "0.5px solid #e7e3e3" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={9} md={9} sm={9} lg={9}>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 600,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          openDemoDetails
                            ? handleClosedemo()
                            : handleSubmitDemo()
                        }
                      >
                        Demo Details
                      </Typography>
                    </Grid>
                    <Grid item xs={3} md={3} sm={3} lg={3} textAlign="end">
                      {openDemoDetails ? (
                        <ArrowDropUpIcon
                          onClick={() => handleClosedemo()}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <ArrowDropDownIcon
                          onClick={() => handleSubmitDemo()}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </Grid>

                    {openDemoDetails && (
                      <>
                        <Grid item xs={12} lg={12} sm={12} md={12}>
                          {demoData.length === 0 ? (
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              NO Data Available
                            </Typography>
                          ) : (
                            <StyledTableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    {demoColumns.map((column) => (
                                      <TableCell
                                        key={column.field}
                                        align="center"
                                      >
                                        {column.headerName}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {demoData.map((row, index) => (
                                    <TableRow key={index}>
                                      {demoColumns.map((column) => (
                                        <TableCell
                                          key={column.field}
                                          align="center"
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: "14px",
                                              fontWeight: 600,
                                              color: "blue",
                                            }}
                                          >
                                            {row[column.field]}
                                          </Typography>
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </StyledTableContainer>
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </MainCard>
              </Grid>

              {/* Event Registration */}

              <Grid item xs={12} lg={12} sm={12} md={12}>
                <MainCard style={{ border: "0.5px solid #e7e3e3" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={9} md={9} sm={9} lg={9}>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 600,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          openEventRegistration
                            ? handleCloseevent()
                            : handlesubmitevent()
                        }
                      >
                        Event Registration
                      </Typography>
                    </Grid>
                    <Grid item xs={3} md={3} sm={3} lg={3} textAlign="end">
                      {openEventRegistration ? (
                        <ArrowDropUpIcon
                          onClick={() => handleCloseevent()}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <ArrowDropDownIcon
                          onClick={() => handlesubmitevent()}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </Grid>

                    {openEventRegistration && (
                      <>
                        <Grid item xs={12} lg={12} sm={12} md={12}>
                          {eventData.length === 0 ? (
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              NO Data Available
                            </Typography>
                          ) : (
                            <StyledTableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    {eventColumns.map((column) => (
                                      <TableCell
                                        key={column.field}
                                        align="center"
                                      >
                                        {column.headerName}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {eventData.map((row, index) => (
                                    <TableRow key={index}>
                                      {eventColumns.map((column) => (
                                        <TableCell
                                          key={column.field}
                                          align="center"
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: "14px",
                                              fontWeight: 600,
                                            }}
                                          >
                                            {row[column.field]}
                                          </Typography>
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </StyledTableContainer>
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </MainCard>
              </Grid>

              {/* Revenue Colleceted */}

              <Grid item xs={12} lg={12} sm={12} md={12}>
                <MainCard style={{ border: "0.5px solid #e7e3e3" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={9} md={9} sm={9} lg={9}>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 600,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          openRevenueCollected
                            ? handleCloseRevenue()
                            : handleSubmitRevenue()
                        }
                      >
                        Revenue Collected
                      </Typography>
                    </Grid>
                    <Grid item xs={3} md={3} sm={3} lg={3} textAlign="end">
                      {openRevenueCollected ? (
                        <ArrowDropUpIcon
                          onClick={() => handleCloseRevenue()}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <ArrowDropDownIcon
                          onClick={() => handleSubmitRevenue()}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </Grid>

                    {openRevenueCollected && (
                      <>
                        <Grid item xs={12} lg={12} sm={12} md={12}>
                          {revenueData.length === 0 ? (
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              NO Data Available
                            </Typography>
                          ) : (
                            <StyledTableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    {revenueColumns.map((column) => (
                                      <TableCell
                                        key={column.field}
                                        className="header"
                                        align="center"
                                      >
                                        {column.headerName}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {revenueData.map((row, index) => (
                                    <TableRow key={index}>
                                      {revenueColumns.map((column) => (
                                        <TableCell
                                          key={column.field}
                                          align="center"
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: "14px",
                                              fontWeight: 600,
                                              color: "blue",
                                            }}
                                          >
                                            {row[column.field]}
                                          </Typography>
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </StyledTableContainer>
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </MainCard>
              </Grid>

              {/* Enrolement Details */}

              <Grid item xs={12} lg={12} sm={12} md={12}>
                <MainCard style={{ border: "0.5px solid #e7e3e3" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={9} md={9} sm={9} lg={9}>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 600,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          openEnrolmentDetails
                            ? handleCloseEnrolement()
                            : handleSubmitEnrolement()
                        }
                      >
                        Enrolment Details
                      </Typography>
                    </Grid>
                    <Grid item xs={3} md={3} sm={3} lg={3} textAlign="end">
                      {openEnrolmentDetails ? (
                        <ArrowDropUpIcon
                          onClick={() => handleCloseEnrolement()}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <ArrowDropDownIcon
                          onClick={() => handleSubmitEnrolement()}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </Grid>

                    {openEnrolmentDetails && (
                      <>
                        <Grid item xs={12} lg={12} sm={12} md={12}>
                          {enrolementData.length === 0 ? (
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              NO Data Available
                            </Typography>
                          ) : (
                            <StyledTableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    {enrolementColumns.map((column) => (
                                      <TableCell
                                        key={column.field}
                                        align="center"
                                      >
                                        {column.headerName}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {enrolementData.map((row, index) => (
                                    <TableRow key={index}>
                                      {enrolementColumns.map((column) => (
                                        <TableCell
                                          key={column.field}
                                          align="center"
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: "14px",
                                              fontWeight: 600,
                                            }}
                                          >
                                            {row[column.field]}
                                          </Typography>
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </StyledTableContainer>
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </MainCard>
              </Grid>

              {/* Class Details */}
              {/* <Grid item xs={12} lg={12} sm={12} md={12}>
                    <MainCard style={{ border: "0.5px solid #e7e3e3" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={9} md={9} sm={9} lg={9}>
                          <Typography
                            sx={{
                              fontSize: "18px",
                              fontWeight: 600,
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              openClassExtension
                                ? handleCloseClass()
                                : handleopenClass()
                            }
                          >
                            Class Extension details
                          </Typography>
                        </Grid>
                        <Grid item xs={3} md={3} sm={3} lg={3} textAlign="end">
                          {openClassExtension ? (
                            <ArrowDropUpIcon
                              onClick={() => handleCloseClass()}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => handleopenClass()}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </Grid>

                        {openClassExtension && (
                          <>
                            <Grid item xs={12} md={12} sm={12} lg={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography>
                                  No Class Details Available
                                </Typography>
                              </Box>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </MainCard>
                  </Grid> */}
            </>
          )}
        </Grid>
      </Paper>

      <CustomPopup
        open={openReportForm}
        onClose={handleClosereportform}
        component={
          <SendAdvisorReport
            onClose={handleClosereportform}
            showSnackbar={showSnackbar}
            selectedData={selectedData}
            savedEmailData={savedEmailData}
          />
        }
        title="Send Report"
        maxWidth="sm"
        showDivider={true}
      />
    </>
  );
}
