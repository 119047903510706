// Author : " Ayesha Shaikh"
// Date : 04 aug 2024

import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import {
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { useNavigate } from "react-router-dom";
import { useGetTokenOrLogout } from "../../../utils/token";
export default function ApprovePaymentFilter({
  setPayments,
  isLoading,
  showSnackbar,
  setIsLoading,
  originalData,
}) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [branchlist, setBranchlist] = useState([]);
  const [branch, setBranch] = useState([]);
  const [fromdate, setfromdate] = useState("");
  const [enddate, setenddate] = useState("");
  const [createdbylist, setCreatedbylist] = useState([]);
  const [createdby, setCreatedby] = useState([]);
  const [status, setStatus] = useState([]);
  const [amount, setAmount] = useState("");
  const [approvedBy, setApprovedBy] = useState([]);
  const [paymentmodelist, setPaymentModeslist] = useState([]);
  const [paymentmode, setPaymentmode] = useState([]);
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleNamechange = (e) => {
    setName(e.target.value);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();

          setBranchlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allbranchList = [{ branch_name: "Select All" }, ...branchlist];

  const handelbranchchange = (event, value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchlist);
    } else {
      setBranch(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          setCreatedbylist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allcreatedByList = [{ first_name: "Select All" }, ...createdbylist];
  const handelcretedby = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setCreatedby(createdbylist);
    } else {
      setCreatedby(value);
    }
  };
  const statuslist = [
    { id: 1, name: "Received", value: "received" },
    {
      id: 2,
      name: "Deposited",
      value: "deposited",
    },
    { id: 3, name: "Confirmed", value: "confirmed" },
  ];

  const handlestatuschange = (event, value) => {
    setStatus(value);
  };

  const handlefromdatechange = (e) => {
    console.log(e.target.value);
    setfromdate(e.target.value);
  };

  const handletodatechange = (e) => {
    console.log(e.target.value);
    setenddate(e.target.value);
  };

  const handleApprovedby = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setApprovedBy(createdbylist);
    } else {
      setApprovedBy(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}paymentmode`);
        if (response.ok) {
          const data = await response.json();
          const activePayments = data.filter((item) => item.is_active === true);
          console.log(activePayments);
          setPaymentModeslist(activePayments);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handlepaymentmode = (event, value) => {
    setPaymentmode(value);
  };

  const newBranch = branch.map((i) => i.id);
  const newcreatedby = createdby.map((i) => i.id);
  const newStatus = status.map((i) => i.value);
  const newPaymentMode = paymentmode.map((i) => i.id);
  const newApprovesBy = approvedBy.map((i) => i.id);

  const handleApplyFilter = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = { 
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json" // Make sure to keep the content type
    };
    const requestData = {
      payment_mode_id: newPaymentMode,

      collected_by: newcreatedby,

      from_date: fromdate || null,
      to_date: enddate || null,
      user_id: parseInt(userId),

      class_id: [],
    };

    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/paymentfilter`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const result = await response.json();

        const sortedData = result.sort(
          (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
        );
        // const filterdData = sortedData.filter(
        //   (item) =>
        //     item.collected_status === "confirmed"

        // );
        setPayments(sortedData);
        setIsLoading(false);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    }catch{
      navigate("/page500");
    }
  };

  const resetfilter = () => {
    setPayments(originalData);
    setName("");
    setBranch([]);

    setCreatedby([]);
    setStatus([]);
    setAmount("");
    setApprovedBy([]);
    setPaymentmode([]);
  };

  const handleamount = (e) => {
    setAmount(e.target.value);
  };
      // Add default 1 month start and end date
const formatDate = (date) => date.toISOString().split('T')[0];
useEffect(() => {
  let oneMonthAgo = new Date();
  oneMonthAgo.setDate(oneMonthAgo.getDate()-30);
  
  
  let today=new Date()
 
  setenddate(formatDate(today));
  setfromdate(formatDate(oneMonthAgo));
}, []);
  return (
    <MainCard>
      <Stack
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            marginLeft: "5px",
            fontSize: "24px",
            fontWeight: 700,
          }}
        >
          Approved Payment
        </Typography>
        <FilterListOutlinedIcon onClick={handleToggle} fontSize="medium" />
      </Stack>

      <Collapse in={open} sx={{ marginTop: "5px" }}>
        <Grid container spacing={2}>
          {/* <Grid item xs={3} lg={3} md={6} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>Enquiry Name </Typography>
              <TextField
              size='small'
              value={name}
              onChange={handleNamechange}
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
                fullWidth
              />
            </Grid> */}

          {/* <Grid item xs={12} lg={3} md={6} sm={6}>
            <Typography sx={{ fontWeight: 600 }}>Branch</Typography>

            <Autocomplete
              size="small"
              fullWidth
              value={branch}
              multiple
              onChange={handelbranchchange}
              options={allbranchList}
              getOptionLabel={(option) => option.branch_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid> */}
          <Grid item xs={12} lg={3} sm={6} md={6}>
            <Typography sx={{ fontWeight: 600 }}> Payment From Date </Typography>
            <TextField
              size="small"
              value={fromdate}
              onChange={handlefromdatechange}
              type="date"
              InputProps={{
                style: { borderRadius: "15px" },
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={3} sm={6} md={6}>
            <Typography sx={{ fontWeight: 600 }}> Payment To Date </Typography>
            <TextField
              size="small"
              value={enddate}
              onChange={handletodatechange}
              type="date"
              InputProps={{
                style: { borderRadius: "15px" },
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={3} md={6} sm={6}>
            <Typography sx={{ fontWeight: 600 }}>Payment Mode</Typography>

            <Autocomplete
              size="small"
              value={paymentmode}
              multiple
              onChange={handlepaymentmode}
              options={paymentmodelist}
              getOptionLabel={(option) => option.payment_mode}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>

          {/* <Grid item xs={12} lg={3} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600 }}>
                {" "}
                Amount Collected{" "}
              </Typography>
              <TextField
                size='small'
                 value={amount}
                 onChange={handleamount}
                type="number"
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
                fullWidth
              />
            </Grid> */}

          {(userRole === "admin" || userRole === "center_admin") && (
            <Grid item xs={12} lg={3} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600 }}>Initiated By </Typography>

              <Autocomplete
                size="small"
                fullWidth
                value={createdby}
                multiple
                onChange={handelcretedby}
                options={allcreatedByList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : ""} ${
                    option.last_name ? option.last_name : ""
                  }`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>
          )}

          {/* <Grid item xs={12} lg={3} sm={6} md={6}>
        
        <Typography sx={{ fontWeight: 600 }}>Approved  By </Typography>
       
    
    <Autocomplete
        size="small"
        fullWidth
        value={approvedBy}
        multiple
        onChange={handleApprovedby}
        options={allcreatedByList}
        getOptionLabel={(option) =>
        `${option.first_name ? option.first_name : ""} ${
            option.last_name ? option.last_name : ""
          }`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { borderRadius: "15px" }, // Add borderRadius here
            }}
          />
        )}
      />
    </Grid> */}
          {/* <Grid item xs={12} lg={3} md={6} sm={6}>
           
           <Typography sx={{ fontWeight: 600 }}>Status</Typography>
          
        <Autocomplete
           size="small"
           fullWidth
           value={status}
           multiple
           onChange={handlestatuschange}
           options={statuslist}
           getOptionLabel={(option) =>
            option.name
           }
           renderInput={(params) => (
             <TextField
               {...params}
               InputProps={{
                 ...params.InputProps,
                 style: { borderRadius: "15px" }, // Add borderRadius here
               }}
             />
           )}
         />
       </Grid> */}

          <Grid item xs={12} lg={12} textAlign="end">
            <Button
              variant="contained"
              color="error"
              style={{
                borderRadius: "24px",
                fontWeight: 500,
                fontSize: "14px",
                marginRight: "8px",
              }}
              onClick={resetfilter}
            >
              Reset Filter
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{
                borderRadius: "24px",
                fontWeight: 500,
                fontSize: "14px",
              }}
              onClick={handleApplyFilter}
            >
              Apply Filter
            </Button>
          </Grid>
        </Grid>
      </Collapse>
    </MainCard>
  );
}
