import { Box, Button, Chip, CircularProgress } from '@mui/material';
import React, { useState } from 'react'
import { Person as PersonIcon } from '@mui/icons-material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { fDate } from '../../utils/formatTime';

function SpecificTaskPage( {tasks, onClose }) {
    const navigate = useNavigate();
    const user_role = localStorage.getItem('userRole');

    console.log(tasks)
    const [tasksData, setTasksData] = useState(tasks)
    

    /*const handleViewMore = (row) => {
        console.log('View More:', row.enquiry_id?.id);
        console.log('View More:', row.demo_id);
    
        if (user_role === 'admin' || user_role === 'trainer') {
            console.log('Task Type:', row.type_of_task.name_of_task_type);
    
            if (row.type_of_task.name_of_task_type === 'Demo request' || 
                row.type_of_task.name_of_task_type === 'Test prep demo' || 
                row.type_of_task.name_of_task_type === 'Evaluation demo') {
                console.log('Navigating to Demo Details');
                if(row.demo_id) {
                   // navigate(`/demodetails/${row.demo_id}`);
                   window.open(`/demodetails/${row.demo_id}`, '_blank');
                    onClose();
                } else {
                    //navigate('/page404')
                    window.open('/page404', '_blank');
                    onClose();
                }
               
            } else {
               // navigate(`/enquirydetails/${row.enquiry_id?.id}`);
                window.open(`/enquirydetails/${row.enquiry_id?.id}`, '_blank');
                onClose()
            }
        } else {
            console.log('Navigating to Enquiry Details');
            //navigate(`/enquirydetails/${row.enquiry_id?.id}`);
            window.open(`/enquirydetails/${row.enquiry_id?.id}`, '_blank');
            onClose()
        }
       
    };*/

    const handleViewMore = (row) => {
      console.log('View More:', row.enquiry_id?.id);
      console.log('View More:', row.demo_id);
  
      if (user_role === 'admin' || user_role === 'trainer') {
          console.log('Task Type:', row.type_of_task.name_of_task_type);
  
          if ([/*'Demo request',*/ 'Test prep demo', 'Evaluation demo'].includes(row.type_of_task.name_of_task_type)) {
              console.log('Navigating to Demo Details');
              if (row.demo_id) {
                  window.open(`/demodetails/${row.demo_id}`, '_blank');
              } else {
                  window.open('/page404', '_blank');
              }
          } else {
              console.log('Navigating to Enquiry Details');
              window.open(`/enquirydetails/${row.enquiry_id?.id}`, '_blank');
          }
      } else {
          console.log('Navigating to Enquiry Details');
          window.open(`/enquirydetails/${row.enquiry_id?.id}`, '_blank');
      }
  

  };
  

      
      const columns = [
        {
            field: 'name',
            headerName: 'Enquiry Name',
            flex:2,
            renderCell: (params) => {
              console.log('Cell Renderer - params.row:', params.row);
              return (
                <div>
                  <Button size='small' onClick={() => handleViewMore(params.row)}>
                    <PersonIcon /> {params.row.enquiry_id?.first_name} {params.row.enquiry_id?.last_name}
                  </Button>
                </div>
              );
            },
            /*renderCell: (params) => (
              <div>
                <Button size='small' onClick={() => handleViewMore(params.row)}>
                <PersonIcon /> {params.row.first_name} {params.row.last_name}
                </Button>
              </div>
            ),*/
          },
    
          { 
            field: 'taskType', 
            headerName: 'Type of Task', 
            flex: 1, 
            filterable: true,
            renderCell: (params) => <div>{params.row.type_of_task?.name_of_task_type}</div>,
           },
           {
            field: 'due_date',
            headerName: 'Due Date',
            flex: 1, 
            renderCell: (params) => <div>{fDate(params.value)}</div>,
          },
          { 
            field: 'stage', 
            headerName: 'Stage', 
            flex: 1, 
            filterable: true,
            renderCell: (params) => <div>{params.row.enquiry_id?.stage_id?.name}</div>,
           },
           { 
            field: 'sub_service_id', 
            headerName: 'Sub Service', 
            flex: 1, 
            filterable: true,
            renderCell: (params) => <div>{params.row.enquiry_id?.sub_service_id?.name}</div>,
           },
           { 
            field: 'taskOwner', 
            headerName: 'Task Owner', 
            flex: 1, 
            filterable: true,
            renderCell: (params) => <div>{params.row.task_owner?.first_name}</div>,
           },
           { 
            field: 'createdBy', 
            headerName: 'Created By', 
            flex: 1, 
            filterable: true,
            renderCell: (params) => <div>{params.row.created_by?.first_name}</div>,
           },
           {
            field: 'createdAt',
            headerName: 'Created Date',
            flex: 1, 
            renderCell: (params) => <div>{fDate(params.value)}</div>,
          },
          {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (params) => {
              let chipColor = '';
              let chipLabel = '';
        
              switch (params.value) {
                case 0:
                  chipColor = 'secondary';
                  chipLabel = 'Closed';
                  break;
                case 1:
                  chipColor = 'error';
                  chipLabel = 'Open';
                  break;
                default:
                  chipColor = 'orange'; 
                  chipLabel = 'Unknown';
              }
        
              return (
                <Chip
                  label={chipLabel}
                  color={chipColor === 'default-color' ? 'default' : chipColor}
                />
              )
            }
            },
          {
            field: 'closedBy', 
            headerName: 'Closed By', 
            flex: 1, 
            filterable: true,
            renderCell: (params) => <div>{params.row.closed_by?.first_name}</div>,
    
           },
           {
            field: 'closed_date',
            headerName: 'Closed Date',
            flex: 1, 
            renderCell: (params) => <div>{fDate(params.value)}</div>,
          },
    ]

  return (
    <>
      
    <div style={{ height: '400', width: '100%' }}>
      <DataGrid
        columns={columns}
        rows={tasksData}
        autoHeight
        pagination
       // pageSize={tasksPerPage}
       // rowCount={tasksPerPage * totalPages}
        //paginationMode="server"
        //onPageChange={handlePageChange}
        slots={{
          toolbar: GridToolbar,
          NoRowsOverlay: () => (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <p>No Tasks available</p>
            </div>
          )
        }}
        slotProps={{
          toolbar: {
              exportButton: {
                csvOptions: {
                  fileName: 'task_report', 
                },
              },
            },
       }}
       sx={{ '--DataGrid-overlayHeight': '300px' ,
       backgroundColor: 'rgba(255,255,255)',
       boxShadow: 2,
       border: 2,
       borderColor: 'primary.light',
       '& .MuiDataGrid-cell:hover': {
         color: 'primary.main',
       },
     }}
      />
    </div>
      
       </>
  )
}

export default SpecificTaskPage