// Author : " Ayesha Shaikh"
// Date : "09 aug 2024"

import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SendTrainerReport({
  showSnackbar,
  selectedData,
  onClose,
  savedEmailData,
}) {
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  console.log("selectedData", savedEmailData);
  const [remark, setRemark] = useState(null);
  const [error, setError] = useState({ remark: false });
  const [isLoading, setIsloading] = useState(false);
  const [ccFields, setCCFields] = useState([]);
  const [toFields, setToFields] = useState(null);
  const [toField1, setToField1] = useState(null);
  const [showTofield, setShowTofield] = useState(false);
  const [ccAddresses, setCcAddresses] = useState([]);
  const [userlist, setUserlist] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();

          setUserlist(data);
          const defaultvalue = data.find(
            (i) => i.email === savedEmailData.to_email
          );
          if (defaultvalue) {
            setToFields(defaultvalue);
          } else if (savedEmailData.length === 0) {
            setShowTofield(false);
          } else {
            setShowTofield(true);
            setToField1(savedEmailData.to_email);
          }

          const defaultCC = data.filter((email) =>
            savedEmailData.cc_email.includes(email.email)
          );

          const defaultCCEmails = defaultCC.map((cc) => cc.email);

          const defaultCCTextfields = savedEmailData.cc_email.filter(
            (email) => !defaultCCEmails.includes(email)
          );

          console.log("defaultCCTextfields", defaultCCTextfields);

          setCCFields(defaultCC);
          setCcAddresses(defaultCCTextfields);

          console.log(data);
        } else if (response.status === 404) {
          setShowTofield(false);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleChangeRemark = (e) => {
    setRemark(e.target.value);
  };

  const handlechangeTofileld = (event, value) => {
    setToFields(value);
    setToField1(null);
    setError({ ...error, toFields: !value });
  };

  const handlechangeTofileld1 = (e) => {
    setToField1(e.target.value);
    setToFields(null);
    setError({ ...error, toField1: !e.target.value });
  };

  const AddToAddress = () => {
    setShowTofield(!showTofield);
    setToFields(null);
    setToField1(null);
  };

  const handleccchange = (event, value) => {
    setCCFields(value);
  };

  // add 1 more cc text field
  const addTextField = () => {
    setCcAddresses([...ccAddresses, ""]);
  };

  const handleCcAddressChange = (index, value) => {
    const updatedCcAddresses = [...ccAddresses];
    updatedCcAddresses[index] = value;
    setCcAddresses(updatedCcAddresses);
  };

  const ccAddress = ccFields.map((i) => i.email);
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const handleSubmit = async () => {
    const isToField1Invalid = !toField1 || !emailRegex.test(toField1);
    const isToFieldsInvalid =
      !toFields || !emailRegex.test(toFields?.email || "");

    if (isToField1Invalid && isToFieldsInvalid) {
      setError({
        toField1: isToField1Invalid,
        toFields: isToFieldsInvalid,
      });

      showSnackbar("Please Add a Valid Receiver Address", "error");
      return;
    }

    const requestData = {
      user_id: parseInt(userId),
      filter: selectedData?.filterfor,
      from_date: selectedData?.fromDate || null,
      to_date: selectedData?.toDate || null,
      report_for: selectedData?.trainerName?.user_id || null,
      remark: remark,
      email_to: toFields?.email || toField1,
      email_cc: [...ccAddress, ...ccAddresses],
    };

    const requestData2 = {
      user_id: parseInt(userId),

      to_email: toFields?.email || toField1,
      cc_email: [...ccAddress, ...ccAddresses],
    };

    try {
      setIsloading(true);
      const response = await fetch(
        `${API_ENDPOINT}reports/trainerautosendreport`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        showSnackbar("Report Sent Successfully");
        setIsloading(false);

        const response = await fetch(
          `${API_ENDPOINT}saveemailforreports/saveemailbyuserid`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData2),
          }
        );

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else if (response.status === 400) {
        showSnackbar("Invalid filter date range", "error");

        setIsloading(false);
      } else if (response.status === 404) {
        showSnackbar("User not found", "error");
        setIsloading(false);
      } else if (response.status === 403) {
        setIsloading(false);
        showSnackbar("Unknown User", "error");
      } else if (response.status === 500) {
        setIsloading(false);
        showSnackbar("Failed to fetch data", "error");
      } else {
        setIsloading(false);
        showSnackbar("Error", "error");
        showSnackbar("Error", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      setIsloading(false);
    }
  };

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Report Sending .....
        </Typography>
        <CircularProgress color="secondary" />
      </Backdrop>
      <Grid container spacing={3}>
        {!showTofield && (
          <Grid item xs={12}>
            <Typography>
              TO{" "}
              <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "2px",
                  fontSize: "1.3em",
                }}
              >
                *
              </span>
            </Typography>

            <Autocomplete
              size="small"
              fullWidth
              value={toFields}
              options={userlist}
              onChange={handlechangeTofileld}
              disableCloseOnSelect
              getOptionLabel={(option) => option.email}
              renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={key} {...optionProps}>
                    {`${option.first_name ? option.first_name : ""} ${
                      option.last_name ? option.last_name : ""
                    } :- ${option.email}`}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>
        )}
        {showTofield && (
          <Grid item xs={12}>
            {" "}
            <Typography>
              TO{" "}
              <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "2px",
                  fontSize: "1.3em",
                }}
              >
                *
              </span>
            </Typography>
            <TextField
              placeholder="Email TO"
              fullWidth
              size="small"
              value={toField1}
              onChange={handlechangeTofileld1}
              InputProps={{ style: { borderRadius: "30px" } }}
              error={error.toField1}
              helperText={error.toField1 && "Valid Email is required"}
            />
          </Grid>
        )}
        <Grid item xs={12} textAlign="right">
          <Button
            size="small"
            variant="contained"
            color="primary"
            style={{ borderRadius: "24px" }}
            onClick={AddToAddress}
          >
            {!showTofield ? "Add TO Address" : "Select TO Address "}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography>CC</Typography>

          <Autocomplete
            multiple
            size="small"
            fullWidth
            value={ccFields}
            options={userlist}
            onChange={handleccchange}
            disableCloseOnSelect
            getOptionLabel={(option) => option.email}
            renderOption={(props, option, { selected }) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key} {...optionProps}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {`${option.first_name} ${
                    option.last_name ? option.last_name : ""
                  } :- ${option.email}`}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { borderRadius: "15px" }, // Add borderRadius here
                }}
              />
            )}
          />
        </Grid>

        {ccAddresses.map((value, index) => (
          <Grid item xs={12}>
            <TextField
              size="small"
              key={index}
              id={`outlined-basic-${index}`}
              label={`Email CC`}
              variant="outlined"
              fullWidth
              value={value}
              onChange={(e) => handleCcAddressChange(index, e.target.value)}
              sx={{ marginTop: index === 0 ? "10px" : "5px" }}
              InputProps={{
                style: {
                  borderRadius: "30px",
                },
              }}
            />
          </Grid>
        ))}

        <Grid item xs={12} textAlign="right">
          <Button
            size="small"
            variant="contained"
            color="primary"
            style={{ borderRadius: "24px", marginTop: "5px" }}
            onClick={addTextField}
          >
            Add CC Address
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography>Remark </Typography>
          <TextField
            placeholder="Type Something...."
            value={remark}
            onChange={handleChangeRemark}
            multiline
            fullWidth
            InputProps={{
              style: { borderRadius: "15px" }, // Add borderRadius here
            }}
            rows={4}
          />
        </Grid>

        <Grid item xs={12} textAlign="end">
          <Button variant="contained" color="secondary" onClick={handleSubmit}>
            {" "}
            Send Email{" "}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
