import {
  Backdrop,
  CircularProgress,
  Grid,
  Paper,
  Select,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { formatTime } from "../../../../../utils/formatTime";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function ViewClassDetails({selectedId}) {
  const [trainerData, setTrainerData] = useState([]);
  const [loading, setLoading] = useState(false);

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    marginTop: "20px",
    "& .MuiTableCell-head": {
      backgroundColor: "#abf7b1",
      fontWeight: 600,
      padding: "8px 16px",

      borderRight: "0.1px solid #C3C3C3",
      borderLeft: "0.1px solid #C3C3C3",
      color: "#000",
    },
    "& .MuiTableCell-body": {
      fontSize: "14px",
      fontWeight: 600,
      borderRight: "0.1px solid #C3C3C3",
      borderLeft: "0.1px solid #C3C3C3",
      align: "center",
      padding: "8px 16px",
     
    },
  }));


// Fetch Employee Name
useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}class/prevclassdetail/${selectedId}`);
        if (response.ok) {
          const data = await response.json();
          setTrainerData(data);
          setLoading(false)
        } else {
            setLoading(false)
          throw new Error(`Request failed with status: ${response.status}`);
        
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false)
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {/* <Grid container spacing={1.5}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4">Slots</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h5">Slots</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4">Test Type</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h5">Test Type</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h4">Trainer Name</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h5">Trainer Name</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h4">Class Capacity</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h5">Class Capacity</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h4">Active Students</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h5">Active Students</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h4">Seat Available</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h5">Seats Available</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4"> Trainer History</Typography>
        </Grid>
      </Grid> */}

      <Grid container>
        <Grid item xs={12} container>
          <Typography variant="h4" mr={1}>
            Class Name:{" "}
          </Typography>
          <Typography>{trainerData?.class?.name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledTableContainer
            component={Paper}
            style={{ maxHeight: "300px" }}
          >
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>New Trainer Name</TableCell>
                  <TableCell>Previous Trainer</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Time</TableCell>
                  
                 
                  <TableCell>Updated By</TableCell>
                </TableRow>
              </TableHead>

              {loading ? (
                <Backdrop open={loading} style={{ zIndex: 9999 }}>
                  <Stack direction="row" spacing={1}>
                    <Typography
                      variant="h4"
                      color="secondary"
                      fontWeight={800}
                      marginRight={2}
                    >
                      Fetching
                    </Typography>
                    <CircularProgress color="primary" />
                  </Stack>
                </Backdrop>
              ) : trainerData?.prev_detail?.length === 0 ? (
                <Grid item xs={12}>
                  <Typography variant="h5">No data available</Typography>
                </Grid>
              ) : (
                trainerData?.prev_detail?.map((row, index) => (
                  <>
                    <TableBody>
                      <TableRow key={row.id}>
                       <TableCell>{row?.new_trainer_id?.first_name} {row?.new_trainer_id?.last_name}</TableCell>
                       <TableCell>{row?.prev_trainer_id?.first_name} {row?.prev_trainer_id?.last_name}</TableCell>
                      <TableCell>{row?.created_date}</TableCell>
                      <TableCell>{formatTime(  row?.time)}</TableCell>
                      <TableCell>{row?.updated_by?.first_name} {row?.updated_by?.last_name}</TableCell>
                      </TableRow>
                    </TableBody>
                  </>
                ))
              )}
            </Table>
          </StyledTableContainer>
        </Grid>
      </Grid>
    </>
  );
}
