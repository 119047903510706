import {
  Box,
  Button,
  Chip,
  Grid,
  styled,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Backdrop,
  CircularProgress,
  Tooltip,
  IconButton,
  Switch,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import { DataGrid } from "@mui/x-data-grid";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CustomPopup from "../../../components/CustomPopup";
import AddEmployeeStatus from "./AddEmployeeStatus";
import EditEmployeeStatus from "./EditEmployeeStatus";
import EditIcon from "@mui/icons-material/Edit";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { useGetTokenOrLogout } from "../../../utils/token";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function AllEmployeeStatus() {
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const [employeeStatusData, setEmployeeStatusData] = useState([]);
  const [searchData, setSearchData] =useState([])
  const [openAddPopup, setOpenAddPoup] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [isLoading, setIsloading] = useState(false);
  const [statusLoading, setStatusLoading] =useState()
  const [openEditPopup, setOpenEditPoup] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [checkedState, setCheckedState] = useState({});

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [selectedUser, setSelectedUser] = useState(null);

  console.log("checkedState", checkedState);

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginateddata = employeeStatusData.slice(startIndex, endIndex);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOpenPopup = () => {
    setOpenAddPoup(true);
  };

  const handleclosePopup = () => {
    setOpenAddPoup(false);
  };

  const handleOpenEditPopup = (row) => {
    setOpenEditPoup(true);
    setSelectedData(row);
  };

  const handlecloseEditPopup = () => {
    setOpenEditPoup(false);
  };
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Available</Box>
      </StyledGridOverlay>
    );
  }

  useEffect(() => {
    setIsloading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
   
    };
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}branch-testtype-wise-advisor` ,{headers}
        );
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          console.log(data);
          setEmployeeStatusData(sortedData);
          setSearchData(sortedData)
          setIsloading(false);
            const initialState = sortedData.reduce((acc, row) => {
            acc[row.id] = row.is_active === 1;
            return acc;
          }, {});
          setCheckedState(initialState);
        }  else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch (error) {
        navigate("/page500");
      }
    };

    fetchData();
  }, []);

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({}));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: "#abf7b1",
    fontWeight: 600,
  }));

  const ActionButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: "#E5E4E2",
  }));

  // Handle the switch change for a specific row
  const handleChange = async (event, id, row) => {
    const isChecked = event.target.checked;
    console.log("updatedData", isChecked);
    setCheckedState((prevState) => ({
      ...prevState,
      [id]: isChecked, // Update the state for the specific row ID
    }));

    try {
      const requestData = {
        is_active: isChecked,
        user_id: parseInt(userId),
      };
      const response = await fetch(
        `${API_ENDPOINT}branch-testtype-wise-advisor/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      if (response.ok) {
        showSnackbar(
          isChecked === true
            ? "User acount is activated successfully"
            : "User acount is deactivated successfully",
          "success"
        );

        const updatedData = employeeStatusData.map((item) =>
          item?.id === row.id
            ? { ...item, is_active: isChecked === true ? 1 : 0 }
            : item
        );

        setEmployeeStatusData(updatedData);
      } else if (response.status === 401) {
        showSnackbar("Unknown User", "error");
      } else if (response.status === 404) {
        showSnackbar("Error to Updating Status", "error");
        setCheckedState((prevState) => ({
          ...prevState,
          [id]: !isChecked, // Revert the state if the request fails
        }));
      } else if (response.status === 401) {
        showSnackbar("Unathorized User", "error");
      } else {
        showSnackbar("Error to Updating Status", "error");
        setCheckedState((prevState) => ({
          ...prevState,
          [id]: !isChecked, // Revert the state if the request fails
        }));
        throw new Error("Error to Updating Status");
      }
    } catch (error) {
      setCheckedState((prevState) => ({
        ...prevState,
        [id]: !isChecked, // Revert the state if the request fails
      }));
      showSnackbar("Error to Updating Status", "error");
    }
  };


    // Search By Name
    const handleSearchByName = (e) => {
      const value = e.target.value;
      let filteredData1 = searchData.filter((data) =>
        `${data?.advisor_name?.first_name} ${data?.advisor_name?.last_name}`
          ?.toLowerCase()
          .replace(/\s+/g, "")
          .includes(value.toLowerCase().replace(/\s+/g, ""))
      );
      setEmployeeStatusData(filteredData1);
    };

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching
        </Typography>
        <CircularProgress color="secondary" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Chip
            variant="contained"
            sx={{
              background: "white",
              fontSize: "20px",
              fontWeight: 600,
              padding: "10px",
            }}
            label="Auto-Assign Advisor"
          />
        </Grid>
        <Grid item xs={12} sm={6} textAlign="end">
          <Button
            startIcon={<ControlPointIcon fontSize="small" color="orange" />}
            variant="contained"
            style={{
              background: "white",
              fontSize: "14px",
              fontWeight: 600,
              color: "black",
              borderRadius: "25px",
            }}
            size="small"
            onClick={handleOpenPopup}
          >
            {" "}
            Add Auto-Assign Advisor
          </Button>
        </Grid>

        <Grid item xs={12}>
          <MainCard>

<Grid container spacing={2}>
  <Grid item xs={12} textAlign='end' >
    <Box sx={{display:"flex", alignItems:'center', justifyContent:'flex-end'}}>
  <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                  Search:{"  "}
                </Typography>
                <TextField
                  // value={value}
                  onChange={handleSearchByName}
                  size="small"
                  InputProps={{ style: { borderRadius: "15px" } }}
                />
                </Box>
  </Grid>


<Grid item xs={12}>
            <StyledTableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Action</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Branch</StyledTableCell>
                    <StyledTableCell>Sub Service</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginateddata?.length === 0 ? (
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h5" textAlign="center">
                        No data available
                      </Typography>
                    </Grid>
                  ) : (
                    paginateddata.map((row, index) => (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#f0f0f0" : "inherit",
                        }}
                      >
                        <TableCell style={{ width: "130px" }}>
                          <ActionButton
                            onClick={() => handleOpenEditPopup(row)}
                            style={{ marginLeft: "7px" }}
                          >
                            <Tooltip title="Edit">
                              <EditIcon
                                fontSize="small"
                                sx={{ color: "#2196F3" }}
                              />
                            </Tooltip>
                          </ActionButton>

                          <Tooltip
                            title={
                              row.is_active === 0
                                ? "Make Active"
                                : "Make Inactive"
                            }
                          >
                            <Switch
                              checked={!!checkedState[row.id]}
                              onChange={(event) =>
                                handleChange(event, row.id, row)
                              }
                              size="small"
                              color="secondary"
                            />
                          </Tooltip>
                        </TableCell>
                        <TableCell component="th" scope="row" style={{width:"160px"}}>
                          {row.advisor_name.first_name}{" "}
                          {row.advisor_name.last_name}
                        </TableCell>
                        <TableCell>{row.branch_id.join(", ")}</TableCell>
                        <TableCell>{row.sub_service_id.join(", ")}</TableCell>
                        <TableCell>
                          {row?.is_active === 1 ? (
                            <Chip
                              color="secondary"
                              label="Active"
                              size="small"
                            />
                          ) : (
                            <Chip color="error" label="Inactive" size="small" />
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={employeeStatusData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>

      <CustomPopup
        open={openAddPopup}
        onClose={handleclosePopup}
        component={<AddEmployeeStatus onClose={handleclosePopup} />}
        title="Add Auto-Assign Advisor"
        maxWidth="sm"
        showDivider={true}
      />

      <CustomPopup
        open={openEditPopup}
        onClose={handlecloseEditPopup}
        component={
          <EditEmployeeStatus
            onClose={handlecloseEditPopup}
            selectedData={selectedData}
          />
        }
        title="Edit Auto-Assign Advisor"
        maxWidth="sm"
        showDivider={true}
      />

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
