// Author : Ayesha Shaikh
//  Date : 02 Dec 2024

import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useGetTokenOrLogout } from "../../../utils/token";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function AddEmployeeTarget() {
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem("userId");
  const [advisorList, setAdvisorList] = useState([]);
  const [advisorName, setAdvisorName] = useState(null);

  const [monthList, setMonthList] = useState([]);
  const [monthname, setMonthName] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}advisor`);
        if (response.ok) {
          const data = await response.json();

          setAdvisorList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}month`);
        if (response.ok) {
          const data = await response.json();

          setMonthList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  console.log(advisorList);

  const handleemployeeChange = (event, value) => {
    setAdvisorName(value);
    setValue("adv_user_id", value?.user_id);
  };

  const handleMonthChange = (event, value) => {
    setMonthName(value);
    setValue(
      "month_id",
      value.map((i) => i.id)
    );
  };

  const onSubmit = async (data) => {
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const requestData = {
        ...data,
        user_id: parseInt(userId),
      };
      const response = await fetch(`${API_ENDPOINT}advisor-rev-target`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });
      if (response.ok) {
        showSnackbar("Employee Target Added Successfully", "success");

        setTimeout(() => {
         window.location.reload()
        }, 1500);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        showSnackbar("Error in add employee target",'error');
      }
    } catch (error) {
      showSnackbar("Error in add employee target",'error');
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography>
            {" "}
            Employee Name{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.2em",
              }}
            >
              *
            </span>
          </Typography>
          <Controller
            name="adv_user_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                size="small"
                value={advisorName}
                onChange={handleemployeeChange}
                options={advisorList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : " "} ${
                    option.last_name ? option.last_name : " "
                  }`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "14px" }, // Add borderRadius here
                    }}
                    error={errors.adv_user_id}
                    helperText={
                      errors.adv_user_id && "Employee name is required"
                    }
                  />
                )}
              />
            )}
          ></Controller>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography>
            Add Target{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.2em",
              }}
            >
              *
            </span>
          </Typography>

          <Controller
            name="rev_target"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                size="small"
                {...field}
                InputProps={{
                  style: { borderRadius: "14px", height: "37px" },
                }}
                onChange={(e) =>
                  field.onChange(parseInt(e.target.value, 10) || 0)
                } // Ensure it's an integer
                fullWidth
                error={errors.rev_target}
                helperText={errors.rev_target && "Target is required"}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography>
            {" "}
            Select Month{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.2em",
              }}
            >
              *
            </span>
          </Typography>
          <Controller
            name="month_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                size="small"
                multiple
                value={monthname}
                onChange={handleMonthChange}
                options={monthList}
                getOptionLabel={(option) => option.month_full_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "14px" }, // Add borderRadius here
                    }}
                    error={errors.month_id}
                    helperText={errors.month_id && "Month is required"}
                  />
                )}
              />
            )}
          ></Controller>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography>
            Year{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.2em",
              }}
            >
              *
            </span>
          </Typography>

          <Controller
            name="year"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                size="small"
                type="number"
                {...field}
                InputProps={{
                  style: { borderRadius: "14px", height: "37px" },
                }}
                fullWidth
                error={errors.year}
                helperText={errors.year && "Year is required"}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} textAlign="end">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
