import React, { useEffect, useState } from 'react'
import MainCard from '../../../components/Maincard'
import { Autocomplete, Button, Checkbox, Collapse, FormControl, Grid, ListItemText, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { useGetTokenOrLogout } from '../../../utils/token';
import { useNavigate } from 'react-router-dom';
export default function ApproveCashDepositeFilter({  setPayments,
  isLoading,
  showSnackbar,
  setIsLoading,
  originalData,}) {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };
    const [open,setOpen]=useState(false)
    const handleToggle=()=>{
        setOpen(!open)
    }
    const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
    const userId = localStorage.getItem("userId");
    const userRole=localStorage.getItem('userRole')
    const [name,setName] =useState("")
 const [amount,setAmount] =useState("")
 const [createdbylist, setCreatedbylist] = useState([]);
 const [collectedby, setCollectedby] = useState([]);
 const [createfrom, setCreatedfrom] = useState("");
 const [createdto, setCreatedto] = useState("");
 const [branchlist, setBranchlist] = useState([]);
 const [branch, setBranch] = useState([]);
 const [uploadedby,setUploadedby] =useState([])
 const [status, setStatus] =useState([])
    const handleNamechange=(e)=>{
        setName(e.target.value)
    }

    const handleAmountchange=(e)=>{
        setAmount(e.target.value)
    }

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`${API_ENDPOINT}route/getalluser`);
          if (response.ok) {
            const data = await response.json();
            setCreatedbylist(data);
          } else {
            throw new Error(`Request failed with status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData();
    }, []);
  
    const allcreatedByList=[{first_name:"Select All"},...createdbylist]
  const handelcretedby = (event,value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setCollectedby(createdbylist);
    } else {
      setCollectedby(value);
    }
  };

  const handelUploadedby = (event,value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setUploadedby(createdbylist);
    } else {
      setUploadedby(value);
    }
  };
    const handlecratedfrom = (e) => {
      setCreatedfrom(e.target.value);
    };
  
    const handlecreatedto = (e) => {
      setCreatedto(e.target.value);
    };
      
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`${API_ENDPOINT}branch`);
          if (response.ok) {
            const data = await response.json();
  
            setBranchlist(data);
          } else {
            throw new Error(`Request failed with status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData();
    }, []);
    const allbranchList=[{branch_name:"Select All"},...branchlist]

    const handelbranchchange = (event,value) => {
      if (value.some((option) => option.branch_name === "Select All")) {
        setBranch(branchlist);
      } else {
        setBranch(value);
      }
    };

    const statuslist = [
      { id: 1, name: "Generated", value: "received" },
      {
        id: 2,
        name: "Confirmed",
        value: 0,
      },
      {id:3, name:"Deposited",value:"deposited"},
      {id:4, name:"Uploaded", value:1},
    ];

    const handlestatuschange = (event,value) => {
      setStatus(value);
    };

    const  newBranch=branch.map((i)=>i.id)
const newCollectedBy=collectedby.map((i)=>i.id)
const newStatus=status.map((i)=>i.value)
const newUploadedBy=uploadedby.map((i)=>i.id)
const handleApplyFilter = async (event) => {
  event.preventDefault();
  const token = getTokenOrLogout();
  if (!token) return;
  let headers = { 
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json" // Make sure to keep the content type
  };
  setIsLoading(true);
  const requestData = {
    from_date:createfrom || null,





  
    to_date:createdto || null,
 
    uploaded_by:newUploadedBy,
    user_id : parseInt(userId)
  };

  try {
    const response = await fetch(`${API_ENDPOINT}route2.0/approvedepositslipfilter`, {
      method: "POST",
      headers,
      body: JSON.stringify(requestData),
    });

    if (response.ok) {
      const result = await response.json();

      const sortedData = result.sort(
        (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
      );
      // const filterdData = sortedData.filter(
      //   (item) =>
      //     item.collected_status === "confirmed"

      // );
      setPayments(sortedData);
      setIsLoading(false);
    }  else if (response.status === 404) {
      navigate("/page404");
    } else if (response.status === 401) {
      navigate("/page401");
    } else if (response.status === 403) {
      navigate("/page403");
    } else if (response.status === 440) {
      navigate("/page440");
    } else {
      navigate("/page500");
    }}
      catch{
        navigate("/page500");
      }
};

      const resetfilter = () => {
     setPayments(originalData)
        setName("")
        setAmount("")
       setCollectedby([])
 

       setUploadedby([])
       setStatus([])
      }
    

          // Add default 1 month start and end date
const formatDate = (date) => date.toISOString().split('T')[0];
useEffect(() => {
  let oneMonthAgo = new Date();
  oneMonthAgo.setDate(oneMonthAgo.getDate()-30);
  
  
  let today=new Date()
 
  setCreatedto(formatDate(today));
  setCreatedfrom(formatDate(oneMonthAgo));
}, []);
  return (
  <>
  <MainCard>
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              marginLeft: "5px",
              fontSize: "24px",
              fontWeight: 700,
            }}
          >
           Approve Deposit Slips
          </Typography>
          <FilterListOutlinedIcon onClick={handleToggle} fontSize="medium" />
        </Stack>

        <Collapse in={open} sx={{marginTop:'10px'}}>
        <Grid container spacing={2}>
            {/* <Grid item xs={12} lg={3} sm={6} md={6}>
            <Typography sx={{ fontWeight: 600 }}>Enquiry Name</Typography>
                <TextField
                size='small'
                value={name}
                onChange={handleNamechange}
                  InputProps={{
                    style: { borderRadius: "15px" },
                  }}
                  fullWidth
                />
            </Grid> */}

      
            {/* <Grid item xs={12} lg={3} sm={6} md={6}>
            <Typography sx={{ fontWeight: 600 }}>Amount Paid</Typography>
                <TextField
                 size='small'
                value={amount}
                onChange={handleAmountchange}
                  InputProps={{
                    style: { borderRadius: "15px" },
                  }}
                  fullWidth
                  type="number"
                />
            </Grid> */}

          

            <Grid item xs={12} lg={3} sm={6} md={6}>
            <Typography sx={{ fontWeight: 600 }}>Payment From Date </Typography>
            <TextField
             size='small'
              value={createfrom}
              onChange={handlecratedfrom}
              type="date"
              InputProps={{
                style: { borderRadius: "15px" },
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={3} sm={6} md={6}>
            <Typography sx={{ fontWeight: 600 }}>Payment To Date </Typography>
            <TextField
             size='small'
              value={createdto}
              onChange={handlecreatedto}
              type="date"
              InputProps={{
                style: { borderRadius: "15px" },
              }}
              fullWidth
            />
          </Grid>

          {/* {(userRole==="admin" || userRole==="center_admin" ||userRole==="accountant"  ) && ( 
          <Grid item xs={12} lg={3} sm={6} md={6}>
        
        <Typography sx={{ fontWeight: 600 }}>Collected By </Typography>
   
    <Autocomplete
        size="small"
        fullWidth
        value={collectedby}
        multiple
        onChange={handelcretedby}
        options={allcreatedByList}
        getOptionLabel={(option) =>
        `${option.first_name ? option.first_name : ""} ${
            option.last_name ? option.last_name : ""
          }`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { borderRadius: "15px" }, // Add borderRadius here
            }}
          />
        )}
      />
    </Grid>
          )} */}
         

         {(userRole==="admin" || userRole==="center_admin" ||userRole==="accountant"  ) && (
    <Grid item xs={12} lg={3} sm={6} md={6}>
        
        <Typography sx={{ fontWeight: 600 }}>Uploaded  By </Typography>
   
    <Autocomplete
        size="small"
        fullWidth
        value={uploadedby}
        multiple
        onChange={handelUploadedby}
        options={allcreatedByList}
        getOptionLabel={(option) =>
        `${option.first_name ? option.first_name : ""} ${
            option.last_name ? option.last_name : ""
          }`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { borderRadius: "15px" }, // Add borderRadius here
            }}
          />
        )}
      />
    </Grid>
         )}

            
            {/* <Grid item xs={12} lg={3} md={6} sm={6}>
           
           <Typography sx={{ fontWeight: 600 }}>Branch</Typography>

          
     
     <Autocomplete
           size="small"
           fullWidth
           value={branch}
           multiple
           onChange={handelbranchchange}
           options={allbranchList}
           getOptionLabel={(option) =>
            option.branch_name
           }
           renderInput={(params) => (
             <TextField
               {...params}
               InputProps={{
                 ...params.InputProps,
                 style: { borderRadius: "15px" }, // Add borderRadius here
               }}
             />
           )}
         />
       </Grid> */}

         {/* <Grid item xs={12} lg={3} md={6} sm={6}>
           
                <Typography sx={{ fontWeight: 600 }}>Status</Typography>
                
             <Autocomplete
                size="small"
                fullWidth
                value={status}
                multiple
                onChange={handlestatuschange}
                options={statuslist}
                getOptionLabel={(option) =>
                 option.name
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={12} lg={3} mt={3}>
              <Button
                variant="contained"
                color="error"
                style={{
                  borderRadius: "24px",
                  fontWeight: 500,
                  fontSize: "14px",
                  marginRight: "8px",
                }}
                 onClick={resetfilter}
              >
                Reset Filter
              </Button>

              <Button
                variant="contained"
                color="primary"
                style={{
                  borderRadius: "24px",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
                 onClick={handleApplyFilter}
              >
                Apply Filter
              </Button>
            </Grid>
        </Grid>
        </Collapse>
        </MainCard>
  </>
  )
}
